import React, { useEffect, useState } from 'react';
import { shopServices } from 'api/services';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppState } from 'store/store';
import './AboutCompanyPage.sass';

export const AboutCompanyPage = () => {
  const [shopDescription, setShopDescription] = useState('');
  const [shopTitle, setShopTitle] = useState('');
  const { user } = useSelector((state: AppState) => state.profileReducer);
  const history = useNavigate();
  useEffect(() => {
    getShop();
  }, []);

  const getShop = async () => {
    if (!user?.shopId) {
      history('/');
      return;
    }
    const res = await shopServices
      .getShopById(user?.shopId)
      .then((res) => res.data);
    setShopTitle(res.name);
    const phone = res.phone?.replace('+', '').split('');
    let code = '';
    if (!!phone?.length) {
      code = `+${phone[0]}${phone[1]}${phone[2]} ${phone[3]}${phone[4]} ${phone[5]}${phone[6]}${phone[7]} ${phone[8]}${phone[9]} ${phone[10]}${phone[11]}`;
    }
    setShopDescription(
      `${!!res?.address?.length ? res?.address + ',' : ''} ${
        !!res.account?.length ? 'р/с' + ' ' + res.account : ''
      } ${!!res.bank?.length ? 'в' + ' ' + res.bank + ',' + '<br/>' : ''} ${
        !!res.mfo?.length ? 'МФО' + ' ' + res.mfo + ',' : ''
      }  ${!!res.tin?.length ? 'ИНН' + ' ' + res.tin : ''} ${
        !!code.length ? 'тел:' + ' ' + code + '<br/>' : ''
      } ${
        !!res?.additionalInformation?.length ? res.additionalInformation : ''
      }`
    );
  };

  return (
    <div className='about-company-page'>
      <div className='about-company-page__title'>{shopTitle}</div>
      <div
        className='about-company-page__description'
        dangerouslySetInnerHTML={{ __html: shopDescription }}
      ></div>
    </div>
  );
};
