export enum TemplateType {
  GET_BREADCRUBS = '@template/GET_BREADCRUMBS',
  SHOW_MODAL = '@template/SHOW_MODAL',
  HIDE_MODAL = '@template/HIDE_MODAL',
  WITH_MODAL = '@template/WITH_MODAL',
  WITH_HASHES = '@template/WITH_HASHES',
}

export interface IGetBreadCrumb {
  type: TemplateType.GET_BREADCRUBS;
}

export interface IShowModal {
  type: TemplateType.SHOW_MODAL;
}

export interface IHideModal {
  type: TemplateType.HIDE_MODAL;
}

export interface IHaveModal {
  type: TemplateType.WITH_MODAL;
}

export interface IWithHas {
  type: TemplateType.WITH_HASHES;
}

export interface IBreadCrumb {
  title: string;
  id?: number | string;
}

export interface IState {
  breadCrumbs: IBreadCrumb[];
  showModal: boolean;
  haveModal: boolean;
  hashes: string[] | null;
}
