import { CouponType } from 'api/services/coupons/types';

export const checkCount = (type: CouponType) => {
  return (
    type === CouponType.COMMENTS ||
    type === CouponType.FAVORITE_SHOPS ||
    type === CouponType.COUNT_ORDER_SUM ||
    type === CouponType.REFERRAL_LINK
  );
};

export const checkCouponType = (type: CouponType) => {
  return type === CouponType.COUNT_ORDER_SUM || type === CouponType.ORDER_SUM;
};
