import {
  AttributeType,
  AttributeViewType,
} from 'api/services/attributes/types';
import { CommentStatus } from 'api/services/comments/types';
import { CouponType } from 'api/services/coupons/types';
import { TransactionType } from 'api/services/transactions/types';
import { MarginType } from 'enums/marginType';
import { OrderStatus } from 'enums/orderStatus';
import { UserRole } from 'enums/users';
import { VariantStatusTypes } from 'enums/variant-status';

export const translateMarginTypes = (marginType: MarginType): string => {
  switch (marginType) {
    case MarginType.FLAT:
      return 'Фиксированаая';
    default:
      return 'Процент';
  }
};

export const translateAttributeViewType = (type: AttributeViewType): string => {
  switch (type) {
    case AttributeViewType.CHECKBOX:
      return 'Флажки';
    case AttributeViewType.RADIO:
      return 'Радиокнопки';
    case AttributeViewType.SWITCH:
      return 'Тумблер';
    case AttributeViewType.SELECT:
      return 'Выпадающий список';
    case AttributeViewType.COLOR:
      return 'Цвет';
    default:
      return '';
  }
};

export const translateUserRole = (role: UserRole): string => {
  switch (role) {
    case UserRole.ADMIN:
      return 'Администратор';
    case UserRole.CONTENT_MANAGER:
      return 'Контент менеджер';
    case UserRole.COURIER:
      return 'Курьер';
    case UserRole.SALES_MANAGER:
      return 'Менеджер по продажам';
    case UserRole.SHOP_EMPLOYEE:
      return 'Сотрудник магазина';
    case UserRole.SHOP_OWNER:
      return 'Владелец магазина';
    case UserRole.USER:
      return 'Пользователь';
    default:
      return 'Супер пользователь';
  }
};

export const translateAttributeType = (type: AttributeType): string => {
  switch (type) {
    case AttributeType.NUMBER:
      return 'Число';
    case AttributeType.TEXT:
      return 'Строка';
    default:
      return 'Список';
  }
};

export const translateRole = (role?: UserRole) => {
  switch (role) {
    case UserRole.SU:
      return 'Администратор';
    default:
      return 'Пользователь';
  }
};

// export const translateBannerType = (type: BannerTypes): string => {
//   switch (type) {
//     case BannerTypes.SQUARE:
//       return 'Квадратный';
//     case BannerTypes.SLIDER:
//       return 'Слайдер';
//     case BannerTypes.SHORT_FULL_WIDTH:
//       return 'Полная ширина';
//     case BannerTypes.ACTION:
//       return 'Действующий';
//     case BannerTypes.LIQUIDATION_SQUARE:
//       return 'Ликвидация квадртаный';
//     case BannerTypes.LIQUIDATION_RECTANGLE:
//       return 'Ликвидация прямоугольный';
//     case BannerTypes.AFFORDABLE_SQUARE:
//       return 'Доступная площадь';
//     default:
//       return 'Доступная полная ширина';
//   }
// };

export const translateOptionStatus = (status: VariantStatusTypes): string => {
  switch (status) {
    case VariantStatusTypes.UNDER_CONSIDERATION:
      return 'На рассмотрении';
    case VariantStatusTypes.DELETED:
      return 'Удален';
    case VariantStatusTypes.VERIFIED:
      return 'Подтвержден';
    case VariantStatusTypes.REJECTED:
      return 'Отменен';
    default:
      return '';
  }
};

export const translateOrderStatus = (status: OrderStatus): string => {
  switch (status) {
    case OrderStatus.ACCEPTED:
      return 'Принят';
    case OrderStatus.CANCELED:
      return 'Отменен';
    case OrderStatus.COMPLETED:
      return 'Завершено';
    case OrderStatus.DELIVERED:
      return 'Доставлен';
    case OrderStatus.NEW:
      return 'Обрабатывается';
    case OrderStatus.ON_THE_WAY:
      return 'Передан курьеру';
      return '';
  }
};

export const translateCommentStatus = (status: CommentStatus) => {
  if (status === CommentStatus.NEW) return 'Новый';
  return 'Подтвержденный';
};

export const translatePayment = (type: TransactionType) => {
  switch (type) {
    case TransactionType.CASH:
      return 'Наличные';
    default:
      return 'Payme';
  }
};

export const translateCouponType = (type: CouponType): string => {
  switch (type) {
    case CouponType.SIGN_UP:
      return 'При регистрации';
    case CouponType.FIRST_ORDER:
      return 'При заказе для нового пользователя';
    case CouponType.COMMENTS:
      return 'При написании N отзывов';
    case CouponType.ORDER_SUM:
      return 'При заказе на сумму превышающую N сум за Х период';
    case CouponType.COUNT_ORDER_SUM:
      return 'При Заказе N количества заказов превышающие X сум каждый за Y период';
    case CouponType.FAVORITE_SHOPS:
      return 'При добавлении N магазинов в избранные';
    case CouponType.REFERRAL_LINK:
      return 'Если зарегистрировались N пользователей по реферальной ссылке пользователя и сделали X заказ';
    default:
      return '';
  }
};
