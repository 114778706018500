import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreateBanner,
  IDeleteBanner,
  IGetBannerByid,
  IGetBanners,
  IUpdateBanner,
} from './types';

export const getBanners: IGetBanners = (params) =>
  instance.get(`/banners?${qs.stringify(params, { skipEmptyString: true })}`);

export const getBannerById: IGetBannerByid = (id) =>
  instance.get(`/banners/${id}`);

export const createBanner: ICreateBanner = (data) =>
  instance.post(`/banners`, data);

export const updateBanner: IUpdateBanner = (id, data) =>
  instance.put(`/banners/${id}`, data);

export const deleteBanner: IDeleteBanner = (id) =>
  instance.delete(`/banners/${id}`);
