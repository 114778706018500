import { fileServices } from 'api/services';
import { FILE_URL } from 'constants/project';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import cn from 'classnames';
import 'react-quill/dist/quill.snow.css';

import './HtmlEditor.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';

interface Props {
  onChange: (value: string) => void;
  value: string;
  label?: string;
  helperText?: string;
  classess?: string;
  error?: boolean;
}

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  },
};

export const HtmlEditor: React.FC<Props> = ({ value, onChange, error }) => {
  const quillRef = useRef<any>();
  const [isInit, setIsInit] = useState(false);
  const [editorModules, setEditorModules] = useState<any>();
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    setEditorModules({
      toolbar: {
        ...modules.toolbar,
        handlers: { image: uploadImageCallBack },
      },
    });
    setIsInit(true);
  }, []);

  const uploadImageCallBack = () => {
    const editor = quillRef.current.getEditor();

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      if (!input.files) return;
      const file = input.files[0];

      const formData = new FormData();
      try {
        formData.append('file', file);
        const res = await fileServices
          .uploadFile(formData)
          .then((res) => res.data);
        try {
          const link = `${FILE_URL}/${res?.userId}/${res.name}.${res.ext}`;
          editor.insertEmbed(editor.getSelection(), 'image', link);
        } catch (err) {
          showNotification(
            'Произошла ошибка при загрузке файла, попробуйте еще раз',
            NotificationStatus.WARNING
          );
        }
      } catch {}
    };
  };
  return (
    <>
      {isInit && (
        <ReactQuill
          ref={quillRef}
          className={cn('html-editor', { 'html-editor--error': error })}
          value={value}
          onChange={onChange}
          modules={editorModules}
        />
      )}
    </>
  );
};

export const HtmlEditor2: React.FC<Props> = ({ value, onChange, error }) => {
  const quillRef = useRef<any>();
  const [isInit, setIsInit] = useState(false);
  const [editorModules, setEditorModules] = useState<any>();
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    setEditorModules({
      toolbar: {
        ...modules.toolbar,
        handlers: { image: uploadImageCallBack },
      },
    });
    setIsInit(true);
  }, []);

  const uploadImageCallBack = () => {
    const editor = quillRef.current.getEditor();

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      if (!input.files) return;
      const file = input.files[0];

      const formData = new FormData();
      try {
        formData.append('file', file);
        const res = await fileServices
          .uploadFile(formData)
          .then((res) => res.data);
        try {
          const link = `${FILE_URL}/${res?.userId}/${res.name}.${res.ext}`;
          editor.insertEmbed(editor.getSelection(), 'image', link);
        } catch (err) {
          showNotification(
            'Произошла ошибка при загрузке файла, попробуйте еще раз',
            NotificationStatus.WARNING
          );
        }
      } catch {}
    };
  };
  return (
    <>
      {isInit && (
        <ReactQuill
          ref={quillRef}
          className={cn('html-editor', { 'html-editor--error': error })}
          value={value}
          onChange={onChange}
          modules={editorModules}
        />
      )}
    </>
  );
};
