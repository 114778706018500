import React, { useState } from 'react';
import { authServices } from 'api/services';
import logo from 'assets/images/logo.svg';
import { useDispatch } from 'react-redux';
import * as authActions from 'store/ducks/auth/actions';
import * as profileActions from 'store/ducks/profile/actions';
import { useNavigate } from 'react-router-dom';
import { checkEmail, checkPhone } from 'helpers/checkLogin';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ConfirmEmailForm } from 'components/ConfirmEmailForm/ConfirmEmailForm';
import cn from 'classnames';
import './AuthPage.sass';

export const AuthPage = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isNotConfirmed, setIsNotConfirmed] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!checkPhone(loginReplace()) && !checkEmail(login)) {
      showNotification('Не корректный логин', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    if (!login.trim() || password.length < 6) {
      showNotification(
        'Проверьте правильность заполнения данных',
        NotificationStatus.WARNING
      );
      setHasError(true);
      return;
    }
    try {
      const res = await authServices
        .signIn({ login: loginReplace(), password })
        .then((res) => res.data);
      if (res.user.role === 'USER') {
        showNotification('Нет доступа');
        return;
      }
      localStorage.setItem('accessToken', res.accessToken);
      dispatch(authActions.signIn(res.accessToken));
      dispatch(profileActions.getProfile(res.user));
      showNotification(
        'Авторизация прошла успешно',
        NotificationStatus.SUCCESS
      );
      history('/');
    } catch (e: any) {
      if (
        e.response.data.message === 'Email not confirmed' ||
        e.response.data.message === 'Phone not confirmed'
      ) {
        generateOtp();
        setIsNotConfirmed(true);
        return;
      }
      showNotification('Неверный логин или пароль');
    }
  };

  const generateOtp = async () => {
    try {
      await authServices.generateOtp({ login: loginReplace() });
      showNotification(
        'Код отправлен вам на почту',
        NotificationStatus.WARNING
      );
    } catch {
      showNotification('Произошла ошибка, попробуйте позже');
    }
  };

  const confirm = async (code: string) => {
    try {
      await authServices.confirmEmail({ login: loginReplace(), otp: code });
      showNotification(
        'Ваш email успешно подтвержден',
        NotificationStatus.SUCCESS
      );
      setHasError(true);
      setIsNotConfirmed(false);
    } catch {
      showNotification('Вы ввели неверный код');
    }
  };

  const loginReplace = () => {
    if (login[0] === '+') {
      return login.replace('+', '');
    }
    return login;
  };

  return (
    <div className='auth-page'>
      <div className='auth-page__container'>
        <img src={logo} alt='logo' className='auth-page__logo' />
        {isNotConfirmed ? (
          <ConfirmEmailForm confirm={confirm} generateOtp={generateOtp} />
        ) : (
          <form className='auth-page__form' onSubmit={submit}>
            <h4 className='auth-page__form-title'>Логин</h4>
            <input
              type='text'
              className={cn(
                'auth-page__form-field auth-page__form-field-login',
                { 'auth-page__form-field--error': hasError && !login.trim() }
              )}
              placeholder='Логин'
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
            <input
              type='password'
              className={cn('auth-page__form-field', {
                'auth-page__form-field--error': hasError && password.length < 6,
              })}
              placeholder='Пароль'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type='submit' className='auth-page__form-field-button'>
              Войти
            </button>
          </form>
        )}
      </div>
    </div>
  );
};
