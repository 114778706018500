import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppState } from 'store/store';

export const NotFoundPage = () => {
  const history = useNavigate();
  const token = localStorage.getItem('accessToken');
  const { user } = useSelector((state: AppState) => state.profileReducer);
  useEffect(() => {
    if (!token) {
      return history('/auth/sign-in');
    }
    if (!!token && !!user?.role) {
      history('/');
    }
  }, [!!token]);
  return <></>;
};
