import instance from 'api/instance';
import qs from 'query-string';
import {
  IConfirmComment,
  IGetComment,
  IGetCommentById,
  ICreateComment,
} from './types';

export const getComments: IGetComment = (attrs) =>
  instance.get(
    `/comments/admin?${qs.stringify(attrs, { skipEmptyString: true })}`
  );

export const getCommentById: IGetCommentById = (id) =>
  instance.get(`/comments/${id}`);

export const createComment: ICreateComment = (data) =>
  instance.post('/comments', data);

export const confirmComment: IConfirmComment = (id) =>
  instance.put(`/comments/${id}/status`);

export const deleteComment = (id: number) => instance.delete(`/comments/${id}`);
