import React from 'react';
import cn from 'classnames';
import './Field.sass';

interface Props {
  value: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  text?: string;
  error?: boolean;
  type: string;
  className: string;
  color?: 'gray';
  size?: 'l' | 'm';
  icon?: string;
  add?: () => void;
  label?: string;
}

const Field: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  text,
  error,
  type,
  className,
  color,
  size,
  label,
}) => {
  return (
    <div
      className={cn('field', {
        [className]: className,
        'field--error': error,
        'field--gray': color === 'gray',
        'field--l': size === 'l',
        'field--m': size === 'm',
      })}
    >
      <div className='field__in'>
        {label && <div className='field__label'>{label}</div>}
        <div className='field__row'>
          <input
            type={type}
            onChange={onChange}
            value={value}
            className='field__input'
            placeholder={placeholder}
          />
        </div>
      </div>

      <div className='field__text'>{text}</div>
    </div>
  );
};

export default Field;
