import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
import { IUser } from 'api/services/users/types';
import { UserRole, userRoles, UserSex, userSex } from 'enums/users';
import { MainInput } from 'components/MainInput/MainInput';
import { InputType } from 'components/MainInput/types';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { MainButton } from 'components/MainButton/MainButton';
import { shopServices, userServices } from 'api/services';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import showNotification from 'helpers/showNotification';
import { IResShop } from 'api/services/shops/types';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { translateUserRole } from 'helpers/translate';
import './UpdateUser.sass';

interface Props {
  submit: (data: IUser) => void;

  userId?: number;
  closeModal: () => void;
}

const userRolesList = userRoles
  .filter((i) => i.value !== UserRole.SU)
  .map((i) => ({ ...i, label: translateUserRole(i.label as UserRole) }));

export const UpdateUser: React.FC<Props> = ({ submit, userId, closeModal }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [sex, setSex] = useState<ISelect>();
  const [role, setRole] = useState<ISelect>();
  const [password, setPassword] = useState('');
  const [selectedShopId, setSelectedShopId] = useState<number>();
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [deliveryAddress, setDeliveryAddresses] = useState<string>('');
  const [avatar, setAvatar] = useState('');
  const [additionalPhone, setAdditionalPhone] = useState<string[]>([]);
  const [phoneInputs, setPhoneInputs] = useState<string[]>(['']);
  const [shops, setShops] = useState<ISearchableSelect[]>([]);
  const [shopsLoading, setShopsLoading] = useState(false);
  const [selectedShopValue, setSelectedShopValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const { user } = useSelector((state: AppState) => state.profileReducer);
  const [additionalDeliveryAddresses, setAdditionalDeliveryAddresses] =
    useState<string[]>([]);

  useEffect(() => {
    getDataById();
  }, [userId]);

  useEffect(() => {
    getShops();
  }, []);

  useEffect(() => {
    if (
      role?.value !== UserRole.SHOP_EMPLOYEE &&
      role?.value !== UserRole.SHOP_OWNER &&
      (role as any) !== UserRole.SHOP_EMPLOYEE &&
      (role as any) !== UserRole.SHOP_OWNER
    ) {
      setSelectedShopId(undefined);
    }
  }, [role]);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const phoneWithoutPlus = phone.replace('+', '');
    const allPhonesWithoutPlus = phoneInputs.map((i) => i.replace('+', ''));
    if (!phone.trim() && !email.trim()) {
      showNotification('Заполните телефон или email');
      setHasError(true);
      return;
    }
    if (!name.trim() || !role) {
      showNotification('Поля не заполнены');
      setHasError(true);
      return;
    }
    if (password.length && password.length < 6) {
      showNotification('Пароль должен состоять не менее чем из 6 символов');
      return;
    }
    submit({
      name,
      phone: phoneWithoutPlus,
      email,
      ...(password.trim() ? { password } : {}),
      ...(sex ? { sex: (sex.value as UserSex) || (sex as any) } : {}),
      role: (role?.value as UserRole) || role,
      ...(!!dateOfBirth.trim() ? { dateOfBirth } : {}),
      additionalDeliveryAddresses: [],
      additionalPhone: allPhonesWithoutPlus.filter((i) => !!i.trim()),
      ...(selectedShopId ? { shopId: selectedShopId } : {}),
    });
  };

  const getShops = async (value?: string) => {
    try {
      setShopsLoading(true);
      const res = await shopServices
        .getShops({ skip: 0, take: 25, search: value })
        .then((res) => res.data.data);
      setShops(res.map((i) => ({ name: i.name, value: i.id })));
    } finally {
      setShopsLoading(false);
    }
  };

  const getDataById = async () => {
    if (!userId) return;
    const res = await userServices.getUserById(userId).then((res) => res.data);
    setName(res.name || '');
    setPhone(res.phone || '');
    setEmail(res.email || '');
    res.sex &&
      setSex({
        label: res.sex === 'M' ? 'Мужчина' : 'Женщина',
        value: res.sex as UserSex,
      });
    setRole({
      label: res.role,
      value: res.role,
    });
    if (res.shopId) setSelectedShopId(res.shopId);
    setDateOfBirth(res.dateOfBirth ? res.dateOfBirth : '');
    setDeliveryAddresses((res.deliveryAddress?.name as string) || '');
    setPhoneInputs(
      !!res.additionalPhone && !!res.additionalPhone.length
        ? res.additionalPhone.map((i) => i)
        : ['']
    );
    setAdditionalDeliveryAddresses(res.additionalDeliveryAddresses);
    setSelectedShopValue(res.shop?.name || '');
  };

  const phoneInputValue = (value: string, index: number) => {
    setPhoneInputs((prev) => {
      const clone = _.clone(prev);
      const result = clone.map((i, indx) => {
        if (indx === index) {
          i = value;
        }
        return i;
      });
      return result;
    });
  };

  return (
    <form onSubmit={formSubmit} className='update-user'>
      <div className='update-user__modal'>
        <div className='update-user__tabs'>
          <div className='update-user__tabs-item'>
            <span>Пользователь</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='update-user__in'>
          {
            <div className='update-user__form'>
              <label className='update-user__form-row'>
                <div className='update-user__form-label'>ФИО:</div>
                <MainInput
                  updateInputValue={setName}
                  inputValue={name}
                  error={hasError && !name.trim()}
                />
              </label>
              <label className='update-user__form-row'>
                <div className='update-user__form-label'>Номер телефона:</div>
                <MainInput
                  updateInputValue={setPhone}
                  inputValue={phone}
                  error={hasError && !phone.trim()}
                  type={InputType.PHONE_NUMBER}
                />
              </label>
              <label className='update-user__form-row'>
                <div className='update-user__form-label'>Email:</div>
                <MainInput
                  updateInputValue={setEmail}
                  inputValue={email}
                  error={hasError && !email.trim()}
                />
              </label>
              <label className='update-user__form-row'>
                <div className='update-user__form-label'>Пароль:</div>
                <MainInput
                  updateInputValue={setPassword}
                  inputValue={password}
                  type={InputType.PASSWORD}
                />
              </label>
              <label className='update-user__form-row'>
                <div className='update-user__form-row-item'>
                  <div className='update-user__form-label'>Пол:</div>
                  <div className='update-user__form-row-container'>
                    <MainSelect
                      items={userSex}
                      selectItem={setSex}
                      value={sex}
                      isGray
                    />
                  </div>
                </div>
              </label>
              <label className='update-user__form-row'>
                <div className='update-user__form-row-item'>
                  <div className='update-user__form-label'>Роль:</div>
                  <div className='update-user__form-row-container'>
                    <MainSelect
                      items={
                        user?.role === UserRole.CONTENT_MANAGER
                          ? userRolesList.filter((i) => i.value !== 'ADMIN')
                          : userRolesList
                      }
                      selectItem={setRole}
                      value={role}
                      isGray
                    />
                  </div>
                </div>
              </label>
              <label className='update-user__form-row'>
                <div className='update-user__form-label'>День рождения:</div>
                <MainInput
                  updateInputValue={setDateOfBirth}
                  inputValue={dateOfBirth}
                  type={InputType.DATE}
                />
              </label>
              {/* <label className='update-user__form-row'>
                <div className='update-user__form-label'>Адрес доставки:</div>
                <MainInput
                  updateInputValue={setDeliveryAddresses}
                  inputValue={deliveryAddress}
                />
              </label> */}
              {(role?.value === UserRole.SHOP_EMPLOYEE ||
                role?.value === UserRole.SHOP_OWNER ||
                (role as any) === UserRole.SHOP_EMPLOYEE ||
                (role as any) === UserRole.SHOP_OWNER) && (
                <label className='update-user__form-row'>
                  <div className='update-user__form-label'>Магазин:</div>
                  <CustomSearchableSelect
                    items={shops}
                    loading={shopsLoading}
                    setValue={getShops}
                    setLoading={setShopsLoading}
                    setSelectItem={setSelectedShopId}
                    searchValue={selectedShopValue}
                    setSearchValue={setSelectedShopValue}
                    placeholder='Введите Магазин'
                  />
                </label>
              )}
              <div className='update-user__form-row update-user__form-row--double'>
                <label className='update-user__form-row-item'>
                  <div className='update-user__form-label'>
                    Дополнительные телефоны:
                  </div>
                  <div className='update-user__form-row-container'>
                    <div className='update-user__form-row-container-inputs'>
                      {phoneInputs.map((i, index) => (
                        <MainInput
                          key={index}
                          updateInputValue={(value: string) =>
                            phoneInputValue(value, index)
                          }
                          inputValue={i}
                          type={InputType.PHONE_NUMBER}
                        />
                      ))}
                    </div>
                    <div
                      className='update-user__form-row-buttons'
                      onClick={() => setPhoneInputs((prev) => [...prev, ''])}
                    >
                      <MainButton title='Добавить' />
                    </div>
                  </div>
                </label>
              </div>
            </div>
          }
        </div>
      </div>
      <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
    </form>
  );
};
