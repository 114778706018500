import { RadioType } from 'enums/radioType';
import React from 'react';

import './RadioInput.sass';

interface Props {
  label: string;
  checked: boolean;
  classes: string;
  type: RadioType;
  onChange: (type: RadioType) => void;
}
export const RadioInput: React.FC<Props> = ({
  label,
  checked,
  classes,
  onChange,
  type,
}) => {
  const handleEvent = (value: boolean) => {
    if (value) {
      onChange(type);
    }
  };

  return (
    <label className={`radio-input ${classes}`}>
      <input
        type='radio'
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleEvent(e.target.checked)
        }
      />
      {label}
      <span className='checkmark'></span>
    </label>
  );
};
