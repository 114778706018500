import { UserRole } from 'enums/users';

export const isEditUser = (role?: UserRole) => {
  if (!role) return false;
  return (
    role === UserRole.SU ||
    role === UserRole.CONTENT_MANAGER ||
    role === UserRole.ADMIN
  );
};

export const isCreateProductUser = (role?: UserRole) => {
  if (!role) return false;

  return (
    role === UserRole.SU ||
    role === UserRole.CONTENT_MANAGER ||
    role === UserRole.SHOP_EMPLOYEE ||
    role === UserRole.SHOP_OWNER ||
    role === UserRole.ADMIN
  );
};

export const isProductWhitoutShop = (role?: UserRole) => {
  if (!role) return false;
  return (
    role === UserRole.SU ||
    role === UserRole.CONTENT_MANAGER ||
    role === UserRole.ADMIN ||
    role === UserRole.SALES_MANAGER
  );
};

export const isShop = (role?: UserRole) => {
  if (!role) return false;
  return role === UserRole.SHOP_EMPLOYEE || role === UserRole.SHOP_OWNER;
};

export const isOrder = (role?: UserRole) => {
  if (!role) return;
  return (
    role === UserRole.SU ||
    role === UserRole.SALES_MANAGER ||
    role === UserRole.ADMIN ||
    role === UserRole.COURIER
  );
};
