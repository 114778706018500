import React, { useCallback, useEffect, useRef, useState } from 'react';
import CheckIcon from 'assets/images/icons/check-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow-icon.svg';
import cn from 'classnames';
import _ from 'lodash';

import './CustomSearchableSelect.sass';

interface Props {
  setValue: (value: string) => void;
  items: any[];
  loading: boolean;
  setLoading: (value: boolean) => void;
  setSelectItem: (value: number) => void;
  value?: number;
  setSearchValue: (value: string) => void;
  searchValue: string;
  error?: boolean;
  isWhite?: boolean;
  placeholder?: string;
  disabled?: boolean;
}
export const CustomSearchableSelect: React.FC<Props> = ({
  setValue,
  items,
  loading,
  setLoading,
  setSelectItem,
  setSearchValue,
  searchValue,
  error,
  isWhite,
  placeholder,
  disabled,
}) => {
  const [showItems, setShowItems] = useState(false);

  const selectRef = useRef<any>();
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShowItems(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const selectItem = (e: React.MouseEvent, name: string, value: number) => {
    e.stopPropagation();
    e.preventDefault();
    setSearchValue(name);
    setSelectItem(value);
    setShowItems(false);
  };

  const request = _.debounce(async (value) => {
    return await setValue(value);
  }, 1000);

  const debouceRequest = useCallback((value) => {
    setLoading(true);
    return request(value);
  }, []);

  const updateValue = (val: string) => {
    setSearchValue(val);
    debouceRequest(val);
  };

  const focusedInput = () => {
    setShowItems(true);
  };

  return (
    <div className='custom-searchable-select' ref={selectRef}>
      <div className='custom-searchable-select__container'>
        <input
          className={cn('custom-searchable-select__input', {
            'custom-searchable-select__input--error': error,
            'custom-searchable-select__input--white': isWhite,
            'custom-searchable-select__input--disabled': disabled,
          })}
          value={searchValue}
          disabled={disabled}
          placeholder={placeholder || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateValue(e.target.value)
          }
          onFocus={focusedInput}
        />
        <div className='custom-searchable-select__icons'>
          {loading && (
            <div className='custom-searchable-select__loading'>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
          {!disabled && (
            <div className='custom-searchable-select__border'></div>
          )}
          <div
            className={cn('custom-searchable-select__arrow', {
              'custom-searchable-select__arrow--active': showItems,
            })}
            onClick={() => (disabled ? null : setShowItems((prev) => !prev))}
          >
            {!disabled && <img src={CheckIcon} alt='' />}
          </div>
        </div>
      </div>
      <div
        className={cn('custom-searchable-select__list', {
          'custom-searchable-select__list--active': showItems,
          'custom-searchable-select__list--white': isWhite,
        })}
      >
        <div className='custom-searchable-select__list-container'>
          {items.map((i, index) => (
            <div
              className='custom-searchable-select__list-item'
              key={index}
              onClick={(e) => selectItem(e, i.name, i.value)}
            >
              {i.name}
            </div>
          ))}
        </div>
        {!items.length && (
          <div className='custom-searchable-select__list-item custom-searchable-select__list-item--empty'>
            Результатов не найдено
          </div>
        )}
      </div>
    </div>
  );
};
