import _ from 'lodash';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { MainInput } from 'components/MainInput/MainInput';
import { InputType } from 'components/MainInput/types';
import { ReactComponent as GlassIcon } from 'assets/images/icons/glass-icon.svg';
import './ModalFilter.sass';
import { MainButton } from 'components/MainButton/MainButton';
import React, { useState } from 'react';

interface Props {
  updateSearchValue: (value: string) => void;
  searchValue: string;
  submit?: () => void;
  closeFilter: () => void;
  hideSearch?: boolean;
}

export const ModalFilter: React.FC<Props> = ({
  searchValue,
  updateSearchValue,
  children,
  closeFilter,
  hideSearch,
}) => {
  const [modalFilterSearch, setModalFilterSearch] = useState('');
  const filterSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateSearchValue(modalFilterSearch);
    closeFilter();
  };
  return (
    <form className='modal-filter' onSubmit={filterSubmit}>
      <h4 className='modal-filter__title'>Фильтр</h4>
      {!hideSearch && (
        <div className='modal-filter__search'>
          <input
            type='text'
            placeholder='Поиск ...'
            value={modalFilterSearch}
            autoFocus
            onChange={(e) =>
              setModalFilterSearch((e.target as HTMLInputElement).value)
            }
          />
          <button className='filter__search-button'>
            <GlassIcon />
          </button>
        </div>
      )}
      {children}
      <div className='modal-filter__actions'>
        <MainButton
          title='Отменить'
          isCancel
          classes='modal-fitler__action-button'
          submit={closeFilter}
          type='button'
        />
        <MainButton
          title='Применить'
          classes='modal-fitler__action-button'
          submit={filterSubmit}
          type='submit'
        />
      </div>
    </form>
  );
};
