import { AppActionTypes, AppTypes } from './types';

const langs = ['ru', 'uz'];

export const setLang: AppActionTypes = (lang) => {
  const parsedLang: string =
    lang && langs.some((i) => i === lang) ? lang : 'ru';
  localStorage.setItem('lang', parsedLang);
  return {
    type: AppTypes.SET_LANG,
    lang: parsedLang,
  };
};
