import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { Moderation } from 'components/Modal/components/Moderation/Moderation';
import { Filter } from 'components/Filter/Filter';
import { IArticle, IResArticle } from 'api/services/articles/types';
import { articleServices, postCategoryServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { IResPostCategory } from 'api/services/postCategories/types';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { Checkbox } from 'components/Checkbox/Checkbox';
import * as settingActions from 'store/ducks/settings/actions';

import './ModerationArticlesPage.sass';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';

const tabs: IModalTab[] = [
  {
    label: 'Загрзка статей',
    index: 4,
  },
  {
    label: 'Теги',
    index: 5,
  },
];

export const ModerationArticlesPage = () => {
  const [activeTab, setActiveTab] = useState(25);
  const [showModal, setShowModal] = useState(false);
  const [articles, setArticles] = useState<IResArticle[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [article, setArticle] = useState<IResArticle>();
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<IResPostCategory[]>([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const [onlyActive, setOnlyActive] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const dispatch = useDispatch();

  const { key } = useSelector((state: AppState) => state.localeReducer);
  const { postsSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Статьи', id: '' },
        { title: 'Все статьи', id: 'posts/all' },
      ])
    );
    getCategories();
  }, []);

  useEffect(() => {
    getArticles();
  }, [page, searchValue]);

  const getArticles = async () => {
    setLoading(true);
    const searchVal = searchFilterValue || searchValue;
    const attrs = {
      skip: page * take,
      take: take,
      search: searchVal,
      ...(selectedCategoryId ? { postCategoryIds: [selectedCategoryId] } : {}),
      ...(onlyActive ? { isActive: onlyActive } : {}),
    };
    try {
      const res = await articleServices
        .getArticles(attrs)
        .then((res) => res.data);
      setTotalCount(res.count);
      setArticles(res.data);
    } finally {
      setLoading(false);
    }
  };

  const getCategories = async (value?: string) => {
    setCategoriesLoading(true);
    try {
      const res = await postCategoryServices
        .getPostCategories({ skip: 0, take: take, search: value })
        .then((res) => res.data);
      setCategories(res.data);
    } finally {
      setCategoriesLoading(false);
    }
  };

  const submitUploadArticles = async (article: IArticle) => {
    setSubmitLoading(true);
    try {
      await articleServices.createArticle(article);
      showNotification('Пост создан успешно', NotificationStatus.SUCCESS);
      closeModal();
      getArticles();
    } catch {
      showNotification('Произошла ошибка, попробуйте позже');
    } finally {
      setSubmitLoading(false);
    }
  };

  const submitUpdateArticle = async (article: IArticle, id: number) => {
    setSubmitLoading(true);
    try {
      await articleServices.updateArticle(id, article);
      showNotification('Пост изменен успешно', NotificationStatus.SUCCESS);
      closeModal();
      getArticles();
    } catch {
      showNotification('Произошла ошибка, попробуйте позже');
    } finally {
      setSubmitLoading(false);
    }
  };

  const getArticle = async (id: number) => {
    const res = await articleServices
      .getArticleById(id)
      .then((res) => res.data);
    setArticle(res);
    setActiveTab(4);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setArticle(undefined);
    setActiveTab(4);
  };

  const closeFilter = () => setFilterShowModal(false);

  const deleteArticle = async (id: number) => {
    try {
      await articleServices.deleteArticle(id);
      showNotification('Удалено', NotificationStatus.SUCCESS);
    } catch {
      showNotification('Ошибка попробуйте позже');
    }
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setPostsSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getArticles();
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Статьи'
            items={postsSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          >
            <div className='moderation-articles-page__filter-inputs'>
              <label className='moderation-articles-page__filter-inputs-item'>
                <CustomSearchableSelect
                  items={[
                    { name: 'Нет', value: null },
                    ...categories.map((i) => ({
                      name: i.titleRu,
                      value: i.id,
                    })),
                  ]}
                  loading={categoriesLoading}
                  setLoading={setCategoriesLoading}
                  setValue={getCategories}
                  setSelectItem={setSelectedCategoryId}
                  setSearchValue={setSelectedCategoryValue}
                  searchValue={selectedCategoryValue}
                  isWhite
                />
                <span className='moderation-articles-page__filter-inputs-item-label'>
                  Категория
                </span>
              </label>
              <label className='moderation-articles-page__filter-inputs-item'>
                <Checkbox isChecked={onlyActive} setChecked={setOnlyActive} />
                <span className='moderation-articles-page__filter-inputs-item-label'>
                  Активность
                </span>
              </label>
            </div>
          </ModalFilter>
        </Modal>
      )}
      {showModal && (
        <Modal closeModal={closeModal}>
          <Moderation
            tabs={tabs}
            submitUploadArticle={submitUploadArticles}
            submitUpdateArticle={submitUpdateArticle}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            closeModal={closeModal}
            article={article}
            loading={submitLoading}
          />
        </Modal>
      )}
      <div className='page moderation-articles-page'>
        <Filter
          createFunc={() => setShowModal(true)}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          loading={loading}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data: articles,
              keys: postsSettings.filter((i) => i.isActive).map((i) => i.value),
            }}
            tableHead={postsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            skipHandler={setPage}
            totalCount={totalCount}
            take={take}
            selectItem={getArticle}
            deleteHandler={deleteArticle}
            page={page}
          />
        </div>
      </div>
    </>
  );
};
