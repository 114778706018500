import { ISelect } from 'components/MainSelect/MainSelect';

export enum UserRole {
  SU = 'SU',
  USER = 'USER',
  SHOP_OWNER = 'SHOP_OWNER',
  SHOP_EMPLOYEE = 'SHOP_EMPLOYEE',
  ADMIN = 'ADMIN',
  SALES_MANAGER = 'SALES_MANAGER',
  CONTENT_MANAGER = 'CONTENT_MANAGER',
  COURIER = 'COURIER',
}

export const userRoles: ISelect[] = [
  {
    label: UserRole.SU,
    value: UserRole.SU,
  },
  {
    value: UserRole.USER,
    label: UserRole.USER,
  },
  {
    value: UserRole.SHOP_OWNER,
    label: UserRole.SHOP_OWNER,
  },
  {
    value: UserRole.SHOP_EMPLOYEE,
    label: UserRole.SHOP_EMPLOYEE,
  },
  {
    value: UserRole.ADMIN,
    label: UserRole.ADMIN,
  },
  {
    value: UserRole.SALES_MANAGER,
    label: UserRole.SALES_MANAGER,
  },

  {
    value: UserRole.CONTENT_MANAGER,
    label: UserRole.CONTENT_MANAGER,
  },
  {
    value: UserRole.COURIER,
    label: UserRole.COURIER,
  },
];

export enum UserSex {
  M = 'M',
  F = 'F',
}

export const userSex: ISelect[] = [
  {
    label: 'Мужчина',
    value: UserSex.M,
  },
  {
    label: 'Женщина',
    value: UserSex.F,
  },
];
