import instance from 'api/instance';
import qs from 'query-string';
import { ICreateShop, IGetShopById, IGetShops, IUpdateShop } from './types';

export const getShops: IGetShops = (params) =>
  instance.get(
    `/shops/admin?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const getShopById: IGetShopById = (id) =>
  instance.get(`/shops/${id}/admin`);

export const createShop: ICreateShop = (data) => instance.post('/shops', data);

export const updateShop: IUpdateShop = (id, data) =>
  instance.put(`/shops/${id}`, data);

export const deleteShop = (id: number) => instance.delete(`/shops/${id}`);
