import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, IHash } from 'components/Filter/Filter';
import { CommentStatus, IResComment } from 'api/services/comments/types';
import { commentServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import * as settingActions from 'store/ducks/settings/actions';
import { AppState } from 'store/store';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Checkbox } from 'components/Checkbox/Checkbox';
import cn from 'classnames';
import { translateCommentStatus } from 'helpers/translate';
import { ActionButtons } from 'components/Modal/components/ActionButtons/ActionButtons';
import { isEditUser } from 'helpers/checkUser';
import './ModerationCommentsPage.sass';

const hashes: IHash[] = [
  {
    title: translateCommentStatus(CommentStatus.NEW),
    value: CommentStatus.NEW,
  },
  {
    title: translateCommentStatus(CommentStatus.VERIFIED),
    value: CommentStatus.VERIFIED,
  },
];

export const ModerationCommentsPage = () => {
  const [comments, setComments] = useState<IResComment[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState<IResComment>();
  const { user } = useSelector((state: AppState) => state.profileReducer);

  const dispatch = useDispatch();
  const { commentsSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Модерация', id: '' },
        { title: 'Комментарии', id: 'moderation/comments' },
      ])
    );
  }, []);

  useEffect(() => {
    getComments();
  }, [page, searchValue]);

  const deleteComment = async (id: number) => {
    if (!id) return;
    await commentServices.deleteComment(id);
    showNotification('Комментарий удален', NotificationStatus.SUCCESS);
    getComments();
    setShowInfoModal(false);
  };

  const getComments = async (value?: string | number) => {
    const attrs = {
      skip: page * take,
      take,
      status: value?.toString(),
      search: searchValue,
      isArticle: true,
      isComment: true,
    };
    const res = await commentServices
      .getComments(attrs)
      .then((res) => res.data);
    setTotalCount(res.count);
    setComments(res.data);
  };

  const confirmComment = async (id: number) => {
    try {
      await commentServices.confirmComment(id);
      showNotification('Подтверждено', NotificationStatus.SUCCESS);
      getComments();
      setShowInfoModal(false);
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  const selectStatus = (value: string | number) => {
    setSearchValue('');
    getComments(value);
  };

  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setCommentsSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getComments();
    setSearchValue(value);
  };

  const openInfoModal = async (id: number) => {
    setShowInfoModal(true);
    const res = await commentServices
      .getCommentById(id)
      .then((res) => res.data);
    setSelectedComment(res);
  };

  const closeInfoModal = () => {
    setSelectedComment(undefined);
    setShowInfoModal(false);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Комментарии'
            items={commentsSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showInfoModal && selectedComment && (
        <Modal closeModal={closeInfoModal}>
          <div className='moderation-comments-page__info'>
            <div className='moderation-comments-page__info-in'>
              <div className='moderation-comments-page__info-status'>
                Статус:
                <span
                  className={cn('moderation-comments-page__info-status-text', {
                    'moderation-comments-page__info-status-text--verified':
                      selectedComment.status === 'VERIFIED',
                  })}
                >
                  {translateCommentStatus(selectedComment.status)}
                </span>
              </div>
              <div className='moderation-comments-page__info-block'>
                <div className='moderation-comments-page__info-title'>
                  Нравится:
                  <span>{selectedComment.likesCount || 0}</span>
                </div>
              </div>
              <div className='moderation-comments-page__info-block'>
                <div className='moderation-comments-page__info-title'>
                  Не нравится:
                  <span>{selectedComment.dislikesCount || 0}</span>
                </div>
              </div>
              <div className='moderation-comments-page__info-block'>
                <div className='moderation-comments-page__info-title'>
                  Автор:
                  <span>
                    {selectedComment.user?.name ||
                      selectedComment.user?.email ||
                      ''}
                  </span>
                </div>
              </div>
              <div className='moderation-comments-page__info-block'>
                <div className='moderation-comments-page__info-title'>
                  Отзыв:
                </div>
                <div className='moderation-comments-page__info-text'>
                  {selectedComment.text}
                </div>
              </div>
            </div>
            <ActionButtons
              closeModal={closeInfoModal}
              deleteFunc={
                isEditUser(user?.role)
                  ? () => selectedComment && deleteComment(selectedComment.id)
                  : undefined
              }
              isConfirmed={
                selectedComment.status !== 'VERIFIED' && isEditUser(user?.role)
              }
              submit={
                isEditUser(user?.role)
                  ? () => confirmComment(selectedComment.id)
                  : undefined
              }
              hideCancel
            />
          </div>
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          >
            {/* <label className='moderation-comments-page__filter'>
              <Checkbox
                isChecked={isArticle}
                setChecked={(value) => checkFilter(true, value)}
              />
              <span>Посты</span>
            </label>
            <label className='moderation-comments-page__filter'>
              <Checkbox
                isChecked={isProduct}
                setChecked={(value) => checkFilter(false, value)}
              />
              <span>Продукты</span>
            </label> */}
          </ModalFilter>
        </Modal>
      )}
      <div className='page moderation-comments-page'>
        <Filter
          hashes={hashes}
          selectHash={selectStatus}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          search={getComments}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data: comments.map((i) => ({
                ...i,
                status: translateCommentStatus(i.status),
                productOrArticle: i.article || i.product,
                userName: i.user,
              })),
              keys: commentsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={commentsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            totalCount={totalCount}
            take={take}
            selectItem={openInfoModal}
            skipHandler={setPage}
            deleteHandler={deleteComment}
            confirm={confirmComment}
            page={page}
          />
        </div>
      </div>
    </>
  );
};
