import instance from 'api/instance';
import qs from 'query-string';
import {
  IDeleteProduct,
  IGetProductById,
  IGetProducts,
  IUpdateProduct,
  ICreateProduct,
  IImportProduct,
  IExportProduct,
} from './types';

export const getProducts: IGetProducts = (params) =>
  instance.get(
    `/products/admin?${qs.stringify(params, { skipEmptyString: true })}`
  );
export const getProductsOnlyActive: IGetProducts = (params) =>
  instance.get(`/products?${qs.stringify(params, { skipEmptyString: true })}`);

export const getProductById: IGetProductById = (id) =>
  instance.get(`/products/${id}/admin`);

export const createProduct: ICreateProduct = (data) =>
  instance.post(`/products`, data);

export const updateProduct: IUpdateProduct = (id, data) =>
  instance.put(`/products/${id}`, data);

export const deleteProduct: IDeleteProduct = (id) =>
  instance.delete(`/products/${id}`);

export const importProduct: IImportProduct = (data) =>
  instance.post('/products/import', data);

export const exportProducts: IExportProduct = (data) =>
  instance.get(`/products/export?${qs.stringify(data)}`);
