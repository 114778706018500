import instance from 'api/instance';
import {
  ICreateKnowledgeBase,
  IDeleteKnowledgeBase,
  IGetKnowledgeBaseById,
  IGetKnowledgeBases,
  IUpdateKnowledgeBase,
} from './types';
import qs from 'query-string';

export const getKnowledgeBase: IGetKnowledgeBases = (params) =>
  instance.get(
    `/knowledge-base?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const createKnowledgeBase: ICreateKnowledgeBase = (data) =>
  instance.post(`/knowledge-base`, data);

export const updateKnowledgeBase: IUpdateKnowledgeBase = (id, data) =>
  instance.put(`/knowledge-base/${id}`, data);

export const deleteKnowledgeBase: IDeleteKnowledgeBase = (id) =>
  instance.delete(`/knowledge-base/${id}`);

export const getKnowledgeBaseById: IGetKnowledgeBaseById = (id) =>
  instance.get(`/knowledge-base/${id}`);
