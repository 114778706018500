import instance from 'api/instance';
import {
  ICreateDelivery,
  IGetDelivery,
  IGetDeliveryById,
  IUpdateDelivery,
} from './types';

export const getDelivery: IGetDelivery = () => instance.get(`/deliveries`);

export const createDelivery: ICreateDelivery = (data) =>
  instance.post(`/deliveries`, data);

export const getDeliveryById: IGetDeliveryById = (id) =>
  instance.get(`/deliveries/${id}`);

export const updateDelivery: IUpdateDelivery = (id, data) =>
  instance.put(`/deliveries/${id}`, data);

export const deleteDelivery = (id: number) =>
  instance.delete(`/deliveries/${id}`);
