import React, { useEffect, useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { MainInput } from 'components/MainInput/MainInput';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg';
import { HtmlEditor, HtmlEditor2 } from 'components/Editor/HtmlEditor';
import { RadioType } from 'enums/radioType';
import { IArticle, IResArticle } from 'api/services/articles/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { postCategoryServices, productServices } from 'api/services';
import { IResProduct } from 'api/services/products/types';
import { IResTag } from 'api/services/tags/types';
import { ActionButtons } from 'components/Modal/components/ActionButtons/ActionButtons';
import { Tags } from 'components/Tags/Tags';
import showNotification from 'helpers/showNotification';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { FileModal } from '../../FileModal/FileModal';
import { IResFile } from 'api/services/files/types';
import { FileList } from 'components/FileList/FileList';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { FileUploader } from 'components/FileUploader/FileUploader';
import './UpdateArticle.sass';

interface Props {
  submit?: (data: IArticle, id: number) => void;
  isTags: boolean;
  closeModal: () => void;
  article?: IResArticle;
  loading: boolean;
}

export const UpdateArticle: React.FC<Props> = ({
  submit,
  isTags,
  closeModal,
  article,
  loading,
}) => {
  const [redactorValueRu, setRedactorValueRu] = useState('');
  const [redactorValueUz, setRedactorValueUz] = useState('');
  const [descriptionType, setDescriptionType] = useState<RadioType>(
    RadioType.TEXT
  );
  const [isActive, setIsActive] = useState(false);
  const [onMainPage, setOnMainPage] = useState(true);
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [slug, setSlug] = useState('');
  const [products, setProducts] = useState<IResProduct[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<IResProduct[]>([]);
  const [video, setVideo] = useState('');
  const [selectedTags, setSelectedTags] = useState<IResTag[]>([]);
  const [searchablePostCategories, setSearchablePostCategories] = useState<
    ISearchableSelect[]
  >([]);
  const [searchableProducts, setSearchableProducts] = useState<
    ISearchableSelect[]
  >([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [postCategoriesLoading, setPostCategoriesLoading] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const [selectedPostCategoryId, setSelectedPostCategoryId] =
    useState<number>();
  const [postCategorySearchValue, setPostCategorySearchValue] = useState('');
  const [productSearchValue, setProductSearchValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [articleFile, setArticleFile] = useState<IResFile>();
  const { key, lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    getArticle();
    getPostCategories();
  }, []);

  useEffect(() => {
    const findName = searchablePostCategories.find(
      (i) => i.value === selectedPostCategoryId
    )?.name;
    if (findName) {
      setPostCategorySearchValue(findName);
    }
  }, [selectedPostCategoryId, !!searchablePostCategories.length]);

  useEffect(() => {
    const findName = searchableProducts.find(
      (i) => i.value === selectedProductId
    )?.name;
    if (findName) {
      setProductSearchValue(findName);
    }
  }, [selectedProductId, !!searchableProducts.length]);

  const getArticle = () => {
    if (!article) {
      showNotification('Статья не найдена');
      closeModal();
      return;
    }
    setRedactorValueRu(article.descriptionRu);
    setRedactorValueUz(article.descriptionUz);
    setIsActive(article.isActive);
    setTitleRu(article.titleRu);
    setTitleUz(article.titleUz);
    setSelectedTags(article.tags);
    setVideo(article.video);
    setSlug(article.slug);
    setSelectedPostCategoryId(article.postCategoryId);
    setSelectedProducts(article.products);
    setArticleFile(article.file);
  };

  const selectTag = (tag: IResTag) => {
    if (!selectedTags.some((i) => i.id === tag.id)) {
      setSelectedTags((prev) => [...prev, tag]);
    }
  };

  const changeDescriptionType = (type: RadioType) => {
    setDescriptionType(type);
  };

  const getPostCategories = async (value?: string) => {
    if (value && value?.length < 3) {
      setPostCategoriesLoading(false);
      setSearchablePostCategories([]);
      return;
    }
    try {
      const res = await postCategoryServices
        .getPostCategories({
          skip: 0,
          take: 25,
          search: value && value.length < 3 ? '' : value,
        })
        .then((res) => res.data);
      const postCtg = res.data.map((i) => ({
        name: i.titleRu,
        value: i.id,
      }));
      setSearchablePostCategories(postCtg);
    } finally {
      setPostCategoriesLoading(false);
    }
  };

  const getProducts = async (value?: string) => {
    if (value && value?.length < 3) {
      setProductsLoading(false);
      setSearchableProducts([]);
      return;
    }
    try {
      const res = await productServices
        .getProducts({
          skip: 0,
          take: 20,
          search: value && value.length < 3 ? '' : value,
        })
        .then((res) => res.data);

      const products = res.data.map((i) => ({
        name: i.titleRu,
        value: i.id,
      }));
      setProducts(res.data);
      setSearchableProducts(products);
    } finally {
      setProductsLoading(false);
    }
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if (!submit || !article) return;
    if (!titleRu.trim() && !titleUz.trim()) {
      setHasError(true);
      showNotification('Заполните название');
      return;
    }
    if (!redactorValueRu.trim() && !redactorValueUz.trim()) {
      showNotification('Заполните описание');
      setHasError(true);
      return;
    }
    if (!selectedPostCategoryId) {
      setHasError(true);
      showNotification('Заполните поля');
      return;
    }
    if (!articleFile) {
      setHasError(true);
      showNotification('Загрузите изображение');
      return;
    }
    submit(
      {
        descriptionRu: redactorValueRu,
        descriptionUz: redactorValueUz,
        onMainPage,
        isActive,
        titleRu,
        titleUz,
        productIds: selectedProducts.map((i) => i.id),
        video,
        postCategoryId: selectedPostCategoryId,
        tagIds: selectedTags.map((i) => i.id),
        slug,
        fileId: articleFile.id,
      },
      article?.id
    );
    closeModal();
  };

  const selectProducts = (id: number) => {
    const product = products?.find((i) => i.id === id);
    if (!product) return;
    if (!selectedProducts.some((i) => i.id === product.id)) {
      setSelectedProducts((prev) => [...prev, product]);
    }
  };

  const mainFileSelect = (file: IResFile) => {
    setArticleFile(file);
    setShowFileModal(false);
  };

  return (
    <>
      {showFileModal && (
        <FileModal
          closeModal={() => setShowFileModal(false)}
          fileSelected={mainFileSelect}
        />
      )}
      <form onSubmit={formSubmit} className='update-article'>
        {isTags ? (
          <Tags
            selectTags={selectTag}
            selectedTags={selectedTags}
            withCreate
            deleteItem={(id) =>
              setSelectedTags((prev) => prev.filter((i) => i.id !== id))
            }
          />
        ) : (
          <div className='update-article__modal'>
            <div className='update-article__row'>
              <label className='update-article__row-item'>
                <div className='update-article__label'>Активность:</div>
                <Checkbox isChecked={isActive} setChecked={setIsActive} />
              </label>
            </div>
            <div className='update-article__row'>
              <label className='update-article__row-item'>
                <div className='update-article__label'>
                  Показывать на главной странице
                </div>
                <Checkbox isChecked />
              </label>
            </div>
            <div className='update-article__row'>
              <label className='update-article__row-item'>
                <div className='update-article__label'>Название</div>
                {lang === 'ru' ? (
                  <MainInput
                    inputValue={titleRu}
                    updateInputValue={setTitleRu}
                  />
                ) : (
                  <MainInput
                    inputValue={titleUz}
                    updateInputValue={setTitleUz}
                  />
                )}
              </label>
            </div>
            <div className='update-article__row'>
              <div className='update-article__row-item'>
                <div className='update-article__label'>Категория</div>
                <CustomSearchableSelect
                  items={searchablePostCategories}
                  loading={postCategoriesLoading}
                  setValue={getPostCategories}
                  setLoading={setPostCategoriesLoading}
                  setSelectItem={setSelectedPostCategoryId}
                  searchValue={postCategorySearchValue}
                  setSearchValue={setPostCategorySearchValue}
                  error={!selectedPostCategoryId && hasError}
                />
              </div>
            </div>
            <div className='update-article__row'>
              <label className='update-article__row-item'>
                <div className='update-article__label'>Ссылка</div>
                <MainInput inputValue={slug} updateInputValue={setSlug} />
              </label>
            </div>
            <div className='update-article__row'>
              <label className='update-article__row-item'>
                <div className='update-article__label'>Ссылка на видео</div>
                <MainInput inputValue={video} updateInputValue={setVideo} />
              </label>
            </div>
            <div className='update-article__row'>
              <label className='update-article__row-item'>
                <div className='update-article__label'>
                  Показать товар в статье
                </div>
                <CustomSearchableSelect
                  items={searchableProducts}
                  loading={productsLoading}
                  setValue={getProducts}
                  setLoading={setProductsLoading}
                  setSelectItem={selectProducts}
                  searchValue={productSearchValue}
                  setSearchValue={setProductSearchValue}
                  error={!selectedProductId && hasError}
                />
              </label>
            </div>
            <div className='update-article__row update-article__row--with-list'>
              <div className='update-article__row-list'>
                {selectedProducts.map((i) => (
                  <div className='update-article__row-list-item' key={i.id}>
                    <span>{i.titleRu}</span>
                    <div
                      className='update-article__row-list-item-close'
                      onClick={() =>
                        setSelectedProducts((prev) =>
                          prev.filter((ii) => ii.id !== i.id)
                        )
                      }
                    >
                      <CloseIcon />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='upload-articles-item upload-articles--uploader'>
              <div className='upload-articles__label--uploader'>
                Загрузить главное изображение:
                <span onClick={() => setShowFileModal(true)}>
                  Выбрать из файла <FolderIcon />
                </span>
              </div>
              <div className='upload-articles__uploader-files'>
                {!!articleFile ? (
                  <FileList
                    file={articleFile}
                    deleteFile={() => setArticleFile(undefined)}
                  />
                ) : (
                  <FileUploader
                    setFile={setArticleFile}
                    error={hasError && !articleFile}
                  />
                )}
              </div>
            </div>
            <div className='update-article__description'>
              <div className='update-article__textarea'>
                {lang === 'ru' ? (
                  <HtmlEditor
                    value={redactorValueRu}
                    onChange={setRedactorValueRu}
                  />
                ) : (
                  <HtmlEditor2
                    value={redactorValueUz}
                    onChange={setRedactorValueUz}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
      </form>
    </>
  );
};
