import { AuthTypes } from './types';
import { Reducer } from 'redux';

const initState = {
  token: undefined,
};

const reducer: Reducer<{ token?: string }> = (state = initState, action) => {
  switch (action.type) {
    case AuthTypes.SIGN_IN:
      return {
        ...state,
        token: action.token,
      };
    case AuthTypes.SIGN_OUT:
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
};

export default reducer;
