import { MainButton } from 'components/MainButton/MainButton';
import React from 'react';

interface Props {
  closeModal: () => void;
  formSubmit?: (e: React.FormEvent) => void;
  loading?: boolean;
  submit?: () => void;
  deleteFunc?: () => void;
  isConfirmed?: boolean;
  hideCancel?: boolean;
}

export const ActionButtons: React.FC<Props> = ({
  closeModal,
  formSubmit,
  loading,
  submit,
  deleteFunc,
  isConfirmed,
  hideCancel,
}) => {
  return (
    <div className='modal__actions'>
      <MainButton submit={closeModal} title={'Закрыть'} isCancel />
      {!hideCancel && (
        <MainButton
          submit={deleteFunc}
          title={deleteFunc ? 'Удалить' : 'Отменить'}
          isCancel
          classes={deleteFunc ? 'modal__actions-button--red' : ''}
        />
      )}
      {deleteFunc && isConfirmed && (
        <MainButton
          type='button'
          title={'Подтвердить'}
          submit={submit}
          isDisabled={loading}
        />
      )}
      {submit && !isConfirmed && (
        <MainButton
          type='submit'
          title='Сохранить'
          submit={submit}
          isDisabled={loading}
        ></MainButton>
      )}
      {formSubmit && (
        <MainButton
          type='submit'
          title='Сохранить'
          submit={formSubmit}
          isDisabled={loading}
        ></MainButton>
      )}
    </div>
  );
};
