import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreatePostCategory,
  IDeletePostCategory,
  IGetPostCategories,
  IGetPostCategoryById,
  IUpdatePostCategory,
} from './types';

export const getPostCategories: IGetPostCategories = (params) =>
  instance.get(
    `/post-categories?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const getPostCategoryById: IGetPostCategoryById = (id) =>
  instance.get(`/post-categories/${id}`);

export const createPostCategory: ICreatePostCategory = (data) =>
  instance.post(`/post-categories`, data);

export const updatePostCategory: IUpdatePostCategory = (id, data) =>
  instance.put(`/post-categories/${id}`, data);

export const deletePostCategory: IDeletePostCategory = (id) =>
  instance.delete(`/post-categories/${id}`);
