import { Reducer } from 'redux';
import { CategoryChildType } from './types';

const initState = {
  ids: [],
};

export const reducer: Reducer<{
  ids: number[];
}> = (state = initState, action) => {
  switch (action.type) {
    case CategoryChildType.SET_CHILDS:
      return {
        ...state,
        ids: action.ids,
      };
    case CategoryChildType.REMOVE_CHILD:
      const result = state.ids;
      const findIndex = state.ids.findIndex((i) => i === action.id);
      result.splice(findIndex, result.length - findIndex);
      return {
        ...state,
        ids: result,
      };
    default:
      return state;
  }
};

export default reducer;
