import React, { useEffect, useState } from 'react';
import { ReactComponent as GlassIcon } from 'assets/images/icons/glass-icon.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross-icon.svg';

import './Tags.sass';
import { IResTag, ITag } from 'api/services/tags/types';
import { tagServices } from 'api/services';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import {
  ISearchableSelect,
  SearchableSelect,
} from 'components/SearchableSelect/SearchableSelect';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import cn from 'classnames';
import { CreateTag } from 'components/CreateTag/CreateTag';
import { Modal } from 'components/Modal/Modal';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { MainButton } from 'components/MainButton/MainButton';
import { UserRole } from 'enums/users';

interface Props {
  selectTags?: (tag: IResTag) => void;
  selectedTags: IResTag[];
  deleteItem: (id: number) => void;
  withCreate?: boolean;
}
export const Tags: React.FC<Props> = ({
  selectTags,
  selectedTags,
  deleteItem,
  withCreate,
}) => {
  const { key } = useSelector((state: AppState) => state.localeReducer);
  const [searchableTags, setSearchableTags] = useState<ISearchableSelect[]>([]);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [tags, setTags] = useState<IResTag[]>([]);
  const [tagSearchValue, setTagSearchValue] = useState('');
  const [showCreateTagModal, setShowCreateTagModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { user } = useSelector((state: AppState) => state.profileReducer);

  useEffect(() => {
    getTags();
  }, []);

  const getTags = async (value?: string) => {
    const res = await tagServices
      .getTags({
        skip: 0,
        take: 25,
        ...(value && value.length > 2 ? { search: value } : {}),
      })
      .then((res) => res.data);
    const arr = res.data.map((i) => ({
      name: i.titleRu,
      value: i.id,
    }));
    setTags(res.data);
    setSearchableTags(arr);
    setTagsLoading(false);
  };

  const selectTag = (id: number) => {
    const tag = tags?.find((i) => i.id === id);
    if (!tag || !selectTags) return;
    selectTags(tag);
  };

  const createTag = async (data: ITag) => {
    setSubmitLoading(true);
    try {
      await tagServices.createTag(data);
      showNotification('Готово', NotificationStatus.SUCCESS);
      setShowCreateTagModal(false);
      getTags();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className='tags'>
      {showCreateTagModal && (
        <Modal closeModal={() => setShowCreateTagModal(false)}>
          <CreateTag
            submit={createTag}
            closeModal={() => setShowCreateTagModal(false)}
            loading={submitLoading}
          />
        </Modal>
      )}
      <div className='tags__modal'>
        <div className='tags__top'>
          <div
            className={cn('tags__search', {
              'tags__search--product': withCreate,
            })}
          >
            <CustomSearchableSelect
              items={searchableTags}
              setValue={getTags}
              loading={tagsLoading}
              setSelectItem={selectTag}
              setLoading={setTagsLoading}
              setSearchValue={setTagSearchValue}
              searchValue={tagSearchValue}
              disabled={user?.role === UserRole.SALES_MANAGER}
            />
          </div>
          {withCreate && user?.role !== UserRole.SALES_MANAGER && (
            <MainButton
              classes='tags__create-button'
              title='Создать +'
              type='button'
              submit={() => setShowCreateTagModal(true)}
            />
          )}
        </div>
        <div className='tags__items'>
          {selectedTags.map((i) => (
            <div className='tags__item' key={i.id}>
              {i.titleRu}
              <div
                className='tags__delete-icon'
                onClick={() => deleteItem(i.id)}
              >
                <CrossIcon />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
