import React, { useState } from 'react';
import cn from 'classnames';
import { IModalTab } from 'components/Modal/types';
import { OrderInfo } from './OrderInfo/OrderInfo';

import './UpdateOrder.sass';
import { OrderProduct } from './OrderProduct/OrderProduct';
import { OrderStatus } from './OrderStatus/OrderStatus';
import { OrderServiceParams } from './OrderServiceParams/OrderServiceParams';
import { MainButton } from 'components/MainButton/MainButton';
import { IResOrder } from 'api/services/orders/types';

interface Props {
  // closeModal: () => void;
  setActiveTab: (tab: number) => void;
  activeTab: number;
  tabs: IModalTab[];
  order: IResOrder;
  closeModal: () => void;
}

export const UpdateOrder: React.FC<Props> = ({
  setActiveTab,
  activeTab,
  tabs,
  order,
  closeModal,
}) => {
  const checkTab = () => {
    switch (activeTab) {
      case 2:
        return <OrderProduct order={order} />;
      case 3:
        return <OrderStatus />;
      default:
        return <OrderInfo order={order} closeModal={closeModal} />;
    }
  };

  return (
    <div className='update-order update-order__modal'>
      <div className='update-order__tabs'>
        {tabs.map((i) => (
          <div
            className={cn('update-order__tabs-item', {
              'update-order__tabs-item--active': activeTab === i.index,
            })}
            key={i.index}
            onClick={() => setActiveTab(i.index)}
          >
            <span>{i.label}</span>
          </div>
        ))}
      </div>
      <div className='update-order__in'>{checkTab()}</div>
    </div>
  );
};
