import { ISetting } from './types';

export const catalogCategoriesSettings: ISetting[] = [
  {
    label: 'ID',
    value: 'id',
    isActive: true,
  },
  {
    label: 'Наименование',
    value: 'titleRu',
    isActive: true,
  },
  {
    label: 'Дата изменения',
    value: 'updatedAt',
    isActive: true,
  },
  {
    label: 'Цена',
    value: 'value',
    isActive: true,
  },
  {
    label: 'Единица измерения',
    value: 'marginType',
    isActive: true,
  },
  {
    label: 'Ссылка',
    value: 'url',
    isActive: true,
  },
];

export const productsSettings: ISetting[] = [
  { label: 'ID', value: 'id', isActive: true },
  { label: 'Наименование', value: 'titleRu', isActive: true },
  { label: 'Тип', value: 'variants', isActive: true },
  { label: 'Активность', value: 'deactivateAt', isActive: true },
  { label: 'Дата изменения', value: 'updatedAt', isActive: true },
  // label: 'Статус', value: , isActive: true""
  { label: 'Элементов', value: 'variantsCount', isActive: true },
];

// БРЕНДЫ

export const brandsSettings: ISetting[] = [
  { label: 'ID', value: 'id', isActive: true },
  { label: 'Название', value: 'name', isActive: true },
  { label: 'Лого', value: 'img', isActive: true },
  { label: 'Дата изменения', value: 'updatedAt', isActive: true },
];

// АКЦИИ

export const stocksSettings: ISetting[] = [
  {
    label: 'ID',
    value: 'id',
    isActive: true,
  },
  {
    label: 'Наименование',
    value: 'titleRu',
    isActive: true,
  },
  {
    label: 'Активность',
    value: 'isActive',
    isActive: true,
  },
  {
    label: 'Подарок',
    value: 'gift',
    isActive: true,
  },
  {
    label: 'Дата изменения',
    value: 'updatedAt',
    isActive: true,
  },
];

export const attributeGroupsSettings: ISetting[] = [
  {
    label: 'ID',
    value: 'id',
    isActive: true,
  },
  {
    label: 'Наименование',
    value: 'titleRu',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
];

export const attributesSettings: ISetting[] = [
  {
    label: 'ID',
    value: 'id',
    isActive: true,
  },
  {
    label: 'Наименование',
    value: 'titleRu',
    isActive: true,
  },
  {
    label: 'Тип',
    value: 'type',
    isActive: true,
  },
  {
    label: 'Вид',
    value: 'viewType',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
  {
    label: 'Категории',
    value: 'categories',
    isActive: true,
  },
];

export const currenciesSettings: ISetting[] = [
  {
    label: 'Название',
    value: 'title',
    isActive: true,
  },
  {
    label: 'Аббревиатура',
    value: 'name',
    isActive: true,
  },
  {
    label: 'Символ',
    value: 'symbol',
    isActive: true,
  },
  {
    label: 'Курс',
    value: 'cost',
    isActive: true,
  },
];

export const supplementaryServicesSettings: ISetting[] = [
  {
    label: 'Услуга',
    value: 'name',
    isActive: true,
  },
  {
    label: 'Описание',
    value: 'description',
    isActive: true,
  },
  {
    label: 'Активность',
    value: 'isActive',
    isActive: true,
  },
  {
    label: 'Цена',
    value: 'price',
    isActive: true,
  },
];

export const activeOrdersSettings: ISetting[] = [
  {
    label: 'Заказ',
    value: 'id',
    isActive: true,
  },
  {
    label: 'Магазин',
    value: 'shop',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
  {
    label: 'Статус',
    value: 'statusColor',
    isActive: true,
  },
  {
    label: 'Сумма/Валюта',
    value: 'totalSum',
    isActive: true,
  },
  {
    label: 'Ответственный',
    value: 'responsibleManager',
    isActive: true,
  },
  {
    label: 'Способ оплаты',
    value: 'paymentMethod',
    isActive: true,
  },
  {
    label: 'Клиент',
    value: 'client',
    isActive: true,
  },
];

export const allOrdersSettings: ISetting[] = [
  {
    label: 'Заказ',
    value: 'order',
    isActive: true,
  },
  {
    label: 'Магазин',
    value: 'shop',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
  {
    label: 'Статус',
    value: 'statusColor',
    isActive: true,
  },
  {
    label: 'Сумма/Валюта',
    value: 'amountCurrency',
    isActive: true,
  },
  {
    label: 'Ответственный',
    value: 'responsible',
    isActive: true,
  },
  {
    label: 'Способ оплаты',
    value: 'paymentMethod',
    isActive: true,
  },
  {
    label: 'Клиент',
    value: 'client',
    isActive: true,
  },
];
export const servicesSettings: ISetting[] = [
  {
    label: 'Заказ',
    value: 'order',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
  {
    label: 'Статус',
    value: 'status',
    isActive: true,
  },
  {
    label: 'Сумма/Валюта',
    value: 'amountCurrency',
    isActive: true,
  },
  {
    label: 'Ответственный',
    value: 'responsible',
    isActive: true,
  },
  {
    label: 'Способ оплаты',
    value: 'paymentMethod',
    isActive: true,
  },
  {
    label: 'Клиент',
    value: 'client',
    isActive: true,
  },
];

export const cashBackSettings: ISetting[] = [
  { label: 'ID', value: 'id', isActive: true },
  { label: 'Клиент', value: 'name', isActive: true },
  { label: 'Статус клиента', value: 'userStatus', isActive: true },
  { label: 'Сумма', value: 'sum', isActive: true },
];

export const usersSettings: ISetting[] = [
  // {
  //   label: 'Магазин',
  //   value: '',
  //   isActive: true,
  // },
  {
    label: 'Логин',
    value: 'email',
    isActive: true,
  },
  {
    label: 'Дата регистрации',
    value: 'createdAt',
    isActive: true,
  },
  {
    label: 'Последний вход',
    value: 'lastAuthorizationAt',
    isActive: true,
  },
  {
    label: 'Статус',
    value: 'isActive',
    isActive: true,
  },
  {
    label: 'Имя',
    value: 'name',
    isActive: true,
  },
  {
    label: 'Номер телефона',
    value: 'phone',
    isActive: true,
  },
  {
    label: 'Уровень доступа',
    value: 'role',
    isActive: true,
  },
  // {
  //   label: 'Подтвержденный',
  //   value: 'cofirmed',
  //   isActive: true,
  // },
  // {
  //   label: 'Ответственный',
  //   value: '',
  //   isActive: true,
  // },
];

export const shopUsersSettings: ISetting[] = [
  {
    label: 'ID',
    value: 'id',
    isActive: true,
  },
  {
    label: 'ФИО',
    value: 'name',
    isActive: true,
  },
  {
    label: 'Должность',
    value: 'role',
    isActive: true,
  },
  {
    label: 'Дата рождения',
    value: 'birthDate',
    isActive: true,
  },
];

export const postCategoriesSettings: ISetting[] = [
  {
    label: 'Название',
    value: 'titleRu',
    isActive: true,
  },
  {
    label: 'URL',
    value: 'url',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
];

export const postsSettings: ISetting[] = [
  { label: 'Наименование', value: 'titleRu', isActive: true },
  { label: 'Дата создания', value: 'createdAt', isActive: true },
  { label: 'Активность', value: 'isActive', isActive: true },
  { label: 'Связь', value: 'postCategory', isActive: true },
  { label: 'Автор', value: 'author', isActive: true },
  { label: 'Количество просмотров', value: 'viewsNumber', isActive: true },
  { label: 'Комментарии', value: 'comment', isActive: true },
];

export const shopsSettings: ISetting[] = [
  {
    label: 'Название',
    value: 'name',
    isActive: true,
  },
  {
    label: 'Лого',
    value: 'file',
    isActive: true,
  },
  {
    label: 'Ссылка',
    value: 'url',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
];
export const knowledgeBaseSettings: ISetting[] = [
  {
    label: 'Название',
    value: 'titleRu',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
  {
    label: 'Активный',
    value: 'isActive',
    isActive: true,
  },
];
export const commentsSettings: ISetting[] = [
  {
    label: 'Отзыв',
    value: 'text',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
  {
    label: 'Статус',
    value: 'status',
    isActive: true,
  },
  {
    label: 'Товар',
    value: 'product',
    isActive: true,
  },
  {
    label: 'Автор',
    value: 'userName',
    isActive: true,
  },
  {
    label: 'Ответственный',
    value: 'responsible',
    isActive: true,
  },
];

export const productCommentsSettings: ISetting[] = [
  {
    label: 'Отзыв',
    value: 'text',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
  {
    label: 'Статус',
    value: 'status',
    isActive: true,
  },
  {
    label: 'Товар',
    value: 'product',
    isActive: true,
  },
  {
    label: 'Автор',
    value: 'userName',
    isActive: true,
  },
  {
    label: 'Ответственный',
    value: 'responsible',
    isActive: true,
  },
];

export const reviewsSettings: ISetting[] = [
  {
    label: 'Текст',
    value: 'text',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
  {
    label: 'Статус',
    value: 'status',
    isActive: true,
  },
  {
    label: 'Товар',
    value: 'product',
    isActive: true,
  },
  {
    label: 'Автор',
    value: 'userName',
    isActive: true,
  },
  {
    label: 'Ответственный',
    value: 'responsible',
    isActive: true,
  },
];

export const merchantReviewsSettings: ISetting[] = [
  {
    label: 'Наименование товара',
    value: 'product',
    isActive: true,
  },
  {
    label: 'Отзыв',
    value: 'text',
    isActive: true,
  },
];

export const tagsSettings: ISetting[] = [
  {
    label: 'ID',
    value: 'id',
    isActive: true,
  },
  {
    label: 'Лого',
    value: 'titleRu',
    isActive: true,
  },
  {
    label: 'Цвет',
    value: 'color',
    isActive: true,
  },
  {
    label: 'Дата изменения',
    value: 'createdAt',
    isActive: true,
  },
];
export const bannersSettings: ISetting[] = [
  {
    label: 'Название',
    value: 'name',
    isActive: true,
  },
  {
    label: 'Дата изменения',
    value: 'updatedAt',
    isActive: true,
  },
  {
    label: 'Активность',
    value: 'isActive',
    isActive: true,
  },
  {
    label: 'URL',
    value: 'url',
    isActive: true,
  },
];
export const storyGroupsSettings: ISetting[] = [
  {
    label: 'ID',
    value: 'id',
    isActive: true,
  },
  {
    label: 'Название',
    value: 'titleRu',
    isActive: true,
  },
  {
    label: 'Дата изменения',
    value: 'updatedAt',
    isActive: true,
  },
];

export const storiesSettings: ISetting[] = [
  {
    label: 'URL',
    value: 'url',
    isActive: true,
  },
  {
    label: 'X',
    value: 'x',
    isActive: true,
  },
  {
    label: 'Y',
    value: 'y',
    isActive: true,
  },
  {
    label: 'Полная ширина',
    value: 'fullWidth',
    isActive: true,
  },
  {
    label: 'Дата изменения',
    value: 'updatedAt',
    isActive: true,
  },
];

export const couponsSettings: ISetting[] = [
  {
    label: 'ID',
    value: 'id',
    isActive: true,
  },
  {
    label: 'Название',
    value: 'name',
    isActive: true,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    isActive: true,
  },
  {
    label: 'Активность',
    value: 'isActive',
    isActive: true,
  },
  {
    label: 'Сумма купона',
    value: 'sum',
    isActive: true,
  },
];

export const promocodesSettings: ISetting[] = [
  { label: 'ID', value: 'id', isActive: true },
  { label: 'Название', value: 'name', isActive: true },
  { label: 'Дата создания', value: 'createdAt', isActive: true },
  { label: 'Активность', value: 'isActive', isActive: true },
  { label: 'Ответственный', value: 'operator', isActive: true },
];

export const deliverySettings: ISetting[] = [
  { label: 'ID', value: 'id', isActive: true },
  { label: 'Название', value: 'titleRu', isActive: true },
  { label: 'Цена', value: 'cost', isActive: true },
  { label: 'Лимит', value: 'freeLimit', isActive: true },
];

export const balanceReportSettings: ISetting[] = [
  { label: '#', value: 'id', isActive: true },
  { label: 'Поставщик', value: 'shopName', isActive: true },
  { label: 'Бренд', value: 'brandName', isActive: true },
  { label: 'Наименование товара', value: 'titleRu', isActive: true },
  { label: 'Заказано', value: 'process', isActive: true },
  { label: 'Завершено', value: 'completed', isActive: true },
  { label: 'Отказанные', value: 'canceled', isActive: true },
  { label: 'Остаток', value: 'count', isActive: true },
];

export const salesReportSettings: ISetting[] = [
  { label: '#', value: 'id', isActive: true },
  { label: 'Поставщик', value: 'shopName', isActive: true },
  { label: 'Покупки', value: 'count', isActive: true },
  { label: 'Сумма', value: 'salesSum', isActive: true },
  { label: 'Доход', value: 'incomePrice', isActive: true },
  { label: 'Наценка', value: 'marginPrice', isActive: true },
];
