import instance from 'api/instance';
import store from 'store/store';
import * as authActions from 'store/ducks/auth/actions';
import * as profileActions from 'store/ducks/profile/actions';
import * as settingActions from 'store/ducks/settings/actions';
import * as widgetActions from 'store/ducks/widgets/actions';

const appConfig = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) return;
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  store.dispatch(profileActions.getProfile());
  store.dispatch(authActions.signIn(token));
  const catalogCategoriesSettings = localStorage.getItem(
    'catalogCategoriesSettings'
  );

  const widgets = localStorage.getItem('widgets');
  if (widgets) store.dispatch(widgetActions.getWidgets(JSON.parse(widgets)));

  const productsSettings = localStorage.getItem('productsSettings');
  const brandsSettings = localStorage.getItem('brandsSettings');
  const stocksSettings = localStorage.getItem('stocksSettings');
  const knowledgeBaseSettings = localStorage.getItem('knowledgeBaseSettings');
  const attributesSettings = localStorage.getItem('attributesSettings');
  const currenciesSettings = localStorage.getItem('currenciesSettings');
  const activeOrdersSettings = localStorage.getItem('activeOrdersSettings');
  const allOrdersSettings = localStorage.getItem('allOrdersSettings');
  const servicesSettings = localStorage.getItem('servicesSettings');
  const cashBackSettings = localStorage.getItem('cashBackSettings');
  const usersSettings = localStorage.getItem('usersSettings');
  const shopUsersSettings = localStorage.getItem('shopUsersSettings');
  const postCategoriesSettings = localStorage.getItem('postCategoriesSettings');
  const postsSettings = localStorage.getItem('postsSettings');
  const shopsSettings = localStorage.getItem('shopsSettings');
  const reviewsSettings = localStorage.getItem('reviewsSettings');
  const commentsSettings = localStorage.getItem('commentsSettings');
  const tagsSettings = localStorage.getItem('tagsSettings');
  const bannersSettings = localStorage.getItem('bannersSettings');
  const storyGroupsSettings = localStorage.getItem(
    'bannersstoryGroupsSettingsSettings'
  );
  const storiesSettings = localStorage.getItem('storiesSettings');
  const couponsSettings = localStorage.getItem('couponsSettings');
  const promocodesSettings = localStorage.getItem('promocodesSettings');
  const balanceReportSettings = localStorage.getItem('balanceReportSettings');
  const salesReportSettings = localStorage.getItem('salesReportSettings');
  const supplementaryServicesSettings = localStorage.getItem(
    'supplementaryServicesSettings'
  );
  if (catalogCategoriesSettings) {
    store.dispatch(
      settingActions.setCatalogCategoriesSettings(
        JSON.parse(catalogCategoriesSettings)
      )
    );
  }
  if (productsSettings)
    store.dispatch(
      settingActions.setProductsSettings(JSON.parse(productsSettings))
    );
  if (brandsSettings)
    store.dispatch(
      settingActions.setBrandsSettings(JSON.parse(brandsSettings))
    );
  if (knowledgeBaseSettings)
    store.dispatch(
      settingActions.setKnowledgeBaseSettings(JSON.parse(knowledgeBaseSettings))
    );
  if (attributesSettings)
    store.dispatch(
      settingActions.setAttributesSettings(JSON.parse(attributesSettings))
    );
  if (stocksSettings)
    store.dispatch(
      settingActions.setStocksSettings(JSON.parse(stocksSettings))
    );
  if (currenciesSettings)
    store.dispatch(
      settingActions.setCurrenciesSettings(JSON.parse(currenciesSettings))
    );
  if (activeOrdersSettings)
    store.dispatch(
      settingActions.setActiveOrdersSettings(JSON.parse(activeOrdersSettings))
    );
  if (allOrdersSettings)
    store.dispatch(
      settingActions.setAllOrdersSettings(JSON.parse(allOrdersSettings))
    );
  if (servicesSettings)
    store.dispatch(
      settingActions.setServicesSettings(JSON.parse(servicesSettings))
    );
  if (supplementaryServicesSettings)
    store.dispatch(
      settingActions.setSupplementaryServicesSettings(
        JSON.parse(supplementaryServicesSettings)
      )
    );
  if (cashBackSettings)
    store.dispatch(
      settingActions.setCashbackSettings(JSON.parse(cashBackSettings))
    );
  if (usersSettings)
    store.dispatch(settingActions.setUsersSettings(JSON.parse(usersSettings)));
  if (shopUsersSettings)
    store.dispatch(
      settingActions.setShopUsersSettings(JSON.parse(shopUsersSettings))
    );
  if (postCategoriesSettings)
    store.dispatch(
      settingActions.setPostCategoriesSettings(
        JSON.parse(postCategoriesSettings)
      )
    );
  if (postsSettings)
    store.dispatch(settingActions.setPostsSettings(JSON.parse(postsSettings)));
  if (shopsSettings)
    store.dispatch(settingActions.setShopsSettings(JSON.parse(shopsSettings)));
  if (reviewsSettings)
    store.dispatch(
      settingActions.setReviewsSettings(JSON.parse(reviewsSettings))
    );
  if (commentsSettings)
    store.dispatch(
      settingActions.setCommentsSettings(JSON.parse(commentsSettings))
    );
  if (tagsSettings)
    store.dispatch(settingActions.setTagsSettings(JSON.parse(tagsSettings)));
  if (bannersSettings)
    store.dispatch(
      settingActions.setBannersSettings(JSON.parse(bannersSettings))
    );
  if (storyGroupsSettings)
    store.dispatch(
      settingActions.setStoryGroupsSettings(JSON.parse(storyGroupsSettings))
    );
  if (storiesSettings)
    store.dispatch(
      settingActions.setStoriesSettings(JSON.parse(storiesSettings))
    );
  if (couponsSettings)
    store.dispatch(
      settingActions.setCouponsSettings(JSON.parse(couponsSettings))
    );
  if (promocodesSettings)
    store.dispatch(
      settingActions.setPromocodesSettings(JSON.parse(promocodesSettings))
    );
  if (balanceReportSettings)
    store.dispatch(
      settingActions.setBalanceReportSettings(JSON.parse(balanceReportSettings))
    );
  if (salesReportSettings)
    store.dispatch(
      settingActions.setSalesReportSettings(JSON.parse(salesReportSettings))
    );
};

export default appConfig;
