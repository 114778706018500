import React, { useEffect } from 'react';
import { MainInput } from 'components/MainInput/MainInput';
import { InputType } from 'components/MainInput/types';
import { MarginType, marginTypes } from 'enums/marginType';
import { useState } from 'react';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { ICategory } from 'api/services/categories/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as serviceActions from 'store/ducks/services/actions';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { FileModal } from '../FileModal/FileModal';
import { IResFile } from 'api/services/files/types';
import { categoryServices } from 'api/services';
import { FileList } from 'components/FileList/FileList';
import showNotification from 'helpers/showNotification';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { Link } from 'react-router-dom';
import { checkAmount } from 'helpers/checkAmount';
import { Checkbox } from 'components/Checkbox/Checkbox';
import './UpdateCategory.sass';

interface Props {
  submit: (data: ICategory) => void;
  name: string;
  titleUz: string;
  titleRu: string;
  parentId: string;
  marginType: ISelect;
  value: string;
  setValue: (value: string) => void;
  setMarginType: (value: ISelect) => void;
  setTitleRu: (value: string) => void;
  setTitleUz: (value: string) => void;
  setParentId: (value: string) => void;
  setName: (value: string) => void;
  loading: boolean;
  closeModal: () => void;
  categoryId?: number;
  order: string;
  setOrder: (value: string) => void;
}

export const UpdateCategory: React.FC<Props> = ({
  submit,
  titleRu,
  titleUz,
  setTitleRu,
  setTitleUz,
  name,
  setName,
  marginType,
  setMarginType,
  value,
  setValue,
  parentId,
  setParentId,
  loading,
  closeModal,
  categoryId,
  order,
  setOrder,
}) => {
  const [showFileModal, setShowFileModal] = useState(false);
  const [file, setFile] = useState<IResFile>();
  const [hasError, setHasError] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [parent, setParent] = useState<ISelect>();
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  const { categories } = useSelector(
    (state: AppState) => state.servicesReducer
  );

  useEffect(() => {
    getData();
  }, []);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !name.trim() ||
      (!titleRu.trim() && !titleUz.trim()) ||
      !marginType ||
      !value.trim() ||
      !file ||
      !order.trim()
    ) {
      setHasError(true);
      showNotification('Заполните поля');
      return;
    }

    const sendedMarginType = marginType?.value || (marginType as any);

    submit({
      name: name.split(' ').join('_'),
      titleRu,
      titleUz,
      isActive,
      ...(parentId.length ? { parentId: Number(parentId) } : {}),
      ...(sendedMarginType !== 'INHERIT'
        ? { marginType: sendedMarginType }
        : {}),
      ...(sendedMarginType === MarginType.INHERIT
        ? {}
        : sendedMarginType === MarginType.PERCENT
        ? { value: Math.round(Number(value)) }
        : { value: +value * 100 }),
      fileId: file?.id,
      order: Number(order),
    });
  };

  const getData = async () => {
    if (!categories.length) serviceActions.getCategories();
    if (!categoryId) return;
    const res = await categoryServices
      .getCategoryById(categoryId)
      .then((res) => res.data);
    setName(res.name);
    setMarginType(
      marginTypes.find((i) => i.value === res.marginType) || marginTypes[2]
    );
    setTitleRu(res.titleRu);
    setTitleUz(res.titleUz);
    setValue(
      res.marginType === 'PERCENT'
        ? res.value?.toString()
        : checkAmount(res.value)
    );
    setParentId(res.parentId?.toString() || '');
    setFile(res.file);
    setOrder(res.order.toString());
    const category = categories.find((i) => i.id === Number(res.parentId));
    if (category)
      setParent({ label: category.titleRu, value: category.id.toString() });
    setIsActive(res.isActive);
  };

  const fileSelected = (file: any) => {
    setFile(file);
    setShowFileModal(false);
  };

  return (
    <>
      {showFileModal && (
        <FileModal
          closeModal={() => setShowFileModal(false)}
          fileSelected={fileSelected}
        />
      )}
      <form onSubmit={formSubmit} className='update-category'>
        <div className='update-category__modal'>
          <div className='update-category__tabs'>
            <div className='update-category__tabs-item'>
              <span>Категория</span>
            </div>
            <Link
              className='update-category__tabs-item'
              to={`/catalog/categories/${categoryId}/products`}
            >
              Продукты
            </Link>
            <ChoiceLang />
          </div>
          <div className='update-category__in'>
            {
              <div className='update-category__form'>
                <label className='create-category__form-row'>
                  <div className='create-category__form-label'>Активность:</div>
                  <Checkbox isChecked={isActive} setChecked={setIsActive} />
                </label>
                <label className='update-category__form-row'>
                  <div className='update-category__form-label'>Название:</div>
                  {lang === 'ru' ? (
                    <MainInput
                      updateInputValue={setTitleRu}
                      inputValue={titleRu}
                    />
                  ) : (
                    <MainInput
                      updateInputValue={setTitleUz}
                      inputValue={titleUz}
                    />
                  )}
                </label>
                <label className='update-category__form-row'>
                  <div className='update-category__form-label'>URL:</div>
                  <MainInput updateInputValue={setName} inputValue={name} />
                </label>
                <label className='update-category__form-row'>
                  <div className='update-category__form-row-item'>
                    <div className='update-category__form-label'>
                      Тип наценки:
                    </div>
                    <div className='update-category__form-row-container'>
                      <MainSelect
                        items={marginTypes}
                        selectItem={setMarginType}
                        value={marginType}
                        isGray
                      />
                    </div>
                  </div>
                </label>
                {marginType.value !== 'INHERIT' &&
                  marginType !== ('INHERIT' as any) && (
                    <label className='update-category__form-row'>
                      <div className='update-category__form-label'>
                        Наценка:
                      </div>
                      <MainInput
                        updateInputValue={setValue}
                        inputValue={value}
                        type={InputType.NUMBER}
                      />
                    </label>
                  )}
                <label className='update-category__form-row'>
                  <div className='update-category__form-label'>Сортировка:</div>
                  <MainInput
                    updateInputValue={setOrder}
                    inputValue={order}
                    type={InputType.NUMBER}
                    error={hasError && !order.trim()}
                  />
                </label>
                {!!categories.length && parent && (
                  <label className='update-category__form-row'>
                    <div className='update-category__form-label'>Родитель:</div>
                    <MainSelect
                      items={categories.map((i) => ({
                        label: i.titleRu,
                        value: i.id.toString(),
                      }))}
                      selectItem={setParentId}
                      isGray
                      value={parent}
                    />
                  </label>
                )}
                <div className='update-category__form-row update-category__form-row-uploader'>
                  <div className='update-category__form-label'>
                    Загрузить изображение
                  </div>
                  <div className='update-category__form-uploader'>
                    {!!file ? (
                      <FileList
                        file={file}
                        deleteFile={() => setFile(undefined)}
                      />
                    ) : (
                      <FileUploader setFile={setFile} />
                    )}
                    <span
                      className='update-category__button main__button'
                      onClick={() => setShowFileModal(true)}
                    >
                      Выбрать из файла <FolderIcon />
                    </span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <ActionButtons
          closeModal={closeModal}
          formSubmit={formSubmit}
          loading={loading}
        />
      </form>
    </>
  );
};
