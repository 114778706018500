import instance from 'api/instance';
import { ICreateBrand, IGetBrand, IGetBrandById, IUpdateBrand } from './types';
import qs from 'query-string';

export const getBrands: IGetBrand = (attrs) =>
  instance.get(`/brands?${qs.stringify(attrs, { skipEmptyString: true })}`);

export const getBrandById: IGetBrandById = (id) =>
  instance.get(`/brands/${id}`);

export const createBrand: ICreateBrand = (data) =>
  instance.post('/brands', data);

export const updateBrand: IUpdateBrand = (id, data) =>
  instance.put(`/brands/${id}`, data);

export const deleteBrand = (id: number) => instance.delete(`/brands/${id}`);
