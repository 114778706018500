import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { IResAttribute } from 'api/services/attributes/types';
import { IVariantAttrubute } from 'api/services/variant-attributes/types';
import { MainInput } from 'components/MainInput/MainInput';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check-icon.svg';
import { InputType } from 'components/MainInput/types';
import './DropDown.sass';

interface Props {
  data: IResAttribute;
  onClick: (data: IVariantAttrubute) => void;
  className: string;
  variantAttributes?: any;
  optionId?: number;
  error?: boolean;
  notUpdated?: boolean;
}

const DropDown: React.FC<Props> = ({
  data,
  onClick,
  className,
  optionId,
  variantAttributes,
  error,
  notUpdated,
}) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState<number>();
  const [attrId, setAttrId] = useState<number>();
  const [newData, setNewData] = useState(data);
  const [selectedAttr, setSelectedAttr] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setAttrId(
      variantAttributes?.find((i: any) => i.attributeId === data?.id)
        ?.attributeOptionId
    );
    if (!!variantAttributes.length) {
      setSelectedAttr(
        variantAttributes?.find((i: any) => i.attributeId === data?.id)
          ?.attributeOption?.titleRu || ''
      );
    }
    if (!variantAttributes.length) {
      setNewData(data);
      setSelectedAttr('');
    }
  }, [JSON.stringify(variantAttributes), variantAttributes.length]);

  useEffect(() => {
    if (notUpdated) setSelectedAttr('');
  }, [notUpdated]);

  useEffect(() => {
    checkFocus();
  }, [isFocused]);

  const toggleHandler = (number?: number) => {
    if (!number) {
      setOpen(false);
      setId(undefined);
      return;
    }
    setId(number);
    let currentId = number;
    if (currentId === number && !open) {
      setId(number);
      setOpen(true);
    } else if (open && currentId === number) {
      setOpen(false);
    }
  };
  const selected = (data: IVariantAttrubute) => {
    onClick(data);
    setSelectedAttr(data.titleRu || data.titleUz || '');
    setAttrId(data?.attributeOptionId);
  };

  const searchAttr = (value: string) => {
    setSelectedAttr(value);
    if (!value?.trim()) {
      setNewData(data);
      onClick({
        titleRu: '',
        titleUz: '',
        attributeId: data.id,
        attributeOptionId: undefined,
      });
      return;
    }
    setNewData(() => ({
      ...data,
      options: data?.options?.filter((i: any) =>
        i.titleRu?.toLowerCase().match(value?.toLocaleLowerCase())
      ),
    }));
  };

  const checkFocus = () => {
    if (isFocused) {
      if (!!selectedAttr?.trim()) searchAttr(selectedAttr);
      toggleHandler(data?.id);
      return;
    }
    toggleHandler();
  };

  return (
    <>
      <div
        className={cn('drop-down', {
          'drop-down--active': id === data?.id && open,
          'drop-down--error': error,
          [className]: className,
        })}
      >
        <div className='drop-down__title'>{data?.titleRu}</div>
        <div className='drop-down__in'>
          {/* <div className='drop-down__label'>
            {data?.options?.find((i) => i.id === attrId)?.titleRu}
          </div> */}
          {
            <MainInput
              inputValue={selectedAttr}
              updateInputValue={searchAttr}
              type={InputType.TEXT_WITH_ICON}
              Icon={<CheckIcon />}
              setFocus={setIsFocused}
            />
          }
          <div className='drop-down__list'>
            {!newData.options?.length && (
              <div className='drop-down__item drop-down__item--not-found'>
                Результатов не найдено
              </div>
            )}
            {!!newData?.options?.length &&
              newData?.options?.map((ii, index) => (
                <div
                  key={index}
                  className='drop-down__item'
                  onClick={() =>
                    selected({
                      titleRu: ii.titleRu,
                      titleUz: ii.titleUz,
                      attributeId: data?.id,
                      attributeOptionId: ii.id,
                    })
                  }
                >
                  {ii.titleRu}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DropDown;
