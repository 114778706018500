import React, { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import CheckIcon from 'assets/images/icons/check-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow-icon.svg';
import cn from 'classnames';

import './MainSelect.sass';

export interface ISelect {
  label: React.ReactNode;
  value: string | number | boolean | null;
}

interface Props {
  items: ISelect[];
  selectItem: (value: any) => void;
  value?: ISelect;
  isGray?: boolean;
  isLang?: boolean;
  isSearchable?: boolean;
  error?: boolean;
  isMulti?: boolean;
  withIcon?: boolean;
  placeholder?: string;
  disabled?: boolean;
  whiteOption?: boolean;
}

export const MainSelect: React.FC<Props> = ({
  items,
  selectItem,
  value,
  isGray = false,
  isLang = false,
  isSearchable = false,
  isMulti = false,
  withIcon = false,
  error,
  placeholder,
  disabled,
  whiteOption,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState<ISelect>();
  const [selectItems, setSelectItems] = useState<ISelect[]>([]);
  const selectRef = useRef<any>();
  useEffect(() => {
    if (!value && !!items.length && value !== 0) {
      setSelectedItem({ value: '', label: '' });
    } else {
      setSelectedItem(
        items.find(
          (i) => i.value === value?.value || i.value === (value as any)
        )
      );
    }
  }, [!!items, value]);

  useEffect(() => {
    setSelectItems(
      items.filter(
        (i) => i.value !== value?.value && i.value !== (value as any)
      )
    );
  }, [!!value?.value, !!value, JSON.stringify(items)]);

  const changeSelectedItem = (item: any) => {
    selectItem(item.value);
    setSelectItems(items.filter((i) => i.value !== item.value));
    setSelectedItem(item);
  };

  const customStyles = {
    menu: (provided: any, state: any) => {
      return {
        ...provided,
        width: '100%',
        border: '1px solid #D8D8D8',
        padding: '0',
        background: isLang ? 'white' : '#F5F5F5',
        overflow: 'hidden',
        zIndex: '9999',
      };
    },

    control: (_: any) => {
      return {
        border: '1px solid #D8D8D8',
        borderRadius: '6px',
        padding: isLang ? '3px 3px' : '6px 9px 6px 0',
        display: 'flex',
      };
    },

    valueContainer: (provided: any) => ({
      ...provided,
      padding: isLang ? '0' : '2px 8px',
      width: isLang ? '40px' : '100%',
      textAlign: 'left',
    }),

    singleValue: (provided: any) => {
      return {
        ...provided,
        width: withIcon ? '100%' : 'fit-content',
        padding: '0',
      };
    },

    option: (provided: any, state: any) => {
      return {
        ...provided,
        ':active': { backgroundColor: 'none' },
        WebkitTapHighlightColor: 'none',
        padding: isLang ? '3px 5px' : '6px 15px',
        color: '$black',
        background: isLang || whiteOption ? 'white' : '#F5F5F5',
        cursor: 'pointer',
        width: '100%',
        textAlign: 'left',
      };
    },
    multiValue: (provided: any) => {
      return {
        ...provided,
        borderRadius: '5px',
        background: '#434AE0',
        color: '#fff',
      };
    },
    dropdownIndicator: (provided: any) => {
      return {
        ...provided,
        transition: 'all 3s ease',
        tranform: 'rotate(180deg)',
        ...(menuIsOpen ? { transform: 'rotate(0)' } : {}),
      };
    },
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {isLang ? (
          <div className='main-select__arrow-icon'>
            <ArrowIcon />
          </div>
        ) : (
          <img
            src={CheckIcon}
            alt=''
            className={cn('main-select__check-icon', {
              'main-select__check-icon--active': menuIsOpen,
            })}
          />
        )}
      </components.DropdownIndicator>
    );
  };

  return (
    <div className='main-select'>
      <Select
        ref={selectRef}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        components={{ DropdownIndicator }}
        value={selectedItem}
        isSearchable={isSearchable}
        placeholder={placeholder || 'Выбрать'}
        isMulti={isMulti}
        noOptionsMessage={() => 'Результатов не найдено'}
        onChange={changeSelectedItem}
        options={selectItems}
        className={cn('main-select__input', {
          'main-select__input--gray': isGray,
          'main-select__input--lang': isLang,
          'main-select__input--error': error,

          'main-select__input--multi': isMulti,
        })}
        styles={customStyles}
        isDisabled={disabled}
      />
    </div>
  );
};
