import React from 'react';
import cn from 'classnames';

import './MainButton.sass';

interface Props {
  title: string;
  submit?: (e: React.FormEvent) => void;
  isCancel?: boolean;
  classes?: string;
  isDisabled?: boolean;
  isDelete?: boolean;
  type?: 'button' | 'submit';
}

export const MainButton: React.FC<Props> = ({
  title,
  submit,
  classes,
  isCancel = false,
  isDisabled,
  isDelete,
  type = 'button',
}) => {
  return (
    <button
      className={cn(`main__button ${classes || ''}`, {
        'main__button--cancel': isCancel || isDisabled,
        'main__button--delete': isDelete,
      })}
      disabled={isDisabled}
      onClick={submit}
      type={type}
    >
      {title}
    </button>
  );
};
