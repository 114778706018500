import { Reducer } from 'redux';
import { IInitState, SettingType } from './types';
import {
  catalogCategoriesSettings,
  productsSettings,
  brandsSettings,
  stocksSettings,
  attributesSettings,
  currenciesSettings,
  activeOrdersSettings,
  allOrdersSettings,
  servicesSettings,
  cashBackSettings,
  usersSettings,
  postCategoriesSettings,
  postsSettings,
  shopsSettings,
  knowledgeBaseSettings,
  reviewsSettings,
  merchantReviewsSettings,
  commentsSettings,
  tagsSettings,
  bannersSettings,
  couponsSettings,
  promocodesSettings,
  storyGroupsSettings,
  storiesSettings,
  deliverySettings,
  attributeGroupsSettings,
  productCommentsSettings,
  shopUsersSettings,
  supplementaryServicesSettings,
  balanceReportSettings,
  salesReportSettings,
} from 'components/Modal/components/Settings/settingList';

const initState = {
  catalogCategoriesSettings,
  productsSettings,
  brandsSettings,
  stocksSettings,
  attributesSettings,
  currenciesSettings,
  activeOrdersSettings,
  allOrdersSettings,
  servicesSettings,
  cashBackSettings,
  usersSettings,
  postCategoriesSettings,
  postsSettings,
  shopsSettings,
  knowledgeBaseSettings,
  reviewsSettings,
  merchantReviewsSettings,
  commentsSettings,
  tagsSettings,
  bannersSettings,
  couponsSettings,
  promocodesSettings,
  storyGroupsSettings,
  storiesSettings,
  deliverySettings,
  attributeGroupsSettings,
  productCommentsSettings,
  shopUsersSettings,
  supplementaryServicesSettings,
  balanceReportSettings,
  salesReportSettings,
};

const reducer: Reducer<IInitState> = (state = initState, action) => {
  const { type, items } = action;

  switch (type) {
    case SettingType.SET_CATALOG_CATEGORIES_SETTINGS:
      return {
        ...state,
        catalogCategoriesSettings: items,
      };
    case SettingType.SET_PRODUCTS_SETTINGS:
      return {
        ...state,
        productsSettings: items,
      };
    case SettingType.SET_BRANDS_SETTINGS:
      return {
        ...state,
        brandsSettings: items,
      };
    case SettingType.SET_STOCKS_SETTINGS:
      return {
        ...state,
        stocksSettings: items,
      };
    case SettingType.SET_ATTRIBUTES_SETTINGS:
      return {
        ...state,
        attributesSettings: items,
      };
    case SettingType.SET_SUPPLEMENTARY_SERVICES_SETTINGS:
      return {
        ...state,
        supplementaryServicesSettings: items,
      };
    case SettingType.SET_ATTRIBUTE_GROUPS_SETTINGS:
      return {
        ...state,
        attributeGroupsSettings: items,
      };
    case SettingType.SET_CURRENCIES_SETTINGS:
      return {
        ...state,
        currenciesSettings: items,
      };
    case SettingType.SET_ACTIVE_ORDERS_SETTINGS:
      return {
        ...state,
        activeOrdersSettings: items,
      };
    case SettingType.SET_ALL_ORDERS_SETTINGS:
      return {
        ...state,
        allOrdersSettings: items,
      };
    case SettingType.SET_SERVICES_SETTINGS:
      return {
        ...state,
        servicesSettings: items,
      };
    case SettingType.SET_CASHBACK_SETTINGS:
      return {
        ...state,
        cashBackSettings: items,
      };
    case SettingType.SET_USERS_SETTINGS:
      return {
        ...state,
        usersSettings: items,
      };
    case SettingType.SET_SHOP_USERS_SETTINGS:
      return {
        ...state,
        shopUsersSettings: items,
      };
    case SettingType.SET_POST_CATEGORIES_SETTINGS:
      return {
        ...state,
        postCategoriesSettings: items,
      };
    case SettingType.SET_POSTS_SETTINGS:
      return {
        ...state,
        postsSettings: items,
      };
    case SettingType.SET_SHOPS_SETTINGS:
      return {
        ...state,
        shopsSettings: items,
      };
    case SettingType.SET_KNOWLEDGE_BASE_SETTINGS:
      return {
        ...state,
        knowledgeBaseSettings: items,
      };
    case SettingType.SET_REVIEWS_SETTINGS:
      return {
        ...state,
        reviewsSettings: items,
      };
    case SettingType.SET_MERCHANT_REVIEWS_SETTINGS:
      return {
        ...state,
        merchantReviewsSettings: items,
      };
    case SettingType.SET_COMMENTS_SETTINGS:
      return {
        ...state,
        commentsSettings: items,
      };
    case SettingType.SET_PRODUCT_COMMENTS_SETTINGS:
      return {
        ...state,
        productCommentsSettings: items,
      };
    case SettingType.SET_TAGS_SETTINGS:
      return {
        ...state,
        tagsSettings: items,
      };
    case SettingType.SET_BANNERS_SETTINGS:
      return {
        ...state,
        bannersSettings: items,
      };
    case SettingType.SET_STORY_GROUPS_SETTINGS:
      return {
        ...state,
        storyGroupsSettings: items,
      };
    case SettingType.SET_STORIES_SETTINGS:
      return {
        ...state,
        storiesSettings: items,
      };
    case SettingType.SET_COUPONS_SETTINGS:
      return {
        ...state,
        couponsSettings: items,
      };
    case SettingType.SET_PROMO_CODES_SETTINGS:
      return {
        ...state,
        promocodesSettings: items,
      };
    case SettingType.SET_DELIVERY_SETTINGS:
      return {
        ...state,
        deliverySettings: items,
      };
    case SettingType.SET_BALANCE_REPORT_SETTINGS:
      return {
        ...state,
        balanceReportSettings: items,
      };
    case SettingType.SET_SALES_REPORT_SETTINGS:
      return {
        ...state,
        salesReportSettings: items,
      };
    default:
      return state;
  }
};

export default reducer;
