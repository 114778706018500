import { AxiosPromise } from 'axios';

export enum FileType {
  IMAGE = 'IMAGE',
  FOLDER = 'FOLDER',
  VIDEO = 'VIDEO',
}

export enum ImageSize {
  PREVIEW = 'preview',
  PRODUCT_SMALL = 'product-small',
  PRODUCT_MAIN = 'product-main',
  PRODUCT_MEDIUM = 'product-medium',
}

export interface IFileFolder {
  name: string;
  parentId?: number;
}

export interface IFile {
  name: string;
  ext?: string;
  userId: number;
  type: FileType;
  oldName?: string;
}

export interface IResFile extends IFile {
  createdAt: Date;
  updatedAt: Date;
  id: number;
  parent: IResFile;
}

export interface IGetFile {
  (attrs?: {
    userId?: string;
    parentId?: string;
    skip: number;
    take: number;
    search?: string;
  }): AxiosPromise<{
    data: IResFile[];
    count: number;
  }>;
}

export interface ICreateFileFolder {
  (data: IFileFolder): AxiosPromise;
}

export interface IUpdateFileFolder {
  (id: string, data: IFileFolder): AxiosPromise;
}

export interface IUploadFile {
  (file: FormData): AxiosPromise;
}

// export interface IUploadFiles {
//   (files: FormData): AxiosPromise<{ errors: string[]; success: IResFile[] }>;
// }

export interface IGetFileById {
  (id: string): AxiosPromise<IResFile>;
}

export interface IDeleteFiles {
  ({ fileIds }: { fileIds: number[] }): AxiosPromise;
}
