import React, { useEffect } from 'react';
import { MainSelect } from 'components/MainSelect/MainSelect';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as localeActions from 'store/ducks/locale/actions';

import './ChoiceLang.sass';

export const ChoiceLang: React.FC = () => {
  const { lang } = useSelector((state: AppState) => state.localeReducer);
  const dispatch = useDispatch();
  const changeLang = (value: string) => {
    dispatch(localeActions.setLang(value));
  };

  return (
    <div className='choice-lang'>
      <span className='choice-lang__title'>Язык заполнения:</span>
      {lang && (
        <MainSelect
          items={[
            { label: 'RU', value: 'ru' },
            { label: 'UZ', value: 'uz' },
          ]}
          selectItem={changeLang}
          value={{
            label: lang == 'ru' ? 'RU' : 'UZ',
            value: lang,
          }}
          isLang
        />
      )}
    </div>
  );
};
