import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { IShop } from 'api/services/shops/types';
import { MainInput } from 'components/MainInput/MainInput';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { productServices, shopServices } from 'api/services';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { FileList } from 'components/FileList/FileList';
import { IResFile } from 'api/services/files/types';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { FileModal } from '../FileModal/FileModal';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { InputType } from 'components/MainInput/types';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { IResProduct } from 'api/services/products/types';
import './UpdateShop.sass';

interface Props {
  submit: (data: IShop) => void;
  closeModal: () => void;
  shopId?: number;
}

const statuses: ISelect[] = [
  {
    label: 'Активный',
    value: true,
  },
  {
    label: 'Неактивный',
    value: false,
  },
];

export const UpdateShop: React.FC<Props> = ({ submit, closeModal, shopId }) => {
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [file, setFile] = useState<IResFile>();
  const [hasError, setHasError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState<ISelect>(statuses[1]);
  const [descriptionRu, setDescriptionRu] = useState('');
  const [descriptionUz, setDescriptionUz] = useState('');
  const [products, setProducts] = useState<IResProduct[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const [productSearchValue, setProductSearchValue] = useState('');
  const [selectedProducts, setSelectedProducts] = useState<IResProduct[]>([]);
  const [searchableProducts, setSearchableProducts] = useState<
    ISearchableSelect[]
  >([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [account, setAccount] = useState('');
  const [bank, setBank] = useState('');
  const [mfo, setMfo] = useState('');
  const [tin, setTin] = useState('');
  const [phone, setPhone] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  const { lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    getDataById();
    getProducts();
  }, [shopId]);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !name.trim() ||
      !url.trim() ||
      (!descriptionRu.trim() && !descriptionUz.trim())
    ) {
      showNotification('Заполните поля', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    if (!file) {
      showNotification('Выберите изображение', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    const isActive = status.value || status;
    submit({
      fileId: file.id,
      name,
      url,
      isActive: isActive === 'false' ? false : true,
      descriptionRu: descriptionRu,
      descritionUz: descriptionRu,
      favoriteProducts: selectedProducts.map((i) => i.id),
      address,
      mfo,
      tin,
      bank,
      phone,
      account,
      additionalInformation: additionalInfo,
    });
  };

  const getDataById = async () => {
    if (!shopId) return;
    const res = await shopServices.getShopById(shopId).then((res) => res.data);
    setName(res.name);
    setUrl(res.url);
    setFile(res.file);
    const findStatus = statuses.find((i) => i.value === res.isActive);
    if (findStatus) setStatus(findStatus);
    setDescriptionRu(res.descriptionRu || '');
    setDescriptionUz(res.descriptionUz || '');
    setSelectedProducts(res.favoriteProducts);
    setAddress(res.address || '');
    setAdditionalInfo(res.additionalInformation || '');
    setPhone(res.phone || '');
    setMfo(res.mfo || '');
    setTin(res.tin || '');
    setBank(res.bank || '');
    setAccount(res.account || '');
  };
  const selectFile = (file: IResFile) => {
    setFile(file);
    setShowModal(false);
  };

  const selectProducts = (id: number) => {
    const product = products?.find((i) => i.id === id);

    if (!product) return;
    if (!selectedProducts.some((i) => i.id === product.id)) {
      if (selectedProducts.length >= 6) {
        showNotification(
          'Количество продуктов не должно превышать 6',
          NotificationStatus.WARNING
        );
        return;
      }
      setSelectedProducts((prev) => [...prev, product]);
    }
  };

  const getProducts = async (value?: string) => {
    if (value && value?.length < 3) {
      setProductsLoading(false);
      setSearchableProducts([]);
      return;
    }
    try {
      const res = await productServices
        .getProducts({
          skip: 0,
          take: 20,
          shopId,
          search: value && value.length < 3 ? '' : value,
        })
        .then((res) => res.data);

      const products = res.data.map((i) => ({
        name: i.titleRu,
        value: i.id,
      }));
      setProducts(res.data);
      setSearchableProducts(products);
    } finally {
      setProductsLoading(false);
    }
  };

  return (
    <>
      {showModal && (
        <FileModal
          fileSelected={selectFile}
          closeModal={() => setShowModal(false)}
        />
      )}
      <form className='update-shop' onSubmit={formSubmit}>
        <div className='update-shop__modal'>
          <div className='update-shop__tabs'>
            <div className='update-shop__tabs-item'>
              <span>Магазин</span>
            </div>
          </div>
          <div className='update-shop__in'>
            <div className='update-shop__form'>
              <label className='update-shop__form-row'>
                <div className='update-shop__form-label'>Название:</div>
                <MainInput updateInputValue={setName} inputValue={name} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Адрес:</div>
                <MainInput updateInputValue={setAddress} inputValue={address} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Расчетный счет:</div>
                <MainInput updateInputValue={setAccount} inputValue={account} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Банк:</div>
                <MainInput updateInputValue={setBank} inputValue={bank} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>МФО:</div>
                <MainInput updateInputValue={setMfo} inputValue={mfo} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>ИНН:</div>
                <MainInput updateInputValue={setTin} inputValue={tin} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Телефон:</div>
                <MainInput
                  updateInputValue={setPhone}
                  inputValue={phone}
                  type={InputType.PHONE_NUMBER}
                />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>
                  Дополнительная информация:
                </div>
                <MainInput
                  updateInputValue={setAdditionalInfo}
                  inputValue={additionalInfo}
                />
              </label>
              <label className='update-shop__form-row'>
                <div className='update-shop__form-label'>URL:</div>
                <MainInput updateInputValue={setUrl} inputValue={url} />
              </label>
              <label className='update-shop__form-row'>
                <div className='update-shop__form-label'>Статус:</div>
                <MainSelect
                  items={statuses}
                  selectItem={setStatus}
                  isGray
                  value={status}
                />
              </label>
              <label className='update-shop__form-row'>
                <div className='update-shop__form-label'>Описание:</div>
                {lang === 'ru' ? (
                  <MainInput
                    updateInputValue={setDescriptionRu}
                    inputValue={descriptionRu}
                    error={hasError && !descriptionRu.trim()}
                  />
                ) : (
                  <MainInput
                    updateInputValue={setDescriptionUz}
                    inputValue={descriptionUz}
                    error={hasError && !descriptionUz.trim()}
                    type={InputType.TEXTAREA}
                  />
                )}
              </label>
              <label className='update-shop__form-row'>
                <div className='update-shop__form-label'>
                  Избранные продукты:
                </div>

                <CustomSearchableSelect
                  items={searchableProducts}
                  loading={productsLoading}
                  setValue={getProducts}
                  setLoading={setProductsLoading}
                  setSelectItem={selectProducts}
                  searchValue={productSearchValue}
                  setSearchValue={setProductSearchValue}
                />
              </label>
              <div className='update-shop__row'>
                <div className='update-shop__row-list'>
                  {selectedProducts.map((i) => (
                    <div className='update-shop__row-list-item' key={i.id}>
                      <span>{i.titleRu}</span>
                      <div
                        className='update-shop__row-list-item-close'
                        onClick={() =>
                          setSelectedProducts((prev) =>
                            prev.filter((ii) => ii.id !== i.id)
                          )
                        }
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='update-shop__form-row update-shop__form-row-uploader'>
              <div className='update-shop__uploader'>
                {!!file ? (
                  <FileList file={file} deleteFile={() => setFile(undefined)} />
                ) : (
                  <FileUploader setFile={setFile} error={hasError && !file} />
                )}
              </div>
              <button
                className='update-shop__choice-file main__button'
                onClick={() => setShowModal(true)}
                type='button'
              >
                Выбрать из файла
                <FolderIcon />
              </button>
            </div>
          </div>
        </div>
        <ActionButtons formSubmit={formSubmit} closeModal={closeModal} />
      </form>
    </>
  );
};
