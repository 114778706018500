import React, { useEffect } from 'react';
import { MainInput } from 'components/MainInput/MainInput';
import { InputType } from 'components/MainInput/types';
import { MarginType, marginTypes } from 'enums/marginType';
import { useState } from 'react';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { ICategory } from 'api/services/categories/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as serviceActions from 'store/ducks/services/actions';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { MainButton } from 'components/MainButton/MainButton';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { FileModal } from '../FileModal/FileModal';
import { fileServices } from 'api/services';
import { IFileFolder, IResFile } from 'api/services/files/types';
import * as templateActions from 'store/ducks/template/actions';
import { CreateFileCategoryFolder } from '../CreateFileCategoryFolder/CreateFileCategoryFolder';
import { Modal } from 'components/Modal/Modal';
import showNotification from 'helpers/showNotification';
import { FileList } from 'components/FileList/FileList';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import * as localeActions from 'store/ducks/locale/actions';

import './CreateCategory.sass';
import { Checkbox } from 'components/Checkbox/Checkbox';

interface Props {
  submit: (data: ICategory) => void;
  name: string;
  titleUz: string;
  titleRu: string;
  parentId: string;
  marginType: ISelect;
  value: string;
  setValue: (value: string) => void;
  setMarginType: (value: ISelect) => void;
  setTitleRu: (value: string) => void;
  setTitleUz: (value: string) => void;
  setParentId: (value: string) => void;
  setName: (value: string) => void;
  order: string;
  setOrder: (value: string) => void;
  closeModal: () => void;
  loading: boolean;
}

export const CreateCategory: React.FC<Props> = ({
  submit,
  titleRu,
  titleUz,
  setTitleRu,
  setTitleUz,
  name,
  setName,
  marginType,
  setMarginType,
  value,
  setValue,
  parentId,
  setParentId,
  closeModal,
  loading,
  order,
  setOrder,
}) => {
  const [showFileModal, setShowFileModal] = useState(false);
  const [file, setFile] = useState<IResFile>();
  const [hasError, setHasError] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    getData();
  }, []);

  const { categories } = useSelector(
    (state: AppState) => state.servicesReducer
  );

  const getData = async () => {
    if (!categories.length) dispatch(serviceActions.getCategories());
  };

  const fileSelected = (file: IResFile) => {
    setFile(file);
    setShowFileModal(false);
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      (!titleRu.trim() && !titleUz.trim()) ||
      !marginType ||
      !name.trim() ||
      !file
    ) {
      showNotification('Заполните обязательные поля');
      setHasError(true);
      return;
    }
    submit({
      name,
      titleRu,
      titleUz,
      isActive,
      ...(parentId.length ? { parentId: Number(parentId) } : {}),
      ...(marginType?.value !== 'INHERIT'
        ? { marginType: marginType.value as any }
        : {}),
      ...(marginType === (MarginType.INHERIT as any) ||
      marginType.value === MarginType.INHERIT
        ? {}
        : marginType === (MarginType.PERCENT as any) ||
          marginType.value === MarginType.INHERIT
        ? { value: Math.round(Number(value)) }
        : { value: +value * 100 }),
      fileId: Number(file.id),
      order: Number(order),
    });
    setHasError(false);
  };

  const updateName = (value: string) => {
    setName(value.split(' ').join('_'));
  };

  return (
    <>
      {showFileModal && (
        <FileModal
          closeModal={() => setShowFileModal(false)}
          fileSelected={fileSelected}
        />
      )}
      <form onSubmit={formSubmit} className='create-category'>
        <div className='create-category__modal'>
          <div className='create-category__tabs'>
            <div className='create-category__tabs-item'>
              <span>Категория</span>
            </div>
            <ChoiceLang />
          </div>
          <div className='create-category__in'>
            {
              <div className='create-category__form'>
                <label className='create-category__form-row'>
                  <div className='create-category__form-label'>Активность:</div>
                  <Checkbox isChecked={isActive} setChecked={setIsActive} />
                </label>
                <label className='create-category__form-row'>
                  <div className='create-category__form-label'>Название:</div>
                  {lang === 'ru' ? (
                    <MainInput
                      updateInputValue={setTitleRu}
                      inputValue={titleRu}
                      error={hasError && !titleRu.trim()}
                    />
                  ) : (
                    <MainInput
                      updateInputValue={setTitleUz}
                      inputValue={titleUz}
                      error={hasError && !titleUz.trim()}
                    />
                  )}
                </label>
                <label className='create-category__form-row'>
                  <div className='create-category__form-label'>URL:</div>
                  <MainInput
                    updateInputValue={updateName}
                    inputValue={name}
                    error={hasError && !name.trim()}
                  />
                </label>
                <label className='create-category__form-row'>
                  <div className='create-category__form-row-item'>
                    <div className='create-category__form-label'>
                      Тип наценки:
                    </div>
                    <div className='create-category__form-row-container'>
                      <MainSelect
                        items={marginTypes}
                        selectItem={setMarginType}
                        value={marginType}
                        isGray
                      />
                    </div>
                  </div>
                </label>
                {marginType.value !== 'INHERIT' &&
                  marginType !== ('INHERIT' as any) && (
                    <label className='create-category__form-row'>
                      <div className='create-category__form-label'>
                        Наценка:
                      </div>
                      <MainInput
                        updateInputValue={setValue}
                        inputValue={value}
                        type={InputType.NUMBER}
                        error={hasError && !value}
                      />
                    </label>
                  )}
                <label className='create-category__form-row'>
                  <div className='create-category__form-label'>Сортировка:</div>
                  <MainInput
                    updateInputValue={setOrder}
                    inputValue={order}
                    type={InputType.NUMBER}
                    error={hasError && !order.trim()}
                  />
                </label>
                {!!categories.length && (
                  <label className='create-category__form-row'>
                    <div className='create-category__form-label'>Родитель:</div>
                    <MainSelect
                      items={categories.map((i) => ({
                        label: i.titleRu,
                        value: i.id.toString(),
                      }))}
                      selectItem={setParentId}
                      isGray
                      isSearchable
                    />
                  </label>
                )}
                <div className='create-category__form-row create-category__form-row-uploader'>
                  <div className='create-category__form-label'>
                    Загрузить изображение
                  </div>
                  <div className='create-category__form-uploader'>
                    <div className='create-category__form-uploader-files'>
                      {!!file ? (
                        <FileList
                          file={file}
                          deleteFile={() => setFile(undefined)}
                        />
                      ) : (
                        <FileUploader
                          setFile={setFile}
                          error={hasError && !file}
                        />
                      )}
                    </div>
                    <span
                      className='create-category__button main__button'
                      onClick={() => setShowFileModal(true)}
                    >
                      Выбрать из файла <FolderIcon />
                    </span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <ActionButtons
          closeModal={closeModal}
          formSubmit={formSubmit}
          loading={loading}
        />
      </form>
    </>
  );
};
