import React, { useRef, useState } from 'react';
import { FILE_URL } from 'constants/project';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg';

import { AppState } from 'store/store';
import { IResFile } from 'api/services/files/types';
import { ShowFile } from 'components/ShowFile/ShowFile';
import cn from 'classnames';
import './FileList.sass';

interface Props {
  list?: IResFile[];
  file?: IResFile;
  deleteFile?: () => void;
  deleteOneFile?: (id: number) => void;
  openImg?: boolean;
}

export const FileList: React.FC<Props> = ({
  list,
  file,
  deleteFile,
  deleteOneFile,
  openImg,
}) => {
  const [showImg, setShowImg] = useState(false);
  const [selectedFile, setSelectedFile] = useState<IResFile>();
  const imageRef = useRef<any>();

  const openFile = (showedFile: IResFile) => {
    if (!openImg) return;
    setShowImg(true);
    setSelectedFile(showedFile);
  };

  return (
    <div className='file-list'>
      {showImg && selectedFile && openImg && (
        <ShowFile closeFile={() => setShowImg(false)} file={selectedFile} />
      )}
      {list ? (
        <>
          <div className='file-list__items'>
            {list.map((i, index) => (
              <div className='file-list__item' key={index}>
                {deleteOneFile && (
                  <div
                    className='file-list__item-delete'
                    onClick={() => deleteOneFile(i.id)}
                  >
                    <CloseIcon />
                  </div>
                )}
                <div
                  className={cn('file-list__item-container', {
                    'file-list__item-container--active': openImg,
                  })}
                  onClick={() => openFile(i)}
                >
                  {i.type === 'IMAGE' ? (
                    <img
                      src={`${FILE_URL}/${i?.userId}/${i.name}.${i.ext}`}
                      alt=''
                      ref={imageRef}
                    />
                  ) : (
                    <video
                      src={`${FILE_URL}/${i?.userId}/${i.name}.${i?.ext}`}
                    />
                  )}
                </div>
              </div>
            ))}
            {/* <div className='file-list__item file-list__item--add'>
              <PlusIcon />
            </div> */}
          </div>
        </>
      ) : (
        <div className='file-list__item'>
          {deleteFile && (
            <div className='file-list__item-delete' onClick={deleteFile}>
              <CloseIcon />
            </div>
          )}
          <div className='file-list__item-container'>
            {file?.type === 'IMAGE' ? (
              <img
                src={`${FILE_URL}/${file?.userId}/${file?.name}.${file?.ext}`}
                alt=''
              />
            ) : (
              <video
                src={`${FILE_URL}/${file?.userId}/${file?.name}.${file?.ext}`}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
