import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as GlassIcon } from 'assets/images/icons/glass-icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/icons/settings-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete-icon.svg';
import { ReactComponent as PicIcon } from 'assets/images/icons/pic-icon.svg';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { IResFile } from 'api/services/files/types';
import { ReactComponent as CalendarIcon } from 'assets/images/icons/calendar-icon.svg';
import cn from 'classnames';

import './Filter.sass';
import { MainInput } from 'components/MainInput/MainInput';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { brandServices, shopServices } from 'api/services';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { isShop } from 'helpers/checkUser';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

interface Props {
  createFunc?: () => void;
  settingFunc?: () => void;
  deleteFunc?: () => void;
  hashes?: IHash[];
  onlyFilter?: boolean;
  buttonActions?: {
    uploadFile: (fileList: IResFile[]) => void;
    createFolder: () => void;
    deleteItems: () => void;
  };
  disabledButton?: boolean;
  selectHash?: (value: number | string) => void;
  filterFunc?: () => void;
  searchValue?: string;
  updateSearchValue?: (value: string) => void;
  search?: () => void;
  loading?: boolean;
  hideFilter?: boolean;
  hideButton?: boolean;
  productImport?: () => void;
  productExport?: () => void;
  date?: Date[];
  setDate?: (date: any) => void;
  isReport?: boolean;
  selectedBrand?: number;
  setSelectedBrand?: (value: number) => void;
  selectedShop?: number;
  setSelectedShop?: (value: number) => void;
}

export interface IHash {
  title: string;
  value: string | number;
}

export const Filter: React.FC<Props> = ({
  createFunc,
  settingFunc,
  deleteFunc,
  hashes,
  onlyFilter = false,
  buttonActions,
  disabledButton = false,
  selectHash,
  filterFunc,
  searchValue,
  updateSearchValue,
  search,
  loading,
  hideFilter,
  hideButton,
  productImport,
  productExport,
  date,
  setDate,
  isReport,
  selectedBrand,
  setSelectedBrand,
  selectedShop,
  setSelectedShop,
}) => {
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const fileRef = useRef<any>();
  const [shops, setShops] = useState<ISearchableSelect[]>([]);
  const [shopsLoading, setShopsLoading] = useState(false);
  const [searchableShopValue, setSearchableShopValue] = useState('');
  const [brands, setBrands] = useState<ISearchableSelect[]>([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [searchableBrandValue, setSearchableBrandValue] = useState('');

  const { user } = useSelector((state: AppState) => state.profileReducer);

  useEffect(() => {
    if (isReport) {
      getBrands();
      if (!isShop(user?.role)) {
        getShops();
      }
    }
  }, []);

  const uploadFile = () => {
    const fileList = [...fileRef.current.files];
    buttonActions?.uploadFile(fileList);
    fileRef.current.value = '';
  };

  const filterSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if (updateSearchValue) updateSearchValue(filterSearchValue);
  };

  const getShops = async (value?: string) => {
    try {
      const res = await shopServices
        .getShops({ skip: 0, take: 25, search: value })
        .then((res) => res.data);
      setShops(
        res.data.map((i) => ({
          name: i.name,
          value: i.id,
        }))
      );
    } finally {
      setShopsLoading(false);
    }
  };

  const getBrands = async (value?: string) => {
    try {
      const res = await brandServices
        .getBrands({ skip: 0, take: 25, search: value })
        .then((res) => res.data);
      setBrands(
        res.data.map((i) => ({
          name: i.name,
          value: i.id,
        }))
      );
    } finally {
      setBrandsLoading(false);
    }
  };

  return (
    <div className={cn('filter', { 'filter--pic': buttonActions })}>
      <div className='filter__container'>
        {isReport && (
          <div className='filter__report'>
            <div className='filter__toFrom'>
              <div className='filter__report-title'>Дата</div>
              <div className='custom-date-time-picker filter__date'>
                <DateTimeRangePicker
                  value={date}
                  onChange={setDate}
                  format={'dd.MM.yyyy'}
                  clearIcon={null}
                  calendarIcon={<CalendarIcon />}
                />
              </div>
            </div>
            {setSelectedShop && !isShop(user?.role) && (
              <div className='filter__shops'>
                <div className='filter__report-title'>Магазин</div>
                <CustomSearchableSelect
                  items={[{ name: 'Нет', value: null }, ...shops]}
                  setSelectItem={setSelectedShop}
                  value={selectedShop}
                  setLoading={setShopsLoading}
                  loading={shopsLoading}
                  setSearchValue={setSearchableShopValue}
                  searchValue={searchableShopValue}
                  setValue={getShops}
                  placeholder='Выбрать'
                  isWhite
                />
              </div>
            )}
            {setSelectedBrand && (
              <div className='filter__brands'>
                <div className='filter__report-title'>Бренд</div>
                <CustomSearchableSelect
                  items={[{ name: 'Нет', value: null }, ...brands]}
                  setSelectItem={setSelectedBrand}
                  value={selectedBrand}
                  setLoading={setBrandsLoading}
                  loading={brandsLoading}
                  setSearchValue={setSearchableBrandValue}
                  searchValue={searchableBrandValue}
                  setValue={getBrands}
                  placeholder='Выбрать'
                  isWhite
                />
              </div>
            )}
          </div>
        )}
        {!!updateSearchValue && (
          <>
            {!hideFilter && (
              <h4 className='filter__title' onClick={filterFunc}>
                Фильтр
              </h4>
            )}
            <form className='filter__search' onSubmit={filterSubmit}>
              <input
                type='text'
                placeholder='Поиск ...'
                value={filterSearchValue}
                onChange={(e) =>
                  setFilterSearchValue((e.target as HTMLInputElement).value)
                }
              />
              <button className='filter__search-button' onClick={filterSubmit}>
                <GlassIcon />
              </button>
            </form>
          </>
        )}
        {!onlyFilter && !buttonActions && (
          <div className='filter__buttons'>
            {deleteFunc && (
              <button className='filter__buttons-delete' onClick={deleteFunc}>
                Удалить <DeleteIcon />
              </button>
            )}
            {productExport && (
              <button
                onClick={productExport}
                className='filter__buttons-export'
              >
                Экспорт
              </button>
            )}
            {productImport && (
              <button
                onClick={productImport}
                className='filter__buttons-import'
              >
                Импорт
              </button>
            )}
            {createFunc && !hideButton && (
              <button onClick={createFunc} className='filter__buttons-create'>
                Создать +
              </button>
            )}
            <SettingsIcon
              className='filter__buttons-settings'
              onClick={() => (settingFunc ? settingFunc() : {})}
            />
          </div>
        )}
        {buttonActions && (
          <div className='filter__buttons filter__buttons--pic'>
            {/* <button
              className='filter__buttons-create'
              disabled={disabledButton}
            >
              Готово
            </button> */}
            <label className='filter__buttons-create filter__buttons-create--pic'>
              Загрузить изображение
              <input
                ref={fileRef}
                type='file'
                onChange={uploadFile}
                accept='image/jpg, image/JPEG, image/JPG, image/jpeg, image/png, image/PNG, image/WEBP, image/webp, video/mp4, video/MP4, video/mov, video/MOV, video/webm, video/WEBM'
                className='custom-file-input'
                multiple
              />
              <PicIcon />
            </label>
            <button
              className='filter__buttons-create filter__buttons-create--pic'
              onClick={buttonActions.createFolder}
            >
              Создать папку <FolderIcon />
            </button>

            <button
              className='filter__buttons-delete'
              onClick={buttonActions.deleteItems}
            >
              Удалить <DeleteIcon />
            </button>
            <SettingsIcon
              className='filter__buttons-settings'
              onClick={() => (settingFunc ? settingFunc() : {})}
            />
          </div>
        )}
      </div>
      {hashes && selectHash && (
        <div className='filter__hashes'>
          {hashes.map((i) => (
            <div
              className='filter__hashes-item'
              key={i.value}
              onClick={() => selectHash(i.value)}
            >
              {i.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
