import React, { useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { MainInput } from 'components/MainInput/MainInput';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { InputType } from 'components/MainInput/types';
import { Switch } from 'components/Switch/Switch';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { MainButton } from 'components/MainButton/MainButton';
import './Promocode.sass';
import { RadioType } from 'enums/radioType';
import { ActionButtons } from '../../ActionButtons/ActionButtons';
import { CustomDateTimePicker } from 'components/CustomDateTimePicker/CustomDateTimePicker';
import { promocodesServices } from 'api/services';
import { IPromocode } from 'api/services/promocodes/types';

interface Props {
  submit: (data: any) => void;
  closeModal: () => void;
}

export const Promocode: React.FC<Props> = ({ submit, closeModal }) => {
  const [descriptionType, setDescriptionType] = useState(RadioType.TEXT);
  const [name, setName] = useState('');
  const [expireAt, setExpireAt] = useState<Date>();
  const [count, setCount] = useState('');
  const [sum, setSum] = useState('');
  const [code, setCode] = useState('');
  const [isActive, setIsActive] = useState(false);

  const changeDescriptionType = (type: RadioType) => {
    setDescriptionType(type);
  };

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data: IPromocode = {
      name,
      ...(expireAt ? { expireAt } : {}),
      count: Number(count),
      sum: Number(sum) * 100,
      code,
      isActive,
    };
    submit(data);
  };

  const genCode = async () => {
    const resCode = await promocodesServices.genCode().then((res) => res.data);
    setCode(resCode);
  };

  return (
    <form onSubmit={formSubmit} className='promocode'>
      <div className='promocode__in'>
        <div className='promocode__row'>
          <label className='promocode__row-item'>
            <div className='promocode__label'>Активность</div>
            <Checkbox isChecked={isActive} setChecked={setIsActive} />
          </label>
        </div>
        <div className='promocode__row'>
          <label className='promocode__row-item'>
            <div className='promocode__label'>Наименование:</div>
            <MainInput updateInputValue={setName} inputValue={name} />
          </label>
        </div>
        <div className='promocode__row'>
          <label className='promocode__row-item'>
            <div className='promocode__label'>Срок действия:</div>
            <CustomDateTimePicker
              date={expireAt}
              setDate={setExpireAt}
              onlyDate
            />
          </label>
        </div>
        <div className='promocode__row'>
          <label className='promocode__row-item'>
            <div className='promocode__label'>Количество пользователей:</div>
            <MainInput
              inputValue={count}
              updateInputValue={setCount}
              type={InputType.NUMBER}
            />
          </label>
        </div>
        {/* <div className='promocode__row'>
          <label className='promocode__row-item'>
            <div className='promocode__label'>Бюджет</div>
            <MainInput />
          </label>
        </div> */}
        <div className='promocode__row'>
          <label className='promocode__row-item'>
            <div className='promocode__label'>Сумма промокода:</div>
            <MainInput
              inputValue={sum}
              updateInputValue={setSum}
              type={InputType.NUMBER}
            />
          </label>
        </div>
        <div className='promocode__row'>
          <label className='promocode__row-item'>
            <div className='promocode__label'>
              Генерировать или написать промокод
            </div>
            <div className='promocode__row-container'>
              <MainInput
                inputValue={code}
                updateInputValue={setCode}
                placeholder='Написать код'
              />
              <div className='promocode__row-buttons'>
                <MainButton
                  title='Сгенерировать'
                  submit={genCode}
                  type='button'
                />
              </div>
            </div>
          </label>
        </div>
      </div>
      <ActionButtons formSubmit={formSubmit} closeModal={closeModal} />
    </form>
  );
};
