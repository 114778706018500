export enum AppTypes {
  SET_LANG = '@i18n/SET_LANG',
}

export interface IState {
  lang: string;
  key: string;
  data: {
    [key: string]: {
      [key: string]: {
        [key: string]: string;
      };
    };
  };
}

export type IData = {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: string;
      };
    };
  };
};

type ISetLang = (lang: string | null) => {
  type: AppTypes.SET_LANG;
  lang: string;
};

export type AppActionTypes = ISetLang;
