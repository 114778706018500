import React, { useEffect, useRef, useState } from 'react';
import { OrderItemCard } from 'components/OrderItemCard/OrderItemCard';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';
import { orderServices } from 'api/services';
import { OrderStatus as orderStatus } from 'enums/orderStatus';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { UserRole } from 'enums/users';
import './OrderStatus.sass';
import socket from 'helpers/socket';
import showNotification from 'helpers/showNotification';

export const OrderStatus: React.FC = () => {
  const [arr, setArr] = useState<any[]>([]);

  const { user } = useSelector((state: AppState) => state.profileReducer);

  const ordersRef = useRef<any[]>([]);

  useEffect(() => {
    getDataForCourier();
    getDataForShop();
    getData();
    subscribeOrder();
  }, []);

  const subscribeOrder = () => {
    socket.connect().on('orders', async (id: number) => {
      const res = await orderServices.getOrderById(id).then((res) => res.data);
      const result = ordersRef.current
        .map((i: any) => ({
          ...i,
          items: i.items.filter((ii: any) => ii.id !== id.toString()),
        }))
        .map((i) => {
          if (res.status === i.status) {
            i.items.unshift({ ...res, id: res.id.toString() });
          }
          // return {...i, items: i.items.sort()};
          return i;
        });
      ordersRef.current = result;
      setArr(result);
    });
  };

  const getDataForShop = async () => {
    if (
      user?.role !== UserRole.SHOP_EMPLOYEE &&
      user?.role !== UserRole.SHOP_OWNER
    )
      return;
    const newOrders = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.NEW })
      .then((res) => res.data.data);

    const acceptedOrders = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.ACCEPTED })
      .then((res) => res.data.data);

    const canceledOrders = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.CANCELED })
      .then((res) => res.data.data);

    Promise.all([newOrders, acceptedOrders, canceledOrders]).then(
      ([newOrders, acceptedOrders, canceledOrders]) => {
        const result = [
          {
            title: 'Обрабатывается',
            status: orderStatus.NEW,
            items: newOrders.map((i) => ({ ...i, id: i.id.toString() })),
          },
          {
            title: 'Принят',
            status: orderStatus.ACCEPTED,
            items: acceptedOrders.map((i) => ({
              ...i,
              id: i.id.toString(),
            })),
          },

          {
            title: 'Отменён',
            status: orderStatus.CANCELED,
            items: canceledOrders.map((i) => ({
              ...i,
              id: i.id.toString(),
            })),
          },
        ];
        setArr(result);
        ordersRef.current = result;
      }
    );
  };

  const getDataForCourier = async () => {
    if (user?.role !== UserRole.COURIER) return;
    const ordersOnTheWay = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.ON_THE_WAY })
      .then((res) => res.data.data);
    const deliveredOrders = orderServices
      .getOrders({
        skip: 0,
        take: 25,
        status: orderStatus.DELIVERED,
      })
      .then((res) => res.data.data);
    const canceledOrders = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.CANCELED })
      .then((res) => res.data.data);
    Promise.all([ordersOnTheWay, deliveredOrders, canceledOrders]).then(
      ([ordersOnTheWay, deliveredOrders, canceledOrders]) => {
        const result = [
          {
            title: 'Передан курьеру',
            status: orderStatus.ON_THE_WAY,
            items: ordersOnTheWay.map((i) => ({
              ...i,
              id: i.id.toString(),
            })),
          },
          {
            title: 'Доставлен',
            status: orderStatus.DELIVERED,
            items: deliveredOrders.map((i) => ({
              ...i,
              id: i.id.toString(),
            })),
          },
          {
            title: 'Отменён',
            status: orderStatus.CANCELED,
            items: canceledOrders.map((i) => ({
              ...i,
              id: i.id.toString(),
            })),
          },
        ];
        setArr(result);
        ordersRef.current = result;
      }
    );
  };

  const getData = async () => {
    if (
      user?.role === UserRole.COURIER ||
      user?.role === UserRole.SHOP_EMPLOYEE ||
      user?.role === UserRole.SHOP_OWNER
    )
      return;
    const newOrders = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.NEW })
      .then((res) => res.data.data);
    const acceptedOrders = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.ACCEPTED })
      .then((res) => res.data.data);
    const ordersOnTheWay = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.ON_THE_WAY })
      .then((res) => res.data.data);
    const deliveredOrders = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.DELIVERED })
      .then((res) => res.data.data);
    const completedOrders = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.COMPLETED })
      .then((res) => res.data.data);
    const canceledOrders = orderServices
      .getOrders({ skip: 0, take: 25, status: orderStatus.CANCELED })
      .then((res) => res.data.data);
    Promise.all([
      newOrders,
      acceptedOrders,
      ordersOnTheWay,
      deliveredOrders,
      completedOrders,
      canceledOrders,
    ]).then(
      ([
        newOrders,
        acceptedOrders,
        ordersOnTheWay,
        deliveredOrders,
        completedOrders,
        canceledOrders,
      ]) => {
        const result = [
          {
            title: 'Обрабатывается',
            status: orderStatus.NEW,
            items: newOrders.map((i) => ({ ...i, id: i.id.toString() })),
          },
          {
            title: 'Принят',
            status: orderStatus.ACCEPTED,
            items: acceptedOrders.map((i) => ({
              ...i,
              id: i.id.toString(),
            })),
          },
          {
            title: 'Передан курьеру',
            status: orderStatus.ON_THE_WAY,
            items: ordersOnTheWay.map((i) => ({
              ...i,
              id: i.id.toString(),
            })),
          },
          {
            title: 'Доставлен',
            status: orderStatus.DELIVERED,
            items: deliveredOrders.map((i) => ({
              ...i,
              id: i.id.toString(),
            })),
          },
          {
            title: 'Завершено',
            status: orderStatus.COMPLETED,
            items: completedOrders.map((i) => ({
              ...i,
              id: i.id.toString(),
            })),
          },
          {
            title: 'Отменён',
            status: orderStatus.CANCELED,
            items: canceledOrders.map((i) => ({
              ...i,
              id: i.id.toString(),
            })),
          },
        ];
        ordersRef.current = result;
        setArr(result);
      }
    );
  };

  function onDragEnd(result: any) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    if (sInd === dInd) {
      const items = reorder(arr[sInd], source.index, destination.index);
      const newState = [...arr];
      newState[sInd] = items;
      setArr(newState);
    } else {
      const result = move(arr[sInd], arr[dInd], source, destination) as any;
      const newState = [...arr];
      newState[sInd].items = result[sInd].items;
      newState[dInd].items = result[dInd].items;
      setArr(newState);
    }
  }
  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone = Array.from(source.items);
    const destClone = Array.from(destination.items);
    const [removed] = sourceClone.splice(droppableSource.index, 1) as any;
    if (
      source.status === orderStatus.CANCELED ||
      source.status === orderStatus.COMPLETED
    ) {
      showNotification('Невозможно изменить статус заказа');
      return;
    }

    orderServices
      .changeOrderStatus(removed.id, {
        status: destination.status as orderStatus,
      })
      .catch((e) => {
        if (e.response.data.message === 'Unprocessable Entity') {
          showNotification('Невозможно изменить статус заказа');
        }
      });
    destClone.splice(droppableDestination.index, 0, removed);

    const result = {} as any;
    result[droppableSource.droppableId] = {
      title: source.title,
      status: source.status,
      items: sourceClone,
    };
    result[droppableDestination.droppableId] = {
      title: destination.title,
      status: destination.status,
      items: destClone,
    };
    return result;
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list.items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return { title: list.title, status: list.status, items: result };
  };

  return (
    <div
      className={cn('order-status', {
        'order-status--mini':
          user?.role === UserRole.SHOP_EMPLOYEE ||
          user?.role === UserRole.SHOP_OWNER ||
          user?.role === UserRole.COURIER,
      })}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        {arr.map((el, ind) => (
          <Droppable key={ind} droppableId={`${ind}`}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div
                  className={cn('order-status__col-title', {
                    'order-status__col-title--processed':
                      el.status === orderStatus.NEW,
                    'order-status__col-title--accepted':
                      el.status === orderStatus.ACCEPTED,
                    'order-status__col-title--handed-courier':
                      el.status === orderStatus.ON_THE_WAY,
                    'order-status__col-title--shipped':
                      el.status === orderStatus.DELIVERED,
                    'order-status__col-title--completed':
                      el.status === orderStatus.COMPLETED,
                    'order-status__col-title--canceled':
                      el.status === orderStatus.CANCELED,
                  })}
                >
                  <span>{el.title}</span>
                </div>
                <div className='order-status__list'>
                  {el.items.map((item: any, index: any) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={
                        item.status === orderStatus.CANCELED ||
                        item.status === orderStatus.COMPLETED
                      }
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <OrderItemCard order={item} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      {/* <div className='order-status__col'>
        <div
          className={cn(
            'order-status__col-title',
            'order-status__col-title--processed'
          )}
        >
          <span>Обрабатывается</span>
        </div>
        <div className='order-status__list'>
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
        </div>
      </div>
      <div className='order-status__col'>
        <div
          className={cn(
            'order-status__col-title',
            'order-status__col-title--accepted'
          )}
        >
          <span>Принят</span>
        </div>
        <div className='order-status__list'>
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
        </div>
      </div>
      <div className='order-status__col'>
        <div
          className={cn(
            'order-status__col-title',
            'order-status__col-title--handed-courier'
          )}
        >
          <span>Передан курьеру</span>
        </div>
        <div className='order-status__list'>
          <OrderItemCard />
        </div>
      </div>
      <div className='order-status__col'>
        <div
          className={cn(
            'order-status__col-title',
            'order-status__col-title--shipped'
          )}
        >
          <span>Доставлен</span>
        </div>
        <div className='order-status__list'>
          <OrderItemCard />
          <OrderItemCard />
        </div>
      </div>
      <div className='order-status__col'>
        <div
          className={cn(
            'order-status__col-title',
            'order-status__col-title--completed'
          )}
        >
          <span>Завершено</span>
        </div>
        <div className='order-status__list'>
          <OrderItemCard />
          <OrderItemCard />
        </div>
      </div>
      <div className='order-status__col'>
        <div
          className={cn(
            'order-status__col-title',
            'order-status__col-title--canceled'
          )}
        >
          <span>Отменен</span>
        </div>
        <div className='order-status__list'>
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
          <OrderItemCard />
        </div>
      </div> */}
    </div>
  );
};
