import {
  IAttributeGroups,
  IResAttributeGroups,
} from 'api/services/attributesGroup/types';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { MainInput } from 'components/MainInput/MainInput';
import showNotification from 'helpers/showNotification';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ActionButtons } from '../../ActionButtons/ActionButtons';

import './UpdateAttributeGroups.sass';

interface Props {
  closeModal: () => void;
  submit: (data: IAttributeGroups) => void;
  data: IResAttributeGroups;
}

export const UpdateAttributeGroups: React.FC<Props> = ({
  closeModal,
  submit,
  data,
}) => {
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [hasError, setHasError] = useState(false);
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    setTitleRu(data.titleRu);
    setTitleUz(data.titleUz);
  }, []);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!titleRu.trim() && !titleUz.trim()) {
      showNotification('Заполните название');
      setHasError(true);
      return;
    }
    submit({ titleRu, titleUz });
  };

  return (
    <form className='update-attribute-groups' onSubmit={formSubmit}>
      <div className='update-attribute-groups__container'>
        <div className='update-attribute-groups__tabs'>
          <div className='update-attribute-groups__tab'>
            <span>Группа атрибутов</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='update-attribute-groups__in'>
          <label className='update-attribute-groups__item'>
            <span className='update-attribute-groups__item-title'>
              Название
            </span>
            {lang === 'ru' ? (
              <MainInput
                updateInputValue={setTitleRu}
                inputValue={titleRu}
                error={hasError && !titleRu.trim()}
              />
            ) : (
              <MainInput
                updateInputValue={setTitleUz}
                inputValue={titleUz}
                error={hasError && !titleUz.trim()}
              />
            )}
          </label>
        </div>
      </div>
      <ActionButtons formSubmit={formSubmit} closeModal={closeModal} />
    </form>
  );
};
