import React, { useEffect, useState } from 'react';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { ReactComponent as CompletedIcon } from 'assets/images/icons/completed-icon.svg';
import { ReactComponent as CompletedIconGray } from 'assets/images/icons/completed-icon-gray.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/minus-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-icon.svg';
import CheckIcon from 'assets/images/icons/check-icon.svg';
import { ReactComponent as MoreIcon } from 'assets/images/icons/more-icon.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/icons/remove-icon.svg';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { MainInput } from 'components/MainInput/MainInput';
import './Stocks.sass';
import { RadioInput } from 'components/RadioInput/RadioInput';
import { InputType } from 'components/MainInput/types';
import { MainButton } from 'components/MainButton/MainButton';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { RadioType } from 'enums/radioType';
import { ActionButtons } from '../../ActionButtons/ActionButtons';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import {
  IStock,
  IStockCondition,
  StockConditionType,
  StockType,
} from 'api/services/stocks/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import {
  brandServices,
  categoryServices,
  productServices,
  shopServices,
  stocksServices,
} from 'api/services';
import { HtmlEditor, HtmlEditor2 } from 'components/Editor/HtmlEditor';
import { UserRole } from 'enums/users';
import _, { find } from 'lodash';
import { IResCategory } from 'api/services/categories/types';
import { IResProduct } from 'api/services/products/types';
import { IResBrand } from 'api/services/brands/types';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { CustomDateTimePicker } from 'components/CustomDateTimePicker/CustomDateTimePicker';

interface Props {
  submit: () => void;
  closeModal: () => void;
}
const conditions: ISelect[] = [
  {
    label: 'Равно',
    value: true,
  },
  {
    label: 'Не равно',
    value: false,
  },
];

const categories: ISelect[] = [
  { label: 'Категория', value: StockConditionType.CATEGORY },
  { label: 'Бренд', value: StockConditionType.BRAND },
  { label: 'Товар', value: StockConditionType.PRODUCT },
  { label: 'Магазин', value: StockConditionType.SHOP },
];

const stockTypes: ISelect[] = [
  { label: 'Все', value: StockType.ALL },
  { label: 'Одно из', value: StockType.ONE_OF },
];

export const Stocks: React.FC<Props> = ({ submit, closeModal }) => {
  const [descriptionType, setDescriptionType] = useState(RadioType.TEXT);
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [descriptionRu, setDescriptionRu] = useState('');
  const [descriptionUz, setDescriptionUz] = useState('');
  const [stockUrl, setStockUrl] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [activateAt, setActivateAt] = useState<Date>();
  const [deactivateAt, setDeactivateAt] = useState<Date>();
  const [stockValue, setStockValue] = useState('');
  const [stockType, setStockType] = useState<ISelect>(stockTypes[0]);
  const [stocksConditions, setStocksConditions] = useState<IStockCondition[]>(
    []
  );
  // const [selectedShopId, setSelectedShopId] = useState<number>();
  const [stockConditionValues, setStockConditionValues] = useState([]);
  const [searchableConditionValue, setSearchableConditionValue] =
    useState<any>('');
  const [searchableConditions, setSearchableConditions] = useState<
    ISearchableSelect[]
  >([]);
  const [searchableConditionsLoading, setSearchableConditionLoading] =
    useState(false);
  const [searchableShopValue, setSearchableShopValue] = useState('');
  const [searchableShops, setSearchableShops] = useState<ISearchableSelect[]>(
    []
  );
  const [searchableShopsLoading, setSearchableShopsLoading] = useState(false);
  const [conditionCategories, setConditionCategories] = useState<
    IResCategory[]
  >([]);
  const [conditionProducts, setConditionProducts] = useState<IResProduct[]>([]);
  const [conditionBrands, setConditionBrands] = useState<IResBrand[]>([]);
  const [conditionShops, setConditionShops] = useState<IResBrand[]>([]);
  const [gift, setGift] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { lang } = useSelector((state: AppState) => state.localeReducer);
  const { user } = useSelector((state: AppState) => state.profileReducer);

  useEffect(() => {
    // getShops();
    getStockConditionValues(undefined, undefined, true);
  }, []);

  const changeDescriptionType = (type: RadioType) => {
    setDescriptionType(type);
  };

  // const getShops = async (value?: string) => {
  //   if (user?.role === UserRole.SHOP_OWNER) return;
  //   const res = await shopServices
  //     .getShops({
  //       skip: 0,
  //       take: 25,
  //       search: value && value.length < 2 ? '' : value,
  //     })
  //     .then((res) => res.data);
  //   setSearchableShops(
  //     res.data.map((i) => ({
  //       name: i.name,
  //       value: i.id,
  //     }))
  //   );
  // };
  const formSubmit = async () => {
    const conditions: any = stocksConditions.map((i) => {
      switch (i.type) {
        case StockConditionType.BRAND:
          return {
            ...i,
            discountConditionValues: i.discountConditionValues.map((ii) => ({
              brandId: ii.id,
            })),
          };
        case StockConditionType.PRODUCT:
          return {
            ...i,
            discountConditionValues: i.discountConditionValues.map((ii) => ({
              productId: ii.id,
            })),
          };
        case StockConditionType.CATEGORY:
          return {
            ...i,
            discountConditionValues: i.discountConditionValues.map((ii) => ({
              categoryId: ii.id,
            })),
          };
        case StockConditionType.SHOP:
          return {
            ...i,
            discountConditionValues: i.discountConditionValues.map((ii) => ({
              shopId: ii.id,
            })),
          };
        default:
          return i;
      }
    });
    if (!stockUrl.trim()) {
      setHasError(true);
      showNotification('Заполните URL');
      return;
    }
    try {
      const stock: IStock = {
        ...(user?.shopId ? { shopId: user?.shopId } : {}),
        titleRu,
        titleUz,
        descriptionRu,
        descriptionUz,
        url: stockUrl,
        ...(activateAt ? { activateAt: new Date(activateAt) } : {}),
        ...(deactivateAt ? { deactivateAt: new Date(deactivateAt) } : {}),
        value: Number(stockValue),
        isActive,
        type: stockType.value || (stockType as any),
        discountConditions: conditions,
        gift,
        showTimer,
      };
      await stocksServices.createStock(stock).then((res) => res.data);
      showNotification('Создано успешно', NotificationStatus.SUCCESS);
      submit();
    } catch (e: any) {
      setHasError(false);
      if (
        e.response.data.message[0] === 'discountConditions should not be empty'
      ) {
        showNotification('Добавьте условие');
        return;
      }
      if (e.response.data.message !== 'Conflict') {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  const getStockConditionValues = async (
    type?: StockConditionType,
    searchValue?: string,
    firstLoad?: boolean,
    index?: number,
    iindex?: number
  ) => {
    const attrs = {
      skip: 0,
      take: 25,
      search: searchValue && searchValue.length < 2 ? '' : searchValue,
    };
    if (firstLoad) {
      if (user?.role !== UserRole.SHOP_OWNER) {
        const shopsRes = await shopServices
          .getShops(attrs)
          .then((res) => res.data);
        setConditionShops(shopsRes.data);
        setSearchableConditionLoading(false);
      }
      const brandsRes = await brandServices
        .getBrands(attrs)
        .then((res) => res.data);
      setConditionBrands(brandsRes.data);
      setSearchableConditionLoading(false);
      const productsRes = await productServices
        .getProducts(attrs)
        .then((res) => res.data);
      setConditionProducts(productsRes.data);
      setSearchableConditionLoading(false);
      const categoriesRes = await categoryServices
        .getCategories({
          search: searchValue && searchValue.length < 2 ? '' : searchValue,
        })
        .then((res) => res.data);
      setConditionCategories(categoriesRes);
      setSearchableConditionLoading(false);
      return;
    }
    switch (type) {
      case StockConditionType.SHOP:
        const shopsRes = await shopServices
          .getShops(attrs)
          .then((res) => res.data);
        setConditionShops(shopsRes.data);
        setSearchableConditionLoading(false);
        return;
      case StockConditionType.BRAND:
        const brandsRes = await brandServices
          .getBrands(attrs)
          .then((res) => res.data);
        setConditionBrands(brandsRes.data);
        setSearchableConditionLoading(false);
        return;
      case StockConditionType.PRODUCT:
        const productsRes = await productServices
          .getProducts(attrs)
          .then((res) => res.data);
        setConditionProducts(productsRes.data);
        setSearchableConditionLoading(false);
        return;
      default:
        const categoriesRes = await categoryServices
          .getCategories({
            search: searchValue && searchValue.length < 2 ? '' : searchValue,
          })
          .then((res) => res.data);
        setConditionCategories(categoriesRes);
        setSearchableConditionLoading(false);
        return;
    }
  };

  const updateCondition = (
    index: number,
    value: any,
    key: any,
    onlyConditionValues?: boolean
  ) => {
    getStockConditionValues(value);
    setStocksConditions((prev) => {
      const clone = _.clone(prev);
      if (!onlyConditionValues) {
        (clone as any)[index][key] = value;
      }
      if (key === 'type') {
        onlyConditionValues
          ? (clone as any)[index].discountConditionValues.push(
              newConditionValue(value)
            )
          : ((clone as any)[index].discountConditionValues = [
              newConditionValue(value),
            ]);
      }
      return clone;
    });
  };

  const newConditionValue = (type: StockConditionType) => {
    switch (type) {
      case StockConditionType.BRAND:
        return {
          id: conditionBrands[0].id,
          label: conditionBrands[0].name,
          value: type,
        };
      case StockConditionType.SHOP:
        return {
          id: conditionShops[0].id,
          label: conditionShops[0].name,
          value: type,
        };
      case StockConditionType.PRODUCT:
        return {
          id: conditionProducts[0].id,
          label: conditionProducts[0].titleRu,
          value: type,
        };
      case StockConditionType.CATEGORY:
        return {
          id: conditionCategories[0].id,
          label: conditionCategories[0].titleRu,
          value: type,
        };
      default:
        return;
    }
  };

  const updateConditionValue = (
    index: number,
    iindex: number,
    type: StockConditionType,
    value: number
  ) => {
    setStocksConditions((prev) => {
      const clone = _.clone(prev);
      let findEl: any;
      switch (type) {
        case StockConditionType.PRODUCT:
          const product = conditionProducts.find((i) => i.id === value);
          findEl = {
            id: product?.id,
            label: product?.titleRu,
            value: StockConditionType.PRODUCT,
          };
          break;
        case StockConditionType.SHOP:
          const shop = conditionShops.find((i) => i.id === value);
          findEl = {
            id: shop?.id,
            label: shop?.name,
            value: StockConditionType.SHOP,
          };
          break;
        case StockConditionType.BRAND:
          const brand = conditionBrands.find((i) => i.id === value);
          findEl = {
            id: brand?.id,
            label: brand?.name,
            value: StockConditionType.BRAND,
          };
          break;
        case StockConditionType.CATEGORY:
          const category = conditionCategories.find((i) => i.id === value);
          findEl = {
            id: category?.id,
            label: category?.titleRu,
            value: StockConditionType.CATEGORY,
          };
          break;
        default:
          break;
      }
      if (!!findEl) clone[index].discountConditionValues[iindex] = findEl;
      return clone;
    });
  };

  const addCondition = () => {
    setStocksConditions((prev) => [
      ...prev,
      {
        type: StockConditionType.CATEGORY,
        equal: false,
        discountConditionValues: [
          {
            id: conditionCategories[0]?.id,
            label: conditionCategories[0].titleRu,
            value: StockConditionType.CATEGORY,
          },
        ],
      },
    ]);
  };

  const addStockConditionValue = (index: number, type: StockConditionType) => {
    updateCondition(index, type, 'type', true);
  };

  const deleteCondition = (itemIndex: number) => {
    setStocksConditions((prev) =>
      prev.filter((i, index) => index !== itemIndex)
    );
  };

  const deleteConditionValue = (index: number) => {
    setStocksConditions(
      (prev) =>
        prev.map((i) => ({
          ...i,
          discountConditionValues: i.discountConditionValues.filter(
            (_, iindex) => iindex !== index
          ),
        })) as any
    );
  };

  const findCondition = (index: number, iindex: number, value: string) => {
    setStocksConditions((prev) => {
      const clone = _.clone(prev);
      clone[index].discountConditionValues[iindex].label = value;
      return clone;
    });
  };

  const checkConditionType = (
    type: StockConditionType
  ): ISearchableSelect[] => {
    switch (type) {
      case StockConditionType.SHOP:
        return conditionShops.map((i) => ({ name: i.name, value: i.id }));
      case StockConditionType.BRAND:
        return conditionBrands.map((i) => ({ name: i.name, value: i.id }));
      case StockConditionType.PRODUCT:
        return conditionProducts.map((i) => ({ name: i.titleRu, value: i.id }));
      default:
        return conditionCategories.map((i) => ({
          name: i.titleRu,
          value: i.id,
        }));
    }
  };

  return (
    <div className='stocks'>
      <div className='stocks__modal'>
        <div className='stocks__tabs'>
          <div className='stocks__tabs-item'>
            <span>Параметры акций</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='stocks__row'>
          <label className='stocks__row-item stocks__row-item--checkboxes'>
            <div className='stocks__label'>Активность:</div>
            <Checkbox isChecked={isActive} setChecked={setIsActive} />
          </label>
          <label className='stocks__row-item stocks__row-item--checkboxes'>
            <div className='stocks__label'>Подарок:</div>
            <Checkbox isChecked={gift} setChecked={setGift} />
          </label>
          <label className='stocks__row-item stocks__row-item--checkboxes'>
            <div className='stocks__label'>Показать таймер:</div>
            <Checkbox isChecked={showTimer} setChecked={setShowTimer} />
          </label>
        </div>
        <div className='stocks__in'>
          <div className='stocks__container'>
            <div className='stocks__row'>
              <label className='stocks__row-item'>
                <div className='stocks__label stocks__row-label'>Название:</div>
                {lang === 'ru' ? (
                  <MainInput
                    updateInputValue={setTitleRu}
                    inputValue={titleRu}
                  />
                ) : (
                  <MainInput
                    updateInputValue={setTitleUz}
                    inputValue={titleUz}
                  />
                )}
              </label>
            </div>
            <div className='stocks__row'>
              <label className='update-stock__row-item'>
                <div className='update-stock__label update-stock__row-label'>
                  URL:
                </div>
                <MainInput
                  updateInputValue={setStockUrl}
                  inputValue={stockUrl}
                  error={hasError && !stockUrl.trim()}
                />
              </label>
            </div>
            {/* <div className='stocks__row'>
              <label className='stocks__row-item'>
                <div className='stocks__label'>URL</div>
                <MainInput />
              </label>
            </div> */}
            {/* <div className='stocks__row'>
              <label className='stocks__row-item'>
                <div className='stocks__label'>Статьи</div>
                <MainInput />
              </label>
              <label className='stocks__row-item stocks__row-item--actions'>
                <MainButton title='Выбрать' isCancel submit={() => {}} />
                <MainButton title='Выбрать' submit={() => {}} />
              </label>
            </div>
            <div className='stocks__row'>
              <label className='stocks__row-item'>
                <div className='stocks__label'>Блог</div>
                <MainInput />
              </label>
              <label className='stocks__row-item stocks__row-item--actions'>
                <MainButton title='Выбрать' isCancel submit={() => {}} />
                <MainButton title='Выбрать' submit={() => {}} />
              </label>
            </div>
            <div className='stocks__row'>
              <label className='stocks__row-item'>
                <div className='stocks__label'>Бренд</div>
                <MainInput />
              </label>
              <label className='stocks__row-item stocks__row-item--actions'>
                <MainButton title='Выбрать' isCancel submit={() => {}} />
                <MainButton title='Выбрать' submit={() => {}} />
              </label>
            </div> */}
            {/* {user?.role !== UserRole.SHOP_OWNER && (
              <div className='stocks__row'>
                <label className='stocks__row-item'>
                  <div className='stocks__label'>Магазин</div>
                  <CustomSearchableSelect
                    items={searchableShops}
                    loading={searchableShopsLoading}
                    setLoading={setSearchableShopsLoading}
                    setSelectItem={setSelectedShopId}
                    setValue={getShops}
                    searchValue={searchableShopValue}
                    setSearchValue={setSearchableShopValue}
                  />
                </label>
                <label className='stocks__row-item stocks__row-item--actions'>
                <MainButton title='Выбрать' isCancel submit={() => {}} />
                <MainButton title='Выбрать' submit={() => {}} />
              </label>
              </div>
            )} */}
            <div className='product__row'>
              <label className='product__row-item'>
                <div className='product__label product__row-label'>
                  Начало активности:
                </div>
                <CustomDateTimePicker
                  setDate={setActivateAt}
                  date={activateAt}
                  onlyDate
                  clear
                />
              </label>
              <label className='product__row-item'>
                <div className='product__label product__row-label'>
                  Окончание активности:
                </div>
                <CustomDateTimePicker
                  setDate={setDeactivateAt}
                  date={deactivateAt}
                  onlyDate
                  clear
                />
              </label>
            </div>
            {/* <div className='stocks__row stocks__row--uploader'>
              <label className='stocks__row-item'>
                <div className='stocks__label stocks__label--uploader'>
                  Загрузить главное изображение:
                </div>
                <FileUploader />
              </label>
            </div> */}
          </div>
          <div className='stocks__container stocks__container-right'>
            <div className='stocks__description'>
              <div className='stocks__label'>Детальное описание</div>
              <div className='stocks__description-tabs'>
                <RadioInput
                  checked={descriptionType === RadioType.TEXT}
                  type={RadioType.TEXT}
                  label='ТЕКСТ'
                  classes='stocks__description-tab'
                  onChange={changeDescriptionType}
                />
                <RadioInput
                  checked={descriptionType === RadioType.REDACTOR}
                  type={RadioType.REDACTOR}
                  label='HTML'
                  classes='stocks__description-tab'
                  onChange={changeDescriptionType}
                />
              </div>
              <div className='stocks__textarea'>
                {descriptionType === RadioType.TEXT ? (
                  lang === 'ru' ? (
                    <MainInput
                      type={InputType.TEXTAREA}
                      inputValue={descriptionRu}
                      updateInputValue={setDescriptionRu}
                    />
                  ) : (
                    <MainInput
                      type={InputType.TEXTAREA}
                      inputValue={descriptionUz}
                      updateInputValue={setDescriptionUz}
                    />
                  )
                ) : lang === 'ru' ? (
                  <HtmlEditor
                    value={descriptionRu}
                    onChange={setDescriptionRu}
                    error={hasError && !descriptionRu.trim()}
                  />
                ) : (
                  <HtmlEditor2
                    value={descriptionUz}
                    onChange={setDescriptionUz}
                    error={hasError && !descriptionUz.trim()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='stocks__conditions'>
          <div className='stocks__conditions-title'>Условия акций</div>
          <div className='stocks__conditions-container'>
            <div className='stocks__conditions-discount'>
              <div className='stocks__conditions-discount-title'>
                Применить скидку
              </div>
              <div className='stocks__conditions-discount-input'>
                <MainInput
                  inputValue={stockValue}
                  updateInputValue={setStockValue}
                  type={InputType.NUMBER}
                />
              </div>
              <div className='stocks__conditions-discount-percent'>%</div>
              <span>K</span>
            </div>
            {/* <div className='stocks__conditions-checkboxes'>
              <div className='stocks__conditions-checkboxes-text'>
                Любое из условий <CompletedIcon />
              </div>
              <div className='stocks__conditions-checkboxes-text'>
                Выполнено
                <CompletedIcon />
              </div>
            </div> */}
            <div className='stocks__conditions-types'>
              <MainSelect
                items={stockTypes}
                selectItem={setStockType}
                value={stockType}
              />
            </div>
            <div className='stocks__conditions-list'>
              {stocksConditions.map((i, index) => (
                <div className='stocks__conditions-item' key={index}>
                  <div className='stocks__conditions-item-input'>
                    <MainSelect
                      items={
                        user?.role === UserRole.SHOP_OWNER
                          ? categories.filter(
                              (ii) => ii.value !== StockConditionType.SHOP
                            )
                          : categories
                      }
                      selectItem={(value: any) =>
                        updateCondition(index, value, 'type')
                      }
                      value={{
                        label:
                          categories.find((ii) => ii.value === i.type)?.label ||
                          '',
                        value: i.type,
                      }}
                    />
                  </div>
                  <div className='stocks__conditions-item-input'>
                    <MainSelect
                      items={conditions}
                      selectItem={(value: any) =>
                        updateCondition(index, value, 'equal')
                      }
                      value={{
                        label: conditions[i.equal ? 0 : 1].label,
                        value: i.equal,
                      }}
                    />
                  </div>
                  <div className='stocks__conditions-item-list'>
                    {i.discountConditionValues.map((ii, iindex) => (
                      <div
                        className='stocks__conditions-item-input'
                        key={iindex}
                      >
                        <CustomSearchableSelect
                          items={checkConditionType(i.type)}
                          loading={searchableConditionsLoading}
                          setLoading={setSearchableConditionLoading}
                          setSelectItem={(value: number) =>
                            updateConditionValue(index, iindex, i.type, value)
                          }
                          setValue={(value: string) =>
                            getStockConditionValues(
                              i.type,
                              value,
                              undefined,
                              index,
                              iindex
                            )
                          }
                          searchValue={ii.label}
                          setSearchValue={(value: string) =>
                            findCondition(index, iindex, value)
                          }
                          isWhite
                        />
                        {i.discountConditionValues.length > 1 && (
                          <RemoveIcon
                            onClick={() => deleteConditionValue(iindex)}
                          />
                        )}
                        {iindex === i.discountConditionValues.length - 1 && (
                          <div
                            className='stocks__conditions-item-input-add-icon'
                            onClick={() =>
                              addStockConditionValue(index, i.type)
                            }
                          >
                            <PlusIcon />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <MainButton
                    title='Удалить условие'
                    isDelete
                    submit={() => deleteCondition(index)}
                  />
                </div>
              ))}
              {/* <div className='stocks__conditions-item'>
                <div className='stocks__conditions-item-input'>
                  <MainSelect
                    items={categories}
                    selectItem={setSelectedCategory2}
                    value={selectedCategory2}
                  />
                </div>
                <div className='stocks__conditions-item-input'>
                  <MainSelect
                    items={conditions}
                    selectItem={setSelectedCondition2}
                    value={selectedCondition2}
                  />
                </div>
                <div className='stocks__conditions-item-list'>
                  <div className='stocks__conditions-item-input'>
                    <MainInput
                      type={InputType.SELECT}
                      Icon={<MoreIcon />}
                      inputValue='Товар 1'
                    />
                    <RemoveIcon />
                  </div>
                  <div className='stocks__conditions-item-input'>
                    <MainInput
                      type={InputType.SELECT}
                      Icon={<MoreIcon />}
                      inputValue='Товар 1'
                    />
                    <RemoveIcon />
                  </div>
                  <div className='stocks__conditions-item-input'>
                    <MainInput
                      type={InputType.SELECT}
                      Icon={<MoreIcon />}
                      inputValue='Товар 1'
                    />
                    <RemoveIcon />
                  </div>
                  <div className='stocks__conditions-item-input'>
                    <MainInput
                      type={InputType.SELECT}
                      Icon={<MoreIcon />}
                      inputValue='Товар 1'
                    />
                    <RemoveIcon />
                  </div>
                  <div className='stocks__conditions-item-input'>
                    <MainInput
                      type={InputType.SELECT}
                      Icon={<MoreIcon />}
                      inputValue='Товар 1'
                    />
                    <RemoveIcon />
                  </div>
                  <div className='stocks__conditions-item-input'>
                    <MainInput
                      type={InputType.SELECT}
                      Icon={<MoreIcon />}
                      inputValue='Товар 1'
                    />
                    <RemoveIcon />
                  </div>
                  <div className='stocks__conditions-item-input'>
                    <MainInput
                      type={InputType.SELECT}
                      Icon={<MoreIcon />}
                      inputValue='Товар 1'
                    />
                    <RemoveIcon />
                  </div>
                  <div className='stocks__conditions-item-input'>
                    <MainInput
                      type={InputType.SELECT}
                      Icon={<MoreIcon />}
                      inputValue='Товар 1'
                    />
                    <RemoveIcon />
                  </div>
                </div>
                <MainButton
                  title='Удалить условие'
                  isDelete
                  submit={() => {}}
                />
              </div> */}
            </div>
          </div>
          <MainButton
            title='Добавить условие +'
            classes='stocks__conditions-button'
            submit={addCondition}
          />
        </div>
      </div>

      <ActionButtons formSubmit={formSubmit} closeModal={closeModal} />
    </div>
  );
};
