import { AxiosPromise } from 'axios';
import { IResBrand } from '../brands/types';
import { IResCategory } from '../categories/types';
import { IResProduct } from '../products/types';
import { IResShop } from '../shops/types';

export interface IStock {
  shopId?: number;
  titleRu?: string;
  titleUz?: string;
  descriptionUz?: string;
  descriptionRu?: string;
  activateAt?: Date | null;
  deactivateAt?: Date | null;
  value: number;
  discountConditions: IStockCondition[];
  isActive: boolean;
  type: StockType;
  url: string;
  gift: boolean;
  showTimer: boolean;
}

export interface IStockCondition {
  type: StockConditionType;
  equal: boolean;
  discountConditionValues: IConditionValues[];
}

export enum StockType {
  ONE_OF = 'ONE_OF',
  ALL = 'ALL',
}

export enum StockConditionType {
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT',
  BRAND = 'BRAND',
  SHOP = 'SHOP',
}

export interface IConditionValues {
  id: number | null;
  label: string;
  value: string;
}

export interface IResStockCondition {
  brandId?: number;
  brand?: IResBrand;
  shopId?: number;
  shop?: IResShop;
  categoryId?: number;
  category?: IResCategory;
  productId?: number;
  product?: IResProduct;
}

export interface IResStock extends IStock {
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IUpdatedStock
  extends Omit<IStock, 'type' | 'discountConditions' | 'value'> {}

export interface IGetStock {
  (attrs: { skip: number; take: number; shopId?: number }): AxiosPromise<{
    data: IResStock[];
    count: number;
  }>;
}

export interface ICreateStock {
  (data: IStock): AxiosPromise;
}

export interface IUpdateStock {
  (id: number, data: IUpdatedStock): AxiosPromise;
}

export interface IGetStockById {
  (id: number): AxiosPromise<IResStock>;
}
