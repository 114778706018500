import React, { useEffect, useState } from 'react';
import { knowledgeBaseServices } from 'api/services';
import {
  IKnowledgeBase,
  IResKnowledgeBase,
} from 'api/services/knowledge-base/types';
import { NotificationStatus } from 'store/ducks/notification/types';
import showNotification from 'helpers/showNotification';
import { Modal } from 'components/Modal/Modal';
import { Table } from 'components/Table/Table';
import { Filter } from 'components/Filter/Filter';
import { CreateKnowledgeBase } from 'components/Modal/components/CreateKnowledgeBase/CreateKnowledgeBase';
import { UpdateKnowledgeBase } from 'components/Modal/components/UpdateKnowledgeBase/UpdateKnowledgeBase';
import { useDispatch, useSelector } from 'react-redux';
import * as templateActions from 'store/ducks/template/actions';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import * as settingActions from 'store/ducks/settings/actions';
import './KnowledgeBasePage.sass';
import { AppState } from 'store/store';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Settings } from 'components/Modal/components/Settings/Settings';

const KnowledgeBasePage: React.FC = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [id, setId] = useState<number>();
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [totalCount, setTotalCount] = useState<number>();
  const [data, setData] = useState<IResKnowledgeBase[]>([]);
  const [loading, setLoading] = useState(false);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const dispatch = useDispatch();
  const { knowledgeBaseSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Модерация', id: '' },
        { title: 'База знаний', id: 'moderation/knowledge-base' },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [page, searchValue]);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        skip: take * page,
        take,
        search: searchValue,
      };
      const res = await knowledgeBaseServices
        .getKnowledgeBase(params)
        .then((res) => res.data);
      setData(res.data);
      setTotalCount(res.count);
    } finally {
      setLoading(false);
    }
  };

  const createKnowledgeBaseHandler = async (data: IKnowledgeBase) => {
    setSubmitLoading(true);
    try {
      await knowledgeBaseServices.createKnowledgeBase(data);
      setShowCreateModal(false);
      showNotification('Готово', NotificationStatus.SUCCESS);
      getData();
    } finally {
      setSubmitLoading(false);
    }
  };

  const updateKnowledgeBase = async (data: IKnowledgeBase, id: number) => {
    setSubmitLoading(true);
    try {
      await knowledgeBaseServices.updateKnowledgeBase(id, data);
      setShowUpdateModal(false);
      showNotification('Готово', NotificationStatus.SUCCESS);
      getData();
    } finally {
      setSubmitLoading(false);
    }
  };

  const deleteBase = async (id: number) => {
    try {
      await knowledgeBaseServices.deleteKnowledgeBase(id);
      getData();
    } finally {
      showNotification('Удалено', NotificationStatus.SUCCESS);
    }
  };

  const showUpdateModalHandler = (id: number) => {
    setId(id);
    setShowUpdateModal(true);
  };

  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setKnowledgeBaseSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='База знаний'
            items={knowledgeBaseSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <Filter
        createFunc={() => setShowCreateModal(true)}
        filterFunc={() => setFilterShowModal(true)}
        updateSearchValue={newFilterValue}
        search={getData}
        loading={loading}
        settingFunc={() => setShowSettings(true)}
      />
      <div className='knowledge-base-page page'>
        <div className='knowledge-base-page__in page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            withPaginate
            selectItem={showUpdateModalHandler}
            tableHead={knowledgeBaseSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            tableBody={{
              data,
              keys: knowledgeBaseSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            deleteHandler={deleteBase}
            page={page}
          />
        </div>
      </div>
      {showCreateModal && (
        <Modal closeModal={() => setShowCreateModal(false)}>
          <CreateKnowledgeBase
            submit={createKnowledgeBaseHandler}
            closeModal={() => setShowCreateModal(false)}
            loading={submitLoading}
          />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal closeModal={() => setShowUpdateModal(false)}>
          <UpdateKnowledgeBase
            id={id}
            submit={updateKnowledgeBase}
            closeModal={() => setShowUpdateModal(false)}
            loading={submitLoading}
          />
        </Modal>
      )}
    </>
  );
};

export default KnowledgeBasePage;
