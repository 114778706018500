import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { Moderation } from 'components/Modal/components/Moderation/Moderation';
import { Filter } from 'components/Filter/Filter';
import { useLocation } from 'react-router';
import { menu } from 'components/Nav/navLinks';
import { Advertising } from 'components/Modal/components/Advertising/Advertising';
import { Coupon } from 'components/Modal/components/Coupon/Coupon';
import * as settingActions from 'store/ducks/settings/actions';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';
import { IResPromocode } from 'api/services/promocodes/types';
import { promocodesServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';

const tabs: IModalTab[] = [
  {
    label: 'Промокоды',
    index: 3,
  },
];

export const PromocodesPage = () => {
  const [activeTab, setActiveTab] = useState(3);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [promocodes, setPromocodes] = useState<IResPromocode[]>([]);
  const [promocode, setPromocode] = useState<IResPromocode>();
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [filterShowModal, setFilterShowModal] = useState(false);

  const { promocodesSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Реклама', id: '' },
        { title: 'Промокоды', id: 'advertising/promo-codes' },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [searchValue, page]);

  const getData = async () => {
    const attrs = {
      skip: page * take,
      take,
      search: searchValue,
    };
    const res = await promocodesServices
      .getPromocodes(attrs)
      .then((res) => res.data);
    setPromocodes(res.data);
    setTotalCount(res.count);
  };

  const createPromocode = async (data: any) => {
    try {
      await promocodesServices.createPromocode(data);
      setShowModal(false);
      getData();
    } catch {
      showNotification('Ошибка, попробуйте позже');
    }
  };

  const updatePromocode = async (data: any) => {
    if (!promocode) return;
    try {
      await promocodesServices.updatePromocode(promocode.id, data);
      setShowUpdateModal(false);
      getData();
      setPromocode(undefined);
    } catch {
      showNotification('Ошибка, попробуйте позже');
    }
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setPromocodesSettings(items));
    setShowSettings(false);
  };

  const selectPromocode = async (id: number) => {
    setActiveTab(6);
    const res = await promocodesServices
      .getPromocodeById(id)
      .then((res) => res.data);
    setPromocode(res);
    setShowUpdateModal(true);
  };

  const closeFilter = () => setFilterShowModal(false);

  const newFilterValue = (value: string) => {
    setPage(0);
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Промокоды'
            items={promocodesSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showModal && (
        <Modal closeModal={() => setShowModal(false)}>
          <Advertising
            setActiveTab={setActiveTab}
            tabs={tabs}
            closeModal={() => setShowModal(false)}
            activeTab={3}
            submit={createPromocode}
            loading={true}
            promocode={promocode}
          />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal closeModal={() => setShowUpdateModal(false)}>
          <Advertising
            setActiveTab={setActiveTab}
            tabs={tabs}
            closeModal={() => setShowUpdateModal(false)}
            activeTab={6}
            submit={updatePromocode}
            loading={true}
            promocode={promocode}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          />
        </Modal>
      )}
      <div className='page active-orders-page'>
        <Filter
          createFunc={() => setShowModal(true)}
          settingFunc={() => setShowSettings(true)}
          search={getData}
          updateSearchValue={newFilterValue}
          filterFunc={() => setFilterShowModal(true)}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data: promocodes,
              keys: promocodesSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={promocodesSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            selectItem={selectPromocode}
            totalCount={totalCount}
            take={take}
            skipHandler={setPage}
          />
        </div>
      </div>
    </>
  );
};
