import React, { useEffect, useState } from 'react';
import { reportsServices } from 'api/services';
import { IResShop } from 'api/services/shops/types';
import { Filter } from 'components/Filter/Filter';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { Modal } from 'components/Modal/Modal';
import { Table } from 'components/Table/Table';
import showNotification from 'helpers/showNotification';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationStatus } from 'store/ducks/notification/types';
import * as templateActions from 'store/ducks/template/actions';
import * as settingActions from 'store/ducks/settings/actions';
import { AppState } from 'store/store';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Settings } from 'components/Modal/components/Settings/Settings';
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'query-string';
import fs from 'file-saver';
import moment from 'moment';
import './ReportSalesPage.sass';
import { ISalesReport } from 'api/services/reports/types';
import { isShop } from 'helpers/checkUser';

const ReportSalesPage: React.FC = () => {
  const [data, setData] = useState<ISalesReport[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [dates, setDates] = useState([
    new Date(new Date().setMonth(new Date().getMonth() - 1)),
    new Date(),
  ]);

  const dispatch = useDispatch();
  const { salesReportSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );
  const { token } = useSelector((state: AppState) => state.authReducer);
  const { user } = useSelector((state: AppState) => state.profileReducer);

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        {
          title: 'Отчет по продажам',
          id: 'reports/sales',
        },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [page, JSON.stringify(dates)]);

  const getData = async () => {
    const attrs = {
      skip: take * page,
      take,
      from: moment(dates[0]).format('yyyy-MM-DD'),
      to: moment(dates[1]).format('yyyy-MM-DD'),
    };
    const res = await reportsServices
      .getSalesReports(attrs)
      .then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
  };

  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    console.log(items);
    dispatch(settingActions.setSalesReportSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getData();
    setSearchValue(value);
  };

  const exportReports = async () => {
    const attrs = {
      skip: take * page,
      take,
      from: moment(dates[0]).format('yyyy-MM-DD'),
      to: moment(dates[1]).format('yyyy-MM-DD'),
    };
    const headers = {
      'Content-type': 'blob',
      common: {
        Authorization: `Bearer ${token}`,
      },
    } as any;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${
        process.env.REACT_APP_BASE_URL
      }/api/v1/reports/sales/export?${qs.stringify(attrs)}`,
      responseType: 'blob',
      headers,
    };

    const res = await axios(config).then((res) => res.data);
    const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
    fs.saveAs(blob, `Отчет по продажам.xlsx`);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Отчет по остаткам'
            items={
              isShop(user?.role)
                ? salesReportSettings.filter((i) => i.value !== 'marginPrice')
                : salesReportSettings
            }
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='report-sales-page page'>
        <Filter
          settingFunc={() => setShowSettings(true)}
          productExport={exportReports}
          date={dates}
          setDate={setDates}
          isReport
        />
        <div className='report-sales-page__in page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            tableBody={{
              data: data?.map((i, index) => ({
                ...i,
                shopName: i.order.shop.name,
                id: index + 1,
                salesSum: `${i.retailPrice || 0} сум`,
                incomePrice: (i.retailPrice || 0) - (i.arrivalPrice || 0),
                count: `${i.count} шт.`,
              })),
              keys: salesReportSettings
                .filter((i) =>
                  isShop(user?.role)
                    ? i.value !== 'marginPrice' && i.isActive
                    : i.isActive
                )
                .map((i) => i.value),
            }}
            tableHead={salesReportSettings
              .filter((i) =>
                isShop(user?.role)
                  ? i.value !== 'marginPrice' && i.isActive
                  : i.isActive
              )
              .map((i) => i.label)}
            withPaginate
            page={page}
          />
        </div>
      </div>
    </>
  );
};

export default ReportSalesPage;
