import React, { useEffect, useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import folder from 'assets/images/icons/folder-icon.png';
import { Link } from 'react-router-dom';

import { FileType, ImageSize, IResFile } from 'api/services/files/types';
import { FILE_URL } from 'constants/project';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomLoader } from 'components/CustomLoader/CustomLoader';
import cn from 'classnames';
import './Files.sass';

interface Props {
  items: IResFile[];
  checkedFiles: number[];
  setCheckedFiles: (id: any) => void;
  refreshItems?: (
    id: number,
    isBreadCrumb?: boolean,
    isScroll?: boolean
  ) => void;
  setPage: any;
  page: number;
  loading?: boolean;
  isFull?: boolean;
}

export const Files: React.FC<Props> = ({
  items,
  checkedFiles,
  setCheckedFiles,
  refreshItems,
  setPage,
  page,
  loading,
  isFull,
}) => {
  const { user } = useSelector((state: AppState) => state.profileReducer);

  useEffect(() => {
    return () => {
      setPage(0);
    };
  }, []);

  const selectFiles = (item: IResFile, value?: boolean) => {
    if (value !== undefined) {
      setCheckedFiles((prev: number[]) =>
        value ? [...prev, item.id] : prev.filter((i) => i !== item.id)
      );
      return;
    }
    setCheckedFiles((prev: number[]) =>
      !checkedFiles.some((i) => i === item.id)
        ? [...prev, item.id]
        : prev.filter((i) => i !== item.id)
    );
  };

  const renderItems = (item: IResFile) => {
    switch (item.type) {
      case FileType.FOLDER:
        return (
          <div className='files__folder-item' key={item.id}>
            <div className='files__folder-item-checkbox'>
              <Checkbox
                isChecked={checkedFiles.some((i) => i === item.id)}
                setChecked={(value: boolean) =>
                  setCheckedFiles((prev: number[]) =>
                    value
                      ? [...prev, item.id]
                      : prev.filter((i) => i !== item.id)
                  )
                }
              />
            </div>
            {refreshItems ? (
              <div
                className='files__folder-item-block'
                onClick={() => {
                  setCheckedFiles([]);
                  refreshItems(item.id);
                }}
              >
                <img src={folder} alt='' />
                <div className='files__folder-item-title'>{item.oldName}</div>
              </div>
            ) : (
              <Link to={`/catalog/files/${item.id}`}>
                <img src={folder} alt='' />
                <div className='files__folder-item-title'>{item.oldName}</div>
              </Link>
            )}
          </div>
        );
      case FileType.VIDEO:
        return (
          <div className='files__video-item' key={item.id}>
            <div className='files__video-item-in'>
              <div className='files__video-item-checkbox'>
                <Checkbox
                  isChecked={checkedFiles.some((i) => i === item.id)}
                  setChecked={(value: boolean) => selectFiles(item, value)}
                />
              </div>
              <video
                src={`${FILE_URL}/${item?.userId}/${item.name}.${item.ext}`}
                onClick={() => selectFiles(item)}
              />
            </div>
            <div className='files__video-item-title'>
              {item.oldName || item.name}
            </div>
          </div>
        );
      default:
        return (
          <div className='files__pic-item' key={item.id}>
            <div className='files__pic-item-in'>
              <div className='files__pic-item-checkbox'>
                <Checkbox
                  isChecked={checkedFiles.some((i) => i === item.id)}
                  setChecked={(value: boolean) => selectFiles(item, value)}
                />
              </div>
              <img
                src={`${FILE_URL}/${item?.userId}/${item.name}-${ImageSize.PREVIEW}.${item.ext}`}
                alt=''
                onClick={() => selectFiles(item)}
              />
            </div>
            <div className='files__pic-item-title'>
              {item.oldName || item.name}
            </div>
          </div>
        );
    }
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={cn('files', { 'files--full': isFull })} id='files'>
          <InfiniteScroll
            dataLength={items.length}
            next={() => setPage(page + 1)}
            hasMore={true}
            loader={''}
            scrollableTarget='files'
            className='files__container'
          >
            {items.map((i, index) => (
              <React.Fragment key={index}>{renderItems(i)}</React.Fragment>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};
