import React, { useState } from 'react';
import { MainInput } from 'components/MainInput/MainInput';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ITag } from 'api/services/tags/types';
import './CreateDelivery.sass';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import { InputType } from 'components/MainInput/types';
import { IDelivery } from 'api/services/delivery/types';

interface Props {
  submit: (data: IDelivery) => void;
  closeModal: () => void;
  loading: boolean;
}

export const CreateDelivery: React.FC<Props> = ({
  closeModal,
  submit,
  loading,
}) => {
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [cost, setCost] = useState('');
  const [freeLimit, setFreeLimit] = useState('');
  const [hasError, setHasError] = useState(false);
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if ((!titleRu.trim() && !titleUz.trim()) || !cost.trim()) {
      showNotification('Заполните поля', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    submit({
      titleRu,
      titleUz,
      cost: Number(cost) * 100,
      ...(!!freeLimit.trim() ? { freeLimit: Number(freeLimit) * 100 } : {}),
    });
  };

  return (
    <form onSubmit={formSubmit} className='create-delivery'>
      <div className='create-delivery__container create-delivery__modal'>
        <div className='create-delivery__tabs'>
          <div className='create-delivery__tabs-item create-delivery__tabs-item--active'>
            <span>Доставка</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='create-delivery__in'>
          <div className='create-delivery__row'>
            <label className='create-delivery__row-item'>
              <div className='create-delivery__label create-delivery__row-label'>
                Название:
              </div>
              {lang === 'ru' ? (
                <MainInput
                  updateInputValue={setTitleRu}
                  inputValue={titleRu}
                  error={hasError && !titleRu.trim()}
                />
              ) : (
                <MainInput
                  updateInputValue={setTitleUz}
                  inputValue={titleUz}
                  error={hasError && !titleUz.trim()}
                />
              )}
            </label>
          </div>
          <div className='create-delivery__row'>
            <label className='create-delivery__row-item'>
              <div className='create-delivery__label create-delivery__row-label'>
                Цена:
              </div>

              <MainInput
                updateInputValue={setCost}
                inputValue={cost}
                error={hasError && !cost.trim()}
                type={InputType.NUMBER}
              />
            </label>
          </div>
          <div className='create-delivery__row'>
            <label className='create-delivery__row-item'>
              <div className='create-delivery__label create-delivery__row-label'>
                Лимит:
              </div>
              <MainInput
                updateInputValue={setFreeLimit}
                inputValue={freeLimit}
                type={InputType.NUMBER}
              />
            </label>
          </div>
        </div>
      </div>
      <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
    </form>
  );
};
