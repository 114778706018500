export enum InputType {
  DATE = 'DATE',
  TEXTAREA = 'TEXTAREA',
  TEXT = 'TEXT',
  TEXT_WITH_ICON = 'TEXT_WITH_ICON',
  SELECT = 'SELECT',
  SEARCH = 'SEARCH',
  NUMBER_WITH_ARROWS = 'NUMBER_WITH_ARROWS',
  NUMBER = 'NUMBER',
  PHONE_NUMBER = 'PHONE_NUMBER',
  PASSWORD = 'PASSWORD',
}
