import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Template } from 'components/Template/Template';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { UpdateOrder } from 'components/Modal/components/UpdateOrder/UpdateOrder';
import { Filter } from 'components/Filter/Filter';
import * as settingActions from 'store/ducks/settings/actions';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { IResOrder } from 'api/services/orders/types';
import { orderServices, shopServices } from 'api/services';
import { OrderStatus } from 'enums/orderStatus';
import { translatePayment } from 'helpers/translate';
import socket from 'helpers/socket';
import _ from 'lodash';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { selectStatuses, selectTypes } from 'constants/project';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { MainInput } from 'components/MainInput/MainInput';
import { InputType } from 'components/MainInput/types';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';

import './ActiveOrdersPage.sass';

const tabs: IModalTab[] = [
  {
    label: 'Информация о заказе',
    index: 1,
  },
  {
    label: 'Товар',
    index: 2,
  },
  {
    label: 'Статус заказа',
    index: 3,
  },
];

const filteredSelectStatuses = selectStatuses.filter(
  (i) =>
    i.value === OrderStatus.ACCEPTED ||
    i.value === OrderStatus.DELIVERED ||
    i.value === OrderStatus.ON_THE_WAY ||
    i.value === 'empty'
);

export const ActiveOrdersPage = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState<IResOrder>();
  const [showModal, setShowModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [orders, setOrders] = useState<IResOrder[]>([]);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [orderStatus, setOrderStatus] = useState<ISelect>(
    filteredSelectStatuses[0].value as any
  );
  const [orderId, setOrderId] = useState('');
  const selectedOrderIdRef = useRef<number>();
  const [searchValue, setSearchValue] = useState('');
  const [shops, setShops] = useState<ISearchableSelect[]>([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [selectedShopValue, setSelectedShopValue] = useState('');
  const [shopsLoading, setShopsLoading] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState<number>();
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [paymentType, setPaymentType] = useState<ISelect>(
    selectTypes[0].value as any
  );

  const ordersRef = useRef<IResOrder[]>([]);

  const { activeOrdersSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Заказы', id: '' },
        { title: 'Активные заказы', id: 'orders/active' },
      ])
    );
  }, []);

  useEffect(() => {
    getOrders();
  }, [page]);

  useEffect(() => {
    subscribe();
    getShops();
  }, []);

  const closeFilter = () => {
    setFilterShowModal(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getOrders();
    setSearchValue(value);
  };

  const getShops = async (value?: string) => {
    try {
      const res = await shopServices
        .getShops({ skip: 0, take: 25, search: value })
        .then((res) => res.data);
      setShops(res.data.map((i) => ({ name: i.name, value: i.id })));
    } finally {
      setShopsLoading(false);
    }
  };

  const subscribe = () => {
    socket.connect().on('orders', async (id: number) => {
      const order = await orderServices
        .getOrderById(id)
        .then((res) => res.data);
      const clone = _.clone(ordersRef.current);
      if (selectedOrderIdRef.current === id) {
        setSelectedOrder(order);
      }
      if (!clone.some((i) => i.id === id) && clone.length !== take) {
        const result = [order, ...clone];
        ordersRef.current = result;
        setOrders(result);
        return;
      }
      if (!clone.some((i) => i.id === id) && clone.length === take) {
        clone.unshift(order);
        clone.length = 25;
        ordersRef.current = clone;
        setOrders(clone);
        return;
      }
      const findOrder = clone.findIndex((i) => i.id === id);
      clone.splice(findOrder, 1, order);
      const result = clone.filter(
        (i) =>
          i.status === OrderStatus.ACCEPTED ||
          i.status === OrderStatus.ON_THE_WAY ||
          i.status === OrderStatus.DELIVERED
      );
      setOrders(result);
      ordersRef.current = result;
    });
  };

  const getOrders = async () => {
    const attrs = {
      take,
      skip: page * take,
      ...(orderStatus && (orderStatus as any) === 'empty'
        ? {
            statuses: [
              OrderStatus.ACCEPTED,
              OrderStatus.ON_THE_WAY,
              OrderStatus.DELIVERED,
            ],
          }
        : {}),
      phone: phoneNumber.replace('+', ''),
      ...(orderStatus && (orderStatus as any) !== 'empty'
        ? { status: orderStatus as any }
        : {}),
      ...(!!orderId.trim() ? { id: Number(orderId) } : {}),
      ...(selectedShopId ? { shopId: selectedShopId } : {}),
      ...(paymentType && (paymentType as any) !== 'empty'
        ? { paymentType: paymentType as any }
        : {}),
    };
    const res = await orderServices.getOrders(attrs).then((res) => res.data);
    ordersRef.current = res.data;
    setOrders(res.data);
    setTotalCount(res.count);
  };

  const openModal = async (id: number) => {
    const res = await orderServices.getOrderById(id).then((res) => res.data);
    setSelectedOrder(res);
    setShowModal(true);
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setActiveOrdersSettings(items));
    setShowSettings(false);
  };

  const closeModal = () => {
    setShowModal(false);
    getOrders();
    setActiveTab(1);
  };

  return (
    <>
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
            hideSearch
          >
            <div className='orders-page__filter'>
              <div className='orders-page__filter-input'>
                <span>Номер заказа</span>
                <MainInput
                  type={InputType.NUMBER}
                  inputValue={orderId}
                  updateInputValue={setOrderId}
                  focused
                />
              </div>
              <div className='orders-page__filter-input'>
                <span>Номер телефона</span>
                <MainInput
                  inputValue={phoneNumber}
                  updateInputValue={setPhoneNumber}
                  type={InputType.PHONE_NUMBER}
                />
              </div>
              <div className='orders-page__filter-input'>
                <span>Email</span>
                <MainInput inputValue={email} updateInputValue={setEmail} />
              </div>
              <div className='orders-page__filter-input'>
                <span>Магазин</span>
                <CustomSearchableSelect
                  items={[{ name: 'Нет', value: null }, ...shops]}
                  loading={shopsLoading}
                  setValue={getShops}
                  setLoading={setShopsLoading}
                  setSelectItem={setSelectedShopId}
                  searchValue={selectedShopValue}
                  setSearchValue={setSelectedShopValue}
                />
              </div>
              <div className='orders-page__filter-input'>
                <span>Статус</span>
                <MainSelect
                  items={filteredSelectStatuses}
                  selectItem={setOrderStatus}
                  value={orderStatus}
                  withIcon
                  isGray
                />
              </div>
              <div className='orders-page__filter-input'>
                <span>Способ оплаты</span>
                <MainSelect
                  items={selectTypes}
                  selectItem={setPaymentType}
                  value={paymentType}
                  withIcon
                  isGray
                />
              </div>
            </div>
          </ModalFilter>
        </Modal>
      )}
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Активные заказы'
            items={activeOrdersSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showModal && selectedOrder && (
        <Modal closeModal={closeModal}>
          <UpdateOrder
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            order={selectedOrder}
            closeModal={closeModal}
          />
        </Modal>
      )}
      <div className='page active-orders-page'>
        <Filter
          settingFunc={() => setShowSettings(true)}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data: orders.map((i) => ({
                ...i,
                statusColor: i.status,
                client: i.user,
                paymentMethod: translatePayment(i.paymentType),
              })),
              keys: activeOrdersSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={activeOrdersSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            selectItem={openModal}
            withPaginate
            skipHandler={setPage}
            take={take}
            totalCount={totalCount}
            page={page}
          />
        </div>
      </div>
    </>
  );
};
