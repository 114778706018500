import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete-icon.svg';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-icon-thin.svg';
import { fileServices } from 'api/services';
import { FILE_URL } from 'constants/project';
import cn from 'classnames';

import './FileUploader.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IResFile } from 'api/services/files/types';
import { FileModal } from 'components/Modal/components/FileModal/FileModal';
import showNotification from 'helpers/showNotification';

interface Props {
  isCustom?: boolean;
  img?: IResFile;
  deleteImg?: () => void;
  setFile?: (file: any) => void;
  file?: IResFile;
  error?: boolean;
  isMultiple?: boolean;
}

export const FileUploader: React.FC<Props> = ({
  isCustom = false,
  img,
  deleteImg,
  setFile,
  file,
  error,
  isMultiple = false,
}) => {
  const [showFileModal, setShowFileModal] = useState(false);
  const { user } = useSelector((state: AppState) => state.profileReducer);
  const fileRef = useRef<any>();
  const fileRef2 = useRef<any>();

  const onDrop = useCallback(async (acceptedFiles) => {
    const formData = new FormData();
    const arrFiles: string[] = [];
    for (let index in acceptedFiles) {
      try {
        if (arrFiles.some((i) => i === acceptedFiles[index].name)) {
          showNotification(
            `Невозможно загрузить файл с именем ${acceptedFiles[index].name}, так как он уже был загружен`
          );
        }
        formData.append('file', acceptedFiles[index] as any);
        arrFiles.push(acceptedFiles[index].name);
        const file = await fileServices
          .uploadFile(formData)
          .then((res) => res.data);
        if (setFile) setFile(file);

        formData.delete('file');
      } catch {
        showNotification(`Не удалось загрузить ${arrFiles}`);
      }
    }
  }, []);

  // const uploadFileCustom = () => {
  //   const files = fileRef.current
  //     ? [...fileRef.current.files]
  //     : [...fileRef2.current.files];
  //   files?.forEach((i: any) => {
  //     const fileName = i.name.split('.');
  //     fileName.pop();
  //     const formData = new FormData();
  //     formData.append('file', i);
  //     formData.append('oldName', fileName.join('.'));
  //     uploadFile(formData);
  //   });
  // };

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      'image/jpg, image/JPEG, image/JPG, image/jpeg, image/png, image/PNG, image/WEBP, image/webp, video/mp4, video/MP4, video/mov, video/MOV, video/webm, video/WEBM',
    onDrop,
    multiple: isMultiple,
  });

  const selectFile = (selectedFile: IResFile) => {
    if (!setFile) return;
    setFile(selectedFile);
    setShowFileModal(false);
  };

  return (
    <>
      {showFileModal && (
        <FileModal
          closeModal={() => setShowFileModal(false)}
          fileSelected={selectFile}
        />
      )}
      {!isCustom ? (
        <div
          {...getRootProps()}
          className={cn('file-uploader', {
            'file-uploader--error': error,
            'file-uploader--with-file': file,
          })}
        >
          <input {...getInputProps()} />
          {file ? (
            <div className='file-uploader__file'>
              {file.type === 'IMAGE' ? (
                <img
                  src={`${FILE_URL}/${file?.userId}/${file.name}.${file.ext}`}
                  alt=''
                />
              ) : (
                <video
                  src={`${FILE_URL}/${file?.userId}/${file.name}.${file.ext}`}
                />
              )}
            </div>
          ) : (
            <div className='file-uploader__text'>
              Загрузить
              <span> изображение (jpg, jpeg, png, webp, bmp)</span>
            </div>
          )}
        </div>
      ) : (
        <div className='file-uploader--custom'>
          {img && deleteImg && (
            <div className='file-uploader__delete' onClick={deleteImg}>
              Удалить
              <DeleteIcon />
            </div>
          )}
          {img ? (
            img.type === 'IMAGE' ? (
              <img
                src={`${FILE_URL}/${img?.userId}/${img.name}.${img.ext}`}
                alt=''
                className='file-uploader__background'
              />
            ) : (
              <video
                src={`${FILE_URL}/${img?.userId}/${img.name}.${img.ext}`}
                className='file-uploader__background'
                muted
                autoPlay
              />
            )
          ) : (
            <div
              className='file-uploader__choice-file--center'
              onClick={() => setShowFileModal(true)}
            >
              <div className='file-uploader__choice-file-icon'>
                <PlusIcon />
              </div>
              {/* <input
                type='file'
                ref={fileRef}
                accept='image/jpg, image/JPEG, image/JPG, image/jpeg, image/png, video/mp4'
                onChange={uploadFileCustom}
                multiple={isMultiple}
              /> */}
              <span>Загрузите файл</span>
            </div>
          )}
          <div
            className='file-uploader__choice-file'
            onClick={() => setShowFileModal(true)}
          >
            <span>Выбрать файл</span>
            {/* <input
              type='file'
              ref={fileRef2}
              accept='image/jpg, image/JPEG, image/JPG, image/jpeg, image/png, video/mp4'
              onChange={uploadFileCustom}
              multiple={isMultiple}
            /> */}
            <FolderIcon />
          </div>
        </div>
      )}
    </>
  );
};
