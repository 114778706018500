import { ISetting } from 'components/Modal/components/Settings/types';
import { SettingType } from './types';

export const setCatalogCategoriesSettings = (items: ISetting[]) => {
  localStorage.setItem('catalogCategoriesSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_CATALOG_CATEGORIES_SETTINGS,
    items,
  };
};

export const setProductsSettings = (items: ISetting[]) => {
  localStorage.setItem('productsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_PRODUCTS_SETTINGS,
    items,
  };
};

export const setBrandsSettings = (items: ISetting[]) => {
  localStorage.setItem('brandsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_BRANDS_SETTINGS,
    items,
  };
};

export const setStocksSettings = (items: ISetting[]) => {
  localStorage.setItem('stocksSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_STOCKS_SETTINGS,
    items,
  };
};

export const setAttributeGroupsSettings = (items: ISetting[]) => {
  localStorage.setItem('attributeGroupsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_ATTRIBUTE_GROUPS_SETTINGS,
    items,
  };
};

export const setAttributesSettings = (items: ISetting[]) => {
  localStorage.setItem('attributesSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_ATTRIBUTES_SETTINGS,
    items,
  };
};

export const setCurrenciesSettings = (items: ISetting[]) => {
  localStorage.setItem('currenciesSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_CURRENCIES_SETTINGS,
    items,
  };
};

export const setActiveOrdersSettings = (items: ISetting[]) => {
  localStorage.setItem('activeOrdersSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_ACTIVE_ORDERS_SETTINGS,
    items,
  };
};

export const setAllOrdersSettings = (items: ISetting[]) => {
  localStorage.setItem('allOrdersSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_ALL_ORDERS_SETTINGS,
    items,
  };
};

export const setServicesSettings = (items: ISetting[]) => {
  localStorage.setItem('servicesSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_SERVICES_SETTINGS,
    items,
  };
};

export const setCashbackSettings = (items: ISetting[]) => {
  localStorage.setItem('cashBackSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_CASHBACK_SETTINGS,
    items,
  };
};

export const setUsersSettings = (items: ISetting[]) => {
  localStorage.setItem('usersSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_USERS_SETTINGS,
    items,
  };
};

export const setShopUsersSettings = (items: ISetting[]) => {
  localStorage.setItem('shopUsersSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_SHOP_USERS_SETTINGS,
    items,
  };
};

export const setSupplementaryServicesSettings = (items: ISetting[]) => {
  localStorage.setItem('supplementaryServicesSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_SUPPLEMENTARY_SERVICES_SETTINGS,
    items,
  };
};

export const setPostCategoriesSettings = (items: ISetting[]) => {
  localStorage.setItem('postCategoriesSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_POST_CATEGORIES_SETTINGS,
    items,
  };
};

export const setPostsSettings = (items: ISetting[]) => {
  localStorage.setItem('postsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_POSTS_SETTINGS,
    items,
  };
};

export const setShopsSettings = (items: ISetting[]) => {
  localStorage.setItem('shopsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_SHOPS_SETTINGS,
    items,
  };
};

export const setKnowledgeBaseSettings = (items: ISetting[]) => {
  localStorage.setItem('knowledgeBaseSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_KNOWLEDGE_BASE_SETTINGS,
    items,
  };
};

export const setReviewsSettings = (items: ISetting[]) => {
  localStorage.setItem('reviewsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_REVIEWS_SETTINGS,
    items,
  };
};

export const setMerchantReviewsSettings = (items: ISetting[]) => {
  localStorage.setItem('merchantReviewsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_MERCHANT_REVIEWS_SETTINGS,
    items,
  };
};

export const setCommentsSettings = (items: ISetting[]) => {
  localStorage.setItem('commentsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_COMMENTS_SETTINGS,
    items,
  };
};

export const setProductCommentsSettings = (items: ISetting[]) => {
  localStorage.setItem('productCommentsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_PRODUCT_COMMENTS_SETTINGS,
    items,
  };
};

export const setTagsSettings = (items: ISetting[]) => {
  localStorage.setItem('tagsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_TAGS_SETTINGS,
    items,
  };
};

export const setBannersSettings = (items: ISetting[]) => {
  localStorage.setItem('bannersSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_BANNERS_SETTINGS,
    items,
  };
};

export const setStoryGroupsSettings = (items: ISetting[]) => {
  localStorage.setItem('storyGroupsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_STORY_GROUPS_SETTINGS,
    items,
  };
};

export const setStoriesSettings = (items: ISetting[]) => {
  localStorage.setItem('storiesSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_STORIES_SETTINGS,
    items,
  };
};

export const setCouponsSettings = (items: ISetting[]) => {
  localStorage.setItem('couponsSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_COUPONS_SETTINGS,
    items,
  };
};

export const setPromocodesSettings = (items: ISetting[]) => {
  localStorage.setItem('promocodesSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_PROMO_CODES_SETTINGS,
    items,
  };
};

export const setDeliverySettings = (items: ISetting[]) => {
  localStorage.setItem('deliverySettings', JSON.stringify(items));
  return {
    type: SettingType.SET_DELIVERY_SETTINGS,
    items,
  };
};

export const setBalanceReportSettings = (items: ISetting[]) => {
  localStorage.setItem('balanceReportSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_BALANCE_REPORT_SETTINGS,
    items,
  };
};
export const setSalesReportSettings = (items: ISetting[]) => {
  localStorage.setItem('salesReportSettings', JSON.stringify(items));
  return {
    type: SettingType.SET_SALES_REPORT_SETTINGS,
    items,
  };
};
