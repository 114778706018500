import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreatePromocode,
  IGetPromocode,
  IGetPromoCodeById,
  IUpdatePromocode,
} from './types';

export const getPromocodes: IGetPromocode = (attrs) =>
  instance.get(`/promocodes?${qs.stringify(attrs, { skipEmptyString: true })}`);

export const getPromocodeById: IGetPromoCodeById = (id) =>
  instance.get(`/promocodes/${id}`);

export const createPromocode: ICreatePromocode = (data) =>
  instance.post('/promocodes', data);

export const updatePromocode: IUpdatePromocode = (id, data) =>
  instance.put(`/promocodes/${id}`, data);

export const deletePromocode = (id: number) =>
  instance.delete(`/promocodes/${id}`);

export const genCode = () => instance.get('/promocodes/gen-code');
