import React, { useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Pagination } from 'components/Pagination/Pagination';
import FolderIcon from 'assets/images/icons/folder-icon.png';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/close-icon.svg';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  translateAttributeType,
  translateAttributeViewType,
  translateOrderStatus,
  translateUserRole,
} from 'helpers/translate';
import RadioTable from 'components/RadioTable/RadioTable';
import { FILE_URL } from 'constants/project';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ReactComponent as SuccessIcon } from 'assets/images/icons/success-icon.svg';
import { ReactComponent as PencilIcon } from 'assets/images/icons/pencil-icon.svg';
import { checkAmount } from 'helpers/checkAmount';
import { OrderStatus } from 'enums/orderStatus';
import { IResVariant } from 'api/services/variants/types';
import { UserRole } from 'enums/users';
import './Table.sass';

interface Props {
  tableHead: string[];
  tableBody?: { data: any; keys: string[] };
  classes?: string;
  selectItem?: (id: number) => void;
  withPaginate?: boolean;
  withIcons?: boolean;
  isLinks?: boolean;
  take?: number;
  totalCount?: number;
  skipHandler?: (page: number) => void;
  radioHanler?: (e: boolean, data: any) => void;
  checked?: any;
  deleteHandler?: (id: number) => void;
  confirm?: (id: number) => void;
  edit?: (id: number) => void;
  isOrder?: boolean;
  page?: number;
  onlySu?: boolean;
  flex?: boolean;
}

export const Table: React.FC<Props> = ({
  tableHead,
  tableBody,
  classes,
  selectItem,
  withPaginate = false,
  withIcons = false,
  isLinks = false,
  take,
  totalCount,
  skipHandler,
  radioHanler,
  checked,
  deleteHandler,
  confirm,
  edit,
  isOrder,
  page,
  onlySu,
  flex,
}) => {
  const { pathname, search } = useLocation();
  const { user } = useSelector((state: AppState) => state.profileReducer);

  const checkKey = (key: string, value: any) => {
    if (key.match('salesSum')) {
      const arr = value.split(' ');
      return `${checkAmount(arr[0])} ${arr[1]}`;
    }
    if (
      (key.match('price') ||
        key.match('Price') ||
        key.match('cost') ||
        key.match('sum')) &&
      key !== 'arrivalPriceCurrencyId'
    )
      return checkAmount(value);
    switch (key) {
      case 'userStatus':
        return value ? 'Активный' : 'Не активный';
      case 'lastAuthorizationAt':
        return value ? moment(value).format('DD.MM.YYYY HH:mm:ss') : '';
      case 'variants':
        return (value as IResVariant[]).some((i) =>
          i.variantAttributes?.some((ii) => !!ii.attributeOptionId)
        )
          ? 'С опциями'
          : 'Простой';
      case 'variant':
        return value.titleRu;
      case 'user':
        return value.user || '';
      case 'userName':
      case 'operator':
        return value?.name || value?.email || '';
      case 'shop':
        return value.name;
      case 'stockActive':
        return value ? 'Подтвержден' : 'На расмотрении';
      case 'variantAttributes':
        return value.map((i: any, index: number) => (
          <span
            className={cn('table__variant-options', {
              'table__variant-options--not-margin':
                value.length > 1 && index === 0,
            })}
            key={i.id}
          >
            {value.length > 1 && index !== 4
              ? index < 5 &&
                `${' '} ${i.attributeOption?.titleRu || i.titleRu},`
              : (index < 5 && i.attributeOption?.titleRu) || i.titleRu}
          </span>
        ));
      case 'color':
        return (
          <div
            className='table__color-td'
            style={{ backgroundColor: value }}
          ></div>
        );
      case 'marginType':
        return value === 'PERCENT' ? 'Процент' : 'Фиксированная';
      case 'totalSum':
        return checkAmount(value) + ' сум';
      case 'img':
        return <img className='table__img' src={value} alt='' />;
      case 'file':
        return (
          <img
            className='table__img'
            src={`${FILE_URL}/${value?.userId}/${value.name}.${value.ext}`}
          />
        );
      case 'client':
      case 'responsibleManager':
        return value ? value?.name || value?.email : '';
      case 'isActive':
      case 'permission':
      case 'fullWidth':
      case 'gift':
        return value ? 'Да' : 'Нет';
      case 'role':
        return translateUserRole(value);
      case 'product':
      case 'article':
        return value?.titleRu;
      case 'isShipped':
        return value ? 'Отгружено' : 'Не отгружено';
      case 'createdAt':
        return moment(value).format('DD.MM.YYYY HH:mm:ss');
      case 'updatedAt':
        return moment(value).format('DD.MM.YYYY HH:mm:ss');
      case 'deactivateAt':
        return value ? moment(value).format('DD.MM.YYYY HH:mm:ss') : '';
      case 'activateAt':
        return value ? moment(value).format('DD.MM.YYYY HH:mm:ss') : '';
      case 'lastAuthorizationAt':
        return moment(value).format('DD.MM.YYYY HH:mm:ss');
      case 'amountCurrency':
        return `${value.amount} ${value.currency}`;
      case 'postCategory':
        return value.titleRu;
      case 'categories':
        return value.map((i: any, index: number) => (
          <React.Fragment key={i.id || i.index}>
            {value.length > 1 && index !== value.length - 1
              ? `${i.titleRu}, `
              : i.titleRu}
          </React.Fragment>
        ));
      case 'viewType':
        return translateAttributeViewType(value);
      case 'type':
        return translateAttributeType(value);
      case 'statusColor':
        return (
          <span
            className={cn('table__item-status', {
              'table__item-status--processed': value === OrderStatus.NEW,
              'table__item-status--canceled': value === OrderStatus.CANCELED,
              'table__item-status--accepted': value === OrderStatus.ACCEPTED,
              'table__item-status--handed-courier':
                value === OrderStatus.ON_THE_WAY,
              'table__item-status--delivered': value === OrderStatus.DELIVERED,
              'table__item-status--completed': value === OrderStatus.COMPLETED,
            })}
          >
            {translateOrderStatus(value)}
          </span>
        );
      default:
        return value;
    }
  };

  const handleItem = (id: number) => {
    if (selectItem) selectItem(id);
  };

  const checkRow = (ii: string, title: string) => {
    if (isLinks) {
      return (
        <Link to={`${pathname}${search}`} className='table__item-title'>
          {ii === 'title' && withIcons && (
            <div className='table__icons'>
              <div className='table__icon'>
                <PlusIcon />
              </div>
              <img className='table__icon' src={FolderIcon} />
            </div>
          )}
          {checkKey(ii, title)}
        </Link>
      );
    }
    return (
      <div className='table__item-title'>
        {ii === 'titleRu' && withIcons && (
          <div className='table__icons'>
            <div className='table__icon-plus'>
              <PlusIcon />
            </div>
            <img className='table__icon' src={FolderIcon} />
          </div>
        )}
        {checkKey(ii, title)}
      </div>
    );
  };

  return (
    <>
      <div className={`table ${classes || ''}`}>
        <table className='table__container'>
          <thead>
            <tr className='table__row table__head-row'>
              {radioHanler && <td className='table__item'></td>}
              {tableHead.map((i) => (
                <td className='table__item' key={i}>
                  {i}
                </td>
              ))}
              {((deleteHandler &&
                (user?.role === UserRole.ADMIN || user?.role === UserRole.SU) &&
                !isOrder &&
                !onlySu) ||
                (onlySu && user?.role === UserRole.SU)) && (
                <td className='table__item'>Действия</td>
              )}
            </tr>
          </thead>
          <tbody>
            {tableBody?.data?.map((i: any, index: number) => (
              <tr className='table__row' key={i.id || index}>
                {radioHanler && (
                  <td className='table__item table__item-checkbox'>
                    <RadioTable
                      value={checked}
                      onChange={(e) => radioHanler(e.target.checked, i)}
                    />
                  </td>
                )}
                {tableBody?.keys.length &&
                  tableBody?.keys.map((ii, index) => (
                    <td
                      className={cn('table__item table__item--hover', {
                        table__order: isOrder,
                      })}
                      key={index}
                      onClick={() => handleItem(i.id)}
                    >
                      {checkRow(ii, i[`${ii}`])}
                    </td>
                  ))}
                <td className='table__item table__item-icon table__item'>
                  <div className='table__item-actions'>
                    {edit && (
                      <span
                        onClick={() => edit(i.id)}
                        className='table__item-icon-block table__item-icon-block--blue'
                      >
                        <PencilIcon />
                      </span>
                    )}
                    {confirm && i.status === 'NEW' && (
                      <span
                        onClick={() => confirm(i.id)}
                        className='table__item-icon-block table__item-icon-block--green'
                      >
                        <SuccessIcon />
                      </span>
                    )}
                    {deleteHandler &&
                      (user?.role === UserRole.ADMIN ||
                        user?.role === UserRole.SU) &&
                      !isOrder &&
                      !onlySu && (
                        <span
                          onClick={() => deleteHandler(i.id)}
                          className='table__item-icon-block table__item-icon-block--red'
                        >
                          <DeleteIcon />
                        </span>
                      )}
                    {onlySu && deleteHandler && user?.role === UserRole.SU && (
                      <span
                        onClick={() => deleteHandler(i.id)}
                        className='table__item-icon-block table__item-icon-block--red'
                      >
                        <DeleteIcon />
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {withPaginate &&
        !!totalCount &&
        !!take &&
        totalCount > take &&
        typeof page === 'number' &&
        page >= 0 && (
          <div className='page__pagination'>
            <Pagination
              skipHandler={skipHandler}
              take={take}
              totalCount={totalCount}
              page={page}
            />
          </div>
        )}
    </>
  );
};
