import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { Filter } from 'components/Filter/Filter';
import { useLocation, useParams } from 'react-router';
import { Advertising } from 'components/Modal/components/Advertising/Advertising';
import { IBanner, IResBanner } from 'api/services/banners/types';
import { bannerServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { bannerTypes, BannerTypes } from 'enums/bannerTypes';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import * as settingActions from 'store/ducks/settings/actions';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Settings } from 'components/Modal/components/Settings/Settings';

const tabs: IModalTab[] = [
  {
    label: 'Загрузка баннера',
    index: 1,
  },
];

export const BannersPage = () => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const [data, setData] = useState<IResBanner[]>([]);
  const { pathname, search } = useLocation();
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [isActive, setIsActive] = useState<boolean>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [banner, setBanner] = useState<IResBanner>();
  const { bannerType } = useParams<{ bannerType: string }>();
  const [totalCount, setTotalCount] = useState<number>();
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const { bannersSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  useEffect(() => {
    getData();
  }, [take, page, pathname, searchValue]);

  useEffect(() => {
    const findBannerType = bannerTypes.find(
      (i) => i.value === bannerType?.toUpperCase().replaceAll('-', '_')
    )?.label;
    dispatch(
      templateActions.getBreadCrumbs([
        {
          title: 'Реклама',
        },
        {
          title: 'Баннеры',
          id: 'advertising/banners/slider',
        },
        {
          title: findBannerType as string,
          id: `advertising/banners/${bannerType}`,
        },
      ])
    );
  }, [bannerType]);

  const getData = async () => {
    if (!bannerType) return;
    setLoading(true);
    try {
      const validBannerType = bannerType
        .toUpperCase()
        .replace(/-/g, '_') as BannerTypes;
      const params = {
        take,
        skip: page * take,
        isActive,
        type: validBannerType,
        search: searchValue,
      };
      const res = await bannerServices
        .getBanners(params)
        .then((res) => res.data);
      setData(res.data);
      setTotalCount(res.count);
    } finally {
      setLoading(false);
    }
  };

  const createBannerHandler = async (data: IBanner) => {
    setSubmitLoading(true);
    try {
      await bannerServices.createBanner(data);
      setShowCreateModal(false);
      showNotification('Готово', NotificationStatus.SUCCESS);
      getData();
    } catch {
    } finally {
      setSubmitLoading(false);
    }
  };

  const updateBannerHandler = async (data: IBanner) => {
    if (!banner) return;
    setSubmitLoading(true);
    try {
      await bannerServices.updateBanner(banner.id, data);
      showNotification('Готово', NotificationStatus.SUCCESS);
      closeModal();
      getData();
    } finally {
      setSubmitLoading(false);
    }
  };

  const deleteBanner = async (id: number) => {
    try {
      await bannerServices.deleteBanner(id);
      showNotification('Удалено', NotificationStatus.SUCCESS);
      getData();
    } catch {
      showNotification('Произошла ошибка, попробуйте позже');
    }
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getData();
    setSearchValue(value);
  };

  const closeModal = () => {
    setShowCreateModal(false);
    setShowUpdateModal(false);
    setBanner(undefined);
  };

  const openUpdateModal = async (id: number) => {
    try {
      const banner = await bannerServices
        .getBannerById(id)
        .then((res) => res.data);
      setBanner(banner);
      setShowUpdateModal(true);
    } catch {
      showNotification('Баннер не найден', NotificationStatus.WARNING);
    }
  };

  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setBannersSettings(items));
    setShowSettings(false);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Баннеры'
            items={bannersSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={closeModal}>
          <Advertising
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            submit={createBannerHandler}
            closeModal={closeModal}
            loading={submitLoading}
          />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal closeModal={closeModal}>
          <Advertising
            tabs={tabs}
            activeTab={5}
            setActiveTab={setActiveTab}
            submit={updateBannerHandler}
            closeModal={closeModal}
            banner={banner}
            loading={submitLoading}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='page active-orders-page'>
        <Filter
          createFunc={() => setShowCreateModal(true)}
          filterFunc={() => setFilterShowModal(true)}
          settingFunc={() => setShowSettings(true)}
          updateSearchValue={newFilterValue}
          loading={loading}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data,
              keys: bannersSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={bannersSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            selectItem={openUpdateModal}
            deleteHandler={deleteBanner}
            skipHandler={setPage}
            take={take}
            totalCount={totalCount}
            page={page}
          />
        </div>
      </div>
    </>
  );
};
