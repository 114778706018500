import React from 'react';
import { ReactComponent as ExitIcon } from 'assets/images/icons/close-icon.svg';
import { IResFile } from 'api/services/files/types';
import { FILE_URL } from 'constants/project';

import './ShowFile.sass';

interface Props {
  closeFile: () => void;
  file: IResFile;
}

export const ShowFile: React.FC<Props> = ({ closeFile, file }) => {
  return (
    <div className='show-file'>
      <div className='show-file__blackout' onClick={closeFile}></div>
      <div className='show-file__container'>
        <div className='show-file__close' onClick={closeFile}>
          <ExitIcon />
        </div>
        <div className='show-file__in'>
          {file.type === 'IMAGE' ? (
            <img src={`${FILE_URL}/${file.userId}/${file.name}.${file.ext}`} />
          ) : (
            <video
              src={`${FILE_URL}/${file.userId}/${file.name}.${file.ext}`}
              autoPlay
              controls
            />
          )}
        </div>
      </div>
    </div>
  );
};
