import React, { useEffect, useState } from 'react';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { MainInput } from 'components/MainInput/MainInput';
import { ActionButtons } from '../../ActionButtons/ActionButtons';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import cn from 'classnames';

import { AttributeOptions } from 'components/AttributeOptions/AttributeOptions';
import {
  AttributeType,
  AttributeViewType,
  IAttributeOption,
  IResAttribute,
} from 'api/services/attributes/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { attributeTypes, attributeViewTypes } from './settings';
import { MainButton } from 'components/MainButton/MainButton';
import { SelectList } from 'components/SelectList/SelectList';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import './UpdateProductAttribute.sass';
import { InputType } from 'components/MainInput/types';
import { attributeGroupServices } from 'api/services';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { Checkbox } from 'components/Checkbox/Checkbox';

interface Props {
  closeModal: () => void;
  submit: (data: any) => void;
  data?: IResAttribute;
}

export const UpdateProductAttribute: React.FC<Props> = ({
  closeModal,
  submit,
  data,
}) => {
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  const [selectedType, setSelectedType] = useState<ISelect>();
  const [selectedView, setSelectedView] = useState<ISelect>();

  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const [options, setOptions] = useState<IAttributeOption[]>([]);
  const [hasError, setHasError] = useState(false);
  const [order, setOrder] = useState('');
  const categories = useSelector((state: AppState) =>
    state.servicesReducer.categories.map((i) => ({
      label: i.titleRu,
      value: i.id,
    }))
  );
  const [attributeGroups, setAttributeGroups] = useState<ISearchableSelect[]>(
    []
  );
  const [isOption, setIsOption] = useState(false);
  const [attributeGroupsLoading, setAttributeGroupsLoading] = useState(false);
  const [selectedAttributeGroup, setSelectedAttributeGroup] =
    useState<number>();
  const [attributeGroupsValue, setAttributeGroupValue] = useState('');
  const [required, setRequired] = useState(false);

  useEffect(() => {
    getAttributeGroups();
  }, []);

  useEffect(() => {
    if (data) {
      setTitleRu(data.titleRu);
      setTitleUz(data.titleUz);
      setOptions(
        data?.options?.map((i) => ({
          ...i,
          label: lang === 'ru' ? i.titleRu : i.titleUz,
          value: i.value,
        })) || []
      );
      setSelectedCategories(
        data?.categories?.map((i) => ({ label: i.titleRu, value: i.id })) || []
      );
      setRequired(data?.required);
      setSelectedView(
        attributeViewTypes.find((i) => i.value === data.viewType) ||
          attributeViewTypes[0]
      );
      setSelectedType(attributeTypes.find((i) => i.value === data.type));
      setOrder(data?.order.toString());
      setAttributeGroupValue(data?.attributeGroup?.titleRu || '');
      setSelectedAttributeGroup(data?.attributeGroupId);
      setIsOption(data?.isOption);
    }
  }, [!!categories, !!data, lang]);

  const [selectedCategory, setSelectedCategory] = useState<ISelect>();
  const [selectedCategories, setSelectedCategories] = useState<ISelect[]>([]);

  const getAttributeGroups = async () => {
    const res = await attributeGroupServices
      .getAttributeGroups({ skip: 0, take: 25 })
      .then((res) => res.data.data);
    setAttributeGroups(res.map((i) => ({ name: i.titleRu, value: i.id })));
    setAttributeGroupsLoading(false);
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!titleRu.trim() && !titleUz.trim()) {
      showNotification('Заполните название', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    // if (!selectedCategories.length) {
    //   showNotification('Выберите категорию', NotificationStatus.WARNING);
    //   setHasError(true);
    //   return;
    // }
    submit({
      titleRu,
      titleUz,
      categoryIds: selectedCategories.map((i) => i.value),
      ...(selectedType?.value === 'LIST' || (selectedType as any) === 'LIST'
        ? {
            options: options.map((i) => ({
              titleRu: i.titleRu,
              titleUz: i.titleUz,
              value: i.value,
              id: i.id,
            })),
          }
        : {}),
      ...(selectedAttributeGroup
        ? { attributeGroupId: selectedAttributeGroup }
        : {}),
      viewType: selectedView?.value || null,
      type: selectedType?.value,
      order: Number(order),
      required,
      isOption,
    });
  };

  const addCategory = () => {
    if (!selectedCategory) {
      setHasError(true);
      showNotification('Выберите категорию');
      return;
    }
    if (selectedCategories.find((i) => i.value === selectedCategory.value))
      return;

    setSelectedCategories((prev) => [...prev, selectedCategory]);
    setSelectedCategory(undefined);
    categories.filter((i) => i.value !== selectedCategory.value);
  };

  const selectCategory = (value: string | number) => {
    const findCat = categories.find((i) => i.value === value);
    if (!findCat) return;

    setSelectedCategory(findCat);
  };

  const changeType = (type: AttributeType) => {
    setSelectedType(attributeTypes.find((i) => i.value === type));
  };
  const changeView = (type: AttributeViewType) => {
    setSelectedView(attributeViewTypes.find((i) => i.value === type));
  };

  return (
    <form onSubmit={formSubmit} className='update-product-attribute'>
      <div className='update-product-attribute__modal'>
        <div className='update-product-attribute__tabs'>
          <div
            className={cn('update-product-attribute__tabs-item', {
              'update-product-attribute__tabs-item--active': activeTab === 1,
            })}
            onClick={() => setActiveTab(1)}
          >
            <span>Атрибут</span>
          </div>
          {selectedType?.value === AttributeType.LIST && (
            <div
              className={cn('update-product-attribute__tabs-item', {
                'update-product-attribute__tabs-item--active': activeTab === 2,
              })}
              onClick={() => setActiveTab(2)}
            >
              <span>Опции атрибута</span>
            </div>
          )}
          <ChoiceLang />
        </div>
        {activeTab === 1 ? (
          <div className='update-product-attribute__in'>
            {/* <div className='update-product-attribute__title'>ID: Новое</div> */}
            <div className='update-product-attribute__container'>
              <div className='update-product-attribute__container-item update-product-attribute__container-item--left'>
                <label className='update-product-attribute__item'>
                  <div className='update-product-attribute__title'>
                    Обязательный
                  </div>
                  <Checkbox isChecked={required} setChecked={setRequired} />
                </label>
                {(selectedType?.value === AttributeType.LIST ||
                  (selectedType as any) === AttributeType.LIST) && (
                  <label className='update-product-attribute__item'>
                    <div className='update-product-attribute__title'>Опция</div>
                    <Checkbox isChecked={isOption} setChecked={setIsOption} />
                  </label>
                )}
                <label className='update-product-attribute__item'>
                  <div className='update-product-attribute__title'>Тип</div>
                  <MainSelect
                    items={attributeTypes}
                    selectItem={changeType}
                    value={selectedType}
                    isGray
                    withIcon
                  />
                </label>
                <label className='update-product-attribute__item'>
                  <div className='update-product-attribute__title'>
                    Вид в умном фильтре
                  </div>
                  <MainSelect
                    items={attributeViewTypes}
                    selectItem={changeView}
                    value={selectedView}
                    isGray
                    withIcon
                  />
                </label>
                <label className='update-product-attribute__item'>
                  <div className='update-product-attribute__title'>
                    Название
                  </div>
                  {lang === 'ru' ? (
                    <MainInput
                      inputValue={titleRu}
                      error={hasError && !titleRu.trim()}
                      updateInputValue={setTitleRu}
                    />
                  ) : (
                    <MainInput
                      inputValue={titleUz}
                      error={hasError && !titleUz.trim()}
                      updateInputValue={setTitleUz}
                    />
                  )}
                </label>
                <label className='update-product-attribute__item'>
                  <div className='update-product-attribute__title'>Группа</div>
                  <CustomSearchableSelect
                    items={[{ name: 'Нет', value: null }, ...attributeGroups]}
                    loading={attributeGroupsLoading}
                    setValue={getAttributeGroups}
                    setLoading={setAttributeGroupsLoading}
                    setSelectItem={setSelectedAttributeGroup}
                    setSearchValue={setAttributeGroupValue}
                    searchValue={attributeGroupsValue}
                  />
                </label>
                <label className='update-product-attribute__item'>
                  <div className='update-product-attribute__title'>
                    Сортировка
                  </div>
                  <MainInput
                    inputValue={order}
                    error={hasError && !order.trim()}
                    updateInputValue={setOrder}
                    type={InputType.NUMBER}
                  />
                </label>
                <label className='update-product-attribute__item update-product-attribute__item--with-button'>
                  <div className='update-product-attribute__title'>
                    Категории
                  </div>
                  <div className='update-product-attribute__item-container'>
                    <MainSelect
                      items={categories}
                      selectItem={selectCategory}
                      value={selectedCategory}
                      error={!selectedCategories.length && hasError}
                      isGray
                      isSearchable
                    />
                    <MainButton title='Добавить' submit={addCategory} />
                  </div>
                </label>
                <div className='update-product-attribute__item'>
                  <SelectList
                    list={selectedCategories}
                    updateList={setSelectedCategories}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <AttributeOptions
            list={options}
            updateList={setOptions}
            lang={lang}
            hasColor={
              selectedView?.value === AttributeViewType.COLOR &&
              selectedType?.value === AttributeType.LIST
            }
          />
        )}
      </div>
      <ActionButtons formSubmit={formSubmit} closeModal={closeModal} />
    </form>
  );
};
