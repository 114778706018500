import React, { useEffect, useState } from 'react';
import { MainButton } from 'components/MainButton/MainButton';
import { Table } from 'components/Table/Table';
import './OrderProduct.sass';
import { IOrderProduct, IResOrder } from 'api/services/orders/types';
import { IResProduct } from 'api/services/products/types';
import { orderServices, productServices } from 'api/services';
import { checkAmount } from 'helpers/checkAmount';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { IResVariant } from 'api/services/variants/types';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { MainInput } from 'components/MainInput/MainInput';
import { InputType } from 'components/MainInput/types';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { TransactionType } from 'api/services/transactions/types';
import moment from 'moment';

const productsTableHead = ['Товар', 'Опции', 'Цена', 'Количество', 'Сумма'];
const productKeys = [
  'variant',
  'variantAttributes',
  'variantPrice',
  'count',
  'totalSum',
];

const deliveryTableHead = ['Спопсоб доставки', 'Чек', 'Сумма', 'Статус'];

const deliveryTableBody = {
  keys: ['method', 'check', 'amount', 'status'],
};

const shipmentTableHead = [
  'Документ отгрузки',
  'Способ доставки',
  'Стоимость',
  'Разрешена',
  'Отгружено',
  'Статус',
];

const shipmentTableBody = {
  keys: ['doc', 'method', 'price', 'permission', 'isShipped', 'statusColor'],
  data: [
    {
      id: 1,
      doc: 'Отгрузка №959 от 24.02.22  22:00:16',
      method: 'Бесплатная доставка',
      price: '0 сум',
      permission: false,
      isShipped: false,
      statusColor: 'Обрабатывается',
    },
  ],
};
interface Props {
  order: IResOrder;
}

export const OrderProduct: React.FC<Props> = ({ order }) => {
  const [products, setProducts] = useState<IResProduct[]>([]);
  const [searchableProducts, setSearchableProducts] = useState<
    ISearchableSelect[]
  >([]);
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const [productsLoading, setProductsLoading] = useState(false);
  const [productSearchValue, setProductSearchValue] = useState('');
  const [variants, setVariants] = useState<IResVariant[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<ISelect>();
  const [allProducts, setAllProducts] = useState<IResProduct[]>([]);
  const [variantCount, setVariantCount] = useState('');
  const [hasError, setHasError] = useState(false);
  const [deliveryData, setDeliveryData] = useState<any[]>([]);

  const { user } = useSelector((state: AppState) => state.profileReducer);

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    getDeliveryData();
  }, [order]);

  useEffect(() => {
    getVariants();
  }, [selectedProductId]);

  useEffect(() => {
    setProducts(
      order.orderProducts.map((i) => {
        return {
          ...i,
          variantAttributes: i.variant.variantAttributes,
          variantPrice: Number(i.variant.price),
          totalSum: Number(i.sum) * i.count,
        };
      }) as any
    );
  }, [JSON.stringify(order)]);

  const getDeliveryData = async () => {
    // const finalyTotalAmount =
    //   Number(order.totalSum) +
    //   (order.delivery?.cost && !order.freeDelivery
    //     ? Number(order.delivery.cost)
    //     : 0);
    setDeliveryData([
      {
        method: order.freeDelivery ? 'Бесплатная доставка' : 'Платная доставка',
        check: order.transactions.length
          ? `Оплата №${
              order.transactions.find((i) => !i.isCanceled)?.id
            } от ${moment(order.createdAt).format('DD.MM.YYYY HH:mm')}`
          : '',
        amount: `${checkAmount(order.totalSum)} сум`,
        status: order.transactions.find((i) => !i.isCanceled)?.id
          ? 'Оплачено'
          : 'Не оплачено',
      },
    ]);
  };

  const getProducts = async (value?: string) => {
    if (value && value?.length < 3) {
      setProductsLoading(false);
      setSearchableProducts([]);
      return;
    }
    try {
      const res = await productServices
        .getProductsOnlyActive({
          skip: 0,
          take: 25,
          shopId: order.shopId,
          search: value && value.length < 3 ? '' : value,
        })
        .then((res) => res.data);
      const productsArr = res.data.map((i) => ({
        name: i.titleRu,
        value: i.id,
      }));
      setSearchableProducts(productsArr);
      setAllProducts(res.data);
    } finally {
      setProductsLoading(false);
    }
  };

  const getVariants = () => {
    const product = allProducts.find((i) => i.id === selectedProductId);
    if (!product) return;
    setVariants(product?.variants);
  };

  const addVariant = async () => {
    if (!selectedProductId || !selectedVariant || !variantCount.trim()) {
      showNotification('Заполните поля');
      setHasError(true);
      return;
    }
    const findProduct = allProducts.find(
      (i) => i.id === selectedProductId
    ) as any;
    const findVariant = variants.find(
      (i) => i.id === (selectedVariant as any)
    ) as any;
    if (products.some((i) => i.id === findProduct.id)) return;
    const findCurrentVariant = products.find(
      (i) => i.variant.id === findVariant.id
    );
    const arr = [
      ...products
        .filter((i) => i.variant.id !== findCurrentVariant?.variant?.id)
        .map((i) => ({
          variantId: i.variant.id,
          sum: Number(i.variant.price),
          count: i.count,
          id: i.id,
        })),
      {
        variantId: findVariant.id,
        sum: Number(findVariant.price),
        count: findCurrentVariant
          ? Number(findCurrentVariant.count) + Number(variantCount)
          : Number(variantCount),
        ...(findCurrentVariant ? { id: findCurrentVariant.id } : {}),
      },
    ] as IOrderProduct[];

    const data = {
      products: arr,
      totalSum:
        Number(order.totalSum) +
        Number(findVariant.price) * Number(variantCount),
      lat: order.lat,
      lng: order.lng,
      address: order.address,
      isGift: order.isGift,
      freeDelivery: order.freeDelivery,
      name: order.name,
      paymentType: order.paymentType,
      phone: order.phone,
    };
    try {
      await orderServices.updateOrder(order.id, data);
      setSelectedProductId(undefined);
      setSelectedVariant(undefined);
      setProductSearchValue('');
      setVariantCount('');
      showNotification(
        `Товар добавлен в заказ #${order.id}`,
        NotificationStatus.SUCCESS
      );
    } catch {}
  };

  const deleteProduct = async (id: number) => {
    const arr = products.filter((i) => i.id !== id);
    const foundEl = products.find((i) => i.id === id);
    const data = {
      products: [
        ...arr.map((i) => ({
          variantId: i.variant.id,
          sum: Number(i.variant.price),
          count: i.count,
          id: i.id,
        })),
      ] as IOrderProduct[],
      totalSum: Number(order.totalSum) - (foundEl?.totalSum || 0),
      lat: order.lat,
      lng: order.lng,
      address: order.address,
      isGift: order.isGift,
      freeDelivery: order.freeDelivery,
      name: order.name,
      paymentType: order.paymentType,
      phone: order.phone,
    };
    try {
      await orderServices.updateOrder(order.id, data);
      showNotification(`Товар удален`, NotificationStatus.WARNING);
    } catch {}
  };

  const orderPay = async () => {
    try {
      await orderServices.payOrder(order.id);
      showNotification('Заказ оплачен', NotificationStatus.SUCCESS);
    } catch (e: any) {
      if (e.response.data.statusCode !== 422) {
        showNotification('Произошла ошибка, попробуйте позже');
      }
    }
  };

  return (
    <div className='order-product'>
      {!order.transactions.length && (
        <div className='order-product__add'>
          <div className='order-product__add-item'>
            <CustomSearchableSelect
              items={searchableProducts}
              loading={productsLoading}
              setValue={getProducts}
              setLoading={setProductsLoading}
              setSelectItem={setSelectedProductId}
              searchValue={productSearchValue}
              setSearchValue={setProductSearchValue}
              placeholder='Выберите продукт'
              error={!selectedProductId && hasError}
            />
          </div>
          {!!selectedProductId && (
            <div className='order-product__add-item'>
              <MainSelect
                items={variants.map((i) => ({ label: i.titleRu, value: i.id }))}
                selectItem={setSelectedVariant}
                value={selectedVariant}
                isGray
                placeholder='Выберите товар'
              />
            </div>
          )}
          {!!selectedVariant && (
            <div className='order-product__add-item order-product__add-item--count'>
              <MainInput
                inputValue={variantCount}
                updateInputValue={setVariantCount}
                placeholder='Количество'
                type={InputType.NUMBER}
                error={!variantCount.trim() && hasError}
              />
            </div>
          )}
          <MainButton submit={addVariant} title='Добaвить товар +' />
        </div>
      )}
      <div className='order-product__row'>
        <Table
          classes='order-product__table'
          tableHead={productsTableHead}
          deleteHandler={deleteProduct}
          tableBody={{
            data: products,
            keys: productKeys,
          }}
          // isOrder
        />
      </div>
      <div className='order-product__row'>
        <Table
          classes='order-product__table'
          tableHead={deliveryTableHead}
          tableBody={{
            keys: deliveryTableBody.keys,
            data: deliveryData,
          }}
        />
      </div>
      {/* <div className='order-product__row'>
        <Table
          classes='order-product__table'
          tableHead={shipmentTableHead}
          tableBody={shipmentTableBody}
        />
      </div> */}
      {order?.responsibleManagerId === user?.id &&
        !order.transactions.length &&
        order.paymentType === TransactionType.CASH && (
          <div className='order-product__button'>
            <MainButton
              title='Оплатить'
              submit={orderPay}
              isDisabled={!!order.transactionId}
            />
          </div>
        )}
    </div>
  );
};
