import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HomePage } from 'pages/HomePage/HomePage';
import { AuthPage } from 'pages/AuthPage/AuthPage';
import { ProductPage } from 'pages/ProductPage/ProductPage';
import { BrandPage } from 'pages/BrandPage/BrandPage';
import { StocksPage } from 'pages/StocksPage/StocksPage';
import { AttributesPage } from 'pages/AttributesPage/AttributesPage';
// import { OptionsPage } from 'pages/OptionsPage/OptionsPage';
import { ActiveOrdersPage } from 'pages/ActiveOrdersPage/ActiveOrdersPage';
import { OrdersPage } from 'pages/OrdersPage/OrdersPage';
import { OrderServicesPage } from 'pages/OrderServicesPage/OrderServicesPage';
import { OrderCashbackPage } from 'pages/OrderCashbackPage/OrderCashbackPage';
import { UsersShopPage } from 'pages/UsersShopPage/UsersShopPage';
import { ModerationArticlesPage } from 'pages/ModerationArticlesPage/ModerationArticlesPage';
import { ModerationReviewsPage } from 'pages/ModerationReviewsPage/ModerationReviewsPage';
import { ModerationCommentsPage } from 'pages/ModerationCommentPage/ModerationCommentsPage';
import { BannersPage } from 'pages/BannsersPage/BannsersPage';
import { CouponsPage } from 'pages/CouponsPage/CouponsPage';
import { PromocodesPage } from 'pages/PromocodesPage/PromocodesPage';
import { ReportsPage } from 'pages/ReportsPage/ReportsPage';
import { CatalogCategoriesPage } from 'pages/CatalogCategoriesPage/CatalogCategoriesPage';
import { FileCategoriesPage } from 'pages/FileCategoriesPage/FileCategoriesPage';
import { FileProductsPage } from 'pages/FileProductsPage/FileProductsPage';
import ShopsPage from 'pages/ShopsPage/ShopsPage';
import PostCategoriesPage from 'pages/PostCategoriesPage/PostCategoriesPage';
import KnowledgeBasePage from 'pages/KnowledgeBasePage/KnowledgeBasePage';
import { TagsPage } from 'pages/TagsPage/TagsPage';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { StoriesPage } from 'pages/StoriesPage/StoriesPage';
import { StoryGroupsPage } from 'pages/StoryGroupsPage/StoryGroupsPage';
import CurrenciesPage from 'pages/CurrenciesPage/CurrenciesPage';
import { UserRole, userRoles } from 'enums/users';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';
import { AttributeGroupsPage } from 'pages/AttributeGroupsPage/AttributeGroupsPage';
import { DeliveryPage } from 'pages/DeliveryPage/DeliveryPage';
import { ProductCommentsPage } from 'pages/ProductCommentsPage/ProductCommentsPage';
import { MerchantReviewsPage } from 'pages/MerchantReviewsPage/MerchantReviewsPage';
import { AboutCompanyPage } from 'pages/AboutCompanyPage/AboutCompanyPage';
import { UsersClientPage } from 'pages/UsersClientPage/UsersClientPage';
import { SupplementaryServicesPage } from 'pages/SupplementaryServicesPage/SupplementaryServicesPage';
import ReportBalancePage from 'pages/ReportBalancePage/ReportBalancePage';
import ReportSalesPage from 'pages/ReportSalesPage/ReportSalesPage';

interface IRoute {
  element: JSX.Element;
  path: string;
  role?: UserRole[];
}

const AppRoutes: React.FC = () => {
  const { token } = useSelector((state: AppState) => state.authReducer);
  const { user } = useSelector((state: AppState) => state.profileReducer);

  const allRoutes: IRoute[] = [
    {
      element: <HomePage />,
      path: '/',
    },
    {
      element: <CatalogCategoriesPage />,
      path: '/catalog/categories',
    },
    {
      element: <CatalogCategoriesPage />,
      path: '/catalog/categories/:categoryId',
    },
    {
      element: <BrandPage />,
      path: '/catalog/brands',
    },
    {
      element: <ProductPage />,
      path: '/catalog/categories/:categoryId/products',
      role: [
        UserRole.SU,
        UserRole.ADMIN,
        UserRole.CONTENT_MANAGER,
        UserRole.SHOP_OWNER,
        UserRole.SALES_MANAGER,
        UserRole.SHOP_EMPLOYEE,
      ],
    },
    {
      element: <StocksPage />,
      path: '/catalog/stocks',
      role: [
        UserRole.SU,
        UserRole.ADMIN,
        UserRole.SALES_MANAGER,
        UserRole.SHOP_OWNER,
      ],
    },
    {
      element: <AttributeGroupsPage />,
      path: 'catalog/attribute-groups',
      role: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
    },
    {
      element: <AttributesPage />,
      path: 'catalog/attributes',
      role: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
    },
    {
      element: <FileCategoriesPage />,
      path: '/catalog/files',
    },
    {
      element: <DeliveryPage />,
      path: '/catalog/delivery',
      role: [
        UserRole.SU,
        UserRole.ADMIN,
        UserRole.CONTENT_MANAGER,
        UserRole.SALES_MANAGER,
      ],
    },
    {
      element: <FileProductsPage />,
      path: '/catalog/files/:folderId',
      role: [UserRole.SU, UserRole.ADMIN],
    },
    {
      element: <CurrenciesPage />,
      path: '/catalog/currencies',
      role: [UserRole.SU, UserRole.ADMIN],
    },
    {
      element: <ActiveOrdersPage />,
      path: '/orders/active',
      role: [
        UserRole.SU,
        UserRole.SALES_MANAGER,
        UserRole.ADMIN,
        UserRole.COURIER,
      ],
    },
    {
      element: <OrdersPage />,
      path: '/orders/all',
      role: [
        UserRole.SU,
        UserRole.SALES_MANAGER,
        UserRole.ADMIN,
        UserRole.SHOP_EMPLOYEE,
        UserRole.SHOP_OWNER,
        UserRole.COURIER,
      ],
    },
    {
      element: <SupplementaryServicesPage />,
      path: '/orders/services',
      role: [UserRole.SU, UserRole.SALES_MANAGER, UserRole.ADMIN],
    },
    {
      element: <OrderCashbackPage />,
      path: '/orders/cashback',
      role: [UserRole.SU, UserRole.SALES_MANAGER, UserRole.ADMIN],
    },
    {
      element: <UsersShopPage />,
      path: '/users',
      role: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
    },
    {
      element: <UsersClientPage />,
      path: '/users',
      role: [UserRole.SHOP_EMPLOYEE, UserRole.SHOP_OWNER],
    },
    {
      element: <ShopsPage />,
      path: '/shops',
      role: [UserRole.SU, UserRole.ADMIN],
    },
    {
      element: <PostCategoriesPage />,
      path: '/posts/categories',
      role: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
    },
    {
      element: <ModerationArticlesPage />,
      path: '/posts/all',
      role: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
    },
    {
      element: <KnowledgeBasePage />,
      path: '/moderation/knowledge-base',
      role: [
        UserRole.SU,
        UserRole.ADMIN,
        UserRole.SALES_MANAGER,
        UserRole.CONTENT_MANAGER,
        UserRole.COURIER,
      ],
    },
    {
      element: <ModerationArticlesPage />,
      path: '/moderation/articles',
      role: [
        UserRole.SU,
        UserRole.ADMIN,
        UserRole.CONTENT_MANAGER,
        UserRole.SALES_MANAGER,
      ],
    },
    {
      element: <ModerationReviewsPage />,
      path: '/moderation/reviews',
      role: [
        UserRole.SU,
        UserRole.ADMIN,
        UserRole.CONTENT_MANAGER,
        UserRole.SALES_MANAGER,
      ],
    },
    {
      element: <MerchantReviewsPage />,
      path: '/reviews',
      role: [
        UserRole.SU,
        UserRole.ADMIN,
        UserRole.SALES_MANAGER,
        UserRole.CONTENT_MANAGER,
        UserRole.SHOP_EMPLOYEE,
        UserRole.SHOP_OWNER,
      ],
    },
    {
      element: <AboutCompanyPage />,
      path: '/about-company',
      role: [UserRole.SU, UserRole.SHOP_EMPLOYEE, UserRole.SHOP_OWNER],
    },
    {
      element: <AboutCompanyPage />,
      path: '/about-company',
      role: [UserRole.SU, UserRole.SHOP_EMPLOYEE, UserRole.SHOP_OWNER],
    },
    {
      element: <ModerationCommentsPage />,
      path: '/moderation/post-comments',
      role: [
        UserRole.SU,
        UserRole.ADMIN,
        UserRole.CONTENT_MANAGER,
        UserRole.SALES_MANAGER,
      ],
    },
    {
      element: <ProductCommentsPage />,
      path: '/moderation/product-comments',
      role: [
        UserRole.SU,
        UserRole.ADMIN,
        UserRole.CONTENT_MANAGER,
        UserRole.SALES_MANAGER,
      ],
    },
    {
      element: <TagsPage />,
      path: '/moderation/tags',
      role: [
        UserRole.SU,
        UserRole.CONTENT_MANAGER,
        UserRole.ADMIN,
        UserRole.SALES_MANAGER,
      ],
    },
    {
      element: <StoryGroupsPage />,
      path: '/advertising/banners/story-groups',
      role: [
        UserRole.SU,
        UserRole.CONTENT_MANAGER,
        UserRole.ADMIN,
        UserRole.SALES_MANAGER,
        UserRole.SHOP_EMPLOYEE,
        UserRole.SHOP_OWNER,
      ],
    },
    {
      element: <StoriesPage />,
      path: '/advertising/banners/:storyGroupId/stories',
      role: [
        UserRole.SU,
        UserRole.CONTENT_MANAGER,
        UserRole.ADMIN,
        UserRole.SALES_MANAGER,
        UserRole.SHOP_EMPLOYEE,
        UserRole.SHOP_OWNER,
      ],
    },
    {
      element: <BannersPage />,
      path: '/advertising/banners/:bannerType',
      role: [
        UserRole.SU,
        UserRole.CONTENT_MANAGER,
        UserRole.ADMIN,
        UserRole.SALES_MANAGER,
        UserRole.SHOP_EMPLOYEE,
        UserRole.SHOP_OWNER,
      ],
    },
    {
      element: <CouponsPage />,
      path: '/advertising/coupons',
      role: [
        UserRole.SU,
        UserRole.SALES_MANAGER,
        UserRole.CONTENT_MANAGER,
        UserRole.ADMIN,
      ],
    },
    {
      element: <PromocodesPage />,
      path: '/advertising/promo-codes',
      role: [
        UserRole.SU,
        UserRole.SALES_MANAGER,
        UserRole.CONTENT_MANAGER,
        UserRole.ADMIN,
      ],
    },
    {
      element: <SupplementaryServicesPage />,
      path: '/services',
      role: [
        UserRole.SU,
        UserRole.ADMIN,
        UserRole.CONTENT_MANAGER,
        UserRole.SALES_MANAGER,
        UserRole.SHOP_OWNER,
        UserRole.SHOP_EMPLOYEE,
      ],
    },
    {
      element: <ReportBalancePage />,
      path: '/reports/balance',
      role: [
        UserRole.SU,
        UserRole.SALES_MANAGER,
        UserRole.SHOP_EMPLOYEE,
        UserRole.SHOP_OWNER,
        UserRole.ADMIN,
      ],
    },
    {
      element: <ReportSalesPage />,
      path: '/reports/sales',
      role: [
        UserRole.SU,
        UserRole.SALES_MANAGER,
        UserRole.SHOP_EMPLOYEE,
        UserRole.SHOP_OWNER,
        UserRole.ADMIN,
      ],
    },
  ];
  return (
    <Routes>
      {user && user?.role !== UserRole?.USER ? (
        allRoutes.map(
          (i) =>
            (i.role?.some((ii) => ii === user?.role) || !i.role) && (
              <Route path={i.path} element={i.element} key={i.path} />
            )
        )
      ) : (
        <Route path='/auth/sign-in' element={<AuthPage />} />
      )}
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
