import React from 'react';
import { IJobLog } from 'api/services/jobs/types';
import './Progress.sass';

interface Props {
  progress: number;
  errors: IJobLog[];
}

export const Progress: React.FC<Props> = ({ progress, errors }) => {
  return (
    <div className='progress'>
      <div className='progress__top'>
        {progress === 100 ? (
          <div className='progress__title--success'>Загрузка завершена</div>
        ) : (
          <>
            <div className='progress__title'>Загрузка...</div>
            <div className='progress__line'>
              <div
                className='progress__line-bg'
                style={{ width: progress + '%' }}
              ></div>
            </div>
          </>
        )}
      </div>
      {!!errors && !!errors.length && (
        <div className='progress__container'>
          {/* <div className='progress__title'>Не загруженные продукты</div> */}
          <table className='progress__in'>
            <thead className='progress__head'>
              <tr>
                <th className='progress__head-item'>Строка</th>
                <th className='progress__head-item'>Колонка</th>
                <th className='progress__head-item'>Код</th>
                <th className='progress__head-item'>Ошибка</th>
              </tr>
            </thead>
            <tbody className='progress__body'>
              {errors.map((i, index) => (
                <tr className='progress__body-row' key={index}>
                  <td className='progress__body-item'>{i.row}</td>
                  <td className='progress__body-item'>{i.col}</td>
                  <td className='progress__body-item'>{i.code}</td>
                  <td className='progress__body-item'>
                    {i.error === 'Not Found' ? 'Не найден' : i.error}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
