import instance from 'api/instance';
import qs from 'query-string';
import {
  IGetAttributes,
  IGetAttributeById,
  ICreateAttribute,
  IUpdateAttribute,
} from './types';

export const getAttributes: IGetAttributes = (attrs) =>
  instance.get(`/attributes?${qs.stringify(attrs, { skipEmptyString: true })}`);

export const getAttributeById: IGetAttributeById = (id) =>
  instance.get(`/attributes/${id}`);

export const createAttribute: ICreateAttribute = (data) =>
  instance.post('/attributes', data);

export const updateAttribute: IUpdateAttribute = (id, data) =>
  instance.put(`/attributes/${id}`, data);

export const deleteAttribute = (id: number) =>
  instance.delete(`/attributes/${id}`);
