import { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import * as templateActions from 'store/ducks/template/actions';
import { AppState } from 'store/store';
import { Filter } from 'components/Filter/Filter';
import { attributeGroupServices, attributeServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import './AttributeGroupsPage.sass';
import { ISetting } from 'components/Modal/components/Settings/types';
import * as settingActions from 'store/ducks/settings/actions';
import { Settings } from 'components/Modal/components/Settings/Settings';
import {
  IAttributeGroups,
  IResAttributeGroups,
} from 'api/services/attributesGroup/types';
import { CreateAttributeGroups } from 'components/Modal/components/CreateProduct/CreateAttributeGroups/CreateAttributeGroups';
import { UpdateAttributeGroups } from 'components/Modal/components/CreateProduct/UpdateAttributeGroups/UpdateAttributeGroups';

export const AttributeGroupsPage = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedAttributeGroups, setSelectedAttributeGroups] =
    useState<IResAttributeGroups>();
  const [attributeGroups, setAttributeGroups] = useState<IResAttributeGroups[]>(
    []
  );
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<ISelect>();
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const { key } = useSelector((state: AppState) => state.localeReducer);
  const { categories } = useSelector(
    (state: AppState) => state.servicesReducer
  );
  const { attributeGroupsSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Каталог', id: '' },
        { title: 'Группы атрибутов', id: 'catalog/attribute-groups' },
      ])
    );
  }, []);

  useEffect(() => {
    getAttributeGroups();
  }, [page, searchValue]);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setAttributeGroupsSettings(items));
    setShowSettings(false);
  };

  const getAttributeGroups = async () => {
    setLoading(true);

    const attrs = {
      take,
      skip: take * page,
      search: searchValue,
    };
    try {
      const res = await attributeGroupServices
        .getAttributeGroups(attrs)
        .then((res) => res.data);
      setAttributeGroups(res.data);
      setTotalCount(res.count);
    } finally {
      setLoading(false);
    }
  };

  const createAttrGroup = async (data: IAttributeGroups) => {
    if (loading) return;
    setLoading(true);
    try {
      await attributeGroupServices.createAttributeGroups(data);
      showNotification('Успешно создано', NotificationStatus.SUCCESS);
      setShowCreateModal(false);
      getAttributeGroups();
    } catch {
      showNotification('Произошла ошибка, попробуйте позже');
    } finally {
      setLoading(false);
    }
  };

  const updateAttrGroup = async (data: IAttributeGroups) => {
    if (!selectedAttributeGroups) return;
    if (loading) return;
    setLoading(true);
    try {
      await attributeGroupServices.updateAttributeGroups(
        selectedAttributeGroups.id,
        data
      );
      showNotification('Изменение успешно', NotificationStatus.SUCCESS);
      closeModal();
      getAttributeGroups();
    } catch {
      showNotification('Произошла ошибка, попробуйте позже');
    } finally {
      setLoading(false);
    }
  };

  const getAttributeGroup = async (id: number) => {
    const res = await attributeGroupServices
      .getAttributeGroupsById(id)
      .then((res) => res.data);
    setSelectedAttributeGroups(res);
    setShowUpdateModal(true);
  };

  const closeModal = () => setShowUpdateModal(false);

  const closeFilter = () => setFilterShowModal(false);

  const deleteAttributeGroup = async (id: number) => {
    if (loading) return;
    setLoading(true);
    try {
      await attributeGroupServices.deleteAttributeGroups(id);
      showNotification('Удалено', NotificationStatus.SUCCESS);
      getAttributeGroups();
    } finally {
      setLoading(false);
    }
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getAttributeGroups();
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Группы атрибутов'
            items={attributeGroupsSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={() => setShowCreateModal(false)}>
          <CreateAttributeGroups
            closeModal={() => setShowCreateModal(false)}
            submit={createAttrGroup}
          />
        </Modal>
      )}
      {showUpdateModal && selectedAttributeGroups && (
        <Modal closeModal={closeModal}>
          <UpdateAttributeGroups
            closeModal={closeModal}
            submit={updateAttrGroup}
            data={selectedAttributeGroups}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          >
            <div className='product-page__filter-inputs'>
              <label className='attributes-page__filter-inputs-item'>
                <MainSelect
                  items={[
                    { label: 'Нет', value: null },
                    ...categories.map((i) => ({
                      label: i.titleRu,
                      value: i.id,
                    })),
                  ]}
                  selectItem={setSelectedCategory}
                  value={selectedCategory}
                  withIcon
                />
                <span className='product-page__filter-inputs-item-label'>
                  Категория
                </span>
              </label>
            </div>
          </ModalFilter>
        </Modal>
      )}
      <div className='page attributes-page'>
        <Filter
          createFunc={() => setShowCreateModal(true)}
          filterFunc={() => {
            setFilterShowModal(true);
          }}
          updateSearchValue={newFilterValue}
          loading={loading}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              keys: attributeGroupsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
              data: attributeGroups,
            }}
            tableHead={attributeGroupsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            selectItem={getAttributeGroup}
            skipHandler={setPage}
            totalCount={totalCount}
            take={take}
            deleteHandler={deleteAttributeGroup}
            page={page}
          />
        </div>
      </div>
    </>
  );
};
