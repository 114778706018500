import React, { useState } from 'react';
import cn from 'classnames';

import { Step1 } from './Step1/Step1';
import { MainButton } from 'components/MainButton/MainButton';
import { Step2 } from './Step2/Step2';
import { Step3 } from './Step3/Step3';
import { CouponType, ICoupon, PeriodType } from 'api/services/coupons/types';
import './Coupon.sass';
import showNotification from 'helpers/showNotification';
import { checkCount, checkCouponType } from './checkTypes';

interface Props {
  // closeModal: () => void;
  setActiveTab: (tab: number) => void;
  activeTab: number;
  tabs: { label: string; index: number }[];
  submit: (data: ICoupon) => void;
  submitTitle?: string;
  activeStep?: number;
  cancel: () => void;
}

export const Coupon: React.FC<Props> = ({
  setActiveTab,
  activeTab,
  tabs,
  submit,
  submitTitle,
  activeStep,
  cancel,
}) => {
  const [selectedType, setSelectedType] = useState<CouponType>();
  const [name, setName] = useState('');
  const [shopIds, setShopIds] = useState<number[]>([]);
  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [brandIds, setBrandIds] = useState<number[]>([]);
  const [isActive, setIsActive] = useState(true);
  const [isPublic, setIsPublic] = useState(false);
  const [descriptionRu, setDescriptionRu] = useState('');
  const [sum, setSum] = useState('');
  const [expireAt, setExpireAt] = useState<Date>();
  const [count, setCount] = useState('');
  const [limit, setLimit] = useState('');
  const [orderSum, setOrderSum] = useState('');
  const [minOrderSum, setMinOrderSum] = useState('');
  const [periodType, setPeriodType] = useState<PeriodType>();
  const [periodValue, setPeriodValue] = useState('');
  const [type, setType] = useState<CouponType>();
  const [hasError, setHasError] = useState(false);
  const [usersCount, setUsersCount] = useState('');

  const checkTab = () => {
    switch (activeStep) {
      case 2:
        return (
          <Step2
            setShopIds={setShopIds}
            setCategoryIds={setCategoryIds}
            setBrandIds={setBrandIds}
            orderSum={orderSum}
            setOrderSum={setOrderSum}
            count={count}
            setCount={setCount}
            setPeriodType={setPeriodType}
            setPeriodValue={setPeriodValue}
            periodValue={periodValue}
            hasError={hasError}
            type={type}
            setUsersCount={setUsersCount}
            usersCount={usersCount}
          />
        );
      case 3:
        return (
          <Step3
            isActive={isActive}
            setIsActive={setIsActive}
            isPublic={isPublic}
            setIsPublic={setIsPublic}
            descriptionRu={descriptionRu}
            setDescriptionRu={setDescriptionRu}
            sum={sum}
            setSum={setSum}
            expireAt={expireAt}
            setExpireAt={setExpireAt}
            limit={limit}
            setLimit={setLimit}
            minOrderSum={minOrderSum}
            setMinOrderSum={setMinOrderSum}
            name={name}
            setName={setName}
            hasError={hasError}
          />
        );
      default:
        return <Step1 selectItem={setType} selectedItem={type} />;
    }
  };

  const submitData = () => {
    if (!type) {
      showNotification('Выберите действие');
      setHasError(true);
      return;
    }
    if (!count.trim() && activeStep === 2 && checkCount(type)) {
      showNotification('Заполните количество');
      setHasError(true);
      return;
    }
    if (
      !usersCount.trim() &&
      activeStep === 2 &&
      type === CouponType.REFERRAL_LINK
    ) {
      showNotification('Заполните количество пользователей');
      setHasError(true);
      return;
    }
    if (!orderSum.trim() && activeStep === 2 && checkCouponType(type)) {
      showNotification('Заполните сумму заказа');
      setHasError(true);
      return;
    }
    if (!name.trim() && activeStep === 3) {
      showNotification('Заполните название');
      setHasError(true);
      return;
    }
    if (!descriptionRu.trim() && activeStep === 3) {
      showNotification('Заполните описание');
      setHasError(true);
      return;
    }
    if (!sum.trim() && activeStep === 3) {
      showNotification('Заполните сумму купона');
      setHasError(true);
      return;
    }
    const coupon: ICoupon = {
      shopIds,
      categoryIds,
      brandIds,
      name,
      isActive,
      isPublic,
      descriptionRu,
      sum: Number(sum) * 100,
      expireAt,
      minOrderSum: Number(minOrderSum) * 100,
      limit: Number(limit),
      count: !!count.length ? Number(count) : undefined,
      orderSum: !!orderSum.length ? Number(orderSum) * 100 : undefined,
      periodValue: !!periodValue.length ? Number(periodValue) : undefined,
      periodType,
      type,
      usersCount: usersCount.length ? Number(usersCount) : undefined,
    };
    setHasError(false);
    submit(coupon);
  };

  return (
    <div className='coupon'>
      <div className='coupon__tabs'>
        {tabs.map((i) => (
          <div
            className={cn('coupon__tabs-item', {
              'coupon__tabs-item--active': activeTab === i.index,
            })}
            onClick={() => setActiveTab(i.index)}
            key={i.index}
          >
            <span>{i.label}</span>
          </div>
        ))}
      </div>
      <div className='coupon__container'>{checkTab()}</div>
      <div className='coupon__buttons'>
        {activeStep !== 1 && (
          <MainButton
            classes='coupon__buttons-item'
            title={'Назад'}
            submit={cancel}
            type='button'
            isCancel
          />
        )}
        <MainButton
          classes='coupon__buttons-item'
          title={submitTitle || 'Готово'}
          submit={submitData}
        />
      </div>
    </div>
  );
};
