import React, { useState } from 'react';
import showNotification from 'helpers/showNotification';
import cn from 'classnames';

import './ConfirmEmailForm.sass';

interface Props {
  confirm: (code: string) => void;
  generateOtp: () => void;
}

export const ConfirmEmailForm: React.FC<Props> = ({ confirm, generateOtp }) => {
  const [code, setCode] = useState('');
  const [hasError, setHasError] = useState(false);

  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    if (code.length < 6) {
      showNotification('Введите корректный код');
      setHasError(true);
      return;
    }
    confirm(code);
  };

  return (
    <form className='confirm-email-form' onSubmit={submit}>
      <h4 className='confirm-email-form-title'>Введите код</h4>
      <input
        type='text'
        className={cn(
          'confirm-email-form-field confirm-email-form-field-login',
          { 'confirm-email-form-field--error': hasError && code.length < 6 }
        )}
        placeholder='Код'
        value={code}
        onChange={(e) => setCode(e.target.value)}
        autoFocus
      />
      <span className='confirm-email-form__resend' onClick={generateOtp}>
        Отправить еще раз
      </span>
      <button type='submit' className='confirm-email-form-field-button'>
        Отправить
      </button>
    </form>
  );
};
