import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreateCategory,
  IGetCategory,
  IGetCategoryByid,
  IUpdateCategory,
} from './types';

export const getCategories: IGetCategory = (attrs) =>
  instance.get(
    `/categories/admin?${qs.stringify(attrs, { skipEmptyString: true })}`
  );

export const getCategoryById: IGetCategoryByid = (id) =>
  instance.get(`/categories/${id}/admin`);

export const createCategory: ICreateCategory = (data) =>
  instance.post('/categories', data);

export const updateCategory: IUpdateCategory = (id, data) =>
  instance.put(`/categories/${id}`, data);

export const deleteCategory = (id: number | string) =>
  instance.delete(`/categories/${id}`);
