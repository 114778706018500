import React, { useState } from 'react';
import { MainInput } from 'components/MainInput/MainInput';
import { ActionButtons } from '../Modal/components/ActionButtons/ActionButtons';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ITag } from 'api/services/tags/types';
import './CreateTag.sass';

interface Props {
  submit: (data: ITag) => void;
  closeModal: () => void;
  loading: boolean;
}

export const CreateTag: React.FC<Props> = ({ closeModal, submit, loading }) => {
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [color, setColor] = useState('#');
  const [url, setUrl] = useState('');
  const [hasError, setHasError] = useState(false);
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if ((!titleRu.trim() && !titleUz.trim()) || !color.trim() || !url.trim()) {
      showNotification('Заполните поля', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    submit({
      titleRu,
      titleUz,
      color,
      url,
    });
  };

  const updateColorInput = (value: string) => {
    if (!value.trim()) {
      setColor('#');
      return;
    }
    value.replace(/^/, '#');
    setColor(value);
  };
  return (
    <form onSubmit={formSubmit} className='create-tag'>
      <div className='create-tag__container create-tag__modal'>
        <div className='create-tag__tabs'>
          <div className='create-tag__tabs-item create-tag__tabs-item--active'>
            <span>Тег</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='create-tag__in'>
          <div className='create-tag__row'>
            <label className='create-tag__row-item'>
              <div className='create-tag__label create-tag__row-label'>
                Название:
              </div>
              {lang === 'ru' ? (
                <MainInput updateInputValue={setTitleRu} inputValue={titleRu} />
              ) : (
                <MainInput updateInputValue={setTitleUz} inputValue={titleUz} />
              )}
            </label>
          </div>
          <div className='create-tag__row'>
            <label className='create-tag__row-item'>
              <div className='create-tag__label create-tag__row-label'>
                Цвет (hex):
              </div>

              <MainInput
                updateInputValue={updateColorInput}
                inputValue={color}
              />
            </label>
          </div>
          <div className='create-tag__row'>
            <label className='create-tag__row-item'>
              <div className='create-tag__label create-tag__row-label'>
                URL:
              </div>
              <MainInput updateInputValue={setUrl} inputValue={url} />
            </label>
          </div>
        </div>
      </div>
      <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
    </form>
  );
};
