import { currencyServices } from 'api/services';
import { Filter } from 'components/Filter/Filter';
import { Modal } from 'components/Modal/Modal';
import { Table } from 'components/Table/Table';
import showNotification from 'helpers/showNotification';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as templateActions from 'store/ducks/template/actions';
import { NotificationStatus } from 'store/ducks/notification/types';
import { CreateCurrency } from 'components/Modal/components/CreateCurrency/CreateCurrency';
import { ICurrency, IResCurrency } from 'api/services/currencies/types';
import { UpdateCurrency } from 'components/Modal/components/UpdateCurrency/UpdateCurrency';
import './CurrenciesPage.sass';
import { AppState } from 'store/store';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';
import * as settingActions from 'store/ducks/settings/actions';

const CurrenciesPage: React.FC = () => {
  const [data, setData] = useState<IResCurrency[]>();
  const [id, setId] = useState<number>();
  const [showCreateCurrencyModal, setShowCreateCurrencyModal] = useState(false);
  const [showUpdateCurrencyModal, setShowUpdateCurrencyModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const dispatch = useDispatch();

  const { currenciesSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  useEffect(() => {
    getData();
    dispatch(
      templateActions.getBreadCrumbs([
        {
          title: 'Валюта',
          id: 'catalog/currencies',
        },
      ])
    );
  }, []);

  const getData = async () => {
    const res = await currencyServices.getCurrencies().then((res) => res.data);
    setData(res);
  };

  const createCurrency = async (data: ICurrency) => {
    try {
      await currencyServices.createCurrency(data);
      setShowCreateCurrencyModal(false);
      showNotification('Готово', NotificationStatus.SUCCESS);
      getData();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  const updateCurrency = async (data: ICurrency) => {
    if (!id) return;

    try {
      await currencyServices.updateCurrency(id, data);
      setShowUpdateCurrencyModal(false);
      getData();
      showNotification('Готово', NotificationStatus.SUCCESS);
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  const closeModal = () => {
    setId(undefined);
    setShowUpdateCurrencyModal(false);
    setShowCreateCurrencyModal(false);
  };

  const openCurrencyModal = (id: number) => {
    setId(id);
    setShowUpdateCurrencyModal(true);
  };

  const deleteItem = async (id: number) => {
    try {
      await currencyServices.deleteCurrency(id);
      showNotification('Валюта удалена', NotificationStatus.SUCCESS);
      getData();
    } catch {
      showNotification('Ошибка, попробуйте позже');
    }
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setCurrenciesSettings(items));
    setShowSettings(false);
  };
  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Товары'
            items={currenciesSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      <div className='currencies-page page'>
        <Filter
          createFunc={() => setShowCreateCurrencyModal(true)}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='currencies-page__in'>
          <Table
            selectItem={openCurrencyModal}
            tableBody={{
              data: data?.map((i) => ({ ...i, cost: i.value })),
              keys: currenciesSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={currenciesSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            deleteHandler={deleteItem}
          />
        </div>
      </div>
      {showCreateCurrencyModal && (
        <Modal closeModal={() => setShowCreateCurrencyModal(false)}>
          <CreateCurrency submit={createCurrency} />
        </Modal>
      )}

      {showUpdateCurrencyModal && (
        <Modal closeModal={closeModal}>
          <UpdateCurrency id={id} submit={updateCurrency} />
        </Modal>
      )}
    </>
  );
};

export default CurrenciesPage;
