import React, { useEffect, useMemo, useState } from 'react';
import {
  YMaps,
  Map,
  Placemark,
  withYMaps,
  GeolocationControl,
  ZoomControl,
} from 'react-yandex-maps';

import './YandexMap.sass';

interface Props {
  onChangeCoords?: (addres: string, coords: any, isSearch?: boolean) => void;
  address: string;
  lat: number;
  lng: number;
  refreshMap?: boolean;
}

const YandexMap: React.FC<Props> = ({
  lat,
  lng,
  onChangeCoords,
  refreshMap,
}) => {
  const Location = useMemo(
    () =>
      ({ ymaps }: any) => {
        const loadSuggest = (ymaps: any) => {
          const searchControl = new ymaps.control.SearchControl({
            options: {
              provider: 'yandex#map',
            },
          });

          const suggestView = new ymaps.SuggestView('suggest');
          suggestView.events.add('select', async (e: any) => {
            const data = await searchControl
              .search(e.get('item').value)
              .then((res: any) => res);
            const geoObj = data?.geoObjects?.get(0);
            const newCoords = geoObj.geometry.getCoordinates();
            const area = geoObj.getAdministrativeAreas()
              ? `${geoObj.getAdministrativeAreas()}`
              : '';
            const location = geoObj.getLocalities()
              ? `, ${geoObj.getLocalities()}`
              : '';
            const thoroughFare = geoObj.getThoroughfare()
              ? `, ${geoObj.getThoroughfare()}`
              : '';
            const premise = geoObj.getPremiseNumber()
              ? `, ${geoObj.getPremiseNumber()}`
              : '';
            const newAddress = `${area}${location}${thoroughFare}${premise}`;
            if (onChangeCoords) onChangeCoords(newAddress, newCoords, true);
          });
        };

        const GeolocationHandler = async () => {
          const res = await ymaps.geolocation.get().then((res: any) => res);
          const firstGeoObject = res.geoObjects.get(0);
          const area = firstGeoObject.getAdministrativeAreas()
            ? `${firstGeoObject.getAdministrativeAreas()}`
            : '';
          const location = firstGeoObject.getLocalities()
            ? `, ${firstGeoObject.getLocalities()}`
            : '';
          const thoroughFare = firstGeoObject.getThoroughfare()
            ? `, ${firstGeoObject.getThoroughfare()}`
            : '';
          const premise = firstGeoObject.getPremiseNumber()
            ? `, ${firstGeoObject.getPremiseNumber()}`
            : '';
          const newAddress = `${area}${location}${thoroughFare}${premise}`;
          const newCoords = firstGeoObject.geometry.getCoordinates();
          if (onChangeCoords) onChangeCoords(newAddress, newCoords);
        };

        const onBoundsChange = async (event: any) => {
          const newCoords = event.originalEvent.newCenter;
          // setZoom(event.originalEvent.map.action._zoom);
          await ymaps.geocode(newCoords).then((res: any) => {
            const firstGeoObject = res.geoObjects.get(0);
            const area = firstGeoObject.getAdministrativeAreas()
              ? `${firstGeoObject.getAdministrativeAreas()}`
              : '';
            const location = firstGeoObject.getLocalities()
              ? `, ${firstGeoObject.getLocalities()}`
              : '';
            const thoroughFare = firstGeoObject.getThoroughfare()
              ? `, ${firstGeoObject.getThoroughfare()}`
              : '';
            const premise = firstGeoObject.getPremiseNumber()
              ? `, ${firstGeoObject.getPremiseNumber()}`
              : '';
            const newAddress = `${area}${location}${thoroughFare}${premise}`;
            if (onChangeCoords) onChangeCoords(newAddress, newCoords);
          });
        };
        return (
          <Map
            onLoad={(ymaps) => loadSuggest(ymaps)}
            defaultState={{
              center: [lat || 41.31113476915153, lng || 69.279730927077],
              zoom: 17,
            }}
            modules={[
              'SuggestView',
              'control.SearchControl',
              'geocode',
              'geolocation',
              'Map',
              'Placemark',
            ]}
            width='100%'
            onBoundsChange={(e: any) => onBoundsChange(e)}
          >
            <ZoomControl />
            <Placemark
              style={{ position: 'absolute', left: '50%', top: '50%' }}
              geometry={[lat, lng]}
            />

            <GeolocationControl onClick={GeolocationHandler} />
          </Map>
        );
      },
    [lat, lng, refreshMap]
  );

  const ConnectedMap = useMemo(() => {
    return withYMaps(Location, true);
  }, [!!lat, !!lng, refreshMap]);

  return (
    <>
      <input
        placeholder={'Введите адрес'}
        className='yandex-map__search'
        type='text'
        id='suggest'
      />
      <YMaps query={{ apikey: '6242a99a-a4e9-42c6-a6fd-df6deb3b4f37' }}>
        <ConnectedMap width='100%' height='100%' />
      </YMaps>
      <div className='yandex-map__pin'></div>
    </>
  );
};

export default YandexMap;
