import React from 'react';

import './Switch.sass';

interface Props {
  toggleTitle: string;
  toggleTitle2?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: any
}

export const Switch: React.FC<Props> = ({ toggleTitle, toggleTitle2, onChange, value }) => {
  return (
    <label className='switch'>
      <span className='switch__label'>{toggleTitle}</span>
      <div className='switch__container'>
        <input type='checkbox' value={value} onChange={onChange}/>
        <span className='slider round'></span>
      </div>
      {toggleTitle2 && <span className='switch__label'>{toggleTitle2}</span>}
    </label>
  );
};
