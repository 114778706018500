import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { IPostCategory } from 'api/services/postCategories/types';
import { MainInput } from 'components/MainInput/MainInput';
import './CreatePostCategory.sass';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ActionButtons } from 'components/Modal/components/ActionButtons/ActionButtons';
import showNotification from 'helpers/showNotification';

interface Props {
  submit: (data: IPostCategory) => void;
  closeModal: () => void;
}

export const CreatePostCategory: React.FC<Props> = ({ submit, closeModal }) => {
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [url, setUrl] = useState('');
  const [hasError, setHasError] = useState(false);
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!titleRu.trim() && !titleUz.trim()) {
      showNotification('Заполните поля');
      setHasError(true);
      return;
    }
    submit({
      titleRu,
      titleUz,
      url,
    });
  };

  return (
    <form className='create-post-category' onSubmit={formSubmit}>
      <div className='create-post-category__modal'>
        <div className='create-post-category__tabs'>
          <div className='create-post-category__tabs-item'>
            <span>Категория поста</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='create-post-category__in'>
          <div className='create-post-category__form'>
            <label className='create-post-category__form-row'>
              <div className='create-post-category__form-label'>Название:</div>
              {lang === 'ru' ? (
                <MainInput
                  error={hasError && !titleRu.trim()}
                  updateInputValue={setTitleRu}
                  inputValue={titleRu}
                />
              ) : (
                <MainInput
                  error={hasError && !titleUz.trim()}
                  updateInputValue={setTitleUz}
                  inputValue={titleUz}
                />
              )}
            </label>
            <label className='create-post-category__form-row'>
              <div className='create-post-category__form-label'>URL:</div>
              <MainInput updateInputValue={setUrl} inputValue={url} />
            </label>
          </div>
        </div>
      </div>
      <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
    </form>
  );
};
