import React from 'react';
import './CustomLoader.sass';
export const CustomLoader = () => {
  return (
    <div className='custom-loader'>
      <div className='custom-loader-container'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
