import React, { useEffect, useState } from 'react';
import { IBrand } from 'api/services/brands/types';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { MainInput } from 'components/MainInput/MainInput';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import './Brand.sass';
import { ActionButtons } from '../../ActionButtons/ActionButtons';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { FileModal } from '../../FileModal/FileModal';
import { fileServices } from 'api/services';
import { IResFile } from 'api/services/files/types';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { FileList } from 'components/FileList/FileList';

interface Props {
  submit: (data: IBrand) => void;
  closeModal: () => void;
}

export const Brand: React.FC<Props> = ({ closeModal, submit }) => {
  const [name, setName] = useState('');
  const [showFileModal, setShowFileModal] = useState(false);
  const [file, setFile] = useState<IResFile>();
  const [hasError, setHasError] = useState(false);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!file) {
      showNotification('Выберите изображение', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    submit({
      name,
      fileId: file.id,
    });
  };

  const fileSelected = (file: IResFile) => {
    setFile(file);
    setShowFileModal(false);
  };

  return (
    <>
      {showFileModal && (
        <FileModal
          closeModal={() => setShowFileModal(false)}
          fileSelected={fileSelected}
        />
      )}
      <form onSubmit={formSubmit} className='brand'>
        <div className='brand__container brand__modal'>
          <div className='brand__tabs'>
            <div className='brand__tabs-item brand__tabs-item--active'>
              <span>Бренд</span>
            </div>
            <ChoiceLang />
          </div>
          <div className='brand__in'>
            <div className='brand__row'>
              <label className='brand__row-item'>
                <div className='brand__label brand__row-label'>Название</div>
                <MainInput updateInputValue={setName} inputValue={name} />
              </label>
            </div>
            <div className='brand__row brand__row--uploader'>
              <label
                className='brand__row-item'
                onClick={() => setShowFileModal(true)}
              >
                <div className='brand__label brand__label--uploader'>
                  Загрузить изображение:
                  <span>
                    Выбрать из файла <FolderIcon />
                  </span>
                </div>
              </label>
              {file ? (
                <FileList file={file} deleteFile={() => setFile(undefined)} />
              ) : (
                <FileUploader setFile={setFile} error={!file && hasError} />
              )}
            </div>
          </div>
        </div>
        <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
      </form>
    </>
  );
};
