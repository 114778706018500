import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as GlassIcon } from 'assets/images/icons/glass-icon.svg';
import { ReactComponent as PicIcon } from 'assets/images/icons/pic-icon.svg';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete-icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/icons/settings-icon.svg';
import { Files } from 'components/Files/Files';
import { IFileFolder, IResFile } from 'api/services/files/types';
import cn from 'classnames';
import { fileServices } from 'api/services';

import './FileModal.sass';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { Modal } from 'components/Modal/Modal';
import { CreateFileCategoryFolder } from '../CreateFileCategoryFolder/CreateFileCategoryFolder';
import { Notification } from 'components/Notification/Notification';
import _ from 'lodash';

interface Props {
  closeModal: () => void;
  fileSelected?: (file: IResFile) => void;
  someFileSelect?: (file: IResFile[]) => void;
}
export const FileModal: React.FC<Props> = ({
  closeModal,
  fileSelected,
  someFileSelect,
}) => {
  const [files, setFiles] = useState<IResFile[]>([]);
  const [take, setTake] = useState(100);
  const [page, setPage] = useState(0);
  const [folderId, setFolderId] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [checkedFiles, setCheckedFiles] = useState<number[]>([]);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [totalFileCount, setTotalFileCount] = useState(0);
  const [showCount, setShowCount] = useState(false);
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [breadCrumbs, setBreadCrumbs] = useState<
    { title: string; id?: number }[]
  >([{ title: 'Изображения' }]);
  const fileRef = useRef<any>();
  const filesRef = useRef<any>();

  useEffect(() => {
    getFiles(folderId.trim() ? Number(folderId) : undefined, undefined, true);
  }, [page]);

  useEffect(() => {
    document.addEventListener('keydown', submitWithKey);

    return () => {
      document.removeEventListener('keydown', submitWithKey);
    };
  }, [checkedFiles.length]);

  const submitWithKey = (e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      selectFile();
      return;
    }
  };

  const uploadFile = async () => {
    const files = [...fileRef.current.files];
    const formData = new FormData();
    const arrFiles: string[] = [];
    try {
      for (let index in files) {
        try {
          setFileCount((prev) => prev + 1);
          if (arrFiles.some((i) => i === files[index].name)) {
            // showNotification(
            //   `Невозможно загрузить файл с именем ${files[index].name}, так как он уже был загружен`
            // );
          }
          formData.append('file', files[index] as any);
          if (folderId.trim()) formData.append('parentId', folderId);

          arrFiles.push(files[index].name);
          await fileServices.uploadFile(formData);
          fileRef.current.value = '';
        } catch {
          // showNotification(`Не удалось загрузить ${arrFiles}`);
        } finally {
          formData.delete('file');
          formData.delete('parentId');
        }
      }
    } finally {
      setPage(0);
      setShowCount(false);
      setFileCount(0);
      setTotalFileCount(0);
      getFiles(Number(folderId) || undefined);
    }
  };

  const deleteItems = async () => {
    if (!checkedFiles.length) {
      showNotification('Выберите файл для удаления');
      return;
    }
    try {
      await fileServices.deleteFiles({ fileIds: checkedFiles });
      showNotification('Удалено', NotificationStatus.WARNING);
      setFiles((prev) =>
        prev.filter((i) => !checkedFiles.some((ii) => ii === i.id))
      );
      setCheckedFiles([]);
    } catch {}
  };

  const getFiles = async (
    id?: number,
    isBreadCrumb?: boolean,
    isScroll?: boolean
  ) => {
    if (id === breadCrumbs[breadCrumbs.length - 1].id && isBreadCrumb) return;
    const res = await fileServices
      .getFiles({
        skip: !isScroll ? 0 : page * take,
        take,
        search: searchFilterValue,
        ...(id ? { parentId: id.toString() } : {}),
      })
      .then((res) => res.data);

    if (page > 0 && isScroll) {
      setFiles((prev) => [...prev, ...res.data]);
      return;
    }
    if (!isScroll) {
      setFiles(res.data);
    }
    setFiles(res.data);
    setFolderId(id?.toString() || '');
    setTotalCount(res.count);
    if (id) {
      const folder = await fileServices
        .getFileById(id.toString())
        .then((res) => res.data);
      setBreadCrumbs((prev) => {
        const clone = _.clone(prev);
        const findIndex = clone.findIndex((i) => i.id === id);
        if (findIndex >= 0) {
          clone.splice(findIndex + 1, clone.length - findIndex);
          return clone;
        }
        return [...clone, { title: folder.name, id: folder.id }];
      });
    }
    if (!id) {
      setBreadCrumbs([{ title: 'Изображения' }]);
    }
  };

  const selectFile = () => {
    if (!checkedFiles.length) {
      showNotification('Выберите файл', NotificationStatus.WARNING);
      return;
    }
    if (someFileSelect) {
      const arr = checkedFiles.map((i) => {
        const findFile = files.find((ii) => ii.id === i);
        if (!findFile) return;
        return findFile;
      }) as IResFile[];

      someFileSelect(arr);
      return;
    }
    if (checkedFiles.length > 1) {
      showNotification('Выберите один файл');
      return;
    }
    const file = files.find((i) => i.id === checkedFiles[0]);
    if (!file) return;
    if (file.type === 'FOLDER') {
      showNotification('Выберите файл');
      return;
    }
    if (fileSelected) fileSelected(file);
  };

  const createFolder = async (folder: any) => {
    if (!folder) return;
    try {
      await fileServices.createFolder({
        ...folder,
        ...(folderId ? { parentId: Number(folderId) } : {}),
      });
    } finally {
      setShowCreateFolderModal(false);
      getFiles(Number(folderId));
    }
  };

  return (
    <>
      {showCreateFolderModal && (
        <Modal
          closeModal={() => setShowCreateFolderModal(false)}
          classes=' file-modal--priority'
        >
          <CreateFileCategoryFolder
            submit={createFolder}
            closeModal={closeModal}
          />
        </Modal>
      )}
      <div className='modal file-modal'>
        <Notification
          show={showCount}
          componentMessage={`Загружено ${fileCount} из ${totalFileCount}`}
          componentStatus
        />
        <div className='file-modal__blackout' onClick={closeModal}></div>
        <div className='file-modal-close'></div>
        <div className='file-modal__container'>
          <div className='file-modal__header'>
            <div className='file-modal__search'>
              <input
                type='text'
                placeholder='Поиск ...'
                onChange={(e) => setSearchFilterValue(e.target.value)}
                value={searchFilterValue}
              />
              <button
                className='file-modal__search-button'
                onClick={() => getFiles(Number(folderId) || undefined)}
              >
                <GlassIcon />
              </button>
            </div>
            <div className='file-modal__buttons'>
              <div
                className={cn('file-modal__buttons-item', {
                  'file-modal__buttons-item--disabled': !checkedFiles.length,
                })}
                onClick={selectFile}
              >
                Готово
              </div>
              <label className='file-modal__buttons-item file-modal__buttons-item--pic'>
                Загрузить изображение
                <input
                  ref={fileRef}
                  type='file'
                  onChange={uploadFile}
                  accept='image/jpg, image/JPEG, image/JPG, image/jpeg, image/png, image/PNG, image/WEBP, image/webp, video/mp4, video/MP4, video/mov, video/MOV, video/webm, video/WEBM'
                  className='custom-file-input'
                  multiple
                />
                <PicIcon />
              </label>
              <div
                className='file-modal__buttons-item'
                onClick={() => setShowCreateFolderModal(true)}
              >
                Создать папку <FolderIcon />
              </div>
              <div
                className='file-modal__buttons-item file-modal__buttons-item--delete'
                onClick={deleteItems}
              >
                Удалить <DeleteIcon />
              </div>
              <div className='file-modal__buttons-item file-modal__buttons-item--settings'>
                <SettingsIcon />
              </div>
            </div>
          </div>
          <div className='file-modal__breadcrumbs'>
            {breadCrumbs.map((i, index) => (
              <div
                key={index}
                className='file-modal__breadcrumbs-item'
                onClick={() => getFiles(i.id, true)}
              >
                {i.title}
              </div>
            ))}
          </div>
          <div className='file-modal__files'>
            <div className='file-modal__files-in'>
              <Files
                items={files}
                checkedFiles={checkedFiles}
                setCheckedFiles={setCheckedFiles}
                refreshItems={getFiles}
                setPage={setPage}
                page={page}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
