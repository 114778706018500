import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { IPostCategory } from 'api/services/postCategories/types';
import { MainInput } from 'components/MainInput/MainInput';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ActionButtons } from 'components/Modal/components/ActionButtons/ActionButtons';
import showNotification from 'helpers/showNotification';
import { ICurrency } from 'api/services/currencies/types';
import './UpdateCurrency.sass';
import { currencyServices } from 'api/services';
import { InputType } from 'components/MainInput/types';

interface Props {
  submit: (data: ICurrency) => void;
  id?: number;
}

export const UpdateCurrency: React.FC<Props> = ({ submit, id }) => {
  const [symbol, setSymbol] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    getCurrencyById();
  }, [id]);

  const getCurrencyById = async () => {
    if (!id) return;
    const res = await currencyServices
      .getCurrencyById(id)
      .then((res) => res.data);
    setName(res.name);
    setSymbol(res.symbol);
    setValue((res.value / 100).toString());
    setTitle(res.title);
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if ((!symbol.trim() && !name.trim()) || !title.trim() || !value.trim()) {
      showNotification('Заполните поля');
      setHasError(true);
      return;
    }
    submit({
      name,
      symbol,
      value: parseFloat(value) * 100,
      title,
    });
  };

  return (
    <form className='update-currency' onSubmit={formSubmit}>
      <div className='update-currency__modal'>
        <div className='update-currency__tabs'>
          <div className='update-currency__tabs-item'>
            <span>Валюта</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='update-currency__in'>
          <div className='update-currency__form'>
            <label className='update-currency__form-row'>
              <div className='update-currency__form-label'>Название:</div>
              <MainInput
                error={hasError && !title.trim()}
                updateInputValue={setTitle}
                inputValue={title}
              />
            </label>
            <label className='update-currency__form-row'>
              <div className='update-currency__form-label'>Аббревиатура:</div>
              <MainInput
                error={hasError && !name.trim()}
                updateInputValue={setName}
                inputValue={name}
              />
            </label>
            <label className='update-currency__form-row'>
              <div className='update-currency__form-label'>Символ:</div>
              <MainInput
                error={hasError && !symbol.trim()}
                updateInputValue={setSymbol}
                inputValue={symbol}
              />
            </label>
            <label className='update-currency__form-row'>
              <div className='update-currency__form-label'>Курс:</div>
              <MainInput
                error={hasError && !value.trim()}
                updateInputValue={setValue}
                inputValue={value}
                type={InputType.NUMBER}
              />
            </label>
          </div>
        </div>
      </div>
      <ActionButtons closeModal={() => {}} formSubmit={formSubmit} />
    </form>
  );
};
