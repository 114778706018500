import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { Filter } from 'components/Filter/Filter';
import { useLocation } from 'react-router';
import { Advertising } from 'components/Modal/components/Advertising/Advertising';
import { IBanner, IResBanner } from 'api/services/banners/types';
import { storyGroupsServices, storyServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { IResStory } from 'api/services/stories/types';
import { IResStoryGroup } from 'api/services/storyGroups/types';
import { Link } from 'react-router-dom';
import { MainInput } from 'components/MainInput/MainInput';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { ActionButtons } from 'components/Modal/components/ActionButtons/ActionButtons';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import * as settingActions from 'store/ducks/settings/actions';

import './StoryGroupsPage.sass';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';

const tabs = [
  {
    label: 'Загрузка Stories',
    index: 2,
  },
];

export const StoryGroupsPage = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<IResStoryGroup[]>([]);
  const { pathname, search } = useLocation();
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedStoryGroupId, setSelectedStoryGroupId] = useState<number>();
  const [storyGroupTitleRu, setStoryGroupTitleRu] = useState('');
  const [storyGroupTitleUz, setStoryGroupTitleUz] = useState('');
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const { lang } = useSelector((state: AppState) => state.localeReducer);
  const { storyGroupsSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  useEffect(() => {
    getData();
  }, [take, page, pathname, searchValue]);

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        {
          title: 'Реклама',
          id: '',
        },
        {
          title: 'Баннеры',
          id: '',
        },
        {
          title: 'Stories группы',
          id: 'advertising/banners/story-groups',
        },
      ])
    );
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const attrs = {
        take,
        skip: page * take,
        search: searchValue,
      };
      const res = await storyGroupsServices
        .getStoryGroups(attrs)
        .then((res) => res.data);
      setData(res.data);
      setTotalCount(res.count);
    } finally {
      setLoading(false);
    }
  };

  const createStoryGroup = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitLoading(true);
    if (!storyGroupTitleRu.trim() && !storyGroupTitleUz.trim()) {
      showNotification('Заполните название', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    try {
      await storyGroupsServices.createStoryGroup({
        titleRu: storyGroupTitleRu,
        titleUz: storyGroupTitleUz,
      });
      showNotification('Готово', NotificationStatus.SUCCESS);
      closeModal();
      getData();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Произошла ошибка, попробуйте позже');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const updateStoryGroup = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitLoading(true);
    if (!selectedStoryGroupId) return;
    if (!storyGroupTitleRu.trim() && !storyGroupTitleUz.trim()) {
      showNotification('Заполните название', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    try {
      await storyGroupsServices.updateStoryGroup(selectedStoryGroupId, {
        titleRu: storyGroupTitleRu,
        titleUz: storyGroupTitleUz,
      });
      showNotification('Готово', NotificationStatus.SUCCESS);
      closeModal();
      getData();
    } catch {
      showNotification('Произошла ошибка, попробуйте позже');
    } finally {
      setSubmitLoading(false);
    }
  };

  const closeModal = () => {
    setShowCreateModal(false);
    setShowUpdateModal(false);
    setSelectedStoryGroupId(undefined);
  };

  const deleteStoryGroup = async (id: number) => {
    try {
      await storyGroupsServices.deleteStoryGroup(id);
      showNotification('Успешно удалено', NotificationStatus.SUCCESS);
      getData();
    } catch {
      showNotification('Произошла ошибка, попробуйте позже');
    }
  };

  const selectStoryGroup = async (id: number) => {
    try {
      const { titleRu, titleUz } = await storyGroupsServices
        .getStoryGroupById(id)
        .then((res) => res.data);
      setStoryGroupTitleRu(titleRu);
      setStoryGroupTitleUz(titleUz);
      setSelectedStoryGroupId(id);
      setShowUpdateModal(true);
    } catch {
      showNotification('Группа не найдена');
    }
  };

  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setStoryGroupsSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    setSearchValue(value);
  };
  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Группы Stories'
            items={storyGroupsSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={closeModal}>
          <form
            onSubmit={createStoryGroup}
            className='story-groups-page__modal'
          >
            <div className='story-groups-page__modal-in'>
              <div className='story-groups-page__modal-tabs'>
                <div className='story-groups-page__modal-tabs-item'>
                  <span className='story-groups-page__modal-tabs-title'>
                    Создание группы
                  </span>
                </div>
                <ChoiceLang />
              </div>
              <div className='story-groups-page__modal-container'>
                <label className='story-groups-page__modal-input'>
                  <div className='story-groups-page__modal-input-title'>
                    Название:
                  </div>
                  {lang === 'ru' ? (
                    <MainInput
                      error={hasError && !storyGroupTitleRu.trim()}
                      inputValue={storyGroupTitleRu}
                      updateInputValue={setStoryGroupTitleRu}
                    />
                  ) : (
                    <MainInput
                      error={hasError && !storyGroupTitleUz.trim()}
                      inputValue={storyGroupTitleUz}
                      updateInputValue={setStoryGroupTitleUz}
                    />
                  )}
                </label>
              </div>
            </div>
            <ActionButtons
              formSubmit={createStoryGroup}
              closeModal={closeModal}
              loading={submitLoading}
            />
          </form>
        </Modal>
      )}
      {showUpdateModal && selectedStoryGroupId && (
        <Modal closeModal={closeModal}>
          <form onSubmit={updateStoryGroup} className='story-grous-page__modal'>
            <div className='story-groups-page__modal-in'>
              <div className='story-groups-page__modal-tabs'>
                <div className='story-groups-page__modal-tabs-item'>
                  <span className='story-groups-page__modal-tabs-title'>
                    Изменить группу
                  </span>
                </div>

                <Link
                  className='story-groups-page__modal-tabs-item story-groups-page__modal-tabs-title'
                  to={`/advertising/banners/${selectedStoryGroupId}/stories/`}
                >
                  Stories
                </Link>
                <ChoiceLang />
              </div>
              <div className='story-groups-page__modal-container'>
                <label className='story-groups-page__modal-input'>
                  <div className='story-groups-page__modal-input-title'>
                    Название:
                  </div>
                  {lang === 'ru' ? (
                    <MainInput
                      error={hasError && !storyGroupTitleRu.trim()}
                      inputValue={storyGroupTitleRu}
                      updateInputValue={setStoryGroupTitleRu}
                    />
                  ) : (
                    <MainInput
                      error={hasError && !storyGroupTitleUz.trim()}
                      inputValue={storyGroupTitleUz}
                      updateInputValue={setStoryGroupTitleUz}
                    />
                  )}
                </label>
              </div>
            </div>
            <ActionButtons
              closeModal={closeModal}
              formSubmit={updateStoryGroup}
              loading={submitLoading}
            />
          </form>
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='page story-banner-groups-page'>
        <Filter
          createFunc={() => setShowCreateModal(true)}
          filterFunc={() => setFilterShowModal(true)}
          settingFunc={() => setShowSettings(true)}
          updateSearchValue={newFilterValue}
          loading={loading}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data,
              keys: storyGroupsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={storyGroupsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            selectItem={selectStoryGroup}
            deleteHandler={deleteStoryGroup}
            totalCount={totalCount}
            take={take}
            skipHandler={setPage}
            page={page}
          />
        </div>
      </div>
    </>
  );
};
