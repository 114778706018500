import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { Filter } from 'components/Filter/Filter';
import { CreateUser } from 'components/Modal/components/CreateUser/CreateUser';
import { IResUser, IUser } from 'api/services/users/types';
import { shopServices, userServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { UpdateUser } from 'components/Modal/components/UpdateUser/UpdateUser';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { UserRole, userRoles } from 'enums/users';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import * as settingActions from 'store/ducks/settings/actions';

import './UsersClientPage.sass';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { translateUserRole } from 'helpers/translate';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';

export const UsersClientPage: React.FC = () => {
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [data, setData] = useState<IResUser[]>([]);
  const [totalCount, setTotalcount] = useState('');
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRole, setSelectedRole] = useState<ISelect>();
  const [selectedShopId, setSelectedShopId] = useState<number>();
  const { shopUsersSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );
  const [showSettings, setShowSettings] = useState(false);
  const { user } = useSelector((state: AppState) => state.profileReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Пользователи', id: '' },
        { title: 'Все сотрудники', id: 'users' },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [page, searchValue]);

  const getData = async (value?: string) => {
    const params = {
      skip: take * page,
      take,
      ...(selectedRole ? { role: selectedRole as any } : {}),
      search: searchValue || value,
      ...(selectedShopId ? { shopId: selectedShopId } : {}),
    };
    const res = await userServices.getUsers(params).then((res) => res.data);
    setData(res.data);
    setTotalcount(res.count.toString());
  };

  const closeFilter = () => {
    setFilterShowModal(false);
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setShopUsersSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getData();
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Сотрудники'
            items={shopUsersSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}

      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          >
            {/* <div className='users-client-page__filter-inputs'>
              <label className='users-client-page__filter-inputs-item'>
                <MainSelect
                  items={[
                    { label: 'Нет', value: null },
                    ...userRoles.map((i) => ({
                      ...i,
                      label: translateUserRole(i.label as UserRole),
                    })),
                  ]}
                  selectItem={setSelectedRole}
                  placeholder='Выбрать'
                  value={selectedRole}
                  withIcon
                />
                <span className='product-page__filter-inputs-item-label'>
                  Роль
                </span>
              </label>
            </div> */}
          </ModalFilter>
        </Modal>
      )}
      <div className='page'>
        <Filter
          // createFunc={() => setShowCreateModal(true)}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          search={getData}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            tableBody={{
              data: data.map((i) => ({
                ...i,
                userStatus: i.isActive,
                phone: i.phone ? '+' + i.phone.replace('+', '') : '',
              })),
              keys: shopUsersSettings
                .filter((i) => i.isActive)
                .map((i) => ({
                  ...i,
                  ...(i.value === 'isActive' ? { value: 'userStatus' } : {}),
                }))
                .map((i) => i.value),
            }}
            tableHead={shopUsersSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            page={page}
            onlySu
          />
        </div>
      </div>
    </>
  );
};
