import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreateUser,
  IGetProfile,
  IGetUserById,
  IGetUsers,
  IUpdateUser,
} from './types';

export const getUsers: IGetUsers = (attrs) =>
  instance.get(`/users?${qs.stringify(attrs, { skipEmptyString: true })}`);

export const getUserById: IGetUserById = (id) => instance.get(`/users/${id}`);

export const createUser: ICreateUser = (data) => instance.post(`/users`, data);

export const updateUser: IUpdateUser = (id, data) =>
  instance.put(`/users/${id}`, data);

export const getProfile: IGetProfile = () => instance.get('/auth/profile');

export const deleteUser = (id: number) => instance.delete(`/users/${id}`);
