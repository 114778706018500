import React, { useEffect, useState } from 'react';
import { MainInput } from 'components/MainInput/MainInput';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ITag } from 'api/services/tags/types';
import './UpdateDelivery.sass';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import { InputType } from 'components/MainInput/types';
import { IDelivery } from 'api/services/delivery/types';
import { deliveryServices } from 'api/services';

interface Props {
  submit: (data: IDelivery) => void;
  closeModal: () => void;
  loading: boolean;
  id?: number;
}

export const UpdateDelivery: React.FC<Props> = ({
  closeModal,
  submit,
  loading,
  id,
}) => {
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [cost, setCost] = useState('');
  const [freeLimit, setFreeLimit] = useState('');
  const [hasError, setHasError] = useState(false);
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    getDelivery();
  }, []);

  const getDelivery = async () => {
    if (!id) return;
    const res = await deliveryServices
      .getDeliveryById(id)
      .then((res) => res.data);
    setTitleRu(res.titleRu);
    setTitleUz(res.titleUz);
    setCost((res.cost / 100).toString());
    setFreeLimit(res.freeLimit ? Number(res.freeLimit / 100)?.toString() : '');
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if ((!titleRu.trim() && !titleUz.trim()) || !cost.trim()) {
      showNotification('Заполните поля', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    const params = {
      titleRu,
      titleUz,
      cost: Number(cost) * 100,
      ...(!!freeLimit.trim() ? { freeLimit: Number(freeLimit) * 100 } : {}),
    }
    submit(params);
  };

  return (
    <form onSubmit={formSubmit} className='update-delivery'>
      <div className='update-delivery__container update-delivery__modal'>
        <div className='update-delivery__tabs'>
          <div className='update-delivery__tabs-item update-delivery__tabs-item--active'>
            <span>Доставка</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='update-delivery__in'>
          <div className='update-delivery__row'>
            <label className='update-delivery__row-item'>
              <div className='update-delivery__label update-delivery__row-label'>
                Название:
              </div>
              {lang === 'ru' ? (
                <MainInput
                  updateInputValue={setTitleRu}
                  inputValue={titleRu}
                  error={hasError && !titleRu.trim()}
                />
              ) : (
                <MainInput
                  updateInputValue={setTitleUz}
                  inputValue={titleUz}
                  error={hasError && !titleUz.trim()}
                />
              )}
            </label>
          </div>
          <div className='update-delivery__row'>
            <label className='update-delivery__row-item'>
              <div className='update-delivery__label update-delivery__row-label'>
                Цена:
              </div>

              <MainInput
                updateInputValue={setCost}
                inputValue={cost}
                error={hasError && !cost.trim()}
                type={InputType.NUMBER}
              />
            </label>
          </div>
          <div className='update-delivery__row'>
            <label className='update-delivery__row-item'>
              <div className='update-delivery__label update-delivery__row-label'>
                Лимит:
              </div>
              <MainInput
                updateInputValue={setFreeLimit}
                inputValue={freeLimit}
                type={InputType.NUMBER}
              />
            </label>
          </div>
        </div>
      </div>
      <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
    </form>
  );
};
