import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Step1 } from '../Step1/Step1';
import { MainButton } from 'components/MainButton/MainButton';
import { Step2 } from '../Step2/Step2';
import { Step3 } from '../Step3/Step3';
import {
  CouponType,
  ICoupon,
  IResCoupon,
  PeriodType,
} from 'api/services/coupons/types';
import showNotification from 'helpers/showNotification';
import './UpdateCoupon.sass';
import { IResBrand } from 'api/services/brands/types';
import { IResCategory } from 'api/services/categories/types';
import { IResShop } from 'api/services/shops/types';
import { checkCount, checkCouponType } from '../checkTypes';

interface Props {
  // closeModal: () => void;
  setActiveTab: (tab: number) => void;
  activeTab: number;
  tabs: { label: string; index: number }[];
  submit: (data: ICoupon) => void;
  submitTitle?: string;
  activeStep?: number;
  cancel: () => void;
  coupon?: IResCoupon;
}

export interface ICouponCondition {
  brands: IResBrand[];
  categories: IResCategory[];
  shops: IResShop[];
}

export const UpdateCoupon: React.FC<Props> = ({
  setActiveTab,
  activeTab,
  tabs,
  submit,
  submitTitle,
  activeStep,
  cancel,
  coupon,
}) => {
  const [name, setName] = useState('');
  const [shopIds, setShopIds] = useState<number[]>([]);
  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [brandIds, setBrandIds] = useState<number[]>([]);
  const [isActive, setIsActive] = useState(true);
  const [isPublic, setIsPublic] = useState(false);
  const [descriptionRu, setDescriptionRu] = useState('');
  const [sum, setSum] = useState('');
  const [expireAt, setExpireAt] = useState<Date>();
  const [count, setCount] = useState('');
  const [limit, setLimit] = useState('');
  const [orderSum, setOrderSum] = useState('');
  const [minOrderSum, setMinOrderSum] = useState('');
  const [periodType, setPeriodType] = useState<PeriodType>();
  const [periodValue, setPeriodValue] = useState('');
  const [type, setType] = useState<CouponType>();
  const [hasError, setHasError] = useState(false);
  const [step2Data, setStep2Data] = useState<ICouponCondition>();
  const [usersCount, setUsersCount] = useState('');

  useEffect(() => {
    if (coupon) {
      setName(coupon.name || '');
      setShopIds(coupon.shops.map((i) => i.id));
      setCategoryIds(coupon.categories.map((i) => i.id));
      setBrandIds(coupon.brands.map((i) => i.id));
      setIsActive(coupon.isActive);
      setIsPublic(coupon.isPublic);
      setDescriptionRu(coupon.descriptionRu);
      setSum((coupon.sum / 100).toString());
      setExpireAt(coupon.expireAt ? new Date(coupon.expireAt) : undefined);
      setCount(coupon.count?.toString() || '');
      setLimit(coupon.limit?.toString() || '');
      setOrderSum(((coupon.orderSum || 0) / 100)?.toString() || '');
      setMinOrderSum((coupon.minOrderSum / 100).toString());
      setPeriodType(coupon.periodType);
      setPeriodValue(coupon.periodValue?.toString() || '');
      setType(coupon.type || undefined);
      setUsersCount(coupon.usersCount?.toString() || '');
      setStep2Data({
        shops: coupon.shops,
        categories: coupon.categories,
        brands: coupon.brands,
      });
    }
  }, [coupon]);

  useEffect(() => {
    if (coupon && type && type !== coupon?.type) {
      setPeriodType(undefined);
      setPeriodValue('');
      setOrderSum('');
    }
  }, [type]);

  const checkTab = () => {
    switch (activeStep) {
      case 2:
        return (
          <Step2
            setShopIds={setShopIds}
            setCategoryIds={setCategoryIds}
            setBrandIds={setBrandIds}
            orderSum={orderSum}
            setOrderSum={setOrderSum}
            count={count}
            setCount={setCount}
            setPeriodType={setPeriodType}
            periodType={periodType}
            setPeriodValue={setPeriodValue}
            periodValue={periodValue}
            hasError={hasError}
            couponConditions={step2Data}
            shopIds={shopIds}
            categoryIds={categoryIds}
            brandIds={brandIds}
            type={type}
            usersCount={usersCount}
            setUsersCount={setUsersCount}
          />
        );
      case 3:
        return (
          <Step3
            isActive={isActive}
            setIsActive={setIsActive}
            isPublic={isPublic}
            setIsPublic={setIsPublic}
            descriptionRu={descriptionRu}
            setDescriptionRu={setDescriptionRu}
            sum={sum}
            setSum={setSum}
            expireAt={expireAt}
            setExpireAt={setExpireAt}
            limit={limit}
            setLimit={setLimit}
            minOrderSum={minOrderSum}
            setMinOrderSum={setMinOrderSum}
            name={name}
            setName={setName}
            hasError={hasError}
          />
        );
      default:
        return <Step1 selectItem={setType} selectedItem={type} />;
    }
  };

  const submitData = () => {
    if (!type) {
      showNotification('Выберите действие');
      setHasError(true);
      return;
    }
    if (!count.trim() && activeStep === 2 && checkCount(type)) {
      showNotification('Заполните количество');
      setHasError(true);
      return;
    }
    if (
      !usersCount.trim() &&
      activeStep === 2 &&
      type === CouponType.REFERRAL_LINK
    ) {
      showNotification('Заполните количество пользователей');
      setHasError(true);
      return;
    }
    if (!orderSum.trim() && activeStep === 2 && checkCouponType(type)) {
      showNotification('Заполните сумму заказа');
      setHasError(true);
      return;
    }
    if (!name.trim() && activeStep === 3) {
      showNotification('Заполните название');
      setHasError(true);
      return;
    }
    if (!descriptionRu.trim() && activeStep === 3) {
      showNotification('Заполните описание');
      setHasError(true);
      return;
    }
    if (!sum.trim() && activeStep === 3) {
      showNotification('Заполните сумму купона');
      setHasError(true);
      return;
    }
    const coupon: ICoupon = {
      shopIds,
      categoryIds,
      brandIds,
      name,
      isActive,
      isPublic,
      descriptionRu,
      sum: Number(sum) * 100,
      expireAt,
      minOrderSum: Number(minOrderSum) * 100,
      limit: Number(limit),
      count: !!count.length ? Number(count) : undefined,
      orderSum: !!orderSum.length ? Number(orderSum) * 100 : undefined,
      periodValue: !!periodValue.length ? Number(periodValue) : undefined,
      periodType,
      type,
      usersCount: usersCount.length ? Number(usersCount) : undefined,
    };
    setHasError(false);
    submit(coupon);
  };

  return (
    <div className='update-coupon'>
      <div className='update-coupon__tabs'>
        {tabs.map((i) => (
          <div
            className={cn('update-coupon__tabs-item', {
              'update-coupon__tabs-item--active': activeTab === i.index,
            })}
            onClick={() => setActiveTab(i.index)}
            key={i.index}
          >
            <span>{i.label}</span>
          </div>
        ))}
      </div>
      <div className='update-coupon__container'>{checkTab()}</div>
      <div className='update-coupon__buttons'>
        {activeStep !== 1 && (
          <MainButton
            classes='update-coupon__buttons-item'
            title={'Назад'}
            submit={cancel}
            type='button'
            isCancel
          />
        )}
        <MainButton
          classes='update-coupon__buttons-item'
          title={submitTitle || 'Готово'}
          submit={submitData}
        />
      </div>
    </div>
  );
};
