import { IResCategory } from 'api/services/categories/types';
import { Reducer } from 'redux';
import { ServiceType } from './types';

const initState = {
  categories: [],
  categoriesTree: [],
};

export const reducer: Reducer<{
  categories: IResCategory[];
  categoriesTree: IResCategory[];
}> = (state = initState, action) => {
  switch (action.type) {
    case ServiceType.GET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
        categoriesTree: action.categoriesTree,
      };
    default:
      return state;
  }
};

export default reducer;
