import { ISelect } from 'components/MainSelect/MainSelect';
import { orderStatuses, paymentTypes } from 'enums/orderStatus';
import { WidgetType } from 'store/ducks/widgets/types';

export const FILE_URL = `${process.env.REACT_APP_BASE_URL}/uploads`;

export const WIDGETS: ISelect[] = [
  {
    label: 'Активные заказы',
    value: WidgetType.ACTIVE_ORDERS,
  },
  {
    label: 'Все заказы',
    value: WidgetType.ORDERS,
  },
  {
    label: 'Комментарии к статьям',
    value: WidgetType.POST_COMMENTS,
  },
  {
    label: 'Комментарии к продуктам',
    value: WidgetType.PRODUCT_COMMENTS,
  },
  {
    label: 'Отзывы',
    value: WidgetType.REVIEWS,
  },
  {
    label: 'Купоны',
    value: WidgetType.COUPONS,
  },
  {
    label: 'Промокоды',
    value: WidgetType.PROMOCODES,
  },
  // {
  //   label: "Активные заказы",
  //   value: WidgetType.ACTIVE_ORDERS
  // },
  // {
  //   label: "Активные заказы",
  //   value: WidgetType.ACTIVE_ORDERS
  // },
];

export const selectStatuses = [
  { label: 'Все', value: 'empty' },
  ...orderStatuses,
];
export const selectTypes = [{ label: 'Все', value: 'empty' }, ...paymentTypes];
