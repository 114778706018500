import React from 'react';
import { useSelector } from 'react-redux';
import { NotificationStatus } from 'store/ducks/notification/types';
import { AppState } from 'store/store';
import { ReactComponent as SuccessIcon } from 'assets/images/icons/success-icon.svg';
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning-icon.svg';
import cn from 'classnames';

import './Notification.sass';
interface Props {
  show: boolean;
  componentMessage?: string;
  componentStatus?: boolean;
}
export const Notification: React.FC<Props> = ({
  show,
  componentMessage,
  componentStatus,
}) => {
  const { message, status } = useSelector(
    (state: AppState) => state.notificationReducer
  );
  return (
    <div
      className={cn('notification', {
        'notification--success':
          status === NotificationStatus.SUCCESS || componentStatus,
        'notification--warning': status === NotificationStatus.WARNING,
        'notification--shown': show,
      })}
    >
      <div className='notification__message'>{message || componentMessage}</div>
      <div className='notification__icon'>
        {status === NotificationStatus.SUCCESS || componentStatus ? (
          <SuccessIcon />
        ) : (
          <WarningIcon />
        )}
      </div>
    </div>
  );
};
