import { Table } from 'components/Table/Table';
import React from 'react';
import { Link } from 'react-router-dom';
import { IWidget, WidgetType } from 'store/ducks/widgets/types';
import './Widget.sass';

interface Props {
  bodyData: any;
  headData: any;
  url: string;
  deleteWidget: (item: IWidget) => void;
  item: IWidget;
}

export const Widget: React.FC<Props> = ({
  headData,
  bodyData,
  item,
  url,
  deleteWidget,
}) => {
  return (
    <div className='widget'>
      <div className='widget__header'>
        <h4 className='widget__title'>{item.label}</h4>
        <Link to={url} className='widget__expand'>
          Все
        </Link>
        <button
          className='widget__expand widget__expand--delete'
          onClick={() => deleteWidget(item)}
        >
          Удалить
        </button>
      </div>
      <div className='widget__container'>
        <Table tableBody={bodyData} tableHead={headData} flex />
      </div>
    </div>
  );
};
