import { shopServices } from 'api/services';
import { IResShop, IShop } from 'api/services/shops/types';
import { Filter } from 'components/Filter/Filter';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { CreateShop } from 'components/Modal/components/CreateShop/CreateShop';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { UpdateShop } from 'components/Modal/components/UpdateShop/UpdateShop';
import { Modal } from 'components/Modal/Modal';
import { Table } from 'components/Table/Table';
import showNotification from 'helpers/showNotification';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationStatus } from 'store/ducks/notification/types';
import * as templateActions from 'store/ducks/template/actions';
import * as settingActions from 'store/ducks/settings/actions';
import './ShopsPage.sass';
import { AppState } from 'store/store';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Settings } from 'components/Modal/components/Settings/Settings';

const ShopsPage: React.FC = () => {
  const [data, setData] = useState<IResShop[]>();
  const [totalCount, setTotalCount] = useState(0);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [shopId, setShopId] = useState<number>();
  const [showCreateShopModal, setShowCreateShopModal] = useState(false);
  const [showUpdateShopModal, setShowUpdateShopModal] = useState(false);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { shopsSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        {
          title: 'Магазины',
          id: 'shops',
        },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [page, searchValue]);

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      search: searchValue,
    };
    const res = await shopServices.getShops(params).then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
  };

  const createShopHandler = async (data: IShop) => {
    if (loading) return;
    let validation = true;

    if (!data.name.trim()) {
      validation = false;
      showNotification('Поля не заполнены');
    } else if (!data.url.trim()) {
      validation = false;
      showNotification('Поля не заполнены');
    } else if (!data.fileId) {
      validation = false;
      showNotification('Поля не заполнены');
    }

    if (!validation) {
      return;
    }
    setLoading(true);
    try {
      await shopServices.createShop(data);
      setShowCreateShopModal(false);
      showNotification('Магазин создан', NotificationStatus.SUCCESS);
      getData();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    } finally {
      setLoading(false);
    }
  };

  const updateShopHandler = async (data: IShop) => {
    if (!shopId) return;
    if (loading) return;
    let validation = true;

    if (!data.name.trim()) {
      validation = false;
      showNotification('Поля не заполнены');
    } else if (!data.url.trim()) {
      validation = false;
      showNotification('Поля не заполнены');
    } else if (!data.fileId) {
      validation = false;
      showNotification('Поля не заполнены');
    }

    if (!validation) {
      return;
    }
    setLoading(true);
    try {
      await shopServices.updateShop(shopId, data);
      setShowUpdateShopModal(false);
      getData();
      showNotification('Магазин изменен', NotificationStatus.SUCCESS);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const openCreateShopModal = () => {
    setShowCreateShopModal(true);
  };

  const openUpdateShop = (id: number) => {
    setShopId(id);
    setShowUpdateShopModal(true);
  };

  const closeUpdateShopModal = () => {
    setShopId(undefined);
    setShowUpdateShopModal(false);
  };

  const deleteItem = async (id: number) => {
    try {
      await shopServices.deleteShop(id);
      showNotification('Магазин удален', NotificationStatus.SUCCESS);
      getData();
    } catch {
      showNotification('Ошибка, попробуйте позже');
    }
  };

  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setShopsSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getData();
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Магазины'
            items={shopsSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='shops-page page'>
        <Filter
          createFunc={openCreateShopModal}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='shops-page__in page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            selectItem={openUpdateShop}
            tableBody={{
              data,
              keys: shopsSettings.filter((i) => i.isActive).map((i) => i.value),
            }}
            tableHead={shopsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            deleteHandler={deleteItem}
            page={page}
          />
        </div>
      </div>
      {showCreateShopModal && (
        <Modal closeModal={() => setShowCreateShopModal(false)}>
          <CreateShop
            submit={createShopHandler}
            closeModal={() => setShowCreateShopModal(false)}
          />
        </Modal>
      )}
      {showUpdateShopModal && (
        <Modal closeModal={closeUpdateShopModal}>
          <UpdateShop
            shopId={shopId}
            submit={updateShopHandler}
            closeModal={() => setShowUpdateShopModal(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default ShopsPage;
