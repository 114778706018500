import React, { useEffect, useState } from 'react';
import { MainInput } from 'components/MainInput/MainInput';
import { InputType } from 'components/MainInput/types';
import { MainButton } from 'components/MainButton/MainButton';
import cn from 'classnames';
import { IResOrder, ISendOrder } from 'api/services/orders/types';
import { CustomDateTimePicker } from 'components/CustomDateTimePicker/CustomDateTimePicker';
import YandexMap from 'components/Map/YandexMap';
import { orderServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { UserRole } from 'enums/users';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IResPromocode } from 'api/services/promocodes/types';
import { checkAmount } from 'helpers/checkAmount';
import { isShop } from 'helpers/checkUser';
import { IResCoupon } from 'api/services/coupons/types';
import './OrderInfo.sass';

interface Props {
  order: IResOrder;
  closeModal: () => void;
}

export enum DescriptionType {
  HTML = 'HTML',
  TEXT = 'TEXT',
  REDACTOR = 'REDACTOR',
}

interface IOrderPromocode extends IResPromocode {
  orderPromocodeSum: string;
}

export const OrderInfo: React.FC<Props> = ({ order, closeModal }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [createdDate, setCreatedDate] = useState<Date | undefined>(
    order.createdAt
  );
  const [users, setUsers] = useState<ISearchableSelect[]>([]);
  const [selectedUser, setSelectedUser] = useState<number>();
  const [selectedUserValue, setSelectedUserValue] = useState('');
  const [usersLoading, setUsersLoading] = useState(false);
  const [promocode, setPromocode] = useState<IOrderPromocode>();
  const [comment, setComment] = useState('');
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [sms, setSms] = useState('');
  const [hasError, setHasError] = useState(false);
  const [userCoupon, setUserCoupon] = useState<IResCoupon>();
  const [refreshMap, setRefreshMap] = useState(false);

  const { user } = useSelector((state: AppState) => state.profileReducer);

  useEffect(() => {
    console.log(order);
    setEmail(order.user?.email);
    setName(order.name);
    setUserPhone(order.user?.phone ? '+' + order.user?.phone : '');
    setPhone(order.phone ? '+' + order.phone : '');
    setDeliveryAddress(order.address || '');
    setCreatedDate(order.createdAt);
    setSelectedUserValue(
      order.responsibleManager?.name || order.responsibleManager?.email || ''
    );
    setSelectedUser(order.responsibleManagerId);
    setLng(order.lng);
    setLat(order.lat);
    if (order.promocode && order.promocodeSum) {
      setPromocode({
        ...order.promocode,
        orderPromocodeSum: order.promocodeSum,
      });
    }
    if (order.userCoupon && order.couponSum) {
      setUserCoupon(order.userCoupon.coupon);
    }
  }, [!!order]);

  const changeAddress = (
    address: string,
    coords: number[],
    isSearch?: boolean
  ) => {
    // setLat(coords[0]);
    // setLng(coords[1]);
    // setDeliveryAddress(address);
    if (isSearch) setRefreshMap((prev) => !prev);
  };

  // const getUsers = async (value?: string) => {
  //   if (
  //     user?.role !== 'SU' &&
  //     user?.role !== 'ADMIN' &&
  //     user?.role !== 'SALES_MANAGER'
  //   )
  //     return showNotification('Нет доступа обратитесь к администратору');
  //   try {
  //     setUsersLoading(true);
  //     const res = await userServices
  //       .getUsers({
  //         skip: 0,
  //         take: 25,
  //         search: value,
  //         role: UserRole.SALES_MANAGER,
  //       })
  //       .then((res) => res.data.data);
  //     setUsers(
  //       res.map((i) => ({
  //         name: i.name || i.email,
  //         value: i.id,
  //       }))
  //     );
  //   } finally {
  //     setUsersLoading(false);
  //   }
  // };

  const submitOrder = async () => {
    if (!lat || !lng) {
      showNotification('Укажите адрес доставки');
      return;
    }
    if (!selectedUser) {
      showNotification(
        'Выберите ответственного за заказ',
        NotificationStatus.WARNING
      );
      setHasError(true);
      return;
    }

    const orderData: ISendOrder = {
      totalSum: Number(order.totalSum),
      products: order.orderProducts.map((i) => ({
        id: i.id,
        variantId: i.variant.id,
        sum: Number(i.sum),
        count: i.count,
      })),
      lat,
      lng,
      address: deliveryAddress,
    };
    try {
      if (selectedUser && !order.responsibleManager)
        // await orderServices.updateOrder(order.id, orderData);
        showNotification('Сохранено', NotificationStatus.SUCCESS);
      if (!!sms.length) {
        orderServices.sendSmsOrder(order.id, sms);
      }
      closeModal();
    } catch {
      showNotification('Произошла ошибка, попробуйте позже');
    }
  };

  return (
    <div className='order-info'>
      <div className='order-info__title'>Номер заказа: {order.id} </div>
      {!isShop(user?.role) && (
        <>
          <div className='order-info__row order-info__row--with-button'>
            <div className='order-info__row-title'>Аккаунт</div>
            <div className='order-info__row-item '>
              <MainInput
                updateInputValue={(value: string) => setEmail(value)}
                inputValue={email}
                disabled
              />
              {/* <MainButton
            classes='order-info__row-item-button'
            title='Выбрать'
            submit={() => {}}
          /> */}
            </div>
          </div>
          <div className='order-info__row'>
            <div className='order-info__row-title'>Имя</div>
            <div className='order-info__row-item'>
              <MainInput
                inputValue={name}
                updateInputValue={setName}
                disabled
              />
            </div>
          </div>
          {/* <div className='order-info__row'>
        <div className='order-info__row-title'>Фамилия</div>
        <div className='order-info__row-item'>
          <MainInput />
        </div>
      </div> */}
          <div className='order-info__row'>
            <div className='order-info__row-title'>
              Номер телефона заказчика
            </div>
            <div className='order-info__row-item'>
              <MainInput
                inputValue={userPhone}
                updateInputValue={setUserPhone}
                disabled
              />
            </div>
          </div>
          <div className='order-info__row'>
            <div className='order-info__row-title'>
              Номер телефона указанный в заказе
            </div>
            <div className='order-info__row-item'>
              <MainInput
                inputValue={phone}
                updateInputValue={setPhone}
                disabled
              />
            </div>
          </div>
          <div className='order-info__row'>
            <div className='order-info__row-title'>Адрес доставки</div>
            <div className='order-info__row-item'>
              <MainInput
                inputValue={deliveryAddress}
                updateInputValue={setDeliveryAddress}
              />
            </div>
          </div>
          <div className='order-info__row order-info__row--big'>
            <div className='order-info__row-title'>Локация</div>
            <div className='order-info__row-map'>
              <YandexMap
                onChangeCoords={changeAddress}
                lat={lat}
                lng={lng}
                address={deliveryAddress}
                refreshMap={refreshMap}
              />
            </div>
          </div>
          <div className='order-info__row'>
            <div className='order-info__row-title'>Дата заказа</div>
            <div className='order-info__row-item'>
              <CustomDateTimePicker
                date={createdDate}
                setDate={setCreatedDate}
                disabled
              />
            </div>
          </div>
        </>
      )}
      {promocode && (
        <div className='order-info__row'>
          <div className='order-info__row-title'>Промокод:</div>
          <div className='order-info__row-item'>
            <MainInput
              inputValue={promocode.name}
              disabled
              updateInputValue={() => {}}
            />
          </div>
        </div>
      )}
      {promocode && (
        <div className='order-info__row'>
          <div className='order-info__row-title'>Сумма промокода:</div>
          <div className='order-info__row-item'>
            <MainInput
              inputValue={checkAmount(promocode.orderPromocodeSum)}
              disabled
              updateInputValue={() => {}}
            />
          </div>
        </div>
      )}
      {userCoupon && (
        <div className='order-info__row'>
          <div className='order-info__row-title'>Купон:</div>
          <div className='order-info__row-item'>
            <MainInput
              inputValue={userCoupon.name}
              disabled
              updateInputValue={() => {}}
            />
          </div>
        </div>
      )}
      {userCoupon && (
        <div className='order-info__row'>
          <div className='order-info__row-title'>Сумма купона:</div>
          <div className='order-info__row-item'>
            <MainInput
              inputValue={checkAmount(order.couponSum)}
              disabled
              updateInputValue={() => {}}
            />
          </div>
        </div>
      )}
      <div className='order-info__row'>
        <div className='order-info__row-title'>Ответственный</div>
        <div className='order-info__row-item'>
          <MainInput
            inputValue={order.responsibleManager?.name || ''}
            disabled
            updateInputValue={() => {}}
          />
        </div>
      </div>
      {user?.role !== UserRole.COURIER && !isShop(user?.role) && (
        <div className='order-info__row order-info__row--with-textarea'>
          <div className='order-info__tabs'>
            {/* <div
            className={cn('order-info__tab', {
              'order-info__tab--active': activeTab === 1,
            })}
            onClick={() => setActiveTab(1)}
          >
            <span>Комментарий</span>
          </div> */}
            <div
              className={cn('order-info__tab', 'order-info__tab--active')}
              onClick={() => setActiveTab(2)}
            >
              <span>SMS</span>
            </div>
            {/* <div
            className={cn('order-info__tab', {
              'order-info__tab--active': activeTab === 3,
            })}
            onClick={() => setActiveTab(3)}
          >
            <span>E-MAIL</span>
          </div> */}
          </div>
          {/* {activeTab === 1 ? (
          <MainInput
            inputValue={comment}
            updateInputValue={setComment}
            type={InputType.TEXTAREA}
            rows={13}
          />
        ) : ( */}
          <MainInput
            inputValue={sms}
            updateInputValue={setSms}
            type={InputType.TEXTAREA}
            rows={13}
          />
          {/* (
           <MainInput
            inputValue={email}
            updateInputValue={setEmail}
            type={InputType.TEXTAREA}
            rows={13}
          />
        ) */}
        </div>
      )}
      {user?.role !== UserRole.COURIER && (
        <MainButton
          classes='order-info__submit'
          submit={submitOrder}
          title='Готово'
        />
      )}
    </div>
  );
};
