import { AxiosPromise } from 'axios';
import { IArticle } from '../articles/types';
import { IResFile } from '../files/types';
import { IResProduct } from '../products/types';
import { IResUser } from '../users/types';

export interface IComment {
  text: string;
  productId?: number;
  articleId?: number;
  parentId?: number;
  galleryIds: number[];
  shortcomings: string;
  advantages: string;
}

export interface IResComment extends IComment {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  status: CommentStatus;
  product: IResProduct;
  article: IArticle;
  evaluation: number;
  likesCount: number;
  dislikesCount: number;
  gallery: IResFile[];
  user?: IResUser;
}

export interface IGetComment {
  (attrs: {
    skip?: number;
    take?: number;
    search?: string;
    status?: string;
    isFeedback?: boolean;
    isProduct?: boolean;
    isArticle?: boolean;
    isComment?: boolean;
  }): AxiosPromise<{
    count: number;
    data: IResComment[];
  }>;
}

export interface ISendedComment
  extends Pick<IComment, 'parentId' | 'text' | 'productId'> {}

export interface ICreateComment {
  (data: ISendedComment): AxiosPromise;
}

export interface IConfirmComment {
  (id: number): AxiosPromise;
}

export interface IGetCommentById {
  (id: number): AxiosPromise<IResComment>;
}

export enum CommentStatus {
  VERIFIED = 'VERIFIED',
  NEW = 'NEW',
}
