import React from 'react';
import logo from 'assets/images/logo.svg';
import exitIcon from 'assets/images/icons/exit-icon.svg';
import { Nav } from 'components/Nav/Nav';
import * as authActions from 'store/ducks/auth/actions';
import './Sidebar.sass';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppState } from 'store/store';
import { translateRole } from 'helpers/translate';

export const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const signOut = () => {
    dispatch(authActions.signOut());
    localStorage.clear();
    history('/auth/sign-in');
  };
  const { user } = useSelector((state: AppState) => state.profileReducer);
  return (
    <div className='sidebar'>
      <Link to='/'>
        <img src={logo} alt='logo' className='sidebar__logo' />
      </Link>
      <div className='sidebar__user'>
        {translateRole(user?.role)}: {user?.name || user?.email}
      </div>
      <div className='sidebar__nav'>
        <Nav />
      </div>
      <div className='sidebar__logout' onClick={signOut}>
        Выйти из системы <img src={exitIcon} alt='' />
      </div>
    </div>
  );
};
