import React, { useEffect, useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { MainInput } from 'components/MainInput/MainInput';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import './Product.sass';
import { RadioInput } from 'components/RadioInput/RadioInput';
import { InputType } from 'components/MainInput/types';
import { RadioType } from 'enums/radioType';
import { ActionButtons } from '../../ActionButtons/ActionButtons';
import { HtmlEditor, HtmlEditor2 } from 'components/Editor/HtmlEditor';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import { IResProduct } from 'api/services/products/types';
import { IResFile } from 'api/services/files/types';
import { FileList } from 'components/FileList/FileList';
import { FileModal } from '../../FileModal/FileModal';
import { brandServices, shopServices } from 'api/services';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { IResShop } from 'api/services/shops/types';
import { IResBrand } from 'api/services/brands/types';
import { CustomDateTimePicker } from 'components/CustomDateTimePicker/CustomDateTimePicker';
import { isEditUser, isProductWhitoutShop } from 'helpers/checkUser';

interface Props {
  setShopId: (data: string) => void;
  setBrandId: (data: string) => void;
  setActivateAt?: (data: Date) => void;
  setDeactivateAt?: (data: Date) => void;
  setCode: (data: string) => void;
  setTitleRu: (data: string) => void;
  setTitleUz: (data: string) => void;
  setUrl: (data: string) => void;
  setDescriptionUz: (data: any) => void;
  setDescriptionRu: (data: any) => void;
  setMainFile: (file?: IResFile) => void;
  mainFile?: IResFile;
  brand?: string;
  shopId?: string;
  activateAt: Date;
  deactivateAt: Date;
  code: string;
  titleRu: string;
  titleUz: string;
  url: string;
  descriptionUz: string;
  descriptionRu: string;
  fileId?: number;
  selectedFileUrl: string;
  isUpdate?: boolean;
  hasError?: boolean;
  galleryFiles: IResFile[];
  setGalleryFiles: (file: IResFile[]) => void;
  searchableBrandValue: string;
  setSearchableBrandValue: (value: string) => void;
  setSearchableShopValue: (value: string) => void;
  searchableShopValue: string;
  cashback: string;
  setCashback: (value: string) => void;
}

export const Product: React.FC<Props> = ({
  shopId,
  activateAt,
  deactivateAt,
  code,
  titleRu,
  titleUz,
  url,
  fileId,
  brand,
  isUpdate,
  descriptionUz,
  descriptionRu,
  selectedFileUrl,
  setBrandId,
  setShopId,
  setActivateAt,
  setDeactivateAt,
  setCode,
  setTitleRu,
  setTitleUz,
  setUrl,
  setMainFile,
  mainFile,
  setDescriptionUz,
  setDescriptionRu,
  hasError,
  galleryFiles,
  setGalleryFiles,
  searchableBrandValue,
  setSearchableBrandValue,
  setSearchableShopValue,
  searchableShopValue,
  cashback,
  setCashback,
}) => {
  const { lang } = useSelector((state: AppState) => state.localeReducer);
  const [showFileModal, setShowFileModal] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [descriptionType, setDescriptionType] = useState<RadioType>(
    RadioType.TEXT
  );
  const [shops, setShops] = useState<IResShop[]>([]);
  const [searchableShopsLoading, setSearchableShopsLoading] = useState(false);
  const [brands, setBrands] = useState<IResBrand[]>([]);
  const [searchableBrandsLoading, setSearchableBrandsLoading] = useState(false);

  const [someFiles, setSomeFiles] = useState<IResFile[]>([]);

  const { user } = useSelector((state: AppState) => state.profileReducer);

  useEffect(() => {
    getShops();
    getBrands();
  }, []);

  useEffect(() => {
    if (!someFiles.length) {
      setSomeFiles(galleryFiles);
    }
  }, [!!galleryFiles.length]);

  useEffect(() => {
    setGalleryFiles(someFiles);
  }, [someFiles.length]);

  const getShops = async (value?: string) => {
    if (!isEditUser(user?.role)) return;
    setSearchableShopsLoading(true);
    try {
      const res = await shopServices
        .getShops({
          skip: 0,
          take: 25,
          search: value && value.length < 2 ? '' : value,
        })
        .then((res) => res.data);
      setShops(res.data);
    } finally {
      setSearchableShopsLoading(false);
    }
  };

  const getBrands = async (value?: string) => {
    setSearchableBrandsLoading(true);
    try {
      const res = await brandServices
        .getBrands({
          skip: 0,
          take: 25,
          search: value && value.length < 2 ? '' : value,
        })
        .then((res) => res.data);
      setBrands(res.data);
    } finally {
      setSearchableBrandsLoading(false);
    }
  };
  const galleryFileSelect = (file: IResFile) => {
    if (!galleryFiles.some((i) => i.id === file.id))
      setSomeFiles((prev) => [...prev, file]);
  };

  const gallerySomeFileSelect = (files: IResFile[]) => {
    setSomeFiles(files);
    setShowGalleryModal(false);
  };
  const renderRedactor = () => {
    switch (descriptionType) {
      case RadioType.REDACTOR:
        return lang === 'ru' ? (
          <HtmlEditor
            value={descriptionRu}
            onChange={setDescriptionRu}
            error={hasError && !descriptionRu}
          />
        ) : (
          <HtmlEditor2
            value={descriptionUz}
            onChange={setDescriptionUz}
            error={hasError && !descriptionUz}
          />
        );
      default:
        return lang === 'ru' ? (
          <MainInput
            type={InputType.TEXTAREA}
            inputValue={descriptionRu}
            updateInputValue={setDescriptionRu}
            error={hasError && !descriptionRu}
          />
        ) : (
          <MainInput
            type={InputType.TEXTAREA}
            inputValue={descriptionUz}
            updateInputValue={setDescriptionUz}
            error={hasError && !descriptionUz}
          />
        );
    }
  };

  const changeDescriptionType = (type: RadioType) => {
    setDescriptionType(type);
  };

  const mainFileSelect = (file: IResFile) => {
    setMainFile(file);
    setShowFileModal(false);
  };

  return (
    <div className='product'>
      <div className='product__modal-in'>
        <div className='product__in'>
          <div className='product__container'>
            <div className='product__row'>
              <label className='product__row-item'>
                <div className='product__label product__row-label'>
                  Начало активности:
                </div>
                {/* <MainInput
                  inputValue={activateAt}
                  updateInputValue={setActivateAt}
                  type={InputType.DATE}
                  error={hasError && !activateAt.trim()}
                /> */}
                <CustomDateTimePicker
                  date={activateAt as any}
                  setDate={setActivateAt as any}
                  onlyDate
                />
              </label>
              <label className='product__row-item'>
                <div className='product__label product__row-label'>
                  Окончание активности:
                </div>
                {/* <MainInput
                  inputValue={deactivateAt}
                  updateInputValue={setDeactivateAt}
                  type={InputType.DATE}
                  error={hasError && !deactivateAt.trim()}
                /> */}
                <CustomDateTimePicker
                  date={deactivateAt as any}
                  setDate={setDeactivateAt as any}
                  onlyDate
                />
              </label>
            </div>
            {isProductWhitoutShop(user?.role) && (
              <div className='product__row'>
                <label className='product__row-item'>
                  <div className='product__label'>Магазин</div>
                  <CustomSearchableSelect
                    items={shops.map((i) => ({ name: i.name, value: i.id }))}
                    loading={searchableShopsLoading}
                    setLoading={setSearchableShopsLoading}
                    setSelectItem={(value: number) =>
                      setShopId(value.toString())
                    }
                    setValue={getShops}
                    searchValue={searchableShopValue}
                    error={!searchableShopValue?.trim() && hasError}
                    setSearchValue={setSearchableShopValue}
                    disabled={isUpdate}
                  />
                </label>
              </div>
            )}
            <div className='product__row'>
              <label className='product__row-item'>
                <div className='product__label'>Бренд</div>
                <CustomSearchableSelect
                  items={brands.map((i) => ({ name: i.name, value: i.id }))}
                  loading={searchableBrandsLoading}
                  setLoading={setSearchableBrandsLoading}
                  setSelectItem={(value: number) =>
                    setBrandId(value.toString())
                  }
                  setValue={getBrands}
                  searchValue={searchableBrandValue}
                  error={!searchableBrandValue?.trim() && hasError}
                  setSearchValue={setSearchableBrandValue}
                />
              </label>
            </div>
            <div className='product__row'>
              <label className='product__row-item'>
                <div className='product__label'>Артикул</div>
                <MainInput inputValue={code} updateInputValue={setCode} />
              </label>
            </div>
            <div className='product__row'>
              <label className='product__row-item'>
                <div className='product__label'>Название</div>
                {lang === 'ru' ? (
                  <MainInput
                    inputValue={titleRu}
                    updateInputValue={setTitleRu}
                    error={hasError && !titleRu.trim() && !titleUz.trim()}
                  />
                ) : (
                  <MainInput
                    inputValue={titleUz}
                    updateInputValue={setTitleUz}
                    error={hasError && !titleRu.trim() && !titleUz.trim()}
                  />
                )}
              </label>
            </div>
            {isProductWhitoutShop(user?.role) && (
              <div className='product__row'>
                <label className='product__row-item'>
                  <div className='product__label'>URL</div>
                  <MainInput inputValue={url} updateInputValue={setUrl} />
                </label>
              </div>
            )}

            <div className='product__row'>
              <label className='product__row-item'>
                <div className='product__label'>Cashback %</div>
                <MainInput
                  inputValue={cashback}
                  updateInputValue={setCashback}
                  type={InputType.NUMBER}
                />
              </label>
            </div>

            <div className='product__row product__row--uploader'>
              <div className='product__row-item'>
                <div className='product__label product__label--uploader'>
                  Загрузить главное изображение:
                  <span onClick={() => setShowFileModal(true)}>
                    Выбрать из файла <FolderIcon />
                  </span>
                </div>
                <div className='create-category__form-uploader-files'>
                  {!!mainFile ? (
                    <FileList
                      file={mainFile}
                      deleteFile={() => setMainFile(undefined)}
                    />
                  ) : (
                    <FileUploader
                      setFile={setMainFile}
                      error={hasError && !mainFile}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='product__row product__row--uploader'>
              <div className='product__row-item'>
                <div className='product__label product__label--uploader'>
                  Загрузить изображения:
                  <span onClick={() => setShowGalleryModal(true)}>
                    Выбрать из файла <FolderIcon />
                  </span>
                </div>
                <div className='create-category__form-uploader-files'>
                  {!!galleryFiles.length ? (
                    <FileList
                      list={galleryFiles}
                      deleteOneFile={(id: number) =>
                        setSomeFiles((prev) => prev.filter((i) => i.id !== id))
                      }
                    />
                  ) : (
                    <FileUploader
                      setFile={galleryFileSelect}
                      error={hasError && !galleryFiles.length}
                      isMultiple
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='product__container product__container-right'>
            <div className='product__description'>
              <div className='product__label'>Описание</div>
              <div className='product__description-in'>
                <div className='product__description-tabs'>
                  <RadioInput
                    checked={descriptionType === RadioType.TEXT}
                    type={RadioType.TEXT}
                    label='ТЕКСТ'
                    classes='product__description-tab'
                    onChange={changeDescriptionType}
                  />
                  <RadioInput
                    checked={descriptionType === RadioType.REDACTOR}
                    type={RadioType.REDACTOR}
                    label='Визуальный редактор'
                    classes='product__description-tab'
                    onChange={changeDescriptionType}
                  />
                </div>
                <div className='upload-articles__textarea'>
                  {renderRedactor()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFileModal && (
        <FileModal
          closeModal={() => setShowFileModal(false)}
          fileSelected={mainFileSelect}
        />
      )}
      {showGalleryModal && (
        <FileModal
          closeModal={() => setShowGalleryModal(false)}
          someFileSelect={gallerySomeFileSelect}
        />
      )}
    </div>
  );
};
