import React, { useEffect, useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Pagination } from 'components/Pagination/Pagination';
import FolderIcon from 'assets/images/icons/folder-icon.png';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-icon.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/minus-icon.svg';
import cn from 'classnames';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/close-icon.svg';
import moment from 'moment';

import './TableFolders.sass';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import { checkAmount } from 'helpers/checkAmount';
import { MarginType } from 'enums/marginType';

interface Props {
  tableHead: string[];
  tableBody: { data: any[]; keys: string[] };
  classes?: string;
  withPaginate?: boolean;
  withIcons?: boolean;
  isLinks?: boolean;
  updateItem: (id: any) => void;
  checkedIds: number[];
  setCheckedIds: (value: any) => void;
  items: any[];
  deleteHandler?: (id: number) => void;
  sorting?: boolean;
}

export const TableFolders: React.FC<Props> = ({
  tableHead,
  tableBody,
  classes,
  withPaginate = false,
  withIcons = false,
  isLinks = false,
  updateItem,
  checkedIds,
  setCheckedIds,
  items,
  deleteHandler,
  sorting,
}) => {
  const [openedIds, setOpenedIds] = useState<number[]>([]);
  const { breadCrumbs } = useSelector(
    (state: AppState) => state.templateReducer
  );
  const [allChecked, setAllChecked] = useState(false);
  const [itemsLvl, setItemsLvl] = useState<any[]>([]);
  const { ids } = useSelector((state: AppState) => state.categoryChildsReducer);

  useEffect(() => {
    if (!!ids.length) {
      setOpenedIds(ids);
    }
  }, [ids.length]);

  useEffect(() => {
    if (!!tableBody.data.length) checkLvls(tableBody.data);
  }, [!!tableBody.data.length]);

  const checkLvls = (dataList: any[], num?: number) => {
    const arr = dataList;
    let count = num || 0;
    count = count + 1;
    arr?.map((i) => {
      setItemsLvl((prev) => [...prev, { id: i.id, lvl: count }]);
      checkLvls(i.items, count);
    });
  };

  const checkKey = (key: string, value: any) => {
    switch (key) {
      case 'value':
        return checkAmount(value);
      case 'marginType':
        return !value
          ? 'Наследованная'
          : value === MarginType.FLAT
          ? 'Фиксированная'
          : 'Процент';
      case 'isActive':
      case 'permission':
        return value ? 'Да' : 'Нет';
      case 'isShipped':
        return value ? 'Отгружено' : 'Не отгружено';
      case 'createdAt':
      case 'updatedAt':
        return moment(value).format('DD.MM.YYYY HH:mm:ss');
      case 'amountCurrency':
        return `${value.amount} ${value.currency}`;
      case 'title':
        return value;
      case 'statusColor':
        return (
          <span
            className={cn('table-folders__item-status', {
              'table-folders__item-status--processed':
                value === 'Обрабатывается',
              'table-folders__item-status--canceled': value === 'Отменен',
              'table-folders__item-status--accepted': value === 'Принят',
              'table-folders__item-status--handed-courier':
                value === 'Передан курьеру',
              'table-folders__item-status--delivered': value === 'Доставлен',
              'table-folders__item-status--completed': value === 'Завершено',
            })}
          >
            {value}
          </span>
        );
      default:
        return value;
    }
  };

  const checkRow = (ii: string, title: string, item: any) => {
    const findLvlObj = itemsLvl.find((i) => i.id === item.id);
    return (
      <div
        className='table-folders__item-title'
        style={{
          paddingLeft:
            findLvlObj && ii === 'titleRu'
              ? 17 * (findLvlObj.lvl - 1) + 'px'
              : '',
        }}
      >
        {ii === 'titleRu' && withIcons && (
          <div className='table-folders__icons'>
            <div
              className={cn('table-folders__icon-plus', {
                'table-folders__icon-plus--hide': !item.items?.length,
                'table-folders__icon-plus--remove':
                  !item.parentId && !item.items?.length,
              })}
              onClick={(e) => {
                e.stopPropagation();
                setOpenedIds((prev) => {
                  if (prev.some((b) => b === item.id)) {
                    return prev.filter((b) => b !== item.id);
                  }
                  return [...prev, item.id];
                });
              }}
            >
              {openedIds.some((b) => b === item.id) ? (
                <MinusIcon />
              ) : (
                <PlusIcon />
              )}
            </div>

            <img className='table-folders__icon' src={FolderIcon} />
          </div>
        )}
        <span onClick={() => updateItem(Number(item.id))}>
          {checkKey(ii, title)}
        </span>
      </div>
    );
  };

  const allCheck = (value: boolean) => {
    value ? setCheckedIds(items.map((i: any) => i.id)) : setCheckedIds([]);
    setAllChecked(value);
  };

  const renderChild = (i: any) => {
    return i.items
      ?.sort((a: any, b: any) => a.titleRu.localeCompare(b.titleRu))
      ?.map((iiii: any, index: number) => (
        <React.Fragment key={iiii.id}>
          <tr className='table-folders__row table-folders__row--child'>
            {/* <td className='table-folders__item table-folders__item-checkbox'>
            <Checkbox
              isChecked={checkedIds.some((ii) => ii === iiii.id)}
              setChecked={(value: boolean) =>
                setCheckedIds(() =>
                  value
                    ? [...checkedIds, iiii.id]
                    : checkedIds.filter((ii) => ii !== iiii.id)
                )
              }
            />
          </td> */}
            {tableBody.keys.map((ii) => (
              <td
                className='table-folders__item'
                key={ii}
                onClick={() => updateItem(Number(iiii.id))}
              >
                {checkRow(ii, iiii[`${ii}`], iiii)}
              </td>
            ))}
            {deleteHandler && (
              <td className='table-folders__item table-folders__item-icon table-folders__item'>
                <div className='table-folders__item-actions'>
                  <span
                    onClick={() => deleteHandler(iiii.id)}
                    className='table-folders__item-icon-block table-folders__item-icon-block--red'
                  >
                    <DeleteIcon />
                  </span>
                </div>
              </td>
            )}
          </tr>
          {openedIds?.some((k) => k === iiii.id) &&
            !!iiii?.items?.length &&
            renderChild(iiii)}
        </React.Fragment>
      ));
  };
  return (
    <>
      <div className={`table-folders ${classes || ''}`}>
        <table className='table-folders__container'>
          <thead>
            <tr className='table-folders__row table-folders__head-row'>
              {/* <td className='table-folders__item table-folders__item-checkbox'>
                <Checkbox isChecked={allChecked} setChecked={allCheck} />
              </td> */}
              {tableHead.map((i) => (
                <td className='table-folders__item' key={i}>
                  {i}
                </td>
              ))}
              {deleteHandler && (
                <td className='table-folders__item'>Действия</td>
              )}
            </tr>
          </thead>
          <tbody>
            {tableBody.data
              ?.sort((a, b) => a.titleRu.localeCompare(b.titleRu))
              .map((i: any, index: number) => (
                <React.Fragment key={i.id}>
                  <tr className='table-folders__row'>
                    {/* <td className='table-folders__item table-folders__item-checkbox'>
                      <Checkbox
                        isChecked={checkedIds.some((ii) => ii === i.id)}
                        setChecked={(value: boolean) =>
                          setCheckedIds(() =>
                            value
                              ? [...checkedIds, i.id]
                              : checkedIds.filter((ii) => ii !== i.id)
                          )
                        }
                      />
                    </td> */}
                    {tableBody.keys.map((ii) => (
                      <td
                        className='table-folders__item'
                        key={ii}
                        onClick={() => updateItem(Number(i.id))}
                      >
                        {checkRow(ii, i[`${ii}`], i)}
                      </td>
                    ))}
                    {deleteHandler && (
                      <td className='table-folders__item table-folders__item-icon table-folders__item'>
                        <div className='table-folders__item-actions'>
                          <span
                            onClick={() => deleteHandler(i.id)}
                            className='table-folders__item-icon-block table-folders__item-icon-block--red'
                          >
                            <DeleteIcon />
                          </span>
                        </div>
                      </td>
                    )}
                  </tr>
                  {openedIds.some((k) => k === i.id) &&
                    !!i.items?.length &&
                    renderChild(i)}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
      {withPaginate && (
        <div className='page__pagination'>{/* <Pagination /> */}</div>
      )}
    </>
  );
};
