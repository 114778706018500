import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import { CreateProduct } from 'components/Modal/components/CreateProduct/CreateProduct';
import { Filter } from 'components/Filter/Filter';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  brandServices,
  jobsServices,
  productServices,
  shopServices,
} from 'api/services';
import { useParams } from 'react-router';
import { IResProduct, ProductSort } from 'api/services/products/types';
import { UpdateProduct } from 'components/Modal/components/UpdateProduct/UpdateProduct';
import { NotificationStatus } from 'store/ducks/notification/types';
import showNotification from 'helpers/showNotification';
import { AppState } from 'store/store';
import { IResCategory } from 'api/services/categories/types';
import { upperFirstChar } from 'helpers/upperFirstChar';
import _ from 'lodash';
import { onlyUnique } from 'helpers/onlyUnique';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { Settings } from 'components/Modal/components/Settings/Settings';
import * as settingActions from 'store/ducks/settings/actions';
import { ISetting } from 'components/Modal/components/Settings/types';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { UserRole } from 'enums/users';
import './ProductPage.sass';
import { isCreateProductUser, isEditUser } from 'helpers/checkUser';
import { IJobLog } from 'api/services/jobs/types';
import { Progress } from 'components/Modal/components/Progress/Progress';
import axios, { AxiosRequestConfig } from 'axios';
import fs from 'file-saver';
import qs from 'query-string';
import moment from 'moment';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { variantStatus } from 'enums/variant-status';
import { translateOptionStatus } from 'helpers/translate';

const tabs = [
  {
    label: 'Товар',
    index: 1,
  },
  {
    label: 'Опции',
    index: 2,
  },
  {
    label: 'Теги',
    index: 3,
  },
];

const variantStatuses = [{ label: 'Все', value: 'all' }, ...variantStatus];

export const ProductPage: React.FC = () => {
  const [createShowModal, setCreateShowModal] = useState(false);
  const [updateShowModal, setUpdateShowModal] = useState(false);
  const [productId, setProductId] = useState<number>();
  const [activeTab, setActiveTab] = useState(1);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState<IResProduct[]>([]);
  // const [selectedSort, setSelectedSort] = useState<ISelect>();
  // const [selectedSorting, setSelectedSorting] = useState<ISelect>();
  const [showSettings, setShowSettings] = useState(false);
  const [selectedShop, setSelectedShop] = useState<number>();
  const [shops, setShops] = useState<ISearchableSelect[]>([]);
  const [shopsLoading, setShopsLoading] = useState(false);
  const [searchableShopValue, setSearchableShopValue] = useState('');
  const [selectedBrand, setSelectedBrand] = useState<number>();
  const [brands, setBrands] = useState<ISearchableSelect[]>([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [searchableBrandValue, setSearchableBrandValue] = useState('');
  const { categoryId } = useParams<{ categoryId: string }>();
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState<IJobLog[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<ISelect>(
    variantStatuses[0]
  );
  const [breadCrumbCategories, setBreadCrumbCategories] = useState<
    IResCategory[]
  >([]);
  const { user } = useSelector((state: AppState) => state.profileReducer);
  const { categories } = useSelector(
    (state: AppState) => state.servicesReducer
  );
  const accessToken = localStorage.getItem('accessToken');

  const { key } = useSelector((state: AppState) => state.localeReducer);
  const { productsSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getShops();
    getBrands();
  }, []);

  useEffect(() => {
    const currentCategory = categories.find((i) => i.id === Number(categoryId));
    setBreadCrumbCategories([]);
    setPage(0);
    if (currentCategory) {
      if (!currentCategory.parentId) {
        setBreadCrumbCategories([currentCategory]);
        return;
      }
      checkParents(currentCategory);
    }
  }, [!!categories.length, categoryId]);

  useEffect(() => {
    let breadCrumbArr = _.clone(breadCrumbCategories).sort(
      (a, b) => a.id - b.id
    );

    if (breadCrumbArr[breadCrumbArr.length - 1]?.id > Number(categoryId))
      breadCrumbArr.splice(-1);

    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Каталог', id: 'catalog/categories' },
        ...breadCrumbArr.filter(onlyUnique).map((i) => ({
          title: upperFirstChar(i.titleRu),
          id: `catalog/categories/${i.id}/products`,
        })),
      ])
    );
  }, [JSON.stringify(breadCrumbCategories)]);

  const checkParents = (currentCategory: IResCategory) => {
    if (!currentCategory) return;
    categories.forEach((i) => {
      if (i.id === Number(categoryId)) {
        setBreadCrumbCategories((prev) => [...prev, i]);
      }
      if (i.id === currentCategory.parentId) {
        setBreadCrumbCategories((prev) => [...prev, i]);
        checkParents(i);
      }
    });
  };

  useEffect(() => {
    getData();
  }, [page, take, categoryId, searchValue]);

  const getShops = async (value?: string) => {
    if (!isEditUser(user?.role) && user?.role !== UserRole.SALES_MANAGER)
      return;
    try {
      const res = await shopServices
        .getShops({ skip: 0, take: 25, search: value })
        .then((res) => res.data);
      setShops(
        res.data.map((i) => ({
          name: i.name,
          value: i.id,
        }))
      );
    } finally {
      setShopsLoading(false);
    }
  };

  const getBrands = async (value?: string) => {
    if (!isEditUser(user?.role) && user?.role !== UserRole.SALES_MANAGER)
      return;
    try {
      const res = await brandServices
        .getBrands({ skip: 0, take: 25, search: value })
        .then((res) => res.data);
      setBrands(
        res.data.map((i) => ({
          name: i.name,
          value: i.id,
        }))
      );
    } finally {
      setBrandsLoading(false);
    }
  };
  const getData = async () => {
    const params = {
      skip: page * take,
      take: take,
      categoryId,
      search: searchValue,
      ...(selectedShop ? { shopId: selectedShop } : {}),
      ...(selectedBrand ? { brandIds: [selectedBrand] } : {}),
      ...(!selectedStatus.value && (selectedStatus as any) !== 'all'
        ? { variantStatus: selectedStatus as any }
        : {}),
    };
    const res = await productServices
      .getProducts(params)
      .then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
    setSelectedShop(undefined);
    setSearchableShopValue('');
  };

  const updateProduct = (id: number) => {
    setProductId(id);
    setUpdateShowModal(true);
  };

  const deleteProduct = async (id: number) => {
    try {
      await productServices.deleteProduct(id);
      getData();
      showNotification('Продукт удален', NotificationStatus.SUCCESS);
    } finally {
    }
  };

  const closeModal = () => {
    setActiveTab(1);
    setProductId(undefined);
    setCreateShowModal(false);
    setUpdateShowModal(false);
  };
  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setProductsSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getData();
    setSearchValue(value);
  };
  let timer: any = 0;

  const timerFiles = (id: number) => {
    timer = setInterval(async () => {
      try {
        const res = await jobsServices.getJob(id).then((res) => res.data);
        setProgress(res.progress);
        const logs = JSON.parse(res.logs)?.map((i: any) => ({
          col: i.column,
          row: i.row,
          code: i.errorCode,
          error: i.errorMessage,
        })) as IJobLog[];
        setErrors(logs);
        if (res.progress >= 100) {
          clearInterval(timer);
          getData();
          return;
        }
      } catch {
        clearInterval(timer);
      }
    }, 1000);
  };

  const importProduct = async (e: any) => {
    const file = e.path[0].files[0];
    const formData = new FormData();
    formData.append('file', file);
    const res = await productServices
      .importProduct(formData)
      .then((res) => res.data);
    setShowProgressModal(true);
    timerFiles(res.id);
  };

  const choiceFile = () => {
    const inputFile = document.createElement('input');
    inputFile.accept = '.xlsx';
    inputFile.type = 'file';
    inputFile.onchange = importProduct;
    inputFile.click();
  };

  const closeProgress = () => {
    setShowProgressModal(false);
    clearInterval(timer);
    setErrors([]);
    getData();
  };

  const exportProducts = async () => {
    if (!categoryId) return;
    const headers = {
      'Content-type': 'blob',
      common: {
        Authorization: `Bearer ${accessToken}`,
      },
    } as any;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${
        process.env.REACT_APP_BASE_URL
      }/api/v1/products/export?${qs.stringify({ categoryId: categoryId })}`,
      responseType: 'blob',
      headers,
    };

    const res = await axios(config).then((res) => res.data);
    const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
    fs.saveAs(blob, `Products.xlsx`);
  };

  return (
    <>
      {showProgressModal && (
        <Modal closeModal={closeProgress}>
          <Progress progress={progress} errors={errors} />
        </Modal>
      )}
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Товары'
            items={productsSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {createShowModal && (
        <Modal closeModal={closeModal}>
          <CreateProduct
            setActiveTab={setActiveTab}
            closeModal={closeModal}
            activeTab={activeTab}
            tabs={tabs}
            getData={getData}
          />
        </Modal>
      )}
      {updateShowModal && (
        <Modal closeModal={closeModal}>
          <UpdateProduct
            setActiveTab={setActiveTab}
            closeModal={closeModal}
            activeTab={activeTab}
            tabs={tabs}
            id={productId}
            getData={getData}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          >
            <div className='product-page__filter-inputs'>
              {(isEditUser(user?.role) ||
                user?.role === UserRole.SALES_MANAGER) && (
                <label className='product-page__filter-inputs-item'>
                  <MainSelect
                    items={variantStatuses.map((i) => ({
                      ...i,
                      ...(i.value !== 'all'
                        ? { label: translateOptionStatus(i.label as any) }
                        : {}),
                    }))}
                    selectItem={setSelectedStatus}
                    value={selectedStatus}
                  />
                  <span className='product-page__filter-inputs-item-label'>
                    Статус
                  </span>
                </label>
              )}
            </div>
            <div className='product-page__filter-inputs'>
              {(isEditUser(user?.role) ||
                user?.role === UserRole.SALES_MANAGER) && (
                <label className='product-page__filter-inputs-item'>
                  <CustomSearchableSelect
                    items={[{ name: 'Нет', value: null }, ...shops]}
                    setSelectItem={setSelectedShop}
                    value={selectedShop}
                    setLoading={setShopsLoading}
                    loading={shopsLoading}
                    setSearchValue={setSearchableShopValue}
                    searchValue={searchableShopValue}
                    setValue={getShops}
                    placeholder='Выбрать'
                    isWhite
                  />
                  <span className='product-page__filter-inputs-item-label'>
                    Магазин
                  </span>
                </label>
              )}
            </div>
            <div className='product-page__filter-inputs'>
              {(isEditUser(user?.role) ||
                user?.role === UserRole.SALES_MANAGER) && (
                <label className='product-page__filter-inputs-item'>
                  <CustomSearchableSelect
                    items={[{ name: 'Нет', value: null }, ...brands]}
                    setSelectItem={setSelectedBrand}
                    value={selectedBrand}
                    setLoading={setBrandsLoading}
                    loading={brandsLoading}
                    setSearchValue={setSearchableBrandValue}
                    searchValue={searchableBrandValue}
                    setValue={getBrands}
                    placeholder='Выбрать'
                    isWhite
                  />
                  <span className='product-page__filter-inputs-item-label'>
                    Бренд
                  </span>
                </label>
              )}
            </div>
          </ModalFilter>
        </Modal>
      )}
      <div className='page product-page'>
        <Filter
          createFunc={() => setCreateShowModal(true)}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          search={getData}
          settingFunc={() => setShowSettings(true)}
          hideButton={!isCreateProductUser(user?.role)}
          productImport={choiceFile}
          productExport={exportProducts}
        />
        <div className='page__main-content'>
          <Table
            tableHead={productsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            tableBody={{
              keys: productsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
              data,
            }}
            withPaginate
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            selectItem={updateProduct}
            deleteHandler={deleteProduct}
            page={page}
          />
        </div>
      </div>
    </>
  );
};
