import { AxiosPromise } from 'axios';
import { IResAttributeGroups } from '../attributesGroup/types';
import { IResCategory } from '../categories/types';

export interface IAttribute {
  titleRu: string;
  titleUz: string;
  categoryIds: number[];
  type: AttributeType;
  options?: IAttributeOption[];
  order: number;
  color?: string;
  required: boolean;
  attributeGroupId?: number;
  isOption: boolean;
  [key: string]: any;
}

export interface IResAttribute extends IAttribute {
  id: number;
  createdAt: Date;
  updateAt: Date;
  categories?: IResCategory[];
  groupId?: number;
  attributeGroup?: IResAttributeGroups;
}

export interface IGetAttributes {
  (attrs: {
    skip: number;
    take: number;
    categoryId?: number | string;
    size?: number;
  }): AxiosPromise<{
    count: number;
    data: IResAttribute[];
  }>;
}

export interface IGetAttributeById {
  (id: number): AxiosPromise<IResAttribute>;
}

export interface ICreateAttribute {
  (data: IAttribute): AxiosPromise;
}

export interface IUpdateAttribute {
  (id: number, data: IAttribute): AxiosPromise;
}

export enum AttributeType {
  TEXT = 'TEXT',
  LIST = 'LIST',
  NUMBER = 'NUMBER',
}

export interface IAttributeOption {
  id?: number;
  titleRu: string;
  titleUz: string;
  color?: string;
  [key: string]: any;
}

export enum AttributeViewType {
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  SWITCH = 'SWITCH',
  SELECT = 'SELECT',
  COLOR = 'COLOR',
}
