import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { Filter } from 'components/Filter/Filter';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { ISelect } from 'components/MainSelect/MainSelect';
import * as settingActions from 'store/ducks/settings/actions';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';

import './SupplementaryServicesPage.sass';
import { supplementaryServicesServices } from 'api/services';
import { IResSupplementaryService } from 'api/services/supplementaryServices/types';
import { CreateSupplementaryService } from 'components/Modal/components/CreateSupplementaryService/CreateSupplementaryService';
import { UpdateSupplementaryService } from 'components/Modal/components/UpdateSupplementaryService/UpdateSupplementaryService';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { UserRole } from 'enums/users';

export const SupplementaryServicesPage: React.FC = () => {
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [data, setData] = useState<IResSupplementaryService[]>([]);
  const [totalCount, setTotalcount] = useState(0);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [supplementaryServiceId, setSupplementaryServiceId] =
    useState<number>();
  const [loading, setLoading] = useState(false);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { supplementaryServicesSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );
  const [showSettings, setShowSettings] = useState(false);
  const { user } = useSelector((state: AppState) => state.profileReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Заказы', id: '' },
        { title: 'Услуги', id: 'supplementary-services' },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [page, searchValue]);

  const getData = async (value?: string) => {
    const attrs = {
      skip: page * take,
      take,
      search: searchValue || value || '',
    };
    const res = await supplementaryServicesServices
      .getSupplementaryServices(attrs)
      .then((res) => res.data);
    setData(res.data);
    setTotalcount(res.count);
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setSupplementaryServicesSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getData();
    setSearchValue(value);
  };

  const closeUpdateModal = () => {
    setSupplementaryServiceId(undefined);
    setShowUpdateModal(false);
  };

  const closeFilter = () => {
    setFilterShowModal(false);
  };

  const createSupplementaryService = () => {
    setShowCreateModal(false);
    getData();
  };

  const updateSupplementaryService = () => {
    closeUpdateModal();
    getData();
  };

  const openSupplementaryServices = (id: number) => {
    if (
      user?.role === UserRole.SHOP_EMPLOYEE ||
      user?.role === UserRole.SHOP_OWNER
    )
      return;
    setSupplementaryServiceId(id);
    setShowUpdateModal(true);
  };

  const deleteItem = async (id: number) => {
    try {
      await supplementaryServicesServices.deleteSupplementaryService(id);
      showNotification('Удалено', NotificationStatus.SUCCESS);
      getData();
    } catch {
      showNotification('Ошибка попробуйте позже');
    }
  };

  const IS_MERCHANT =
    user?.role === UserRole.SHOP_EMPLOYEE || user?.role === UserRole.SHOP_OWNER;

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Услуги'
            items={supplementaryServicesSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={() => setShowCreateModal(false)}>
          <CreateSupplementaryService
            submit={createSupplementaryService}
            closeModal={() => setShowCreateModal(false)}
            loading={loading}
          />
        </Modal>
      )}
      {showUpdateModal && supplementaryServiceId && (
        <Modal closeModal={closeUpdateModal}>
          <UpdateSupplementaryService
            submit={updateSupplementaryService}
            closeModal={closeUpdateModal}
            loading={loading}
            supplementaryServiceId={supplementaryServiceId}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='page'>
        <Filter
          createFunc={() => setShowCreateModal(true)}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          search={getData}
          hideButton={IS_MERCHANT}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            selectItem={openSupplementaryServices}
            tableBody={{
              data,
              keys: supplementaryServicesSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={supplementaryServicesSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            deleteHandler={deleteItem}
            page={page}
            onlySu
          />
        </div>
      </div>
    </>
  );
};
