import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { knowledgeBaseServices } from 'api/services';
import { IKnowledgeBase } from 'api/services/knowledge-base/types';
import { HtmlEditor, HtmlEditor2 } from 'components/Editor/HtmlEditor';
import { MainInput } from 'components/MainInput/MainInput';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { ActionButtons } from 'components/Modal/components/ActionButtons/ActionButtons';
import './UpdateKnowledgeBase.sass';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { RadioType } from 'enums/radioType';
import { InputType } from 'components/MainInput/types';
import { RadioInput } from 'components/RadioInput/RadioInput';
import { Checkbox } from 'components/Checkbox/Checkbox';

interface Props {
  submit: (data: IKnowledgeBase, id: number) => void;
  id?: number;
  closeModal: () => void;
  loading: boolean;
}

export const UpdateKnowledgeBase: React.FC<Props> = ({
  submit,
  id,
  closeModal,
  loading,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [video, setVideo] = useState('');
  const [descriptionRedactorRu, setDescriptionRedactorRu] = useState('');
  const [descriptionRedactorUz, setDescriptionRedactorUz] = useState('');
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [hasError, setHasError] = useState(false);
  const [descriptionType, setDescriptionType] = useState<RadioType>(
    RadioType.TEXT
  );

  const { lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    getDataById();
  }, [id]);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if (!id) return;
    if (!titleRu.trim() && !titleUz.trim()) {
      showNotification('Заполните название', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    if (!descriptionRedactorUz.trim() && !descriptionRedactorRu.trim()) {
      showNotification('Заполнте описание', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    submit(
      {
        titleRu,
        titleUz,
        video,
        descriptionRu: descriptionRedactorRu,
        descriptionUz: descriptionRedactorUz,
        isActive,
      },
      id
    );
  };

  const getDataById = async () => {
    if (!id) return;
    const res = await knowledgeBaseServices
      .getKnowledgeBaseById(id)
      .then((res) => res.data);
    setTitleRu(res.titleRu);
    setTitleUz(res.titleUz);
    setDescriptionRedactorRu(res.descriptionRu);
    setDescriptionRedactorUz(res.descriptionUz);
    setIsActive(res.isActive);
    setVideo(res.video);
  };

  return (
    <form onSubmit={formSubmit} className='update-knowledge-base '>
      <div className='update-knowledge-base__modal'>
        <div className='update-knowledge-base__tabs'>
          <div className='update-knowledge-base__tabs-item'>
            <span>Изменить базу знаний</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='update-knowledge-base__in'>
          <div className='update-knowledge-base__form'>
            <div className='create-knowledge-base__form-row'>
              <div className='create-knowledge-base__form-label'>Активный:</div>
              <Checkbox isChecked={isActive} setChecked={setIsActive} />
            </div>
            <label className='update-knowledge-base__form-row'>
              <div className='update-knowledge-base__form-label'>Название:</div>
              {lang === 'ru' ? (
                <MainInput updateInputValue={setTitleRu} inputValue={titleRu} />
              ) : (
                <MainInput updateInputValue={setTitleUz} inputValue={titleUz} />
              )}
            </label>
            <label className='update-knowledge-base__form-row'>
              <div className='update-knowledge-base__form-label'>Видео:</div>
              <MainInput updateInputValue={setVideo} inputValue={video} />
            </label>
            <div className='update-knowledge-base__form-description'>
              <div className='update-knowledge-base__form-label'>Описание</div>
              {lang === 'ru' ? (
                <HtmlEditor
                  value={descriptionRedactorRu}
                  onChange={setDescriptionRedactorRu}
                  error={hasError && !descriptionRedactorRu.trim()}
                />
              ) : (
                <HtmlEditor2
                  value={descriptionRedactorUz}
                  onChange={setDescriptionRedactorUz}
                  error={hasError && !descriptionRedactorUz.trim()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
    </form>
  );
};
