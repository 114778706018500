import { IData } from './types';

const data: IData = {
  ru: {
    general: {},
    components: {},
    pages: {},
  },
  uz: {
    general: {},
    components: {},
    pages: {},
  },
};

export default data;
