import {
  IChangeOrderStatus,
  ICreateOrder,
  IGetOrder,
  IGetOrderById,
  IUpdateOrder,
} from './types';
import qs from 'query-string';
import instance from 'api/instance';

export const getOrders: IGetOrder = (attrs) =>
  instance.get(`/orders?${qs.stringify(attrs, { skipEmptyString: true })}`);

export const getOrderById: IGetOrderById = (id) =>
  instance.get(`/orders/${id}`);

export const createOrder: ICreateOrder = (data) =>
  instance.post('/orders', data);

export const updateOrder: IUpdateOrder = (id, data) =>
  instance.put(`/orders/${id}`, data);

export const changeOrderStatus: IChangeOrderStatus = (id, data) =>
  instance.patch(`/orders/${id}/status`, data);

export const setManager = (orderId: number, id: number) =>
  instance.patch(`/orders/${orderId}/manager`, { userId: id });

export const payOrder = (orderId: number) =>
  instance.post(`/orders/${orderId}/pay`);

export const sendSmsOrder = (id: number, message: string) =>
  instance.post(`/orders/${id}/message`, { message });

export const deleteOrder = (id: number) => instance.delete(`/orders/${id}`);
