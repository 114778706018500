import React, { useEffect, useState } from 'react';
import { MainInput } from 'components/MainInput/MainInput';
import { InputType } from 'components/MainInput/types';
import { Table } from 'components/Table/Table';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { FileModal } from '../../FileModal/FileModal';
import { IResFile } from 'api/services/files/types';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { variantStatus, VariantStatusTypes } from 'enums/variant-status';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IResVariant, IVariant } from 'api/services/variants/types';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { FileList } from 'components/FileList/FileList';
import { IResAttribute } from 'api/services/attributes/types';
import DropDown from '../../DropDown/DropDown';
import { IVariantAttrubute } from 'api/services/variant-attributes/types';
import Field from 'components/Field/Field';
import { Price } from '../Price/Price';
import { translateOptionStatus } from 'helpers/translate';
import { UserRole } from 'enums/users';
import { isProductWhitoutShop } from 'helpers/checkUser';
import { checkAmount } from 'helpers/checkAmount';

import './Options.sass';

const tableHead = [
  'ID',
  'Название',
  'Цена (Приход)',
  'Цена (Розница)',
  'Дата изменения',
  'Статус',
  'Ед измерения',
];

const keys = [
  'id',
  'titleRu',
  'arrivalPrice',
  'retailPrice',
  'updatedAt',
  'statusTranslate',
  'arrivalPriceCurrencyId',
];

interface Props {
  productId?: string;
  attributes?: any;
  status: any;
  price?: string;
  titleRu?: string;
  titleUz?: string;
  fileId?: number;
  gallery: IResFile[];
  count: string;
  selectedFileUrl: string;
  selectedGalleryUrl: string[];
  numberAttrs: any;
  textAttrs: any;
  mainFile?: IResFile;
  setMainFile: (file?: IResFile) => void;
  setGallery: (file?: IResFile, files?: IResFile[]) => void;
  setSelectedFileUrl: (data: string) => void;
  setSelectedGalleryUrl: (data: string) => void;
  setTitleRu?: (data: string) => void;
  setTitleUz?: (data: string) => void;
  setProductId?: (data: string) => void;
  setAttributes: (data: IVariantAttrubute) => void;
  setStatus: (data: ISelect) => void;
  setPrice?: (data: string) => void;
  setCount: (count: string) => void;
  closeModal: () => void;
  createVariant: () => void;
  submit: () => void;
  variants?: IVariant[];
  attrs?: IResAttribute[];
  isUpdate?: boolean;
  setNumberAttrs: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number,
    type: 'NUMBER'
  ) => void;
  setTextAttrs: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number,
    type: 'TEXT'
  ) => void;
  radioHandler?: (e: boolean, data: IResVariant) => void;
  deleteHandler?: (id: number) => void;
  updateVariant?: () => void;
  arrivalPriceCurrencyId?: ISelect;
  setArrivalPriceCurrencyId: (value: ISelect) => void;
  dillerPriceCurrencyId?: ISelect;
  setDillerPriceCurrencyId: (value: ISelect) => void;
  retailPriceCurrencyId?: ISelect;
  setRetailPriceCurrencyId: (value: ISelect) => void;
  arrivalPrice: string;
  setArrivalPrice: (value: string) => void;
  dillerPrice: string;
  setDillerPrice: (value: string) => void;
  retailPrice: string;
  setRetailPrice: (value: string) => void;
  actions?: boolean;
  hideCheck?: boolean;
  hasError: boolean;
  setEditedOption?: (data: any) => void;
  setChecked?: (value: boolean) => void;
  checked?: boolean;
  updatedVariant?: boolean;
  notUpdated?: boolean;
}

export const Options: React.FC<Props> = ({
  attributes,
  status,
  price,
  titleRu,
  titleUz,
  fileId,
  gallery,
  count,
  selectedFileUrl,
  selectedGalleryUrl,
  numberAttrs,
  textAttrs,
  arrivalPriceCurrencyId,
  setArrivalPriceCurrencyId,
  dillerPriceCurrencyId,
  setDillerPriceCurrencyId,
  retailPriceCurrencyId,
  setRetailPriceCurrencyId,
  arrivalPrice,
  setArrivalPrice,
  dillerPrice,
  setDillerPrice,
  retailPrice,
  setRetailPrice,
  setCount,
  mainFile,
  setMainFile,
  setGallery,
  setSelectedFileUrl,
  setSelectedGalleryUrl,
  setTitleRu,
  setTitleUz,
  setProductId,
  setAttributes,
  setStatus,
  setPrice,
  closeModal,
  createVariant,
  setNumberAttrs,
  setTextAttrs,
  submit,
  attrs,
  variants,
  isUpdate,
  radioHandler,
  updateVariant,
  deleteHandler,
  actions,
  hideCheck,
  hasError,
  setEditedOption,
  checked,
  setChecked,
  updatedVariant,
  notUpdated,
}) => {
  const [showFileModal, setShowFileModal] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [variantAttributes, setVariantAttributes] = useState<any[]>([]);
  const { lang } = useSelector((state: AppState) => state.localeReducer);
  const { user } = useSelector((state: AppState) => state.profileReducer);

  useEffect(() => {
    if (updatedVariant) {
      setVariantAttributes([]);
    }
  }, [updatedVariant]);

  useEffect(() => {
    if (notUpdated) setVariantAttributes([]);
  }, [!!notUpdated]);

  useEffect(() => {
    if (!isProductWhitoutShop(user?.role))
      setStatus(variantStatus[0]?.value as any);
  }, []);

  const mainFileSelect = (file: IResFile) => {
    setMainFile(file);
    setShowFileModal(false);
  };

  const galleryFileSelect = (file: IResFile) => {
    if (!gallery.some((i) => i.id === file.id)) {
      setGallery(file);
    }
  };

  const someGalleryFileSelect = (files: IResFile[]) => {
    setGallery(undefined, files);
    setShowGalleryModal(false);
  };

  const radioHandle = (e: boolean, data: any) => {
    if (!setChecked) return;
    setChecked(e);
    radioHandler && radioHandler(e, data);
    setVariantAttributes(data.variantAttributes);
  };

  const numberAttrsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number
  ) => {
    setNumberAttrs(e, id, index, 'NUMBER');
  };

  const textAttrsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number
  ) => {
    setTextAttrs(e, id, index, 'TEXT');
  };

  const editVariant = (id: number) => {
    variants
      ?.find((i) => i.id === id)
      ?.attributes?.map((i) => {
        const foundAttr = attrs?.find((ii) => i.attributeId === ii.id);
        const foundOption = foundAttr?.options?.find(
          (ii) => ii.id === i.attributeOptionId
        );
        if (foundAttr && foundOption) {
          setVariantAttributes((prev) => [
            ...prev,
            { attributeId: foundAttr.id, attributeOption: foundOption },
          ]);
        }
        return i;
      });

    const requiredAttr = attrs?.filter((i) => i.required);
    let validAttrs = false;
    requiredAttr?.forEach((i) => {
      if (!attributes.some((ii: any) => ii.attributeId === i.id)) {
        validAttrs = true;
      }
    });

    const findObj = variants?.find((i) => i.id === id);
    if (!findObj || !setEditedOption) return;
    setEditedOption(findObj);
  };

  return (
    <>
      <div className='options'>
        <div className='options__modal'>
          <div className='options__list'>
            <div className='options__button-wrap'>
              {!isUpdate && (
                <button className='options__button' onClick={createVariant}>
                  Добавить
                </button>
              )}
              {updateVariant &&
                isUpdate &&
                user?.role !== UserRole.SALES_MANAGER && (
                  <button
                    className='options__button options__button--blue'
                    onClick={updateVariant}
                  >
                    Изменить
                  </button>
                )}
            </div>
            {variants && variants.length > 0 && (
              <div className='options__table'>
                <Table
                  tableBody={{
                    keys: keys,
                    data: variants.map((i) => ({
                      ...i,
                      statusTranslate: translateOptionStatus(i.status),
                    })),
                  }}
                  tableHead={tableHead}
                  radioHanler={!hideCheck ? radioHandle : undefined}
                  checked={checked}
                  deleteHandler={deleteHandler}
                  edit={setEditedOption ? editVariant : undefined}
                />
              </div>
            )}
          </div>
          <div className='options__form'>
            {lang === 'ru' ? (
              <label className='options__field'>
                <span>Название</span>
                <MainInput
                  inputValue={titleRu}
                  updateInputValue={setTitleRu}
                  error={hasError && !titleRu?.trim()}
                />
              </label>
            ) : (
              <label className='options__field'>
                <span>Название (узб)</span>
                <MainInput
                  inputValue={titleUz}
                  updateInputValue={setTitleUz}
                  error={hasError && !titleUz?.trim()}
                />
              </label>
            )}
            <label className='options__field'>
              <span>Статус</span>
              <MainSelect
                items={variantStatus.map((i) => ({
                  ...i,
                  label: translateOptionStatus(i.label as VariantStatusTypes),
                }))}
                selectItem={setStatus}
                value={
                  isProductWhitoutShop(user?.role) || !!status?.value
                    ? status
                    : variantStatus[0].value
                }
                isGray
                error={hasError && !status && !status?.value}
                disabled={!isProductWhitoutShop(user?.role)}
              />
            </label>
            {isProductWhitoutShop(user?.role) && !!price?.trim() && (
              <label className='options__field'>
                <span>Цена</span>
                <MainInput
                  inputValue={checkAmount(Number(price) * 100).toString()}
                  updateInputValue={setPrice}
                  type={InputType.NUMBER}
                  error={hasError && !price?.trim()}
                  disabled
                />
              </label>
            )}
            <Price
              arrivalPriceCurrencyId={arrivalPriceCurrencyId}
              setArrivalPriceCurrencyId={setArrivalPriceCurrencyId}
              dillerPriceCurrencyId={dillerPriceCurrencyId}
              setDillerPriceCurrencyId={setDillerPriceCurrencyId}
              retailPriceCurrencyId={retailPriceCurrencyId}
              setRetailPriceCurrencyId={setRetailPriceCurrencyId}
              arrivalPrice={arrivalPrice}
              setArrivalPrice={setArrivalPrice}
              dillerPrice={dillerPrice}
              setDillerPrice={setDillerPrice}
              retailPrice={retailPrice}
              setRetailPrice={setRetailPrice}
              hasError={hasError}
            />
            <label className='options__field'>
              <span>Количество</span>
              <MainInput
                inputValue={count}
                updateInputValue={setCount}
                error={hasError && !count.trim()}
                type={InputType.NUMBER}
              />
            </label>
            <div className='options__field options__field--full-width'>
              <span>Загрузить главное изображение:</span>
              <div className='options__row-item'>
                <div className='options__file'>
                  {!!mainFile ? (
                    <FileList
                      file={mainFile}
                      deleteFile={() => setSelectedFileUrl('')}
                    />
                  ) : (
                    <FileUploader
                      setFile={mainFileSelect}
                      error={hasError && !selectedFileUrl.length}
                    />
                  )}
                </div>
                <div
                  className='options__uploader'
                  onClick={() => setShowFileModal(true)}
                >
                  <span>
                    Выбрать из файла <FolderIcon />
                  </span>
                </div>
              </div>
            </div>
            <div className='options__field options__field--full-width'>
              <span>Загрузить изображения:</span>
              <div className='options__row-item'>
                <div className='options__file'>
                  {!!gallery.length ? (
                    <FileList
                      list={gallery}
                      deleteOneFile={(id: number) =>
                        setGallery(
                          undefined,
                          gallery.filter((i) => i.id !== id)
                        )
                      }
                    />
                  ) : (
                    <FileUploader
                      setFile={galleryFileSelect}
                      error={hasError && !gallery?.length}
                      isMultiple
                    />
                  )}
                </div>
                <div
                  className='options__uploader'
                  onClick={() => setShowGalleryModal(true)}
                >
                  <span>
                    Выбрать из файла <FolderIcon />
                  </span>
                </div>
              </div>
            </div>
            {attrs
              ?.filter((i) => i.type === 'LIST')
              .map((ii) => (
                <DropDown
                  variantAttributes={
                    variantAttributes ? variantAttributes : attributes
                  }
                  key={ii.id}
                  className='options__field'
                  onClick={setAttributes}
                  data={ii}
                  optionId={ii.attributeOptionId}
                  notUpdated={notUpdated}
                  error={
                    hasError &&
                    !attributes.some((iii: any) => iii.attributeId === ii.id) &&
                    ii.required
                  }
                />
              ))}
            {numberAttrs?.map((ii: any, index: number) => (
              <Field
                error={hasError && !ii.label?.trim() && ii.required}
                key={ii.id}
                className='options__field'
                value={ii.label}
                onChange={(e) => numberAttrsChange(e, ii.id, index)}
                type='number'
                label={ii.text}
              />
            ))}
            {textAttrs?.map((ii: any, index: number) =>
              lang === 'ru' ? (
                <Field
                  error={hasError && !ii.titleRu?.trim() && ii.required}
                  key={ii.id}
                  className='options__field'
                  value={ii.titleRu}
                  onChange={(e) => textAttrsChange(e, ii.id, index)}
                  type='text'
                  label={ii.text}
                />
              ) : (
                <Field
                  error={hasError && !ii.titleUz?.trim() && ii.required}
                  key={ii.id}
                  className='options__field'
                  value={ii.titleUz}
                  onChange={(e) => textAttrsChange(e, ii.id, index)}
                  type='text'
                  label={ii.text}
                />
              )
            )}
          </div>
        </div>
      </div>
      {/* {user?.role !== UserRole.SALES_MANAGER && (
        <ProductCustomButtons
          count={count}
          setCount={setCount}
          actions={actions}
          submit={submit}
          closeModal={closeModal}
          error={hasError}
        />
      )} */}
      {showFileModal && (
        <FileModal
          closeModal={() => setShowFileModal(false)}
          fileSelected={mainFileSelect}
        />
      )}
      {showGalleryModal && (
        <FileModal
          closeModal={() => setShowGalleryModal(false)}
          someFileSelect={someGalleryFileSelect}
        />
      )}
    </>
  );
};
