import instance from 'api/instance';
import { ICreateTag, IGetTag, IGetTagById, IUpdateTag } from './types';
import qs from 'query-string';

export const getTags: IGetTag = (attrs) =>
  instance.get(`/tags?${qs.stringify(attrs, { skipEmptyString: true })}`);

export const getTagById: IGetTagById = (id) => instance.get(`/tags/${id}`);

export const createTag: ICreateTag = (data) => instance.post('/tags', data);

export const updateTag: IUpdateTag = (id, data) =>
  instance.put(`/tags/${id}`, data);

export const deleteTag = (id: number) => instance.delete(`/tags/${id}`);
