import { postCategoryServices } from 'api/services';
import {
  IPostCategory,
  IResPostCategory,
} from 'api/services/postCategories/types';
import { Filter } from 'components/Filter/Filter';
import { CreatePostCategory } from 'components/Modal/components/CreatePostCategory/CreatePostCategory';
import { UpdatePostCategory } from 'components/Modal/components/UpdatePostCategory/UpdatePostCategory';
import { Modal } from 'components/Modal/Modal';
import { Table } from 'components/Table/Table';
import showNotification from 'helpers/showNotification';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as templateActions from 'store/ducks/template/actions';
import { NotificationStatus } from 'store/ducks/notification/types';
import './PostCategoriesPage.sass';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { MainSelect } from 'components/MainSelect/MainSelect';
import { Settings } from 'components/Modal/components/Settings/Settings';
import * as settingActions from 'store/ducks/settings/actions';
import { ISetting } from 'components/Modal/components/Settings/types';
import { AppState } from 'store/store';

const PostCategoriesPage: React.FC = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [data, setData] = useState<IResPostCategory[]>([]);
  const [id, setId] = useState<number>();
  const [showCreatePostCategoryModal, setShowCreatePostCategoryModal] =
    useState(false);
  const [showUpdatePostCategoryModal, setShowUpdatePostCategoryModal] =
    useState(false);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const { postCategoriesSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        {
          title: 'Статьи',
          id: 'posts/post-categories',
        },
        {
          title: 'Категории статей',
          id: 'posts/post-categories',
        },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [page, searchValue]);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        skip: take * page,
        take,
        search: searchValue,
      };
      const res = await postCategoryServices
        .getPostCategories(params)
        .then((res) => res.data);
      setData(res.data);
      setTotalCount(res.count);
    } finally {
      setLoading(false);
    }
  };

  const createPostCategoryHandler = async (data: IPostCategory) => {
    try {
      await postCategoryServices.createPostCategory(data);
      setShowCreatePostCategoryModal(false);
      showNotification('Готово', NotificationStatus.SUCCESS);
      getData();
    } catch {}
  };

  const updatePostCategoryHandler = async (data: IPostCategory) => {
    if (!id) return;
    let validation = true;

    if (!data.titleRu.trim() && !data.titleUz.trim()) {
      validation = false;
      showNotification('Название не заполнено');
    }

    if (!validation) {
      return;
    }

    try {
      await postCategoryServices.updatePostCategory(id, data);
      setShowUpdatePostCategoryModal(false);
      getData();
      showNotification('Готово', NotificationStatus.SUCCESS);
    } catch {
    } finally {
    }
  };

  const closeUpdatePostCategoryModal = () => {
    setId(undefined);
    setShowUpdatePostCategoryModal(false);
  };

  const openUpdateCategoryModal = (id: number) => {
    setId(id);
    setShowUpdatePostCategoryModal(true);
  };

  const deleteItem = async (id: number) => {
    try {
      await postCategoryServices.deletePostCategory(id);
      showNotification('Категория поста удалена', NotificationStatus.SUCCESS);
      getData();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setPostCategoriesSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getData();
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Категории постов'
            items={postCategoriesSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='post-categories-page page'>
        <Filter
          createFunc={() => setShowCreatePostCategoryModal(true)}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          search={getData}
          loading={loading}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='post-categories-page__in page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={totalCount}
            take={take}
            selectItem={openUpdateCategoryModal}
            tableBody={{
              data,
              keys: postCategoriesSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={postCategoriesSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            deleteHandler={deleteItem}
            page={page}
          />
        </div>
      </div>
      {showCreatePostCategoryModal && (
        <Modal closeModal={() => setShowCreatePostCategoryModal(false)}>
          <CreatePostCategory
            submit={createPostCategoryHandler}
            closeModal={() => setShowCreatePostCategoryModal(false)}
          />
        </Modal>
      )}
      {showUpdatePostCategoryModal && (
        <Modal closeModal={closeUpdatePostCategoryModal}>
          <UpdatePostCategory
            id={id}
            submit={updatePostCategoryHandler}
            closeModal={closeUpdatePostCategoryModal}
          />
        </Modal>
      )}
    </>
  );
};

export default PostCategoriesPage;
