import AppRoutes from 'AppRoutes/AppRoutes';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'store/store';
import App from './App';

import 'assets/styles/main.sass';

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App>
        <AppRoutes />
      </App>
    </Router>
  </Provider>,
  document.getElementById('root')
);
