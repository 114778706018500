import React from 'react';
import { CouponType } from 'api/services/coupons/types';
import { translateCouponType } from 'helpers/translate';
import cn from 'classnames';

import './Step1.sass';

interface IStep {
  value: CouponType;
  label: string;
}

const steps: IStep[] = [
  {
    value: CouponType.SIGN_UP,
    label: translateCouponType(CouponType.SIGN_UP),
  },
  {
    value: CouponType.FIRST_ORDER,
    label: translateCouponType(CouponType.FIRST_ORDER),
  },
  {
    value: CouponType.COMMENTS,
    label: translateCouponType(CouponType.COMMENTS),
  },
  {
    value: CouponType.ORDER_SUM,
    label: translateCouponType(CouponType.ORDER_SUM),
  },
  {
    value: CouponType.COUNT_ORDER_SUM,
    label: translateCouponType(CouponType.COUNT_ORDER_SUM),
  },
  {
    value: CouponType.FAVORITE_SHOPS,
    label: translateCouponType(CouponType.FAVORITE_SHOPS),
  },
  {
    value: CouponType.REFERRAL_LINK,
    label: translateCouponType(CouponType.REFERRAL_LINK),
  },
];

interface Props {
  selectItem: (value: CouponType) => void;
  selectedItem?: CouponType;
}

export const Step1: React.FC<Props> = ({ selectItem, selectedItem }) => {
  return (
    <div className='step-1'>
      <div className='step__title'>Шаг 1</div>
      <div className='step-1__choice-title'>Выбор действия:</div>
      <div className='step-1__actions'>
        {steps.map((i) => (
          <div
            className={cn('step-1__actions-item', {
              'step-1__actions-item--active': selectedItem === i.value,
            })}
            key={i.value}
            onClick={() => selectItem(i.value)}
          >
            {i.label}
          </div>
        ))}
      </div>
    </div>
  );
};
