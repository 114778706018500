import React, { useEffect } from 'react';
import { Template } from 'components/Template/Template';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import appConfig from 'config/appConfig';
import * as authActions from 'store/ducks/auth/actions';
import { Notification } from 'components/Notification/Notification';
import { useLocation } from 'react-router';
import socket from 'helpers/socket';

appConfig();

const App: React.FC = ({ children }) => {
  const accessToken = localStorage.getItem('accessToken');
  const { user } = useSelector((state: AppState) => state.profileReducer);
  const { isShow } = useSelector(
    (state: AppState) => state.notificationReducer
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!accessToken || user?.role === 'USER') {
      dispatch(authActions.signOut());
    }
  }, [user?.id]);

  useEffect(() => {
    if (!pathname.match('order')) {
      socket.disconnect();
    }
  }, [pathname]);

  return (
    <div className='app'>
      <Notification show={isShow} />
      {!!accessToken && user && user?.role !== 'USER' ? (
        <Template>{children}</Template>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default App;
