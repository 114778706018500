import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-icon-black.svg';
import { Widget } from 'components/Widget/Widget';
import cn from 'classnames';

import './HomePage.sass';
import { Modal } from 'components/Modal/Modal';
import { CreateWidget } from 'components/Modal/components/CreateWidget/CreateWidget';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import {
  commentServices,
  couponsServices,
  orderServices,
  productServices,
  promocodesServices,
  shopServices,
} from 'api/services';
import { useNavigate } from 'react-router';
import { IWidget, WidgetType } from 'store/ducks/widgets/types';
import * as widgetActions from 'store/ducks/widgets/actions';
import { ISelect } from 'components/MainSelect/MainSelect';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { Table } from 'components/Table/Table';
import { IResOrder } from 'api/services/orders/types';
import { translateCommentStatus, translatePayment } from 'helpers/translate';
import { IResComment } from 'api/services/comments/types';
import { IResCoupon } from 'api/services/coupons/types';
import { IResPromocode } from 'api/services/promocodes/types';
import { OrderStatus } from 'enums/orderStatus';

export const HomePage: React.FC = () => {
  const [activeOrders, setActiveOrders] = useState<IResOrder[]>([]);
  const [allOrders, setAllOrders] = useState<IResOrder[]>([]);
  const [postComments, setPostComments] = useState<IResComment[]>([]);
  const [productComments, setProductComments] = useState<IResComment[]>([]);
  const [promocodes, setPromocodes] = useState<IResPromocode[]>([]);
  const [reviews, setReviews] = useState<IResComment[]>([]);
  const [coupons, setCoupons] = useState<IResCoupon[]>([]);
  const history = useNavigate();
  const token = localStorage.getItem('accessToken');
  const { widgets } = useSelector((state: AppState) => state.widgetReducer);

  const {
    activeOrdersSettings,
    commentsSettings,
    productCommentsSettings,
    reviewsSettings,
    couponsSettings,
    promocodesSettings,
  } = useSelector((state: AppState) => state.settingsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) history('/auth/sign-in');
  }, []);

  useEffect(() => {
    getData();
  }, [JSON.stringify(widgets)]);

  const deleteWidget = (item: IWidget) => {
    const filteredWidgets = widgets.filter((i) => i.value !== item.value);
    localStorage.setItem('widgets', JSON.stringify(filteredWidgets));
    dispatch(widgetActions.getWidgets(filteredWidgets));
  };

  const getData = async () => {
    const attrs = {
      take: 25,
      skip: 0,
    };
    for (let i = 0; i <= widgets.length; i++) {
      switch (widgets[i]?.value) {
        case WidgetType.ACTIVE_ORDERS:
          const activeOrdersRes = await orderServices
            .getOrders({
              ...attrs,
              statuses: [
                OrderStatus.ACCEPTED,
                OrderStatus.ON_THE_WAY,
                OrderStatus.DELIVERED,
              ],
            })
            .then((res) => res.data);
          setActiveOrders(activeOrdersRes.data);
          break;
        case WidgetType.ORDERS:
          const allOrdersRes = await orderServices
            .getOrders(attrs)
            .then((res) => res.data);
          setAllOrders(allOrdersRes.data);
          break;
        case WidgetType.POST_COMMENTS:
          const postCommentsRes = await commentServices
            .getComments({ ...attrs, isArticle: true, isComment: true })
            .then((res) => res.data);
          setPostComments(postCommentsRes.data);
          break;
        case WidgetType.PRODUCT_COMMENTS:
          const productCommentsRes = await commentServices
            .getComments({ ...attrs, isComment: true, isProduct: true })
            .then((res) => res.data);
          setProductComments(productCommentsRes.data);
          break;
        case WidgetType.REVIEWS:
          const reviewsRes = await commentServices
            .getComments({ ...attrs, isProduct: true, isFeedback: true })
            .then((res) => res.data);
          setReviews(reviewsRes.data);
          break;
        case WidgetType.COUPONS:
          const couponsRes = await couponsServices
            .getCoupons(attrs)
            .then((res) => res.data);
          setCoupons(couponsRes.data);
          break;
        case WidgetType.PROMOCODES:
          const promocodesRes = await promocodesServices
            .getPromocodes(attrs)
            .then((res) => res.data);
          setPromocodes(promocodesRes.data);
          break;
        default:
          break;
      }
    }
  };

  const createWidget = async (widget: ISelect) => {
    if (widgets.some((i) => widget.value === i.value)) {
      showNotification('Виджет с таким разделом уже существует');
      return;
    }
    dispatch(widgetActions.createWidget(widget as IWidget));
    showNotification('Виджет создан', NotificationStatus.SUCCESS);
    setShowModal(false);
  };

  const checkWidgetType = (i: IWidget) => {
    switch (i.value) {
      case WidgetType.ACTIVE_ORDERS:
        return (
          <Widget
            bodyData={{
              data: activeOrders.map((i) => ({
                ...i,
                statusColor: i.status,
                client: i.user,
                paymentMethod: translatePayment(i.paymentType),
              })),
              keys: activeOrdersSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            headData={activeOrdersSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            item={i}
            url='/orders/active'
            deleteWidget={deleteWidget}
          />
        );
      case WidgetType.ORDERS:
        return (
          <Widget
            bodyData={{
              data: allOrders.map((i) => ({
                ...i,
                statusColor: i.status,
                client: i.user,
                paymentMethod: translatePayment(i.paymentType),
              })),
              keys: activeOrdersSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            headData={activeOrdersSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            item={i}
            url='/orders/all'
            deleteWidget={deleteWidget}
          />
        );
      case WidgetType.POST_COMMENTS:
        return (
          <Widget
            bodyData={{
              data: postComments.map((i) => ({
                ...i,
                status: translateCommentStatus(i.status),
                productOrArticle: i.article || i.product,
                userName: i.user,
              })),
              keys: commentsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            headData={commentsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            item={i}
            url='/moderation/post-comments'
            deleteWidget={deleteWidget}
          />
        );
      case WidgetType.PRODUCT_COMMENTS:
        return (
          <Widget
            bodyData={{
              data: productComments.map((i) => ({
                ...i,
                status: translateCommentStatus(i.status),
                productOrArticle: i.article || i.product,
                userName: i.user,
              })),
              keys: productCommentsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            headData={productCommentsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            item={i}
            url='/moderation/product-comments'
            deleteWidget={deleteWidget}
          />
        );
      case WidgetType.REVIEWS:
        return (
          <Widget
            bodyData={{
              data: reviews.map((i) => ({
                ...i,
                status: translateCommentStatus(i.status),
                productOrArticle: i.article || i.product,
                userName: i.user,
              })),
              keys: reviewsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            headData={reviewsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            item={i}
            url='/reviews'
            deleteWidget={deleteWidget}
          />
        );
      case WidgetType.COUPONS:
        return (
          <Widget
            bodyData={{
              data: coupons,
              keys: couponsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            headData={couponsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            item={i}
            url='/advertising/coupons'
            deleteWidget={deleteWidget}
          />
        );
      case WidgetType.PROMOCODES:
        return (
          <Widget
            bodyData={{
              data: promocodes,
              keys: promocodesSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            headData={promocodesSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            item={i}
            url='/advertising/promo-codes'
            deleteWidget={deleteWidget}
          />
        );
      default:
        return null;
    }
  };

  const [showModal, setShowModal] = useState(false);
  return (
    <div className='home-page'>
      {showModal && (
        <Modal closeModal={() => setShowModal(false)}>
          <CreateWidget
            submit={createWidget}
            closeModal={() => setShowModal(false)}
          />
        </Modal>
      )}
      <button
        className='home-page__add-widget'
        onClick={() => setShowModal(true)}
      >
        Создать виджет
        <PlusIcon />
      </button>
      <div
        className={cn('home-page__widgets', {
          'home-page__widgets--odd': widgets.length % 2 !== 0,
        })}
      >
        {widgets.map((i) => (
          <div className='home-page__widget' key={i.value}>
            {checkWidgetType(i)}
          </div>
        ))}
      </div>
    </div>
  );
};
