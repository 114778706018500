import { ISetting } from 'components/Modal/components/Settings/types';

export enum SettingType {
  SET_CATALOG_CATEGORIES_SETTINGS = '@settings/SET_CATALOG_CATEGORIES_SETTINGS',
  SET_PRODUCTS_SETTINGS = '@settings/SET_PRODUCTS_SETTINGS',
  SET_BRANDS_SETTINGS = '@settings/SET_BRANDS_SETTINGS',
  SET_STOCKS_SETTINGS = '@settings/SET_STOCKS_SETTINGS',
  SET_ATTRIBUTES_SETTINGS = '@settings/SET_ATTRIBUTES_SETTINGS',
  SET_ATTRIBUTE_GROUPS_SETTINGS = '@settings/SET_ATTRIBUTE_GROUPS_SETTINGS',
  SET_CURRENCIES_SETTINGS = '@settings/SET_CURRENCIES_SETTINGS',
  SET_ACTIVE_ORDERS_SETTINGS = '@settings/SET_ACTIVE_ORDERS_SETTINGS',
  SET_ALL_ORDERS_SETTINGS = '@settings/SET_ALL_ORDERS_SETTINGS',
  SET_SERVICES_SETTINGS = '@settings/SET_SERVICES_SETTINGS',
  SET_CASHBACK_SETTINGS = '@settings/SET_CASHBACK_SETTINGS',
  SET_USERS_SETTINGS = '@settings/SET_USERS_SETTINGS',
  SET_SHOP_USERS_SETTINGS = '@settings/SET_SHOP_USERS_SETTINGS',
  SET_POST_CATEGORIES_SETTINGS = '@settings/SET_POST_CATEGORIES_SETTINGS',
  SET_POSTS_SETTINGS = '@settings/SET_POSTS_SETTINGS',
  SET_SHOPS_SETTINGS = '@settings/SET_SHOPS_SETTINGS',
  SET_KNOWLEDGE_BASE_SETTINGS = '@settings/SET_KNOWLEDGE_BASE_SETTINGS',
  SET_REVIEWS_SETTINGS = '@settings/SET_REVIEWS_SETTINGS',
  SET_MERCHANT_REVIEWS_SETTINGS = '@settings/SET_MERCHANT_REVIEWS_SETTINGS',
  SET_COMMENTS_SETTINGS = '@settings/SET_COMMENTS_SETTINGS',
  SET_PRODUCT_COMMENTS_SETTINGS = '@settings/SET_PRODUCT_COMMENTS_SETTINGS',
  SET_TAGS_SETTINGS = '@settings/SET_TAGS_SETTINGS',
  SET_BANNERS_SETTINGS = '@settings/SET_BANNERS_SETTINGS',
  SET_STORY_GROUPS_SETTINGS = '@settings/SET_STORY_GROUPS_SETTINGS',
  SET_STORIES_SETTINGS = '@settings/SET_STORIES_SETTINGS',
  SET_COUPONS_SETTINGS = '@settings/SET_COUPONS_SETTINGS',
  SET_PROMO_CODES_SETTINGS = '@settings/SET_PROMO_CODES_SETTINGS',
  SET_DELIVERY_SETTINGS = '@settings/SET_DELIVERY_SETTINGS',
  SET_PRODUCT_COMMENT_SETTINGS = '@settings/SET_PRODUCT_COMMENT_SETTINGS',
  SET_SUPPLEMENTARY_SERVICES_SETTINGS = '@settings/SET_SUPPLEMENTARY_SERVICES_SETTINGS',
  SET_BALANCE_REPORT_SETTINGS = '@settings/SET_BALANCE_REPORT_SETTINGS',
  SET_SALES_REPORT_SETTINGS = '@settings/SET_SALES_REPORT_SETTINGS',
}

export interface IInitState {
  catalogCategoriesSettings: ISetting[];
  productsSettings: ISetting[];
  brandsSettings: ISetting[];
  stocksSettings: ISetting[];
  attributesSettings: ISetting[];
  attributeGroupsSettings: ISetting[];
  currenciesSettings: ISetting[];
  activeOrdersSettings: ISetting[];
  allOrdersSettings: ISetting[];
  servicesSettings: ISetting[];
  cashBackSettings: ISetting[];
  usersSettings: ISetting[];
  shopUsersSettings: ISetting[];
  postCategoriesSettings: ISetting[];
  postsSettings: ISetting[];
  shopsSettings: ISetting[];
  knowledgeBaseSettings: ISetting[];
  reviewsSettings: ISetting[];
  merchantReviewsSettings: ISetting[];
  commentsSettings: ISetting[];
  productCommentsSettings: ISetting[];
  tagsSettings: ISetting[];
  bannersSettings: ISetting[];
  storyGroupsSettings: ISetting[];
  storiesSettings: ISetting[];
  couponsSettings: ISetting[];
  promocodesSettings: ISetting[];
  deliverySettings: ISetting[];
  supplementaryServicesSettings: ISetting[];
  balanceReportSettings: ISetting[];
  salesReportSettings: ISetting[];
}
