export enum NotificationStatus {
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

export enum NotificationType {
  SHOW_NOTIFICATION = '@notification/SHOW_NOTIFICATION',
  HIDE_NOTIFICATION = '@notification/HIDE_NOTIFICATION',
}

export interface IShowNotification {
  type: NotificationType.SHOW_NOTIFICATION;
}

export interface IHideNotification {
  type: NotificationType.HIDE_NOTIFICATION;
}

export type NotificationActionTypes = IShowNotification | IHideNotification;

export interface IInitState {
  status: NotificationStatus;
  isShow: boolean;
  id: string | null;
  message: string;
}
