import { combineReducers } from 'redux';
import authReducer from './ducks/auth/reducers';
import templateReducer from './ducks/template/reducers';
import servicesReducer from './ducks/services/reducers';
import localeReducer from './ducks/locale/reducers';
import notificationReducer from './ducks/notification/reducers';
import profileReducer from './ducks/profile/reducers';
import categoryChildsReducer from './ducks/categoryChilds/reducers';
import settingsReducer from './ducks/settings/reducers';
import widgetReducer from './ducks/widgets/reducer';

export default combineReducers({
  authReducer,
  templateReducer,
  servicesReducer,
  localeReducer,
  notificationReducer,
  profileReducer,
  categoryChildsReducer,
  settingsReducer,
  widgetReducer,
});
