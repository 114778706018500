import instance from 'api/instance';
import { ISignIn, IConfirmEmail, IGenerateOtp } from './types';

export const signIn: ISignIn = (data) => instance.post('/auth/sign-in', data);

export const confirmEmail: IConfirmEmail = (data) =>
  instance.patch('/auth/confirm', data);

export const generateOtp: IGenerateOtp = (data) =>
  instance.post('/auth/otp', data);
