import React from 'react';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { useLocation } from 'react-router';
import cn from 'classnames';

import './Template.sass';
interface Props {}

export const Template: React.FC<Props> = ({ children }) => {
  const { breadCrumbs } = useSelector(
    (state: AppState) => state.templateReducer
  );

  const { pathname } = useLocation();

  return (
    <div className='template'>
      <div className='template__sidebar'>
        <Sidebar />
      </div>
      <div className='template__main'>
        {window.location.pathname === '/' ? (
          <div className='template__main-home-page'>{children}</div>
        ) : (
          <div className='template__main-pages'>
            {!!breadCrumbs?.length &&
              pathname !== '/about-company' &&
              pathname !== '/reports' && (
                <div className='template__bread-crumbs'>
                  <Breadcrumbs items={breadCrumbs} />
                </div>
              )}
            <div
              className={cn('template__main-in', {
                'template__main-in--report': pathname === '/reports',
                'template__main-in--about': pathname === '/about-company',
              })}
            >
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
