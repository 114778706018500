import { AnyTxtRecord } from 'dns';
import React from 'react';
import './RadioTable.sass';

interface Props {
  value: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioTable: React.FC<Props> = ({ value, onChange }) => {
  return (
    <label className='radio-table'>
      <input
        className='radio-table__input'
        type='radio'
        name='table'
        value={value}
        onChange={onChange}
      />
      <div className='radio-table__item'></div>
    </label>
  );
};

export default RadioTable;
