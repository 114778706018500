import { ISelect } from 'components/MainSelect/MainSelect';

export enum MarginType {
  INHERIT = 'INHERIT',
  PERCENT = 'PERCENT',
  FLAT = 'FLAT',
}

export const marginTypes: ISelect[] = [
  {
    label: 'Процент',
    value: MarginType.PERCENT,
  },
  {
    label: 'Фиксированная',
    value: MarginType.FLAT,
  },
  {
    label: 'Наследованная',
    value: MarginType.INHERIT,
  },
];
