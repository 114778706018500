export enum CategoryChildType {
  SET_CHILDS = '@categoryChilds/SET_CHILDS',
  REMOVE_CHILD = 'categoryChilds/REMOVE_CHILD',
}

export interface IGetCategoryChild {
  type: CategoryChildType.SET_CHILDS;
}

export interface IRemoveCategoryChild {
  type: CategoryChildType.REMOVE_CHILD;
}

export type CategoryChildActionTypes = IGetCategoryChild | IRemoveCategoryChild;
