import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, IHash } from 'components/Filter/Filter';
import { CommentStatus, IResComment } from 'api/services/comments/types';
import { commentServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import * as settingActions from 'store/ducks/settings/actions';
import { AppState } from 'store/store';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';
import { translateCommentStatus } from 'helpers/translate';
import { Rating } from 'components/Rating/Rating';
import { FILE_URL } from 'constants/project';
import { ReactComponent as UserIcon } from 'assets/images/icons/user-icon.svg';
import { ReactComponent as SendIcon } from 'assets/images/icons/send-icon.svg';
// import { ReactComponent as PlayIcon } from 'assets/images/icons/send-icon.svg';
import { ShowFile } from 'components/ShowFile/ShowFile';
import { IResFile } from 'api/services/files/types';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg';
import cn from 'classnames';
import _ from 'lodash';
import arrayToTree from 'array-to-tree';
import './MerchantReviewsPage.sass';

const hashes: IHash[] = [
  {
    title: translateCommentStatus(CommentStatus.NEW),
    value: CommentStatus.NEW,
  },
  {
    title: translateCommentStatus(CommentStatus.VERIFIED),
    value: CommentStatus.VERIFIED,
  },
];

interface ICommentWithItems extends IResComment {
  items?: IResComment[];
}

export const MerchantReviewsPage = () => {
  const [comments, setComments] = useState<IResComment[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState<IResComment>();
  const [selectedFile, setSelectedFile] = useState<IResFile>();
  const [comment, setComment] = useState('');
  const [answerComment, setAnswerComment] = useState<IResComment>();
  const [commentRequests, setCommentRequests] = useState<ICommentWithItems[]>(
    []
  );
  const { user } = useSelector((state: AppState) => state.profileReducer);
  const inputRef = useRef<any>();
  const dispatch = useDispatch();
  const { merchantReviewsSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  useEffect(() => {
    getComments();
  }, [page, searchValue]);

  const deleteComment = async (id: number) => {
    if (!id) return;

    await commentServices.deleteComment(id);
    showNotification('Отзыв удален', NotificationStatus.SUCCESS);
    getComments();
    setShowInfoModal(false);
  };

  useEffect(() => {
    if (!showInfoModal) setSelectedFile(undefined);
  }, [showInfoModal]);

  const getComments = async (value?: string | number, parentId?: number) => {
    const attrs = parentId
      ? {
          ...(!!parentId ? { parentId } : {}),
        }
      : {
          skip: page * take,
          take,
          status: value?.toString(),
          search: searchValue,
          isProduct: true,
          isFeedback: true,
        };
    const res = await commentServices
      .getComments(attrs)
      .then((res) => res.data);
    if (!!parentId) {
      getCommentWithChilds(res.data);
      return;
    }
    setTotalCount(res.count);
    setComments(res.data);
  };

  const getCommentWithChilds = (data: IResComment[]) => {
    data
      .filter((i) => i.status === CommentStatus.VERIFIED)
      .map((i) => {
        setCommentRequests((prev) => {
          const clone = [..._.clone(prev), i];
          return clone;
        });
        getComments(undefined, i.id);
      });
  };

  const confirmComment = async (id: number) => {
    if (selectedComment?.status === 'VERIFIED') {
      setSelectedComment(undefined);
      setShowInfoModal(false);
      return;
    }
    setSelectedFile(undefined);
    try {
      await commentServices.confirmComment(id);
      showNotification('Подтверждено', NotificationStatus.SUCCESS);
      getComments();
      setShowInfoModal(false);
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  const selectStatus = (value: string | number) => {
    setSearchValue('');
    getComments(value);
  };

  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setMerchantReviewsSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getComments();
    setSearchValue(value);
  };

  const getComment = async (id: number) => {
    const res = await commentServices
      .getCommentById(id)
      .then((res) => res.data);
    setSelectedComment(res);
    if (!res.productId) return;
    getComments(undefined, id);
  };

  const openInfoModal = async (id: number) => {
    setShowInfoModal(true);
    getComment(id);
  };

  const closeInfoModal = () => {
    setSelectedComment(undefined);
    setAnswerComment(undefined);
    setShowInfoModal(false);
    setCommentRequests([]);
  };

  const sendComment = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!comment.trim()) {
      showNotification('Заполните поле');
      return;
    }
    if (answerComment) {
      try {
        await commentServices.createComment({
          parentId: answerComment.id || undefined,
          productId: answerComment.productId || undefined,
          text: comment,
        });
        showNotification(
          'Ответ на комментарий будет опубликован после проверки модератора',
          NotificationStatus.SUCCESS
        );
        setCommentRequests([]);
        setComment('');
        setAnswerComment(undefined);
        getComments(undefined, selectedComment?.id);
      } catch {
        showNotification('Ошибка попробуйте позже');
      }
      return;
    }
    if (!selectedComment) return;
    try {
      await commentServices.createComment({
        parentId: selectedComment.id || undefined,
        productId: selectedComment.productId || undefined,
        text: comment,
      });
      showNotification(
        'Комментарий будет опубликован после проверки модератора',
        NotificationStatus.SUCCESS
      );
      setCommentRequests([]);
      getComment(selectedComment.id);
      setComment('');
    } catch {
      showNotification('Ошибка попробуйте позже');
    }
  };

  const selectAnswerComment = (item: IResComment) => {
    setAnswerComment(item);
    inputRef.current.focus();
  };

  const renderMessages = (items: ICommentWithItems[], isChild?: boolean) => {
    return (
      <div className='merchant-reviews-page__comments'>
        {arrayToTree(items, {
          parentProperty: 'parentId',
          customID: 'id',
          childrenProperty: 'items',
        }).map((i) => (
          <React.Fragment key={i.id}>
            <div
              className={cn('merchant-reviews-page__comments-item', {
                'merchant-reviews-page__comments-item--answer': isChild,
              })}
            >
              <div className='merchant-reviews-page__comments-text'>
                {i.text}
                <span
                  className='merchant-reviews-page__comments-text-answer'
                  onClick={() => selectAnswerComment(i)}
                >
                  Ответить
                </span>
              </div>
              {i.user?.file ? (
                <img
                  src={`${FILE_URL}/${i.user?.file?.userId}/${i.user?.file?.name}.${i.user?.file?.ext}`}
                  className='merchant-reviews-page__comments-ava'
                  alt=''
                />
              ) : (
                <div className='merchant-reviews-page__comments-ava'>
                  <UserIcon />
                </div>
              )}
            </div>
            {i.items?.length && renderMessages(i.items, true)}
          </React.Fragment>
        ))}
      </div>
    );
  };
  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Отзывы'
            items={merchantReviewsSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showInfoModal && selectedComment && (
        <Modal closeModal={closeInfoModal}>
          {selectedFile && (
            <ShowFile
              closeFile={() => setSelectedFile(undefined)}
              file={selectedFile}
            />
          )}
          <div className='merchant-reviews-page__info'>
            <div className='merchant-reviews-page__info-in'>
              <div className='merchant-reviews-page__rating'>
                <div className='merchant-reviews-page__title'>
                  Отзыв к товару:
                </div>
                <Rating
                  rating={selectedComment.evaluation}
                  className='merchant-reviews-page__rating-stars'
                />
              </div>
              <div className='merchant-reviews-page__product'>
                <img
                  src={`${FILE_URL}/${selectedComment.product?.file?.userId}/${selectedComment.product.file?.name}.${selectedComment.product?.file?.ext}`}
                  alt=''
                  className='merchant-reviews-page__product-image'
                />
                <div className='merchant-reviews-page__product-about'>
                  {selectedComment.product.titleRu}
                </div>
              </div>
              <div className='merchant-reviews-page__title'>Отзыв</div>
              <div className='merchant-reviews-page__post'>
                <div className='merchant-reviews-page__post-user'>
                  {selectedComment.user?.file ? (
                    <img
                      src={`${FILE_URL}/${selectedComment.user?.file?.userId}/${selectedComment.user?.file?.name}.${selectedComment.user?.file?.ext}`}
                      alt=''
                      className='merchant-reviews-page__post-user-photo'
                    />
                  ) : (
                    <UserIcon className='merchant-reviews-page__post-user-photo' />
                  )}
                  <div className='merchant-reviews-page__post-user-name'>
                    {selectedComment.user?.name ||
                      selectedComment.user?.email ||
                      selectedComment.user?.phone}
                  </div>
                </div>
                <div className='merchant-reviews-page__post-text'>
                  {selectedComment.text}
                </div>
                {!!selectedComment.gallery.length && (
                  <div className='merchant-reviews-page__post-gallery'>
                    {selectedComment.gallery.map((i) => (
                      <div
                        key={i.id}
                        className={cn(
                          'merchant-reviews-page__post-gallery-item',
                          {
                            'merchant-reviews-page__post-gallery-item--video':
                              i.type === 'VIDEO',
                          }
                        )}
                        onClick={() => setSelectedFile(i)}
                      >
                        {i.type === 'IMAGE' ? (
                          <img
                            src={`${FILE_URL}/${i.userId}/${i.name}.${i.ext}`}
                          />
                        ) : (
                          <video
                            src={`${FILE_URL}/${i.userId}/${i.name}.${i.ext}`}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {!!commentRequests.length && renderMessages(commentRequests)}
              {answerComment && (
                <div className='merchant-reviews-page__answer'>
                  <span></span>
                  {answerComment?.text}
                  <CloseIcon onClick={() => setAnswerComment(undefined)} />
                </div>
              )}
              <form
                onSubmit={sendComment}
                className={cn('merchant-reviews-page__message', {
                  'merchant-reviews-page__message--answer': !!answerComment,
                })}
              >
                <input
                  type='text'
                  placeholder={answerComment ? 'Ответить' : 'Комментировать'}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  ref={inputRef}
                />
                <button>
                  <SendIcon />
                </button>
              </form>
            </div>
            {/* {selectedComment.status !== 'VERIFIED' &&
              isEditUser(user?.role) && (
                <ActionButtons
                  closeModal={closeInfoModal}
                  deleteFunc={() => deleteComment(selectedComment.id)}
                  isConfirmed
                  submit={() => confirmComment(selectedComment.id)}
                  hideCancel
                />
              )} */}
          </div>
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          >
            {/* <label className='merchant-reviews-page__filter'>
              <Checkbox
                isChecked={isArticle}
                setChecked={(value) => checkFilter(true, value)}
              />
              <span>Посты</span>
            </label>
            <label className='merchant-reviews-page__filter'>
              <Checkbox
                isChecked={isProduct}
                setChecked={(value) => checkFilter(false, value)}
              />
              <span>Продукты</span>
            </label> */}
          </ModalFilter>
        </Modal>
      )}
      <div className='page merchant-reviews-page'>
        <Filter
          hashes={hashes}
          selectHash={selectStatus}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          search={getComments}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data: comments.map((i) => ({
                ...i,
                status: translateCommentStatus(i.status),
                productOrArticle: i.article || i.product,
                userName: i.user,
              })),
              keys: merchantReviewsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={merchantReviewsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            totalCount={totalCount}
            take={take}
            selectItem={openInfoModal}
            skipHandler={setPage}
            deleteHandler={deleteComment}
            confirm={confirmComment}
            page={page}
          />
        </div>
      </div>
    </>
  );
};
