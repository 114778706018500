import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { Filter } from 'components/Filter/Filter';
import { Coupon } from 'components/Modal/components/Coupon/Coupon';
import * as settingActions from 'store/ducks/settings/actions';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';
import { ICoupon, IResCoupon } from 'api/services/coupons/types';
import { couponsServices } from 'api/services';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { UpdateCoupon } from 'components/Modal/components/Coupon/UpdateCoupon/UpdateCoupon';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';

const tabs: IModalTab[] = [
  {
    label: 'Купоны',
    index: 1,
  },
];

export const CouponsPage = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [showSettings, setShowSettings] = useState(false);
  const [coupons, setCoupons] = useState<IResCoupon[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCoupon, setSelectedCoupon] = useState<IResCoupon>();
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);

  const { couponsSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Реклама', id: '' },
        { title: 'Купоны', id: 'advertising/coupons' },
      ])
    );
    getCoupons();
  }, []);

  const getCoupons = async () => {
    const attrs = {
      skip: page * take,
      take,
    };
    const res = await couponsServices.getCoupons(attrs).then((res) => res.data);

    setCoupons(res.data);
    setTotalCount(res.count);
  };

  useEffect(() => {
    if (!showModal) setActiveStep(1);
  }, [!showModal]);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setCouponsSettings(items));
    setShowSettings(false);
  };

  const selectCoupon = async (id: number) => {
    const res = await couponsServices.getCouponById(id).then((res) => res.data);
    setSelectedCoupon(res);
    setShowUpdateModal(true);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    setSearchValue(value);
  };

  const closeFilter = () => setFilterShowModal(false);

  const deleteCoupon = async (id: number) => {
    try {
      await couponsServices.deleteCoupon(id);
      showNotification('Удалено', NotificationStatus.SUCCESS);
      getCoupons();
    } catch {
      showNotification('Ошибка, попробуйте позже');
    }
  };

  const createCoupon = async (data?: ICoupon) => {
    if (activeStep !== 3) {
      setActiveStep((prev) => prev + 1);
      return;
    }
    if (data) {
      try {
        await couponsServices.createCoupon(data);
        showNotification('Купон создан успешно', NotificationStatus.SUCCESS);
        setShowModal(false);
        setActiveStep(1);
        getCoupons();
      } catch (e: any) {
        if (e.response.data.error !== 'Conflict') {
          showNotification('Произошла ошибка попробуйте позже');
        }
      }
    }
  };

  const updateCoupon = async (data?: ICoupon) => {
    if (activeStep !== 3) {
      setActiveStep((prev) => prev + 1);
      return;
    }
    if (data && selectedCoupon) {
      try {
        await couponsServices.updateCoupon(selectedCoupon.id, data);
        showNotification('Купон изменен', NotificationStatus.SUCCESS);
        setShowUpdateModal(false);
        setActiveStep(1);
        getCoupons();
      } catch (e: any) {
        if (e.response.data.error !== 'Conflict') {
          showNotification('Произошла ошибка попробуйте позже');
        }
      }
    }
  };

  const closeCouponModal = () => {
    setActiveStep(1);
    setShowModal(false);
    setShowUpdateModal(false);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Купоны'
            items={couponsSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showModal && (
        <Modal closeModal={closeCouponModal}>
          <Coupon
            setActiveTab={setActiveTab}
            tabs={tabs}
            activeTab={activeTab}
            submit={createCoupon}
            submitTitle={activeStep === 3 ? 'Завершить' : 'Следующий'}
            activeStep={activeStep}
            cancel={() => setActiveStep((prev) => prev - 1)}
          />
        </Modal>
      )}
      {showUpdateModal && selectedCoupon && (
        <Modal closeModal={closeCouponModal}>
          <UpdateCoupon
            setActiveTab={setActiveTab}
            tabs={tabs}
            activeTab={activeTab}
            submit={updateCoupon}
            submitTitle={activeStep === 3 ? 'Завершить' : 'Следующий'}
            activeStep={activeStep}
            cancel={() => setActiveStep((prev) => prev - 1)}
            coupon={selectedCoupon}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='page active-orders-page'>
        <Filter
          settingFunc={() => setShowSettings(true)}
          createFunc={() => setShowModal(true)}
          updateSearchValue={newFilterValue}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data: coupons,
              keys: couponsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={couponsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            totalCount={totalCount}
            page={page}
            deleteHandler={deleteCoupon}
            selectItem={selectCoupon}
            take={take}
            skipHandler={(page: number) => setPage(page)}
          />
        </div>
      </div>
    </>
  );
};
