import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-icon.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/minus-icon.svg';
import { ReactComponent as PlusBlackIcon } from 'assets/images/icons/plus-icon-black.svg';
import { INav, menu } from './navLinks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import folderIcon from 'assets/images/icons/folder-icon.png';
import { ReactComponent as GlassIcon } from 'assets/images/icons/glass-icon.svg';
import { categoryServices, fileServices } from 'api/services';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as categoryChildActions from 'store/ducks/categoryChilds/actions';
import * as serviceActions from 'store/ducks/services/actions';
import { AppState } from 'store/store';
import { FileType, IResFile } from 'api/services/files/types';

import { UserRole } from 'enums/users';
import './Nav.sass';

export const Nav: React.FC = () => {
  const [openChild, setOpenChild] = useState(false);
  const [openedChildIds, setOpenedChildIds] = useState<string[]>([]);
  const [openedParentId, setOpenedParentId] = useState<string>('');
  const [navMenu, setNavMenu] = useState<INav[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { pathname } = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { categoriesTree } = useSelector(
    (state: AppState) => state.servicesReducer
  );
  const [files, setFiles] = useState<IResFile[]>([]);
  const { user } = useSelector((state: AppState) => state.profileReducer);

  useEffect(() => {
    if (user?.role !== UserRole.COURIER) {
      if (!categoriesTree.length) dispatch(serviceActions.getCategories());
    }
    getData();
  }, [JSON.stringify(categoriesTree)]);

  useEffect(() => {
    if ((!pathname.match('catalog') && !navMenu.length) || pathname === '/') {
      getData();
    }
    if (pathname.match('moderation') || pathname.match('shops')) {
      getData();
    }
  }, [pathname]);

  useEffect(() => {
    getData();
  }, [categoriesTree.length, files.length, pathname]);

  const checkNavItems = (list: any[], id?: string) => {
    list.forEach((i) => {
      if (i.id !== Number(id) && !!i.items) {
        checkNavItems(i.items);
        return;
      }
      handleMenuChilds('' as any, i.id.toString(), !!i.items, i.parenLink);
    });
  };

  useEffect(() => {
    getFiles();
    if (pathname.match('/orders') && user?.role === UserRole.COURIER) {
      const ordersPage = menu[2];
      setOpenedParentId(ordersPage.id);
      handleMenu(ordersPage.id, !!ordersPage.items, ordersPage.isParentLink);
      return;
    }
  }, []);

  const getFiles = async () => {
    if (user?.role === UserRole.COURIER) return;
    const fileData = await fileServices
      .getFiles({ skip: 0, take: 50 })
      .then((res) => res.data);
    setFiles(
      fileData.data.map((i) => ({
        ...i,
        title: i.name,
        hasIcon: true,
        url: `/catalog/files/${i.id}`,
        isPic: true,
      }))
    );
  };

  const getData = () => {
    const clone = _.clone(menu);

    const result = clone.map((i) => {
      if (i.title === 'Каталог товаров') {
        i.items[0].items = categoriesTree;
        i.items[1].items = files.filter((i) => FileType.FOLDER === i.type);
      }
      return { ...i };
    });

    setNavMenu(result);
    if (pathname.match('/catalog/categories')) {
      const productsPage = result[1];
      const catalog = pathname.split('/');
      setOpenedParentId(productsPage.id);
      if (catalog[2] === 'categories' && Number(catalog[3])) {
      }
      if (!openedChildIds.length) {
        handleMenu(
          productsPage.id,
          !!productsPage.items,
          productsPage.isParentLink
        );
        handleMenuChilds(
          null,
          productsPage.items[0].id,
          productsPage.items[0].items
        );
      }
      // handleMenuChilds(null, i.id, productsPage.items);
      return;
    }
    if (pathname.match('/catalog/files')) {
      const productsPage = result[1];
      const catalog = pathname.split('/');
      setOpenedParentId(productsPage.id);
      if (catalog[2] === 'categories' && Number(catalog[3])) {
      }
      handleMenu(
        productsPage.id,
        !!productsPage.items,
        productsPage.isParentLink
      );
      handleMenuChilds(
        null,
        productsPage.items[1].id,
        productsPage.items[1].items
      );
      // handleMenuChilds(null, i.id, productsPage.items);
      return;
    }
    if (pathname.match('/catalog')) {
      const productsPage = result[1];
      const catalog = pathname.split('/');
      setOpenedParentId(productsPage.id);
      if (catalog[2] === 'categories' && Number(catalog[3])) {
      }
      handleMenu(
        productsPage.id,
        !!productsPage.items,
        productsPage.isParentLink
      );
      // handleMenuChilds(null, i.id, productsPage.items);
      return;
    }
    if (pathname.match('/orders')) {
      const ordersPage = result[2];
      setOpenedParentId(ordersPage.id);
      handleMenu(ordersPage.id, !!ordersPage.items, ordersPage.isParentLink);
      return;
    }
    if (pathname.match('/users')) {
      const usersPage =
        user?.role === UserRole.SHOP_EMPLOYEE ||
        user?.role === UserRole.SHOP_OWNER
          ? result[4]
          : result[3];
      setOpenedParentId(usersPage.id);
      handleMenu(usersPage.id, !!usersPage.items, usersPage.isParentLink);
      return;
    }
    if (pathname.match('/posts')) {
      const postsPage = result[5];
      setOpenedParentId(postsPage.id);
      handleMenu(postsPage.id, !!postsPage.items, postsPage.isParentLink);
      return;
    }
    if (pathname.match('/shops')) {
      const shopsPage = result[6];
      setOpenedParentId(shopsPage.id);
      handleMenu(shopsPage.id, !!shopsPage.items, shopsPage.isParentLink);
      return;
    }
    if (pathname.match('/moderation')) {
      const moderationPage = result[7];
      setOpenedParentId(moderationPage.id);
      handleMenu(
        moderationPage.id,
        !!moderationPage.items,
        moderationPage.isParentLink
      );
      return;
    }
    if (pathname.match('/reviews')) {
      const moderationPage = result[8];
      setOpenedParentId(moderationPage.id);
      handleMenu(
        moderationPage.id,
        !!moderationPage.items,
        moderationPage.isParentLink
      );
      return;
    }
    if (pathname.match('/services')) {
      const services = result[9];
      setOpenedParentId(services.id);
      handleMenu(services.id, !!services.items, services.isParentLink);
      return;
    }
    if (pathname.match('/about-company')) {
      const company = result[10];
      setOpenedParentId(company.id);
      handleMenu(company.id, !!company.items, company.isParentLink);
      return;
    }
    if (pathname.match('/advertising/banner')) {
      const advertisingPage = result[11];
      setOpenedParentId(advertisingPage.id);
      handleMenu(
        advertisingPage.id,
        !!advertisingPage.items,
        advertisingPage.isParentLink
      );
      handleMenuChilds(
        null,
        advertisingPage.items[0].id,
        true,
        advertisingPage.items[0].title
      );
      return;
    }
    if (pathname.match('/advertising/coupons')) {
      const advertisingPage = result[11];
      setOpenedParentId(advertisingPage.id);
      handleMenu(
        advertisingPage.id,
        !!advertisingPage.items,
        advertisingPage.isParentLink
      );
      return;
    }
    if (pathname.match('/advertising/promo-codes')) {
      const advertisingPage = result[11];
      setOpenedParentId(advertisingPage.id);
      handleMenu(
        advertisingPage.id,
        !!advertisingPage.items,
        advertisingPage.isParentLink
      );
      return;
    }
    if (pathname.match('/reports')) {
      const reportsPage = result[12];
      setOpenedParentId(reportsPage.id);
      handleMenu(reportsPage.id, !!reportsPage.items, reportsPage.isParentLink);
      handleMenuChilds(
        null,
        reportsPage.items[0].id,
        true,
        reportsPage.items[0].title
      );
      return;
    }
    setOpenedParentId(result[0].id);
  };

  const getCategories = async (e: React.FormEvent) => {
    e.preventDefault();
    if (user?.role === UserRole.COURIER) return;
    try {
      const res = await categoryServices
        .getCategories({ search: searchValue })
        .then((res) => res.data);

      const arrayWithIcon = res.map((i) => ({
        ...i,
        hasIcon: true,
        url: `/catalog/categories/${i.id}/products`,
      }));

      setNavMenu((prev) => {
        if (!openedChildIds.some((i) => i === prev[1].items[0].id)) {
          handleMenuChilds(null, prev[1].items[0].id, true, 'Каталог');
        }
        const clone = _.clone(prev);
        clone[1].items[0].items = [];
        return clone;
      });
      if (!arrayWithIcon.length) return;
      searchNavItem(categoriesTree, arrayWithIcon);
    } finally {
    }
    // setNavMenu((prev) => {
    //   const clone = _.clone(prev);
    //   clone[1].items = !!tree.length ? tree : arrayWithIcon;
    //   return clone;
    // });
  };

  const searchNavItem = (list: any[], items: any[]) => {
    list?.map((i) => {
      const findObj = items.find((ii) => ii.id === i.id);
      if (!!findObj) {
        setNavMenu((prev) => {
          const clone = _.clone(prev);
          clone[1].items[0].items.push(i);
          return clone;
        });
        return;
      }
      if (!!i.items?.length) searchNavItem(i.items as any, items);
    });
  };

  const handleMenu = (
    id: string,
    hasChild: boolean,
    isParentLink?: boolean,
    e?: React.MouseEvent,
    link?: string
  ) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (isParentLink && isParentLink) {
      const findLink = menu.find((i) => i.id === id);
      history(findLink?.url || '');
    }
    if (openedParentId !== id) {
      setOpenChild(true);
    }
    setOpenedChildIds([]);
    setOpenedParentId(id);

    if (!pathname.match('catalog') && !isParentLink && id === navMenu[1]?.id) {
      history('/catalog/categories');
      // handleMenuChilds(null, navMenu[1].items[0].id, true, 'Каталог');
      return;
    }
    if (!pathname.match('orders') && !isParentLink && id === navMenu[2]?.id) {
      if (
        user?.role !== UserRole.COURIER &&
        user?.role !== UserRole.SHOP_EMPLOYEE &&
        user?.role !== UserRole.SHOP_OWNER
      ) {
        history('/orders/active');
        return;
      }
      history('/orders/all');
      return;
    }
    if (!pathname.match('posts') && !isParentLink && id === navMenu[5]?.id) {
      history('/posts/categories');
      return;
    }
    if (
      !pathname.match('moderation') &&
      !isParentLink &&
      id === navMenu[7]?.id
    ) {
      history('/moderation/knowledge-base');
      return;
    }

    if (
      !pathname.match('advertising') &&
      !isParentLink &&
      id === navMenu[11]?.id
    ) {
      history('/advertising/banners/slider');
      // handleMenuChilds(
      //   null,
      //   navMenu[10].items[0].id,
      //   true,
      //   navMenu[10].items[0].title
      // );

      return;
    }
    if (!pathname.match('reports') && !isParentLink && id === navMenu[12]?.id) {
      history('/reports/balance');
      handleMenuChilds(
        null,
        navMenu[12].items[0].id,
        true,
        navMenu[12].items[0].title
      );

      return;
    }
  };

  const handleMenuChilds = (
    e: React.MouseEvent | null,
    id: string,
    hasChild: boolean,
    name?: string
  ) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (!hasChild) {
      setOpenChild(false);
      setOpenedChildIds([]);
      return;
    }
    if (openedChildIds.some((i) => i === id)) {
      setOpenedChildIds((prev) => {
        const result = _.clone(prev);
        const findIndex = result.findIndex((i) => i === id);
        result.splice(findIndex, result.length - findIndex);
        // return [...prev.filter((i) => i !== id)];
        return result;
      });
      if (name !== 'Каталог') {
        dispatch(categoryChildActions.removeCategoryChilds(Number(id)));
      }
      return;
    }
    setOpenedChildIds((prev) => [...prev, id]);
    if (name !== 'Каталог') {
      dispatch(categoryChildActions.setCategoryChilds(Number(id)));
    }
  };

  const renderCatalogMenu = (item: INav, isChild?: boolean): any => {
    return (
      <>
        {item?.items?.map(
          (i: any) =>
            (i.roles?.some((ii: any) => ii === user?.role) || !i.roles) && (
              <li
                className={cn('nav__item', { 'nav__child-item': isChild })}
                key={i.id}
              >
                <div className='nav__item-in'>
                  <div
                    className={cn('nav__item-handle', {
                      'nav__item-handle--invisible':
                        isChild && !i.items?.length,
                      'nav__item-handle--hide': !i.hasIcon,
                    })}
                    onClick={(e) =>
                      handleMenuChilds(e, i.id, !!i.items, i.title)
                    }
                  >
                    {openedChildIds.some((ii) => ii === i.id) ? (
                      <MinusIcon />
                    ) : (
                      <PlusIcon />
                    )}
                  </div>
                  <Link
                    to={i.url || ''}
                    className={cn('nav__item-container', {
                      'nav__item-container--active':
                        pathname === i?.url ||
                        pathname === `/catalog/${i.name}`,
                    })}
                  >
                    {isChild && i.hasIcon && (
                      <div className='nav__item-folder'>
                        <img src={folderIcon} alt='' />
                      </div>
                    )}
                    <span
                      className={cn('nav__item-title', {
                        'nav__item-title--active':
                          (pathname === `${i?.url}` ||
                            pathname === `/catalog/${i.name}`) &&
                          !i.hasIcon,
                      })}
                    >
                      {i.title || i.titleRu}
                    </span>
                  </Link>
                </div>
                {i.items && openedChildIds.some((ii) => ii === i.id) && (
                  <ul className='nav nav__child'>
                    {renderCatalogMenu(i, true)}
                  </ul>
                )}
              </li>
            )
        )}
      </>
    );
  };
  return (
    <ul className='nav'>
      {navMenu?.map(
        (i) =>
          (i.roles?.some((ii) => ii === user?.role) || !i.roles) && (
            <React.Fragment key={i.id}>
              <li
                className={cn('nav__item nav__item-main', {
                  'nav__item-main--links': i.isLinks && openedParentId === i.id,
                })}
                onClick={(e) =>
                  handleMenu(i.id, !!i.items, i.isParentLink, e, i.url)
                }
              >
                <div
                  className={cn('nav__item-menu', {
                    'nav__item-menu--active': openedParentId === i.id,
                  })}
                >
                  <span className='nav__item-title'>{i.title}</span>
                  {i.items && (
                    <PlusBlackIcon
                      className={cn('nav__item-open', {
                        'nav__item-open--active':
                          openedParentId === i.id && openChild,
                      })}
                      onClick={() => setOpenChild((prev) => !prev)}
                    />
                  )}
                </div>
                {i.items &&
                  openChild &&
                  openedParentId === i.id &&
                  i.isLinks && (
                    <ul className='nav__child-links'>
                      {renderCatalogMenu(i, true)}
                    </ul>
                  )}
              </li>
              {i.items && openChild && openedParentId === i.id && !i.isLinks && (
                <ul className={cn('nav__child nav__child-main')}>
                  <>
                    {i.title !== 'Реклама' && (
                      <form className='nav__search' onSubmit={getCategories}>
                        <input
                          type='text'
                          placeholder='Поиск по категориям...'
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <div
                          className='nav__search-button'
                          onClick={getCategories}
                        >
                          <GlassIcon />
                        </div>
                      </form>
                    )}

                    {renderCatalogMenu(i, true)}
                  </>
                </ul>
              )}
            </React.Fragment>
          )
      )}
    </ul>
  );
};
