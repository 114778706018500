import React from 'react';
import { MainInput } from 'components/MainInput/MainInput';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check-icon.svg';
import { InputType } from 'components/MainInput/types';
import { MarginType, marginTypes } from 'enums/marginType';
import { useState } from 'react';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { useEffect } from 'react';
import { IUser } from 'api/services/users/types';
import { checkPhone } from 'helpers/checkLogin';
import { UserRole, userRoles, UserSex, userSex } from 'enums/users';
import { MainButton } from 'components/MainButton/MainButton';
import _ from 'lodash';
import { IFileFolder } from 'api/services/files/types';

import './CreateFileCategoryFolder.sass';
import { ActionButtons } from '../ActionButtons/ActionButtons';

interface Props {
  submit: (data: IFileFolder) => void;
  closeModal: () => void;
}

export const CreateFileCategoryFolder: React.FC<Props> = ({
  submit,
  closeModal,
}) => {
  const [name, setName] = useState('');

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    submit({
      name,
    });
  };

  return (
    <form onSubmit={formSubmit} className='create-file-category-folder'>
      <div className='create-file-category-folder__container'>
        <div className='create-file-category-folder__tabs'>
          <div
            className={
              'create-file-category-folder__tabs-item create-file-category-folder__tabs-item--active'
            }
          >
            <span>Папка</span>
          </div>
        </div>
        <div className='create-file-category-folder__in'>
          <div className='create-file-category-folder__form'>
            <label className='create-file-category-folder__form-row'>
              <div className='create-file-category-folder__form-label'>
                Название:
              </div>
              <MainInput focused updateInputValue={setName} inputValue={name} />
            </label>
          </div>
        </div>
      </div>
      <ActionButtons formSubmit={formSubmit} closeModal={closeModal} />
    </form>
  );
};
