import { userServices } from 'api/services';
import { IResUser } from 'api/services/users/types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/store';
import { ProfileActionTypes, ProfileType } from './types';

export const getProfile =
  (user?: IResUser): ThunkAction<void, AppState, unknown, ProfileActionTypes> =>
  async (dispatch) => {
    try {
      const profile = user
        ? user
        : await userServices.getProfile().then((res) => res.data);
      dispatch({
        type: ProfileType.GET_PROFILE,
        user: profile,
      });
    } catch (e) {
      // console.log(e);
      // dispatch(signOut());
    }
  };

export const clearProfile = () => {
  return {
    type: ProfileType.CLEAR_PROFILE,
  };
};
