import { NotificationStatus } from 'store/ducks/notification/types';
import store from 'store/store';
import * as noticeActions from '../store/ducks/notification/actions';

export default (message: string, status?: NotificationStatus) => {
  store.dispatch(
    noticeActions.showNotification({
      message,
      status: status || NotificationStatus.DANGER,
    })
  );
};
