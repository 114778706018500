import {
  ICreateStoryGroup,
  IGetStoryGroup,
  IGetStoryGroupById,
  IUpdateStoryGroup,
} from './types';
import qs from 'query-string';
import instance from 'api/instance';

export const getStoryGroups: IGetStoryGroup = (attrs) =>
  instance.get(
    `/story-groups?${qs.stringify(attrs, { skipEmptyString: true })}`
  );

export const getStoryGroupById: IGetStoryGroupById = (id) =>
  instance.get(`/story-groups/${id}`);

export const createStoryGroup: ICreateStoryGroup = (data) =>
  instance.post(`/story-groups`, data);

export const updateStoryGroup: IUpdateStoryGroup = (id, data) =>
  instance.put(`/story-groups/${id}`, data);

export const deleteStoryGroup = (id: number) =>
  instance.delete(`/story-groups/${id}`);
