import React, { useEffect, useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { MainInput } from 'components/MainInput/MainInput';
import { InputType } from 'components/MainInput/types';
import { Switch } from 'components/Switch/Switch';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { ISelect } from 'components/MainSelect/MainSelect';
import { ActionButtons } from '../../ActionButtons/ActionButtons';
import { CustomDateTimePicker } from 'components/CustomDateTimePicker/CustomDateTimePicker';
import { useParams } from 'react-router';
import { IResStory, IStory } from 'api/services/stories/types';
import showNotification from 'helpers/showNotification';
import './UpdateStory.sass';
import { IResFile } from 'api/services/files/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

interface Props {
  submit: (data: IStory) => void;
  closeModal: () => void;
  story?: IResStory;
  loading: boolean;
}

export const UpdateStory: React.FC<Props> = ({
  submit,
  closeModal,
  story,
  loading,
}) => {
  const [desktopFile, setDesktopFile] = useState<IResFile>();
  const [mobileFile, setMobileFile] = useState<IResFile>();
  const [isMobile, setIsMobile] = useState(false);
  const [expireDate, setExpireDate] = useState<Date | undefined>(new Date());
  const [url, setUrl] = useState('');
  const [fullWidth, setFullWidth] = useState(false);
  const [x, setX] = useState('');
  const [y, setY] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [hasError, setHasEror] = useState(false);
  const { storyGroupId } = useParams<{ storyGroupId: string }>();

  const { lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    if (story) {
      setDesktopFile(story.file);
      setMobileFile(story.mobileFile);
      setFullWidth(story.fullWidth);
      setX(story.x.toString());
      setY(story.y.toString());
      setButtonText(story.buttonText || '');
      setUrl(story.url);
      setExpireDate(new Date(story.expiredAt));
      setButtonText(story.buttonText ? story.buttonText : '');
    }
  }, []);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if (!desktopFile) {
      showNotification('Выберите изображение');
      setHasEror(true);
      return;
    }
    if (!mobileFile) {
      showNotification('Выберите изображение для мобильной версии');
      setHasEror(true);
      return;
    }
    if (!expireDate || !x.trim() || !y.trim() || !storyGroupId) {
      showNotification('Заполните поля');
      setHasEror(true);
      return;
    }
    submit({
      fileId: desktopFile.id,
      mobileFileId: mobileFile.id,
      expiredAt: expireDate,
      storyGroupId: Number(storyGroupId),
      x: Number(x),
      y: Number(y),
      url,
      fullWidth,
      buttonText,
    });
  };

  return (
    <form onSubmit={formSubmit} className='upload-story'>
      <div className='upload-story__in'>
        <div className='upload-banner__row-top'>
          <Switch
            value={isMobile}
            onChange={(e) => setIsMobile(e.target.checked)}
            toggleTitle='Десктоп'
            toggleTitle2='Мобильный'
          />
        </div>
        <div className='upload-story__row'>
          <div className='upload-story__row-item'>
            <div className='upload-story__label'>Stories</div>
            <div className='upload-story__files'>
              <div className='upload-story__files-item'>
                {!isMobile ? (
                  <FileUploader
                    isCustom
                    deleteImg={() => setDesktopFile(undefined)}
                    img={desktopFile}
                    setFile={setDesktopFile}
                    error={hasError && !desktopFile}
                  />
                ) : (
                  <FileUploader
                    isCustom
                    setFile={setMobileFile}
                    deleteImg={() => setMobileFile(undefined)}
                    img={mobileFile}
                    error={hasError && !mobileFile}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='upload-story__row'>
          <div className='upload-story__row-item'>
            <div className='upload-story__label'>Время жизни</div>
            <div className='upload-story__row-container'>
              <CustomDateTimePicker date={expireDate} setDate={setExpireDate} />
            </div>
          </div>
        </div>
        <div className='upload-story__row'>
          <label className='upload-story__row-item'>
            <div className='upload-story__label'>URL</div>
            <MainInput
              inputValue={url}
              updateInputValue={setUrl}
              error={hasError && !url.trim()}
            />
          </label>
        </div>
        <div className='upload-story__row'>
          <label className='upload-story__row-item'>
            <div className='upload-story__label'>Название кнопки</div>
            <MainInput
              inputValue={buttonText}
              updateInputValue={setButtonText}
            />
          </label>
        </div>
        <div className='upload-story__row'>
          <label className='upload-story__row-item'>
            <div className='upload-story__label'>Полная ширина кнопки</div>
            <Checkbox isChecked={fullWidth} setChecked={setFullWidth} />
          </label>
        </div>
        <div className='upload-story__row'>
          <label className='upload-story__row-item'>
            <div className='upload-story__label'>Позиция кнопки</div>
            <div className='upload-story__row-container upload-story__row-container--last'>
              <MainInput
                type={InputType.NUMBER_WITH_ARROWS}
                placeholder='X'
                inputValue={x}
                updateInputValue={setX}
                error={hasError && !x.trim()}
              />
              <MainInput
                type={InputType.NUMBER_WITH_ARROWS}
                placeholder='Y'
                inputValue={y}
                updateInputValue={setY}
                error={hasError && !y.trim()}
              />
            </div>
          </label>
        </div>
      </div>
      <ActionButtons formSubmit={formSubmit} closeModal={closeModal} />
    </form>
  );
};
