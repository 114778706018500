import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { Filter } from 'components/Filter/Filter';
import { useLocation, useParams } from 'react-router';
import { Advertising } from 'components/Modal/components/Advertising/Advertising';
import { IBanner, IResBanner } from 'api/services/banners/types';
import { storyGroupsServices, storyServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { IResStory, IStory } from 'api/services/stories/types';
import { getStoryById } from 'api/services/stories/stories';
import { upperFirstChar } from 'helpers/upperFirstChar';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import * as settingActions from 'store/ducks/settings/actions';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';

export const StoriesPage = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<IResStory[]>([]);
  const { pathname } = useLocation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [story, setStory] = useState<IResStory>();
  const { storyGroupId } = useParams<{ storyGroupId: string }>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const { storiesSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  useEffect(() => {
    getData();
  }, [pathname]);

  const getData = async () => {
    const res = await storyServices
      .getStories(Number(storyGroupId))
      .then((res) => res.data);
    setData(res);
    if (!storyGroupId) return;
    const { titleRu } = await storyGroupsServices
      .getStoryGroupById(Number(storyGroupId))
      .then((res) => res.data);
    dispatch(
      templateActions.getBreadCrumbs([
        {
          title: 'Реклама',
          id: '',
        },
        {
          title: 'Баннеры',
          id: '',
        },
        {
          title: upperFirstChar(titleRu),
          id: `advertising/banners/story-groups`,
        },
        {
          title: 'Stories',
          id: 'advertising/banners/story',
        },
      ])
    );
  };

  const createStory = async (data: IStory) => {
    setSubmitLoading(true);
    try {
      await storyServices.createStory(data);
      showNotification('Готово', NotificationStatus.SUCCESS);
      getData();
      closeModal();
    } catch {
      showNotification('Ошибка, попробуйте позже');
    } finally {
      setSubmitLoading(false);
    }
  };

  const updateStory = async (data: IStory) => {
    if (!story) return;
    setSubmitLoading(true);
    try {
      await storyServices.updateStory(story?.id, data);
      showNotification('Готово', NotificationStatus.SUCCESS);
      getData();
      closeModal();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const closeModal = () => {
    setShowCreateModal(false);
    setShowUpdateModal(false);
    setStory(undefined);
  };

  const openUpdateModal = async (id: number) => {
    try {
      const res = await getStoryById(id).then((res) => res.data);
      setStory(res);
      setShowUpdateModal(true);
    } catch {
      showNotification('Не найдено');
    }
  };

  const deleteStory = async (id: number) => {
    try {
      await storyServices.deleteStory(id);
      showNotification('Удалено', NotificationStatus.SUCCESS);
      getData();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setStoriesSettings(items));
    setShowSettings(false);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Stories'
            items={storiesSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={closeModal}>
          <Advertising
            tabs={[
              {
                label: 'Загрузка Stories',
                index: 2,
              },
            ]}
            activeTab={2}
            setActiveTab={() => {}}
            submit={createStory}
            closeModal={closeModal}
            loading={submitLoading}
          />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal closeModal={closeModal}>
          <Advertising
            tabs={[
              {
                label: 'Изменить Stories',
                index: 4,
              },
            ]}
            activeTab={4}
            setActiveTab={() => {}}
            submit={updateStory}
            closeModal={closeModal}
            story={story}
            loading={submitLoading}
          />
        </Modal>
      )}
      {/* {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={setSearchValue}
            submit={getData}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )} */}
      <div className='page story-banners-page'>
        <Filter
          createFunc={() => setShowCreateModal(true)}
          // filterFunc={() => setFilterShowModal(true)}
          settingFunc={() => setShowSettings(true)}
          // updateSearchValue={setSearchFilterValue}
          searchValue={searchFilterValue}
          search={getData}
          loading={loading}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data,
              keys: storiesSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={storiesSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            deleteHandler={deleteStory}
            selectItem={openUpdateModal}
          />
        </div>
      </div>
    </>
  );
};
