export interface ITransaction {
  amount: string;
}

export interface IResTransaction extends ITransaction {
  createdAt: Date;
  updatedAt: Date;
  id: number;
  type: TransactionType;
  orderId: number;
  responsibleManagerId?: number;
  isCanceled: boolean;
}

export enum TransactionType {
  CASH = 'CASH',
  PAYME = 'PAYME',
  CLICK = 'CLICK',
}
