import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Template } from 'components/Template/Template';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { UpdateOrder } from 'components/Modal/components/UpdateOrder/UpdateOrder';
import { Filter } from 'components/Filter/Filter';
import { Files } from 'components/Files/Files';
import { ProductList } from 'components/ProductList/ProductList';
import { useLocation, useParams } from 'react-router';
import { fileServices } from 'api/services';
import { IFileFolder, IResFile } from 'api/services/files/types';
import { CreateFileCategoryFolder } from 'components/Modal/components/CreateFileCategoryFolder/CreateFileCategoryFolder';
import { IBreadCrumb } from 'store/ducks/template/types';

import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { Notification } from 'components/Notification/Notification';
import './FileProductsPage.sass';

export const FileProductsPage = () => {
  const [files, setFiles] = useState<IResFile[]>([]);
  const [checkedFiles, setCheckedFiles] = useState<number[]>([]);

  const { pathname } = useLocation();

  const { showModal } = useSelector((state: AppState) => state.templateReducer);
  const { folderId } = useParams<{ folderId: string }>();
  const [take, setTake] = useState(100);
  const [page, setPage] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [totalFileCount, setTotalFileCount] = useState(0);
  const [showCount, setShowCount] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(templateActions.haveModal(true));
    return () => {
      dispatch(templateActions.haveModal(false));
    };
  }, []);

  useEffect(() => {
    getBreadCrumbs();
    setPage(0);
  }, [folderId]);

  useEffect(() => {
    getFiles();
  }, [page, folderId, searchValue]);

  const getBreadCrumbs = async () => {
    if (!folderId) return;
    const folder = await fileServices
      .getFileById(folderId)
      .then((res) => res.data);
    let crumbs: IBreadCrumb[] = [];
    const checkParents = (item: IResFile) => {
      if (item.parent) {
        crumbs.unshift({ title: item.name, id: `catalog/files/${item.id}` });
        checkParents(item.parent);
      } else {
        crumbs.unshift({ title: item.name, id: `catalog/files/${item.id}` });
      }
    };
    checkParents(folder);
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Изображение', id: 'catalog/files' },
        ...crumbs,
      ])
    );
  };

  const uploadFile = async (files: IResFile[]) => {
    const formData = new FormData();
    const arrFiles: string[] = [];
    setTotalFileCount(files.length);
    setShowCount(true);
    try {
      for (let index in files) {
        try {
          setFileCount((prev) => prev + 1);
          if (arrFiles.some((i) => i === files[index].name)) {
            // showNotification(
            //   `Невозможно загрузить файл с именем ${files[index].name}, так как он уже был загружен`
            // );
          }
          formData.append('file', files[index] as any);
          if (folderId) formData.append('parentId', folderId);

          arrFiles.push(files[index].name);
          await fileServices.uploadFile(formData);
        } catch {
          // showNotification(`Не удалось загрузить ${arrFiles}`);
        } finally {
          formData.delete('file');
          formData.delete('parentId');
        }
      }
    } finally {
      setPage(0);
      setShowCount(false);
      setSearchValue('');
      setFileCount(0);
      setTotalFileCount(0);
      getFiles();
    }
  };

  const createFolder = async (data: IFileFolder) => {
    if (!folderId) return;
    try {
      await fileServices.createFolder({ ...data, parentId: Number(folderId) });
      getFiles();
    } finally {
      setSearchValue('');
      dispatch(templateActions.hideModal());
      setPage(0);
    }
  };

  const deleteItems = async () => {
    if (!checkedFiles.length) {
      showNotification(
        'Выберите файлы для удаления',
        NotificationStatus.WARNING
      );
      return;
    }
    try {
      await fileServices.deleteFiles({ fileIds: checkedFiles });
      showNotification('Удалено', NotificationStatus.WARNING);
      setFiles((prev) =>
        prev.filter((i) => !checkedFiles.some((ii) => ii === i.id))
      );
      setCheckedFiles([]);
    } catch {}
  };

  const openModal = (id: number) => {};

  const getFiles = async () => {
    if (!folderId) return;
    const res = await fileServices
      .getFiles({
        skip: take * page,
        take,
        parentId: folderId,
        search: searchValue,
      })
      .then((res) => res.data);
    if (page > 0) {
      setFiles((prev) => [...prev, ...res.data]);
      return;
    }
    setFiles(res.data);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getFiles();
    setSearchValue(value);
  };

  return (
    <>
      {showModal && (
        <Modal closeModal={() => dispatch(templateActions.hideModal())}>
          <CreateFileCategoryFolder
            closeModal={() => dispatch(templateActions.hideModal())}
            submit={createFolder}
          />
        </Modal>
      )}

      <Notification
        show={showCount}
        componentMessage={`Загружено ${fileCount} из ${totalFileCount}`}
        componentStatus
      />

      <div className='page file-page'>
        <Filter
          disabledButton={!checkedFiles.length}
          buttonActions={{
            deleteItems,
            createFolder: () => dispatch(templateActions.showModal()),
            uploadFile,
          }}
          updateSearchValue={newFilterValue}
          search={getFiles}
          hideFilter
        />
        <div className='page__main-content'>
          <Files
            items={files}
            setCheckedFiles={setCheckedFiles}
            checkedFiles={checkedFiles}
            setPage={setPage}
            page={page}
            isFull
          />
        </div>
      </div>
    </>
  );
};
