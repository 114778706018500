import {
  NotificationActionTypes,
  NotificationStatus,
  NotificationType,
} from './types';
import { v1 as uuidv1 } from 'uuid';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/store';

export const showNotification =
  ({
    message,
    status,
  }: {
    message: string;
    status: NotificationStatus;
  }): ThunkAction<void, AppState, unknown, NotificationActionTypes> =>
  (dispatch, getState) => {
    const id = uuidv1();
    dispatch({
      type: NotificationType.SHOW_NOTIFICATION,
      notification: {
        message,
        status,
        id: id,
      },
    });

    setTimeout(() => {
      if (id === getState().notificationReducer.id) {
        dispatch(hideNotice());
      }
    }, 3000);
  };

export const hideNotice = () => ({
  type: NotificationType.HIDE_NOTIFICATION,
});
