import React, { useState } from 'react';
import { MainInput } from 'components/MainInput/MainInput';
import { SearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { ActionButtons } from '../ActionButtons/ActionButtons';

import './CreateWidget.sass';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { IWidget, WidgetType } from 'store/ducks/widgets/types';
import { WIDGETS } from 'constants/project';
import showNotification from 'helpers/showNotification';

interface Props {
  closeModal: () => void;
  loading?: boolean;
  submit: (sectionValue: ISelect) => void;
}

export const CreateWidget: React.FC<Props> = ({ closeModal, submit }) => {
  const [section, setSection] = useState<ISelect>();
  const [sections, setSections] = useState<ISelect[]>(WIDGETS);

  const formSubmit = () => {
    if (!section) {
      showNotification('Выберите раздел');
      return;
    }
    submit({
      label: WIDGETS.find((i) => i.value === (section as any))?.label || '',
      value: section as any,
    });
  };

  const getSections = async (value?: string) => {};

  return (
    <div className='create-widget'>
      <div className='create-widget__modal'>
        <div className='create-widget__tabs'>
          <div className='create-widget__tabs-item'>
            <span>Виджет</span>
          </div>
        </div>
        <div className='create-widget__row'>
          <div className='create-widget__row-title'>Выберите раздел:</div>
          <div className='create-widget__row-input'>
            <MainSelect selectItem={setSection} items={sections} />
          </div>
        </div>
      </div>
      <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
    </div>
  );
};
