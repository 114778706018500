import instance from 'api/instance';
import {
  ICreateArticle,
  IGetArticle,
  IGetArticleById,
  IUpdateArticle,
} from './types';
import qs from 'query-string';

export const getArticles: IGetArticle = (attrs) =>
  instance.get(
    `/articles?${qs.stringify(attrs || {}, { skipEmptyString: true })}`
  );

export const getArticleById: IGetArticleById = (id) =>
  instance.get(`/articles/${id}`);

export const createArticle: ICreateArticle = (data) =>
  instance.post('/articles', data);

export const updateArticle: IUpdateArticle = (id, data) =>
  instance.put(`/articles/${id}`, data);

export const deleteArticle = (id: number) => instance.delete(`/articles/${id}`);
