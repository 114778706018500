import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { Filter } from 'components/Filter/Filter';
import { CreateUser } from 'components/Modal/components/CreateUser/CreateUser';
import { IResUser, IUser } from 'api/services/users/types';
import { shopServices, userServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { UpdateUser } from 'components/Modal/components/UpdateUser/UpdateUser';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { UserRole, userRoles } from 'enums/users';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import * as settingActions from 'store/ducks/settings/actions';

import './UsersShopPage.sass';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { translateUserRole } from 'helpers/translate';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';

export const UsersShopPage: React.FC = () => {
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [data, setData] = useState<IResUser[]>([]);
  const [totalCount, setTotalcount] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState<number>();
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRole, setSelectedRole] = useState<ISelect>();
  const [shops, setShops] = useState<ISearchableSelect[]>([]);
  const [selectedShopValue, setSelectedShopValue] = useState('');
  const [shopsLoading, setShopsLoading] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState<number>();
  const { usersSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );
  const [showSettings, setShowSettings] = useState(false);
  const { user } = useSelector((state: AppState) => state.profileReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Пользователи', id: '' },
        { title: 'Все пользователи', id: 'users' },
      ])
    );
    getShops();
  }, []);

  useEffect(() => {
    getData();
  }, [page, searchValue]);

  const createUserHandler = async (data: IUser) => {
    try {
      setLoading(true);
      await userServices.createUser(data);

      showNotification(
        'Пользователь создан успешно',
        NotificationStatus.SUCCESS
      );
      setShowCreateModal(false);
      getData();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        if (
          e.response.data.message?.[0] ===
          'each value in additionalPhone must match /^998\\d{9}$/ regular expression'
        ) {
          showNotification('Не корректный номер телефона');
          return;
        }
        showNotification('Ошибка попробуйте позже');
      }
    } finally {
      setLoading(false);
    }
  };

  const updateUserHandler = async (data: IUser) => {
    if (!userId) return;
    try {
      setLoading(true);
      await userServices.updateUser(userId, data);

      setShowUpdateModal(false);
      showNotification('Пользователь изменен', NotificationStatus.SUCCESS);
      getData();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        if (
          e.response.data.message?.[0] ===
          'each value in additionalPhone must match /^998\\d{9}$/ regular expression'
        ) {
          showNotification('Не корректный номер телефона');
          return;
        }
        showNotification('Ошибка попробуйте позже');
      }
    } finally {
      setLoading(false);
    }
  };

  const getData = async (value?: string) => {
    const params = {
      skip: take * page,
      take,
      ...(selectedRole ? { role: selectedRole as any } : {}),
      search: searchValue || value,
      ...(selectedShopId ? { shopId: selectedShopId } : {}),
    };
    const res = await userServices.getUsers(params).then((res) => res.data);
    setData(res.data);
    setTotalcount(res.count.toString());
  };

  const closeUpdateUserModal = () => {
    setUserId(undefined);
    setShowUpdateModal(false);
  };

  const openUpdateUserModal = (id: number) => {
    const foundUser = data.find((i) => i.id === id);
    if (user?.role === 'CONTENT_MANAGER' && foundUser?.role === 'ADMIN') {
      showNotification('У вас нет прав для изменения выбранного пользователя');
      return;
    }
    setUserId(id);
    setShowUpdateModal(true);
  };

  const deleteItem = async (id: number) => {
    try {
      await userServices.deleteUser(id);
      showNotification('Пользователь удален', NotificationStatus.SUCCESS);
      getData();
    } catch (e: any) {
      if (
        e.response.data.statusCode !== 409 &&
        e.response.data.statusCode !== 422
      ) {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  const closeFilter = () => {
    setFilterShowModal(false);
  };

  const getShops = async (value?: string) => {
    try {
      const res = await shopServices
        .getShops({ skip: 0, take: 25, search: value })
        .then((res) => res.data);
      setShops(res.data.map((i) => ({ name: i.name, value: i.id })));
    } finally {
      setShopsLoading(false);
    }
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setUsersSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getData();
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Пользователи'
            items={usersSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={() => setShowCreateModal(false)}>
          <CreateUser
            submit={createUserHandler}
            closeModal={() => setShowCreateModal(false)}
          />
        </Modal>
      )}

      {showUpdateModal && (
        <Modal closeModal={closeUpdateUserModal}>
          <UpdateUser
            userId={userId}
            submit={updateUserHandler}
            closeModal={() => setShowUpdateModal(false)}
          />
        </Modal>
      )}

      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          >
            <div className='users-shop-page__filter-inputs'>
              <label className='users-shop-page__filter-inputs-item'>
                <MainSelect
                  items={[
                    { label: 'Нет', value: null },
                    ...userRoles.map((i) => ({
                      ...i,
                      label: translateUserRole(i.label as UserRole),
                    })),
                  ]}
                  selectItem={setSelectedRole}
                  placeholder='Выбрать'
                  value={selectedRole}
                  withIcon
                />
                <span className='product-page__filter-inputs-item-label'>
                  Роль
                </span>
              </label>
              <label className='users-shop-page__filter-inputs-item'>
                <CustomSearchableSelect
                  items={[{ name: 'Нет', value: null }, ...shops]}
                  loading={shopsLoading}
                  setValue={getShops}
                  setLoading={setShopsLoading}
                  setSelectItem={setSelectedShopId}
                  searchValue={selectedShopValue}
                  setSearchValue={setSelectedShopValue}
                  isWhite
                  placeholder='Введите Магазин'
                />
                <span className='product-page__filter-inputs-item-label'>
                  Магазин
                </span>
              </label>
            </div>
          </ModalFilter>
        </Modal>
      )}
      <div className='page'>
        <Filter
          createFunc={() => setShowCreateModal(true)}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          search={getData}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            selectItem={openUpdateUserModal}
            tableBody={{
              data: data.map((i) => ({
                ...i,
                userStatus: i.isActive,
                phone: i.phone ? '+' + i.phone.replace('+', '') : '',
              })),
              keys: usersSettings
                .filter((i) => i.isActive)
                .map((i) => ({
                  ...i,
                  ...(i.value === 'isActive' ? { value: 'userStatus' } : {}),
                }))
                .map((i) => i.value),
            }}
            tableHead={usersSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            deleteHandler={deleteItem}
            page={page}
            onlySu
          />
        </div>
      </div>
    </>
  );
};
