import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Template } from 'components/Template/Template';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IModalTab } from 'components/Modal/types';
import { UpdateOrder } from 'components/Modal/components/UpdateOrder/UpdateOrder';
import { Filter } from 'components/Filter/Filter';
import * as settingAction from 'store/ducks/settings/actions';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';
import { IResOrder } from 'api/services/orders/types';
import { userServices } from 'api/services';
import { UserRole } from 'enums/users';

const tabs: IModalTab[] = [
  {
    label: 'Параметры услуг',
    index: 4,
  },
];

export const OrderCashbackPage = () => {
  const [activeTab, setActiveTab] = useState(4);
  const [selectedOrder, setSelectedOrder] = useState<IResOrder>();
  const [showModal, setShowModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [take, setTake] = useState(25);
  const [data, setData] = useState<any[]>([]);

  const [page, setPage] = useState(0);
  const { cashBackSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Заказы', id: '' },
        { title: 'Cashback', id: 'orders/cashback' },
      ])
    );
  }, []);

  useEffect(() => {
    getCashbacks();
  }, [page]);

  const getCashbacks = async () => {
    const attrs = {
      take,
      skip: page * take,
      role: UserRole.USER,
    };
    const res = await userServices.getUsers(attrs).then((res) => res.data);
    setTotalCount(res.count);
    setData(
      res.data.map((i) => ({
        id: i.id,
        sum: i.cashback,
        name: i.phone ? `+${i.phone}` : i.email ? i.email : '',
        userStatus: i.isActive,
      }))
    );
  };

  const submit = () => {};

  const openModal = (id: number) => {
    // setSelectedOrder(id);
    dispatch(templateActions.showModal());
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingAction.setCashbackSettings(items));
    setShowSettings(false);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Cashback'
            items={cashBackSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showModal && selectedOrder && (
        <Modal closeModal={() => setShowModal(false)}>
          <UpdateOrder
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            order={selectedOrder}
            closeModal={() => {}}
          />
        </Modal>
      )}
      <div className='page order-cashback-page'>
        <Filter settingFunc={() => setShowSettings(true)} />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data,
              keys: cashBackSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={cashBackSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            totalCount={totalCount}
            page={page}
            take={take}
            skipHandler={setPage}
          />
        </div>
      </div>
    </>
  );
};
