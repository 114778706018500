import React, { useEffect, useState } from 'react';
import { MainInput } from 'components/MainInput/MainInput';

import './Step3.sass';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { InputType } from 'components/MainInput/types';
import { CustomDateTimePicker } from 'components/CustomDateTimePicker/CustomDateTimePicker';

interface Props {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
  isPublic: boolean;
  setIsPublic: (value: boolean) => void;
  descriptionRu: string;
  setDescriptionRu: (value: string) => void;
  sum: string;
  setSum: (value: string) => void;
  limit: string;
  setLimit: (value: string) => void;
  name: string;
  setName: (value: string) => void;
  minOrderSum: string;
  setMinOrderSum: (value: string) => void;
  expireAt?: Date;
  setExpireAt: (value?: Date) => void;
  hasError: boolean;
}

export const Step3: React.FC<Props> = ({
  isActive,
  setIsActive,
  isPublic,
  setIsPublic,
  descriptionRu,
  setDescriptionRu,
  sum,
  setSum,
  limit,
  setLimit,
  name,
  setName,
  minOrderSum,
  setMinOrderSum,
  expireAt,
  setExpireAt,
  hasError,
}) => {
  return (
    <div className='step-3'>
      <div className='step__title'>Шаг 3</div>
      <div className='step-3__condition-title'>Данные для заполнения:</div>
      <div className='step-3__condition-form'>
        <div className='step-3__condition-row'>
          <label className='step-3__condition-row-container'>
            <div className='step-3__condition-row-title'>Название</div>
            <div className='step-3__condition-row-item'>
              <MainInput
                inputValue={name}
                updateInputValue={setName}
                error={!name.trim() && hasError}
              />
            </div>
          </label>
        </div>
        <div className='step-3__condition-row'>
          <label className='step-3__condition-row-container'>
            <div className='step-3__condition-row-title'>Описание</div>
            <div className='step-3__condition-row-item'>
              <MainInput
                inputValue={descriptionRu}
                updateInputValue={setDescriptionRu}
                error={!descriptionRu.trim() && hasError}
              />
            </div>
          </label>
        </div>
        <div className='step-3__condition-row'>
          <label className='step-3__condition-row-container'>
            <div className='step-3__condition-row-title'>Сумма купона</div>
            <div className='step-3__condition-row-item'>
              <MainInput
                inputValue={sum}
                updateInputValue={setSum}
                type={InputType.NUMBER}
                error={!sum.trim() && hasError}
              />
            </div>
          </label>
        </div>
        <div className='step-3__condition-row'>
          <label className='step-3__condition-row-container'>
            <div className='step-3__condition-row-title'>Срок действия</div>
            <div className='step-3__condition-row-item'>
              <CustomDateTimePicker
                onlyDate
                date={expireAt}
                setDate={setExpireAt}
              />
            </div>
          </label>
        </div>
        <div className='step-3__condition-row'>
          {/* <div className='step-3__condition-row-item'>
            <div className='step-3__condition-row-title'>
              % соотношение к цене
            </div>
            <MainInput />
          </div> */}
          <div className='step-3__condition-row-container'>
            <div className='step-3__condition-row-title'>
              Минимальная стоимость заказа
            </div>
            <div className='step-3__condition-row-item'>
              <MainInput
                inputValue={minOrderSum}
                updateInputValue={setMinOrderSum}
                type={InputType.NUMBER}
              />
            </div>
          </div>
        </div>
        {/* <div className='step-3__condition-row'>
          <label className='step-3__condition-row-container'>
            <div className='step-3__condition-row-title'>Лимит</div>
            <div className='step-3__condition-row-item'>
              <MainInput />
            </div>
          </label>
        </div> */}
        <div className='step-3__condition-row step-3__condition-row--with-checkbox'>
          <div className='step-3__condition-row-item'>
            <div className='step-3__condition-row-title'>
              Количество использование купона
            </div>
            <MainInput
              inputValue={limit}
              updateInputValue={setLimit}
              type={InputType.NUMBER}
            />
          </div>
          <div className='step-3__condition-row-item'>
            <label className='step-3__condition-row-checkbox'>
              <Checkbox isChecked={isActive} setChecked={setIsActive} />
              Активная
            </label>
            <label className='step-3__condition-row-checkbox'>
              <Checkbox isChecked={isPublic} setChecked={setIsPublic} />
              Публичная
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
