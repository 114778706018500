import { AxiosPromise } from 'axios';
import { IResBrand } from '../brands/types';
import { IResCategory } from '../categories/types';
import { IResShop } from '../shops/types';

export interface ICoupon {
  shopIds: number[];
  categoryIds: number[];
  brandIds: number[];
  name: string;
  isActive: boolean;
  isPublic: boolean;
  descriptionRu: string;
  descriptionUz?: string;
  sum: number;
  expireAt?: Date;
  minOrderSum: number;
  limit: number;
  count?: number;
  orderSum?: number;
  periodValue?: number;
  periodType?: PeriodType;
  type?: CouponType;
  usersCount?: number;
}

export interface IResCoupon extends ICoupon {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  shops: IResShop[];
  categories: IResCategory[];
  brands: IResBrand[];
}

export interface IGetCoupon {
  (attrs: { skip: number; take: number }): AxiosPromise<{
    data: IResCoupon[];
    count: number;
  }>;
}

export interface IGetCouponById {
  (id: number): AxiosPromise<IResCoupon>;
}

export interface ICreateCoupon {
  (data: ICoupon): AxiosPromise;
}

export interface IUpdateCoupon {
  (id: number, data: ICoupon): AxiosPromise;
}

export enum PeriodType {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export enum CouponType {
  SIGN_UP = 'SIGN_UP',
  FIRST_ORDER = 'FIRST_ORDER',
  COMMENTS = 'COMMENTS',
  ORDER_SUM = 'ORDER_SUM',
  COUNT_ORDER_SUM = 'COUNT_ORDER_SUM',
  FAVORITE_SHOPS = 'FAVORITE_SHOPS',
  REFERRAL_LINK = 'REFERRAL_LINK',
}
