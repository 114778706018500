import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { Filter } from 'components/Filter/Filter';
import { Stocks } from 'components/Modal/components/CreateProduct/Stocks/Stocks';
import * as settingActions from 'store/ducks/settings/actions';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';
import { IResStock } from 'api/services/stocks/types';
import { stocksServices } from 'api/services';
import { UpdateStock } from 'components/Modal/components/CreateProduct/UpdateStock/UpdateStock';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';

export const StocksPage = () => {
  const [stocks, setStocks] = useState<IResStock[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedStockId, setSelectedStockId] = useState<number>();
  const [showSettings, setShowSettings] = useState(false);

  const { stocksSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );
  const { user } = useSelector((state: AppState) => state.profileReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Каталог', id: '' },
        { title: 'Акции', id: 'catalog/stocks' },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    const attrs = {
      skip: page * take,
      take,
      ...(user?.shopId ? { shopId: user?.shopId } : {}),
    };
    const res = await stocksServices.getStocks(attrs).then((res) => res.data);
    setTotalCount(res.count);
    setStocks(res.data);
  };

  const createStock = () => {
    setShowCreateModal(false);
    getData();
  };
  const updateStock = () => {
    closeUpdateModal();
    getData();
  };

  const closeUpdateModal = () => {
    setSelectedStockId(undefined);
    setShowUpdateModal(false);
  };
  const deleteItem = async (id: number) => {
    try {
      await stocksServices.deleteStock(id);
      showNotification('Удалено успешно', NotificationStatus.SUCCESS);
      getData();
    } catch (e: any) {
      if (
        e.response.data.statusCode !== 422 &&
        e.response.data.statusCode !== 409
      ) {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    setSearchValue(value);
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setStocksSettings(items));
    setShowSettings(false);
  };

  const selectStock = (id: number) => {
    setShowUpdateModal(true);
    setSelectedStockId(id);
  };

  const closeFilter = () => setFilterShowModal(false);

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Товары'
            items={stocksSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={() => setShowCreateModal(false)}>
          <Stocks
            closeModal={() => setShowCreateModal(false)}
            submit={createStock}
          />
        </Modal>
      )}
      {showUpdateModal && selectedStockId && (
        <Modal closeModal={closeUpdateModal}>
          <UpdateStock
            closeModal={closeUpdateModal}
            submit={updateStock}
            stockId={selectedStockId}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='page stocks-page'>
        <Filter
          createFunc={() => setShowCreateModal(true)}
          settingFunc={() => setShowSettings(true)}
          updateSearchValue={newFilterValue}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              data: stocks,
              keys: stocksSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={stocksSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            deleteHandler={deleteItem}
            totalCount={totalCount}
            page={page}
            skipHandler={setPage}
            take={take}
            selectItem={selectStock}
          />
        </div>
      </div>
    </>
  );
};
