export const checkPhone = (value: string) => {
  const regPhone = /^998\d{9}/;
  return regPhone.test(value);
};

export const checkEmail = (value: string) => {
  const regEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return regEmail.test(value);
};
