import { Reducer } from 'redux';
import { IInitState, WidgetTypes } from './types';

const initState: IInitState = {
  widgets: [],
};

export const reducer: Reducer<IInitState> = (state = initState, action) => {
  const { type, widget, widgets } = action;
  switch (type) {
    case WidgetTypes.CREATE_WIDGET:
      return {
        ...state,
        widgets: [...state.widgets, widget],
      };
    case WidgetTypes.GET_WIDGETS:
      return {
        ...state,
        widgets,
      };
    default:
      return state;
  }
};

export default reducer;
