import { IWidget, WidgetTypes } from './types';

export const createWidget = (widget?: IWidget) => {
  const widgets = JSON.parse(localStorage.getItem('widgets') || '[]');
  widgets.push(widget);
  localStorage.setItem('widgets', JSON.stringify(widgets));
  return {
    type: WidgetTypes.CREATE_WIDGET,
    widget,
  };
};

export const getWidgets = (widgets?: IWidget[]) => {
  return {
    type: WidgetTypes.GET_WIDGETS,
    widgets,
  };
};
