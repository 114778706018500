import { IResOrder } from 'api/services/orders/types';
import moment from 'moment';
import React from 'react';

import './OrderItemCard.sass';

interface Props {
  order: IResOrder;
}

export const OrderItemCard: React.FC<Props> = ({ order }) => {
  return (
    <div className='order-item-card'>
      <div className='order-item-card__number'>Номера заказа № {order.id}</div>
      <div className='order-item-card__date'>
        {moment(order.createdAt).format('DD.MM.YYYY HH:mm:ss')}
      </div>
      <div className='order-item-card__in'>
        <div className='order-item-card__row'>
          <div className='order-item-card__label'>Имя и Фамилия:</div>
          <div className='order-item-card__value'>{order.user?.name}</div>
        </div>
        <div className='order-item-card__row'>
          <div className='order-item-card__label'>
            E-mail или номер телефона:
          </div>
          <div className='order-item-card__value'>
            {`+${order.user?.phone}` || order.user?.email}
          </div>
        </div>
        <div className='order-item-card__row'>
          <div className='order-item-card__label'>Ответственный</div>
          <div className='order-item-card__value'>
            {order.responsibleManager?.name || 'Не назначен'}
          </div>
        </div>
        <div className='order-item-card__row'>
          <div className='order-item-card__label'>Комментарий</div>
          <div className='order-item-card__value'>Нет</div>
        </div>
      </div>
    </div>
  );
};
