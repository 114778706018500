import { Reducer } from 'redux';
import { AppTypes, IState } from './types';
import data from './data';

const initialState = {
  lang: 'ru',
  data: data.ru,
  key: 'Ru',
};

const reducer: Reducer<IState> = (state = initialState, { type, lang }) => {
  switch (type) {
    case AppTypes.SET_LANG:
      return {
        ...state,
        lang: lang,
        data: data[lang],
        key: lang.charAt(0).toUpperCase() + lang.slice(1),
      };
    default:
      return state;
  }
};

export default reducer;
