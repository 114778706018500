import instance from 'api/instance';
import { IGetBalanceReport, IGetSalesReport } from './types';
import qs from 'query-string';

export const getBalanceReports: IGetBalanceReport = (attrs) =>
  instance.get(
    `/reports/balance?${qs.stringify(attrs, { skipEmptyString: true })}`
  );

export const getSalesReports: IGetSalesReport = (attrs) =>
  instance.get(
    `/reports/sales?${qs.stringify(attrs, { skipEmptyString: true })}`
  );

export const exportBalanceReports: IGetBalanceReport = (attrs) =>
  instance.get(
    `/reports/balance/export?${qs.stringify(attrs, { skipEmptyString: true })}`
  );

export const exportSalesReports: IGetSalesReport = (attrs) =>
  instance.get(
    `/reports/sales/export?${qs.stringify(attrs, { skipEmptyString: true })}`
  );
