import React, { useEffect, useState } from 'react';
import { IResTag } from 'api/services/tags/types';
import { ReactComponent as GlassIcon } from 'assets/images/icons/glass-icon.svg';
import { Tags } from 'components/Tags/Tags';
import { ActionButtons } from '../../ActionButtons/ActionButtons';

import './CreateProductTags.sass';
import { tagServices } from 'api/services';

interface Props {
  selectTag: (tag: IResTag[]) => void;
  selectedTags: IResTag[];
}
export const CreateProductTags: React.FC<Props> = ({
  selectTag,
  selectedTags,
}) => {
  const deleteTag = (id: number) => {
    selectTag(selectedTags.filter((i) => i.id !== id));
  };

  const choiceTag = (tag: IResTag) => {
    const tags = !selectedTags.some((i) => i.id === tag.id)
      ? [...selectedTags, tag]
      : selectedTags;
    selectTag(tags);
  };

  return (
    <div className='create-product-tags'>
      <div className='create-product-tags__in'>
        <div className='create-product-tags__title'>
          Показать теги на продукте
        </div>
        <Tags
          withCreate
          selectTags={choiceTag}
          selectedTags={selectedTags}
          deleteItem={deleteTag}
        />
      </div>
    </div>
  );
};
