import { CategoryChildActionTypes, CategoryChildType } from './types';
import { AppState } from 'store/store';
import { ThunkAction } from 'redux-thunk';

export const setCategoryChilds =
  (
    id: number
  ): ThunkAction<void, AppState, unknown, CategoryChildActionTypes> =>
  (dispatch, getState) => {
    const { ids } = getState().categoryChildsReducer;
    if (isNaN(id)) return;
    const result = [...ids, id];
    localStorage.setItem('ids', JSON.stringify(result));
    dispatch({
      type: CategoryChildType.SET_CHILDS,
      ids: result,
    });
  };

export const removeCategoryChilds = (id: number) => {
  return {
    type: CategoryChildType.REMOVE_CHILD,
    id,
  };
};
