import instance from 'api/instance';
import { ThunkAction } from 'redux-thunk';
import store, { AppState } from 'store/store';
import { AuthTypes, AuthActionTypes } from './types';
import * as profileActions from '../profile/actions';

export const signIn = (token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return {
    type: AuthTypes.SIGN_IN,
    token,
  };
};

export const signOut =
  (): ThunkAction<void, AppState, unknown, AuthActionTypes> => (dispatch) => {
    localStorage.removeItem('accessToken');
    dispatch({
      type: AuthTypes.SIGN_OUT,
      token: undefined,
    });
    dispatch(profileActions.clearProfile() as any);
  };
