import instance from 'api/instance';
import {
  IGetCurrencies,
  ICreateCurrency,
  IUpdateCurrency,
  IGetCurrencyById,
} from './types';

export const getCurrencies: IGetCurrencies = () => instance.get('/currencies');

export const createCurrency: ICreateCurrency = (data) =>
  instance.post('/currencies', data);

export const updateCurrency: IUpdateCurrency = (id, data) =>
  instance.put(`/currencies/${id}`, data);

export const getCurrencyById: IGetCurrencyById = (id) =>
  instance.get(`/currencies/${id}`);

export const deleteCurrency = (id: number) =>
  instance.delete(`/currencies/${id}`);
