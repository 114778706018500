import React, { useState } from 'react';
import cn from 'classnames';
import { IModalTab } from 'components/Modal/types';

import './Advertising.sass';
import { UploadBanner } from './UploadBanner/UploadBanner';
import { MainButton } from 'components/MainButton/MainButton';
import { UploadStory } from './UploadStory/UploadStory';
import { Promocode } from './Promocode/Promocode';
import { IResBanner } from 'api/services/banners/types';
import { UpdateStory } from './UpdateStory/UpdateStory';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { IResStory } from 'api/services/stories/types';
import { UpdateBanner } from './UpdateBanner/UpdateBanner';
import { UpdatePromocode } from './UpdatePromocode/UpdatePromocode';
import { IResPromocode } from 'api/services/promocodes/types';

interface Props {
  closeModal: () => void;
  setActiveTab: (tab: number) => void;
  activeTab: number;
  tabs: IModalTab[];
  submit: (data: any) => void;
  banner?: IResBanner;
  story?: IResStory;
  loading: boolean;
  promocode?: IResPromocode;
}

export const Advertising: React.FC<Props> = ({
  setActiveTab,
  activeTab,
  tabs,
  submit,
  closeModal,
  banner,
  story,
  loading,
  promocode,
}) => {
  const checkTab = () => {
    switch (activeTab) {
      case 2:
        return (
          <UploadStory
            submit={submit}
            closeModal={closeModal}
            loading={loading}
          />
        );
      case 3:
        return <Promocode submit={submit} closeModal={closeModal} />;
      case 4:
        return (
          <UpdateStory
            submit={submit}
            closeModal={closeModal}
            story={story}
            loading={loading}
          />
        );
      case 5:
        return (
          <UpdateBanner
            submit={submit}
            closeModal={closeModal}
            banner={banner}
          />
        );
      case 6:
        return (
          <UpdatePromocode
            submit={submit}
            closeModal={closeModal}
            promocode={promocode}
          />
        );
      default:
        return <UploadBanner submit={submit} closeModal={closeModal} />;
    }
  };

  return (
    <div className='advertising'>
      <div className='advertising__tabs'>
        {tabs.map((i) => (
          <div
            className={cn('advertising__tabs-item', {
              'advertising__tabs-item--active':
                activeTab === i.index || activeTab === 5,
            })}
            key={i.index}
            onClick={() => setActiveTab(i.index)}
          >
            <span>{i.label}</span>
          </div>
        ))}
        <ChoiceLang />
      </div>
      <div className='advertising__in'>{checkTab()}</div>
    </div>
  );
};
