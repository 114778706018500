import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { ReactComponent as NextIcon } from 'assets/images/icons/next-icon.svg';
import './Pagination.sass';
import { OptionProps } from 'react-select';

export interface Props {
  totalCount?: number;
  take?: number;
  skipHandler?: (skip: number) => void;
  page: number;
}

export const Pagination: React.FC<Props> = ({
  totalCount,
  take,
  skipHandler,
  page,
}) => {
  const handlePage = ({ selected }: { selected: number }) => {
    if (page === selected) return;
    if (skipHandler) skipHandler(selected);
  };
  return (
    <div className='pagination'>
      {!!totalCount && !!take && (
        <>
          <ReactPaginate
            breakLabel='...'
            className='pagination__list'
            nextLabel={<NextIcon />}
            onPageChange={handlePage}
            forcePage={page}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={Math.ceil(totalCount / take)}
            previousLabel={<NextIcon />}
          />
          <div
            className='pagination__last-page'
            onClick={() =>
              handlePage({ selected: Math.ceil(totalCount / take) - 1 })
            }
          >
            На последнюю <br />
            страницу
          </div>
        </>
      )}
    </div>
  );
};
