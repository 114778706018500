import React, { useState } from 'react';

import './Checkbox.sass';

interface Props {
  isChecked?: boolean;
  setChecked?: (value: boolean) => void;
}

export const Checkbox: React.FC<Props> = ({ isChecked, setChecked }) => {
  const [checkValue, setCheckValue] = useState(false);
  const handler = (value: boolean) => {
    setCheckValue(value);
    if (!setChecked) return;
    setChecked(value);
  };
  return (
    <label className='checkbox'>
      <input
        type='checkbox'
        id='checkbox'
        className='checkbox__input'
        onChange={(e) => handler(e.target.checked)}
        checked={isChecked}
      />
      <div className='checkbox__in'></div>
    </label>
  );
};
