import { Reducer } from 'redux';
import { IInitState, NotificationStatus, NotificationType } from './types';

const initState = {
  isShow: false,
  status: NotificationStatus.DANGER,
  id: null,
  message: '',
};

const reducer: Reducer<IInitState> = (state = initState, action) => {
  const { type } = action;
  const { status, id, message } = action?.notification || {};
  switch (type) {
    case NotificationType.SHOW_NOTIFICATION:
      return {
        ...state,
        isShow: true,
        status,
        id,
        message,
      };
    case NotificationType.HIDE_NOTIFICATION:
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export default reducer;
