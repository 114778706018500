import instance from 'api/instance';
import {
  ICreateCoupon,
  IGetCoupon,
  IGetCouponById,
  IUpdateCoupon,
} from './types';

export const getCoupons: IGetCoupon = () => instance.get('/coupons/admin');

export const getCouponById: IGetCouponById = (id) =>
  instance.get(`/coupons/${id}`);

export const createCoupon: ICreateCoupon = (data) =>
  instance.post('/coupons', data);

export const updateCoupon: IUpdateCoupon = (id, data) =>
  instance.put(`/coupons/${id}`, data);

export const deleteCoupon = (id: number) => instance.delete(`/coupons/${id}`);
