import React, { useEffect, useState } from 'react';
import { MainInput } from 'components/MainInput/MainInput';

import { MainButton } from 'components/MainButton/MainButton';
import { currencyServices } from 'api/services';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { InputType } from 'components/MainInput/types';
import cn from 'classnames';
import './Price.sass';

interface Props {
  arrivalPriceCurrencyId?: ISelect;
  setArrivalPriceCurrencyId: (value: ISelect) => void;
  dillerPriceCurrencyId?: ISelect;
  setDillerPriceCurrencyId: (value: ISelect) => void;
  retailPriceCurrencyId?: ISelect;
  setRetailPriceCurrencyId: (value: ISelect) => void;
  arrivalPrice: string;
  setArrivalPrice: (value: string) => void;
  dillerPrice: string;
  setDillerPrice: (value: string) => void;
  retailPrice: string;
  setRetailPrice: (value: string) => void;
  hasError: boolean;
}
export const Price: React.FC<Props> = ({
  arrivalPriceCurrencyId,
  setArrivalPriceCurrencyId,
  dillerPriceCurrencyId,
  setDillerPriceCurrencyId,
  retailPriceCurrencyId,
  setRetailPriceCurrencyId,
  arrivalPrice,
  setArrivalPrice,
  dillerPrice,
  setDillerPrice,
  retailPrice,
  setRetailPrice,
  hasError,
}) => {
  const [withOptions, setWithOptions] = useState(false);
  const [currencies, setCurrencies] = useState<ISelect[]>([]);

  useEffect(() => {
    getCurrencies();
  }, []);

  const getCurrencies = async () => {
    const res = await currencyServices.getCurrencies().then((res) => res.data);
    setCurrencies(
      res.map((i) => ({
        label: i.name,
        value: i.id,
      }))
    );
  };

  return (
    <div className={cn('price', { 'price--with-options': withOptions })}>
      <div className='price__in'>
        {/* <div className='price__row'>
          <label className='price__row-item'>
            <span>Режим управления цен</span>
            <Checkbox setChecked={setWithOptions} isChecked={withOptions} />
          </label>
        </div> */}
        <div className='price__row'>
          {withOptions && (
            <div className='price__options'>
              <label className='price__row-item'>
                <span>От</span>
                <MainInput />
              </label>
              <label className='price__row-item'>
                <span>До</span>
                <MainInput />
              </label>
            </div>
          )}
          <label className='price__row-item'>
            <span>Цена приход</span>
            <MainInput
              type={InputType.NUMBER}
              inputValue={arrivalPrice}
              updateInputValue={setArrivalPrice}
              error={hasError && !arrivalPrice.trim()}
            />
          </label>
          <label className='price__row-item'>
            <span>Валюта</span>
            {currencies.length > 0 && (
              <MainSelect
                items={currencies}
                selectItem={setArrivalPriceCurrencyId}
                value={arrivalPriceCurrencyId}
                isGray
                withIcon
                error={!arrivalPriceCurrencyId && hasError}
              />
            )}
          </label>
        </div>
        <div className='price__row'>
          {withOptions && (
            <div className='price__options'>
              <label className='price__row-item'>
                <span>От</span>
                <MainInput />
              </label>
              <label className='price__row-item'>
                <span>До</span>
                <MainInput />
              </label>
            </div>
          )}
          <label className='price__row-item'>
            <span>Цена дилера</span>
            <MainInput
              type={InputType.NUMBER}
              inputValue={dillerPrice}
              updateInputValue={setDillerPrice}
              error={!dillerPrice && hasError}
            />
          </label>
          <label className='price__row-item'>
            <span>Валюта</span>
            {currencies.length > 0 && (
              <MainSelect
                items={currencies}
                selectItem={setDillerPriceCurrencyId}
                value={dillerPriceCurrencyId}
                isGray
                withIcon
                error={hasError && !dillerPriceCurrencyId}
              />
            )}
          </label>
        </div>
        <div className='price__row'>
          {withOptions && (
            <div className='price__options'>
              <label className='price__row-item'>
                <span>От</span>
                <MainInput />
              </label>
              <label className='price__row-item'>
                <span>До</span>
                <MainInput />
              </label>
            </div>
          )}
          <label className='price__row-item'>
            <span>Цена в розницу</span>
            <MainInput
              type={InputType.NUMBER}
              inputValue={retailPrice}
              updateInputValue={setRetailPrice}
              error={!retailPrice && hasError}
            />
          </label>
          {withOptions ? (
            <div className='price__row-item'>
              <MainButton
                isDisabled={true}
                title='Добавить'
                submit={() => {}}
              />
            </div>
          ) : (
            <label className='price__row-item'>
              <span>Валюта</span>
              {currencies.length > 0 && (
                <MainSelect
                  items={currencies}
                  selectItem={setRetailPriceCurrencyId}
                  value={retailPriceCurrencyId}
                  isGray
                  withIcon
                  error={!retailPriceCurrencyId && hasError}
                />
              )}
            </label>
          )}
        </div>
      </div>
    </div>
  );
};
