import { bannerTypes } from 'enums/bannerTypes';
import { UserRole } from 'enums/users';
import { v1 as uuidv1 } from 'uuid';

export interface INav {
  title: string;
  items?: INav[] | any;
  id: string;
  url?: string;
  hasIcon?: boolean;
  isLinks?: boolean;
  isParentLink?: boolean;
  isPic?: boolean;
  roles?: UserRole[];
}

export const menu: INav[] = [
  {
    title: 'Рабочий стол',
    id: uuidv1(),
    url: '/',
    isParentLink: true,
    hasIcon: true,
  },
  {
    title: 'Каталог товаров',
    id: uuidv1(),
    hasIcon: true,
    roles: [
      UserRole.ADMIN,
      UserRole.SU,
      UserRole.CONTENT_MANAGER,
      UserRole.SALES_MANAGER,
      UserRole.SHOP_EMPLOYEE,
      UserRole.SHOP_OWNER,
    ],
    items: [
      {
        title: 'Каталог',
        id: uuidv1(),
        url: '/catalog/categories',
        hasIcon: true,
        items: [],
      },
      {
        title: 'Изображения',
        hasIcon: true,
        id: uuidv1(),
        url: '/catalog/files',
        items: [
          {
            title: 'Бренд',
            hasIcon: true,
            id: uuidv1(),
            url: '/catalog/files/brands',
          },
          {
            title: 'Магазин',
            hasIcon: true,
            id: uuidv1(),
            url: '/catalog/files/market',
          },
          {
            title: 'Товары',
            hasIcon: true,
            id: uuidv1(),
            url: '/catalog/files/list',
          },
          {
            title: 'Баннеры',
            hasIcon: true,
            id: uuidv1(),
            url: '/catalog/files/banners',
          },
        ],
      },
      {
        title: 'Бренд',
        id: uuidv1(),
        url: '/catalog/brands',
      },
      {
        title: 'Акции',
        id: uuidv1(),
        url: '/catalog/stocks',
        roles: [
          UserRole.SU,
          UserRole.SALES_MANAGER,
          UserRole.ADMIN,
          UserRole.SHOP_OWNER,
        ],
      },
      {
        title: 'Группы атрибутов',
        id: uuidv1(),
        url: '/catalog/attribute-groups',
        roles: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
      },
      {
        title: 'Атрибуты',
        id: uuidv1(),
        url: '/catalog/attributes',
        roles: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
      },
      {
        title: 'Валюта',
        id: uuidv1(),
        url: '/catalog/currencies',
        roles: [UserRole.SU, UserRole.ADMIN],
      },
      {
        title: 'Доставка',
        id: uuidv1(),
        url: '/catalog/delivery',
        roles: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
      },
    ],
  },
  {
    title: 'Заказы',
    id: uuidv1(),
    isLinks: true,
    roles: [
      UserRole.SU,
      UserRole.ADMIN,
      UserRole.SALES_MANAGER,
      UserRole.SHOP_EMPLOYEE,
      UserRole.SHOP_OWNER,
      UserRole.COURIER,
    ],
    items: [
      {
        title: 'Активные заказы',
        id: uuidv1(),
        url: '/orders/active',
        roles: [UserRole.SU, UserRole.ADMIN, UserRole.SALES_MANAGER],
      },
      {
        title: 'Все заказы',
        id: uuidv1(),
        url: '/orders/all',
        roles: [
          UserRole.SU,
          UserRole.ADMIN,
          UserRole.SALES_MANAGER,
          UserRole.SHOP_EMPLOYEE,
          UserRole.SHOP_OWNER,
          UserRole.COURIER,
        ],
      },
      {
        title: 'Услуги',
        id: uuidv1(),
        url: '/orders/services',
        roles: [UserRole.SU, UserRole.ADMIN, UserRole.SALES_MANAGER],
      },
      {
        title: 'Cashback',
        id: uuidv1(),
        url: '/orders/cashback',
        roles: [UserRole.SU, UserRole.ADMIN, UserRole.SALES_MANAGER],
      },
    ],
  },
  {
    title: 'Пользователи',
    id: uuidv1(),
    url: '/users',
    isParentLink: true,
    roles: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
  },
  {
    title: 'Сотрудники',
    id: uuidv1(),
    url: '/users',
    isParentLink: true,
    roles: [UserRole.SHOP_EMPLOYEE, UserRole.SHOP_OWNER],
  },
  {
    title: 'Статьи',
    id: uuidv1(),
    isLinks: true,
    roles: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
    items: [
      {
        title: 'Категории статей',
        roles: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
        id: uuidv1(),
        url: '/posts/categories',
      },
      {
        title: 'Все статьи',
        id: uuidv1(),
        url: '/posts/all',
        roles: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
      },
    ],
  },
  {
    title: 'Магазины',
    id: uuidv1(),
    url: '/shops',
    isLinks: true,
    isParentLink: true,
    roles: [UserRole.SU, UserRole.ADMIN],
  },
  {
    title: 'Модерация',
    id: uuidv1(),
    isLinks: true,
    roles: [
      UserRole.SU,
      UserRole.ADMIN,
      UserRole.CONTENT_MANAGER,
      UserRole.SALES_MANAGER,
    ],
    items: [
      // {
      //   title: 'Блог',
      //   id: uuidv1(),
      //   url: '/moderation/blogs',
      // },
      {
        title: 'База знаний',
        id: uuidv1(),
        url: '/moderation/knowledge-base',
        roles: [
          UserRole.SU,
          UserRole.ADMIN,
          UserRole.SALES_MANAGER,
          UserRole.CONTENT_MANAGER,
          UserRole.COURIER,
        ],
      },
      // {
      //   title: 'Отзывы',
      //   id: uuidv1(),
      //   url: '/moderation/reviews',
      //   roles: [UserRole.SU, UserRole.ADMIN, UserRole.CONTENT_MANAGER],
      // },
      {
        title: 'Комментарии к статьям',
        id: uuidv1(),
        url: '/moderation/post-comments',
        roles: [
          UserRole.SU,
          UserRole.ADMIN,
          UserRole.CONTENT_MANAGER,
          UserRole.SALES_MANAGER,
        ],
      },
      {
        title: 'Комментарии к продуктам',
        id: uuidv1(),
        url: '/moderation/product-comments',
        roles: [
          UserRole.SU,
          UserRole.ADMIN,
          UserRole.SALES_MANAGER,
          UserRole.CONTENT_MANAGER,
        ],
      },
      {
        title: 'Отзывы',
        id: uuidv1(),
        url: '/moderation/reviews',
        roles: [
          UserRole.SU,
          UserRole.ADMIN,
          UserRole.SALES_MANAGER,
          UserRole.CONTENT_MANAGER,
        ],
      },
      {
        title: 'Теги',
        id: uuidv1(),
        url: '/moderation/tags',
        roles: [UserRole.SU, UserRole.ADMIN, UserRole.CONTENT_MANAGER],
      },
    ],
  },
  {
    title: 'Отзывы',
    id: uuidv1(),
    url: '/reviews',
    isLinks: true,
    isParentLink: true,
    roles: [UserRole.SHOP_EMPLOYEE, UserRole.SHOP_OWNER],
  },
  {
    title: 'Услуги',
    id: uuidv1(),
    url: '/services',
    isLinks: true,
    isParentLink: true,
    roles: [UserRole.SHOP_OWNER, UserRole.SHOP_EMPLOYEE],
  },
  {
    title: 'Данные компании',
    id: uuidv1(),
    url: '/about-company',
    isLinks: true,
    isParentLink: true,
    roles: [UserRole.SHOP_OWNER],
  },
  {
    title: 'Реклама',
    id: uuidv1(),
    roles: [UserRole.SU, UserRole.ADMIN, UserRole.CONTENT_MANAGER],
    items: [
      {
        title: 'Баннеры',
        id: uuidv1(),
        hasIcon: true,
        url: '/advertising/banners/slider',
        items: [
          ...bannerTypes.map((i) => ({
            title: i.label,
            id: uuidv1(),
            url: `/advertising/banners/${i?.value
              ?.toString()
              .replaceAll('_', '-')
              .toLowerCase()}`,
            hasIcon: true,
            roles: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],

            // {
            //   title: 'Квадратные',
            //   id: uuidv1(),
            //   url: '/advertising/banners/square',
            //   hasIcon: true,
            // },
            // {
            //   title: 'Прямоугольные',
            //   id: uuidv1(),
            //   url: '/advertising/banners/rectangle',
            //   hasIcon: true,
            // },
            // {
            //   title: 'Полная ширина',
            //   id: uuidv1(),
            //   url: '/advertising/banners/short-full-width',
            //   hasIcon: true,
            // },
            // {
            //   title: 'Малая половина',
            //   id: uuidv1(),
            //   url: '/advertising/banners/short-half-width',
            //   hasIcon: true,
            // },
            // {
            //   title: 'Средняя половина',
            //   id: uuidv1(),
            //   url: '/advertising/banners/medium-half-width',
            //   hasIcon: true,
            // },
            // {
            //   title: 'Действующий',
            //   id: uuidv1(),
            //   url: '/advertising/banners/action',
            //   hasIcon: true,
            // },
            // {
            //   title: 'Ликвидация квадратный',
            //   id: uuidv1(),
            //   url: '/advertising/banners/liquidation-square',
            //   hasIcon: true,
            // },
            // {
            //   title: 'Ликвидация прямоугольный',
            //   id: uuidv1(),
            //   url: '/advertising/banners/liquidation-rectangle',
            //   hasIcon: true,
            // },
            // {
            //   title: 'Доступная площадь',
            //   id: uuidv1(),
            //   url: '/advertising/banners/affordable-square',
            //   hasIcon: true,
            // },
            // {
            //   title: 'Доступная полная ширина',
            //   id: uuidv1(),
            //   url: '/advertising/banners/affordable-full-width',
            //   hasIcon: true,
            // },
          })),
          {
            title: 'Stories баннеры',
            id: uuidv1(),
            url: '/advertising/banners/story-groups',
            hasIcon: true,
            roles: [UserRole.SU, UserRole.CONTENT_MANAGER, UserRole.ADMIN],
          },
        ],
      },
      {
        title: 'Купоны',
        id: uuidv1(),
        url: '/advertising/coupons',
        roles: [
          UserRole.SU,
          UserRole.ADMIN,
          UserRole.CONTENT_MANAGER,
          UserRole.SALES_MANAGER,
        ],
      },
      {
        title: 'Промокоды',
        id: uuidv1(),
        url: '/advertising/promo-codes',
        roles: [
          UserRole.SU,
          UserRole.ADMIN,
          UserRole.CONTENT_MANAGER,
          UserRole.SALES_MANAGER,
        ],
      },
    ],
  },
  {
    title: 'Отчёты',
    id: uuidv1(),
    isLinks: true,
    roles: [
      UserRole.SU,
      UserRole.SALES_MANAGER,
      UserRole.ADMIN,
      UserRole.SHOP_EMPLOYEE,
      UserRole.SHOP_OWNER,
    ],
    items: [
      {
        title: 'Остатки',
        url: '/reports/balance',
        id: uuidv1(),
        roles: [
          UserRole.SU,
          UserRole.SALES_MANAGER,
          UserRole.ADMIN,
          UserRole.SHOP_EMPLOYEE,
          UserRole.SHOP_OWNER,
        ],
      },
      {
        title: 'Завершенные продажи',
        url: '/reports/sales',
        id: uuidv1(),
        roles: [
          UserRole.SU,
          UserRole.SALES_MANAGER,
          UserRole.ADMIN,
          UserRole.SHOP_EMPLOYEE,
          UserRole.SHOP_OWNER,
        ],
      },
    ],
  },
];
