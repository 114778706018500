import React, { useEffect, useState } from 'react';
import { IBrand } from 'api/services/brands/types';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { MainInput } from 'components/MainInput/MainInput';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import './UpdateBrand.sass';
import { brandServices } from 'api/services';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import { Modal } from 'components/Modal/Modal';
import { FileModal } from '../FileModal/FileModal';
import { IResFile } from 'api/services/files/types';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { FileList } from 'components/FileList/FileList';
import showNotification from 'helpers/showNotification';

interface Props {
  submit: (data: IBrand) => void;
  closeModal: () => void;
  id?: number;
}

export const UpdateBrand: React.FC<Props> = ({ closeModal, submit, id }) => {
  const [name, setName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState<IResFile>();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    getDataById();
  }, [id]);

  const getDataById = async () => {
    if (!id) return;
    const { name, file } = await brandServices
      .getBrandById(id.toString())
      .then((res) => res.data);
    setName(name);
    setFile(file);
  };

  const fileSelected = (file: IResFile) => {
    setFile(file);
    setShowModal(false);
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!file?.id) {
      showNotification('Загрузите изображение');
      setHasError(true);
      return;
    }
    if (!name.trim()) {
      showNotification('Заполните название');
      setHasError(true);
      return;
    }
    submit({
      name,
      fileId: file.id,
    });
  };

  return (
    <>
      {showModal && (
        <FileModal
          closeModal={() => setShowModal(false)}
          fileSelected={fileSelected}
        />
      )}
      <form onSubmit={formSubmit} className='update-brand'>
        <div className='update-brand__modal'>
          <div className='update-brand__tabs'>
            <div className='update-brand__tabs-item'>
              <span>Бренд</span>
            </div>
            <ChoiceLang />
          </div>
          <div className='update-brand__container'>
            <div className='update-brand__row'>
              <label className='update-brand__row-item'>
                <div className='update-brand__label update-brand__row-label'>
                  Название
                </div>
                <MainInput
                  updateInputValue={setName}
                  inputValue={name}
                  error={hasError && !name.trim()}
                />
              </label>
            </div>
            <div className='update-brand__row update-brand__row--uploader'>
              <div className='update-brand__row-item'>
                <div
                  className='update-brand__label update-brand__label--uploader'
                  onClick={() => setShowModal(true)}
                >
                  Загрузить изображение:
                  <span>
                    Выбрать из файла <FolderIcon />
                  </span>
                </div>
                {!!file ? (
                  <FileList deleteFile={() => setFile(undefined)} file={file} />
                ) : (
                  <FileUploader setFile={setFile} error={!file && hasError} />
                )}
              </div>
            </div>
          </div>
        </div>
        <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
      </form>
    </>
  );
};
