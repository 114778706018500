import React, { useEffect, useState } from 'react';
import { Pagination } from 'components/Pagination/Pagination';
import { Table } from 'components/Table/Table';
import { Template } from 'components/Template/Template';
import { Modal } from 'components/Modal/Modal';
import { CreateProduct } from 'components/Modal/components/CreateProduct/CreateProduct';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { Filter } from 'components/Filter/Filter';
import { IBrand, IResBrand } from 'api/services/brands/types';
import { tagServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { IResTag, ITag } from 'api/services/tags/types';
import { CreateTag } from 'components/CreateTag/CreateTag';
import { UpdateTag } from 'components/UpdateTag/UpdateTag';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import * as settingActions from 'store/ducks/settings/actions';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Settings } from 'components/Modal/components/Settings/Settings';

export const TagsPage = () => {
  const [activeTab, setActiveTab] = useState(5);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [data, setData] = useState<IResTag[]>([]);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState<number>();
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<number>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const dispatch = useDispatch();
  const { tagsSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Модерация', id: '' },
        { title: 'Теги', id: 'moderation/tags' },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [page, searchValue]);

  const createTag = async (tag: ITag) => {
    setSubmitLoading(true);
    try {
      await tagServices.createTag(tag);
      setShowCreateModal(false);
      showNotification('Успешно', NotificationStatus.SUCCESS);
      getData();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const updateTag = async (tag: ITag) => {
    setSubmitLoading(true);
    if (!id) return;
    try {
      await tagServices.updateTag(id, tag);
      setShowUpdateModal(false);
      showNotification('Успешно', NotificationStatus.SUCCESS);
      getData();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        skip: take * page,
        take: take,
        search: searchValue,
      };
      const res = await tagServices.getTags(params).then((res) => res.data);
      setData(res.data);
      setTotalCount(res.count);
    } finally {
      setLoading(false);
    }
  };

  const openUpdateTag = (id: number) => {
    setId(id);
    setShowUpdateModal(true);
    setActiveTab(8);
  };

  const closeUpdateModal = () => {
    setId(undefined);
    setShowUpdateModal(false);
  };

  const deleteItem = async (id: number) => {
    try {
      await tagServices.deleteTag(id);
      showNotification('Тег удален', NotificationStatus.SUCCESS);
      getData();
    } catch {
      showNotification('Ошибка, попробуйте позже');
    }
  };

  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setTagsSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Теги'
            items={tagsSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={() => setShowCreateModal(false)}>
          <CreateTag
            closeModal={() => setShowCreateModal(false)}
            submit={createTag}
            loading={submitLoading}
          />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal closeModal={closeUpdateModal}>
          <UpdateTag
            closeModal={closeUpdateModal}
            submit={updateTag}
            id={id}
            loading={submitLoading}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='page brand-page'>
        <Filter
          createFunc={() => setShowCreateModal(true)}
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          loading={loading}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            selectItem={openUpdateTag}
            tableBody={{
              data,
              keys: tagsSettings.filter((i) => i.isActive).map((i) => i.value),
            }}
            tableHead={tagsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            deleteHandler={deleteItem}
            page={page}
          />
        </div>
      </div>
    </>
  );
};
