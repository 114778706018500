import React, { useState } from 'react';
import { MainInput } from 'components/MainInput/MainInput';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ITag } from 'api/services/tags/types';
import './CreateSupplementaryService.sass';
import { InputType } from 'components/MainInput/types';
import { supplementaryServicesServices } from 'api/services';
import { Checkbox } from 'components/Checkbox/Checkbox';

interface Props {
  submit: () => void;
  closeModal: () => void;
  loading: boolean;
}

export const CreateSupplementaryService: React.FC<Props> = ({
  closeModal,
  submit,
  loading,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if (!name.trim() || !description.trim() || !price.trim()) {
      showNotification('Заполните поля');
      setHasError(true);
      return;
    }
    try {
      await supplementaryServicesServices.createSupplementaryService({
        name,
        description,
        price: Number(price) * 100,
        isActive,
      });
      submit();
    } catch {
      showNotification('Ошибка, попробуйте позже');
    }
  };

  return (
    <form onSubmit={formSubmit} className='create-supplementary-service'>
      <div className='create-supplementary-service__container create-supplementary-service__modal'>
        <div className='create-supplementary-service__tabs'>
          <div className='create-supplementary-service__tabs-item create-supplementary-service__tabs-item--active'>
            <span>Услуги</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='create-supplementary-service__in'>
          <div className='create-supplementary-service__row'>
            <label className='create-supplementary-service__row-item'>
              <div className='create-supplementary-service__label create-supplementary-service__row-label'>
                Активность:
              </div>
              <Checkbox isChecked={isActive} setChecked={setIsActive} />
            </label>
          </div>
          <div className='create-supplementary-service__row'>
            <label className='create-supplementary-service__row-item'>
              <div className='create-supplementary-service__label create-supplementary-service__row-label'>
                Название:
              </div>
              <MainInput
                updateInputValue={setName}
                inputValue={name}
                error={hasError && !name.trim()}
              />
            </label>
          </div>
          <div className='create-supplementary-service__row'>
            <label className='create-supplementary-service__row-item'>
              <div className='create-supplementary-service__label create-supplementary-service__row-label'>
                Описание:
              </div>
              <MainInput
                updateInputValue={setDescription}
                inputValue={description}
                error={hasError && !description.trim()}
              />
            </label>
          </div>
          <div className='create-supplementary-service__row'>
            <label className='create-supplementary-service__row-item'>
              <div className='create-supplementary-service__label create-supplementary-service__row-label'>
                Цена:
              </div>
              <MainInput
                updateInputValue={setPrice}
                inputValue={price}
                type={InputType.NUMBER}
                error={hasError && !price.trim()}
              />
            </label>
          </div>
        </div>
      </div>
      <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
    </form>
  );
};
