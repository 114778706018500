import { ISelect } from 'components/MainSelect/MainSelect';

export const bannerTypes: ISelect[] = [
  {
    label: '1536x330',
    value: 'SLIDER',
  },
  {
    label: 'Квадратные 489x489',
    value: 'SQUARE',
  },
  {
    label: '1536x150',
    value: 'SHORT_FULL_WIDTH',
  },
  {
    label: '357х235',
    value: 'RECTANGLE',
  },
  {
    label: 'Акции 450x250x',
    value: 'ACTION',
  },
  {
    label: 'Ликвидация 227x227',
    value: 'LIQUIDATION_SQUARE',
  },
  {
    label: 'Ликвидация 749x227',
    value: 'LIQUIDATION_RECTANGLE',
  },
  {
    label: 'Доступные товары 358x358x',
    value: 'AFFORDABLE_SQUARE',
  },
  {
    label: 'Доступные товары 1536x358x',
    value: 'AFFORDABLE_FULL_WIDTH',
  },
];

export enum BannerTypes {
  SLIDER = 'SLIDER',
  SQUARE = 'SQUARE',
  SHORT_FULL_WIDTH = 'SHORT_FULL_WIDTH',
  ACTION = 'ACTION',
  LIQUIDATION_SQUARE = 'LIQUIDATION_SQUARE',
  LIQUIDATION_RECTANGLE = 'LIQUIDATION_RECTANGLE',
  AFFORDABLE_SQUARE = 'AFFORDABLE_SQUARE',
  AFFORDABLE_FULL_WIDTH = 'AFFORDABLE_FULL_WIDTH',
}
