import instance from 'api/instance';
import {
  ICreateFileFolder,
  IDeleteFiles,
  IGetFile,
  IUploadFile,
} from './types';
import qs from 'query-string';

export const getFiles: IGetFile = (attrs) =>
  instance.get(
    `/files?${qs.stringify(attrs || {}, { skipEmptyString: true })}`
  );

export const uploadFile: IUploadFile = (file) => instance.post('/files', file);

export const createFolder: ICreateFileFolder = (folder) =>
  instance.post('/files/folder', folder);

export const getFileById = (id: string) => instance.get(`/files/${id}`);

export const deleteFiles: IDeleteFiles = (fileIds) =>
  instance.delete('/files', { data: fileIds });
