import { brandServices, categoryServices, shopServices } from 'api/services';
import { CouponType, PeriodType } from 'api/services/coupons/types';
import { IResShop } from 'api/services/shops/types';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { MainButton } from 'components/MainButton/MainButton';
import { MainInput } from 'components/MainInput/MainInput';
import { InputType } from 'components/MainInput/types';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import showNotification from 'helpers/showNotification';
import _, { result } from 'lodash';
import React, { useEffect, useState } from 'react';
import { checkCount, checkCouponType } from '../checkTypes';
import { ICouponCondition } from '../UpdateCoupon/UpdateCoupon';

import './Step2.sass';

const items: ISelect[] = [
  {
    label: 'Часы',
    value: PeriodType.HOURS,
  },
  {
    label: 'Дни',
    value: PeriodType.DAYS,
  },
  {
    label: 'Недели',
    value: PeriodType.WEEKS,
  },
  {
    label: 'Месяцы',
    value: PeriodType.MONTHS,
  },
  {
    label: 'Год',
    value: PeriodType.YEARS,
  },
];

interface ISelectedItem {
  name: string;
  value: number;
}

interface Props {
  setShopIds: (value: number[]) => void;
  shopIds?: number[];
  setCategoryIds: (value: number[]) => void;
  categoryIds?: number[];
  setBrandIds: (value: number[]) => void;
  brandIds?: number[];
  orderSum: string;
  setOrderSum: (value: string) => void;
  count: string;
  setCount: (value: string) => void;
  setPeriodType: (value: PeriodType) => void;
  periodType?: PeriodType;
  periodValue: string;
  setPeriodValue: (value: string) => void;
  hasError: boolean;
  couponConditions?: ICouponCondition;
  type?: CouponType;
  usersCount: string;
  setUsersCount: (value: string) => void;
}

export const Step2: React.FC<Props> = ({
  setShopIds,
  shopIds,
  setCategoryIds,
  categoryIds,
  setBrandIds,
  brandIds,
  orderSum,
  setOrderSum,
  count,
  setCount,
  periodType,
  setPeriodType,
  periodValue,
  setPeriodValue,
  hasError,
  couponConditions,
  type,
  usersCount,
  setUsersCount,
}) => {
  const [selectedItem, setSelectedItem] = useState<ISelect>();
  const [selectedShops, setSelectedShops] = useState<ISelectedItem[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<ISelectedItem[]>(
    []
  );
  const [selectedBrands, setSelectedBrands] = useState<ISelectedItem[]>([]);

  const [searchableShops, setSearchableShops] = useState<ISearchableSelect[]>(
    []
  );
  const [searchableShopsLoading, setSearchableShopsLoading] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState<number>();
  const [searchableShopValue, setSearchableShopValue] = useState('');
  const [searchableCategories, setSearchableCategories] = useState<
    ISearchableSelect[]
  >([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const [searchableCategoriesLoading, setSearchableCategoriesLoading] =
    useState(false);
  const [searchableCategoryValue, setSearchableCategoryValue] = useState('');
  const [searchableBrands, setSearchableBrands] = useState<ISearchableSelect[]>(
    []
  );
  const [selectedBrandId, setSelectedBrandId] = useState<number>();
  const [searchableBrandsLoading, setSearchableBrandsLoading] = useState(false);
  const [searchableBrandValue, setSearchableBrandValue] = useState('');

  useEffect(() => {
    getShops();
    getCategories();
    getBrands();
  }, []);

  useEffect(() => {
    if (couponConditions) {
      setSelectedShops(
        couponConditions.shops.map((i) => ({ name: i.name, value: i.id }))
      );
      setSelectedBrands(
        couponConditions.brands.map((i) => ({ name: i.name, value: i.id }))
      );
      setSelectedCategories(
        couponConditions.categories.map((i) => ({
          name: i.titleRu,
          value: i.id,
        }))
      );
    }
  }, [!!couponConditions]);

  useEffect(() => {
    if (periodType) {
      setSelectedItem({
        label: items.find((i) => i.value === periodType)?.label || '',
        value: periodType,
      });
    }
  }, [!!periodType]);

  const getShops = async (value?: string) => {
    const res = await shopServices
      .getShops({
        skip: 0,
        take: 25,
        search: value && value.length < 2 ? '' : value,
      })
      .then((res) => res.data);
    setSearchableShops(
      res.data.map((i) => ({
        name: i.name,
        value: i.id,
      }))
    );
    setSearchableShopsLoading(false);
  };

  const getCategories = async (value?: string) => {
    const res = await categoryServices
      .getCategories({
        search: value && value.length < 2 ? '' : value,
      })
      .then((res) => res.data);
    const postCtg = res.map((i) => ({
      name: i.titleRu,
      value: i.id,
    }));
    setSearchableCategories(postCtg);
    setSearchableCategoriesLoading(false);
  };

  const selectType = (data: ISelect) => {
    setSelectedItem(data);
    setPeriodType((data.value || data) as any);
  };

  const getBrands = async (value?: string) => {
    const res = await brandServices
      .getBrands({
        search: value && value.length < 2 ? '' : value,
      })
      .then((res) => res.data);
    setSearchableBrands(res.data.map((i) => ({ name: i.name, value: i.id })));
    setSearchableBrandsLoading(false);
  };

  const addShop = () => {
    if (!selectedShopId) {
      showNotification('Выберите магазин');
      return;
    }
    if (selectedShops.some((i) => i.value === selectedShopId)) {
      showNotification('Магазин уже добавлен');
      return;
    }
    setSelectedShops((prev) => {
      const clone = _.clone(prev);
      const result = [
        ...clone,
        { name: searchableShopValue, value: selectedShopId },
      ];
      setShopIds(result.map((i) => i.value));
      return result;
    });
    getShops();
    setSearchableShopValue('');
    setSelectedShopId(undefined);
  };

  const addCategory = () => {
    if (!selectedCategoryId) {
      showNotification('Выберите категорию');
      return;
    }
    if (selectedShops.some((i) => i.value === selectedCategoryId)) {
      showNotification('Категория уже добавлена');
      return;
    }
    setSelectedCategories((prev) => {
      const clone = _.clone(prev);
      const result = [
        ...clone,
        { name: searchableCategoryValue, value: selectedCategoryId },
      ];
      setCategoryIds(result.map((i) => i.value));
      return result;
    });
    getCategories();
    setSearchableCategoryValue('');
    setSelectedCategoryId(undefined);
  };

  const addBrand = () => {
    if (!selectedBrandId) {
      showNotification('Выберите бренд');
      return;
    }
    if (selectedShops.some((i) => i.value === selectedBrandId)) {
      showNotification('Бренд уже добавлен');
      return;
    }
    setSelectedBrands((prev) => {
      const clone = _.clone(prev);
      const result = [
        ...clone,
        { name: searchableBrandValue, value: selectedBrandId },
      ];
      setBrandIds(result.map((i) => i.value));
      return result;
    });
    getBrands();
    setSearchableBrandValue('');
    setSelectedBrandId(undefined);
  };

  const removeShop = (id: number) => {
    setSelectedShops((prev) => {
      const clone = _.clone(prev);
      const result = clone.filter((i) => i.value !== id);
      setShopIds(result.map((i) => i.value));
      return result;
    });
  };

  const removeCategory = (id: number) => {
    setSelectedCategories((prev) => {
      const clone = _.clone(prev);
      const result = clone.filter((i) => i.value !== id);
      setCategoryIds(result.map((i) => i.value));
      return result;
    });
  };

  const removeBrand = (id: number) => {
    setSelectedBrands((prev) => {
      const clone = _.clone(prev);
      const result = clone.filter((i) => i.value !== id);
      setBrandIds(result.map((i) => i.value));
      return result;
    });
  };

  return (
    <div className='step-2'>
      <div className='step__title'>Шаг 2</div>
      <div className='step-2__condition-title'>Условия применения купона:</div>
      <div className='step-2__condition-form'>
        <div className='step-2__condition-row'>
          <label className='step-2__condition-row-container'>
            <div className='step-2__condition-row-title'>Категории</div>
            {selectedCategories.map((i) => (
              <div className='step-2__condition-row-item' key={i.value}>
                <MainInput
                  inputValue={i.name}
                  disabled
                  updateInputValue={() => {}}
                />
                <div className='step-2__condition-row-buttons'>
                  <MainButton
                    title='Отменить'
                    submit={() => removeCategory(i.value)}
                    type='button'
                    isDelete
                  />
                </div>
              </div>
            ))}
            <div className='step-2__condition-row-item'>
              <CustomSearchableSelect
                items={searchableCategories}
                loading={searchableCategoriesLoading}
                setValue={getCategories}
                setLoading={setSearchableCategoriesLoading}
                setSelectItem={setSelectedCategoryId}
                setSearchValue={setSearchableCategoryValue}
                searchValue={searchableCategoryValue}
              />
              <div className='step-2__condition-row-buttons'>
                <MainButton
                  title='Добавить'
                  type='button'
                  submit={addCategory}
                />
              </div>
            </div>
          </label>
        </div>
        <div className='step-2__condition-row'>
          <label className='step-2__condition-row-container'>
            <div className='step-2__condition-row-title'>Магазин</div>
            {selectedShops.map((i) => (
              <div className='step-2__condition-row-item' key={i.value}>
                <MainInput
                  inputValue={i.name}
                  disabled
                  updateInputValue={() => {}}
                />
                <div className='step-2__condition-row-buttons'>
                  <MainButton
                    title='Отменить'
                    submit={() => removeShop(i.value)}
                    type='button'
                    isDelete
                  />
                </div>
              </div>
            ))}
            <div className='step-2__condition-row-item'>
              <CustomSearchableSelect
                items={searchableShops}
                loading={searchableShopsLoading}
                setLoading={setSearchableShopsLoading}
                setSelectItem={setSelectedShopId}
                setValue={getShops}
                searchValue={searchableShopValue}
                setSearchValue={setSearchableShopValue}
              />
              <div className='step-2__condition-row-buttons'>
                {/* <MainButton title='Выбрать' /> */}
                <MainButton title='Добавить' submit={addShop} type='button' />
              </div>
            </div>
          </label>
        </div>
        <div className='step-2__condition-row'>
          <label className='step-2__condition-row-container'>
            <div className='step-2__condition-row-title'>Бренд</div>
            {selectedBrands.map((i) => (
              <div className='step-2__condition-row-item' key={i.value}>
                <MainInput
                  inputValue={i.name}
                  disabled
                  updateInputValue={() => {}}
                />
                <div className='step-2__condition-row-buttons'>
                  <MainButton
                    title='Отменить'
                    submit={() => removeBrand(i.value)}
                    type='button'
                    isDelete
                  />
                </div>
              </div>
            ))}
            <div className='step-2__condition-row-item'>
              <CustomSearchableSelect
                items={searchableBrands}
                loading={searchableBrandsLoading}
                setLoading={setSearchableBrandsLoading}
                setSelectItem={setSelectedBrandId}
                setValue={getBrands}
                searchValue={searchableBrandValue}
                setSearchValue={setSearchableBrandValue}
              />
              <div className='step-2__condition-row-buttons'>
                {/* <MainButton title='Выбрать' /> */}
                <MainButton title='Добавить' submit={addBrand} type='button' />
              </div>
            </div>
          </label>
        </div>
      </div>
      <div className='step-2__other-settings'>
        {type && checkCount(type) && (
          <div className='step-2__other-settings-row'>
            <div className='step-2__other-settings-row-title'>
              {type === CouponType.REFERRAL_LINK
                ? 'Количество заказов'
                : 'Количество*'}
            </div>
            <MainInput
              inputValue={count}
              updateInputValue={setCount}
              type={InputType.NUMBER}
              error={!count.trim() && hasError}
            />
          </div>
        )}
        {type === CouponType.REFERRAL_LINK && (
          <div className='step-2__other-settings-row'>
            <div className='step-2__other-settings-row-title'>
              Количество пользователей
            </div>
            <MainInput
              inputValue={usersCount}
              updateInputValue={setUsersCount}
              type={InputType.NUMBER}
              error={!usersCount.trim() && hasError}
            />
          </div>
        )}
        {type && checkCouponType(type) && (
          <div className='step-2__other-settings-row'>
            <div className='step-2__other-settings-row-title'>
              Сумма заказов*
            </div>
            <MainInput
              inputValue={orderSum}
              updateInputValue={setOrderSum}
              type={InputType.NUMBER}
              error={!orderSum.trim() && hasError}
            />
          </div>
        )}
        {type && checkCouponType(type) && (
          <div className='step-2__other-settings-row'>
            <div className='step-2__other-settings-row-title'>
              Период заказов*
            </div>
            <div className='step-2__other-settings-row-item'>
              <MainInput
                inputValue={periodValue}
                updateInputValue={setPeriodValue}
                type={InputType.NUMBER}
                error={!periodValue.trim() && hasError}
              />
              <MainSelect
                isGray
                items={items}
                selectItem={selectType}
                value={selectedItem}
                error={!selectedItem && hasError}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
