import React, { useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { MainInput } from 'components/MainInput/MainInput';
import './UploadBlogs.sass';
import { RadioInput } from 'components/RadioInput/RadioInput';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg';
import { InputType } from 'components/MainInput/types';
import { RadioType } from 'enums/radioType';
import { ActionButtons } from 'components/Modal/components/ActionButtons/ActionButtons';

interface Props {
  submit?: (data: any) => void;
}

export const UploadBlogs: React.FC<Props> = ({ submit }) => {
  const [descriptionType, setDescriptionType] = useState(RadioType.TEXT);

  const changeDescriptionType = (type: RadioType) => {
    setDescriptionType(type);
  };

  const formSubmit = () => {
    if (!submit) return;
    submit({});
  };

  return (
    <div className='upload-blogs'>
      <div className='upload-blogs__modal'>
        <div className='upload-blogs__row'>
          <label className='upload-blogs__row-item'>
            <div className='upload-blogs__label'>Активность:</div>
            <Checkbox isChecked />
          </label>
        </div>
        <div className='upload-blogs__row'>
          <label className='upload-blogs__row-item'>
            <div className='upload-blogs__label'>
              Показывать на главной странице
            </div>
            <Checkbox isChecked />
          </label>
        </div>
        <div className='upload-blogs__row'>
          <label className='upload-blogs__row-item'>
            <div className='upload-blogs__label'>Нзвание</div>
            <MainInput />
          </label>
        </div>
        <div className='upload-blogs__row'>
          <label className='upload-blogs__row-item'>
            <div className='upload-blogs__label'>Страница категории URL</div>
            <MainInput />
          </label>
        </div>
        <div className='upload-blogs__row'>
          <label className='upload-blogs__row-item'>
            <div className='upload-blogs__label'>Ссылка на видео</div>
            <MainInput />
          </label>
        </div>
        <div className='upload-blogs__row'>
          <label className='upload-blogs__row-item'>
            <div className='upload-blogs__label'>Показать товар в статье</div>

            <MainInput type={InputType.SEARCH} />
          </label>
        </div>
        <div className='upload-blogs__row upload-blogs__row--with-list'>
          <div className='upload-blogs__row-list'>
            <div className='upload-blogs__row-list-item'>
              <span>Стиральная машина</span>
              <div className='upload-blogs__row-list-item-close'>
                <CloseIcon />
              </div>
            </div>
            <div className='upload-blogs__row-list-item'>
              <span>Капсула TIDE 2</span>
              <div className='upload-blogs__row-list-item-close'>
                <CloseIcon />
              </div>
            </div>
            <div className='upload-blogs__row-list-item'>
              <span>Стиральная машина</span>
              <div className='upload-blogs__row-list-item-close'>
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
        <div className='upload-blogs__description'>
          <div className='upload-blogs__description-tabs'>
            <RadioInput
              checked={descriptionType === RadioType.TEXT}
              type={RadioType.TEXT}
              label='ТЕКСТ'
              classes='upload-blogs__description-tab'
              onChange={changeDescriptionType}
            />
            <RadioInput
              checked={descriptionType === RadioType.REDACTOR}
              type={RadioType.REDACTOR}
              label='Визуальный редактор'
              classes='upload-blogs__description-tab'
              onChange={changeDescriptionType}
            />
          </div>
          {RadioType.TEXT && (
            <div className='upload-blogs__textarea'>
              <MainInput type={InputType.TEXTAREA} rows={17} />
            </div>
          )}
        </div>
      </div>

      <ActionButtons closeModal={() => {}} formSubmit={formSubmit} />
    </div>
  );
};
