import React, { useEffect } from 'react';
import { ReactComponent as ExitIcon } from 'assets/images/icons/close-icon.svg';

import { ActionButtons } from './components/ActionButtons/ActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import './Modal.sass';

interface Props {
  closeModal: () => void;
  classes?: string;
}

export const Modal: React.FC<Props> = ({ children, closeModal, classes }) => {
  useEffect(() => {
    document.addEventListener('keydown', closeModalWithKey);

    return () => {
      document.removeEventListener('keydown', closeModalWithKey);
    };
  }, []);

  const closeModalWithKey = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      closeModal();
    }
    return;
  };

  return (
    <div className={`modal ${classes || ''}`}>
      <div className='modal__blackout' onClick={closeModal}></div>
      <div className='modal__container'>
        <div className='modal__close' onClick={closeModal}>
          <ExitIcon />
        </div>
        {children}
      </div>
    </div>
  );
};
