import {
  AttributeType,
  AttributeViewType,
} from 'api/services/attributes/types';
import { ISelect } from 'components/MainSelect/MainSelect';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Table } from 'components/Table/Table';
import { ReactComponent as SettingsIcon } from 'assets/images/icons/settings-icon.svg';
import RadioCheckedIcon from 'assets/images/icons/radio-checked-icon.svg';
import SwitchIcon from 'assets/images/icons/switch-icon.svg';
import RadioIcon from 'assets/images/icons/radio-icon.svg';

export const attributeTypes: ISelect[] = [
  {
    label: 'Список',
    value: AttributeType.LIST,
  },
  {
    label: 'Число',
    value: AttributeType.NUMBER,
  },
  {
    label: 'Строка',
    value: AttributeType.TEXT,
  },
];

export const attributeViewTypes: ISelect[] = [
  {
    label: 'Нет',
    value: null,
  },
  {
    label: 'Флажки',
    value: AttributeViewType.CHECKBOX,
  },
  {
    label: (
      <div className='product-attributes__select-title'>
        <div className='product-attributes__select-title-text'>
          Радиокнопки<i></i>
        </div>
        <div className='product-attributes__select-title-icons'>
          <img src={RadioCheckedIcon} />
          <img src={RadioIcon} />
        </div>
      </div>
    ),
    value: AttributeViewType.RADIO,
  },
  {
    label: (
      <div className='product-attributes__select-title'>
        <div className='product-attributes__select-title-text'>
          Тумблер<i></i>
        </div>
        <div className='product-attributes__select-title-icons product-attributes__select-title-icons--one'>
          <img src={SwitchIcon} />
        </div>
      </div>
    ),
    value: AttributeViewType.SWITCH,
  },
  {
    label: 'Выпадающий список',
    value: AttributeViewType.SELECT,
  },
  {
    label: 'Цвет',
    value: AttributeViewType.COLOR,
  },
];

const tableHead = [
  'ID',
  'Название',
  'Активность',
  'Сортировка',
  'Подразделения',
];

const tableBody = {
  keys: ['id', 'titleRu', 'isActive', 'sort', 'divisions'],
  data: [
    {
      id: 1,
      title: 'Электроника',
      isActive: true,
      sort: 30,
      divisions: 22,
    },
    {
      id: 2,
      title: 'Бытовая техника',
      isActive: true,
      sort: 13,
      divisions: 5,
    },
    {
      id: 3,
      title: 'Кухонная техника',
      isActive: true,
      sort: 12,
      divisions: 3,
    },
    {
      id: 4,
      title: 'Акция 1',
      isActive: true,
      sort: 13,
      divisions: 12,
    },
    {
      id: 5,
      title: 'Акция 1',
      isActive: true,
      sort: 13,
      divisions: 22,
    },
    {
      id: 6,
      title: 'Акция 1',
      isActive: true,
      sort: 13,
      divisions: 22,
    },
    {
      id: 7,
      title: 'Акция 1',
      isActive: true,
      sort: 13,
      divisions: 22,
    },
    {
      id: 8,
      title: 'Акция 1',
      isActive: true,
      sort: 13,
      divisions: 22,
    },
    {
      id: 9,
      title: 'Акция 1',
      isActive: true,
      sort: 13,
      divisions: 22,
    },
  ],
};
