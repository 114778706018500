export const checkAmount = (num: any) => {
  let result = Number(num / 100)
    .toString()
    .split('.') as any;
  if (result.length > 1) {
    result[0] = result[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
    result = result.join('.');
    return result;
  } else {
    return result.join('.').replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
  }
};
