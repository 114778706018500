import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import cn from 'classnames';
import { isEqual } from 'lodash';
import _ from 'lodash';
import { NotificationStatus } from 'store/ducks/notification/types';
import {
  attributeServices,
  productServices,
  variantsServices,
} from 'api/services';
import { IVariantAttrubute } from 'api/services/variant-attributes/types';
import { IResVariant, IVariant } from 'api/services/variants/types';
import { IResAttribute } from 'api/services/attributes/types';
import showNotification from 'helpers/showNotification';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import { Options } from '../CreateProduct/Options/Options';
import { CreateProductTags } from '../CreateProduct/CreateProductTags/CreateProductTags';
import { Product } from '../CreateProduct/Product/Product';
import { ISelect } from 'components/MainSelect/MainSelect';
import { IResTag } from 'api/services/tags/types';
import { IResFile } from 'api/services/files/types';
import { UserRole } from 'enums/users';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import './UpdateProduct.sass';
import { isCreateProductUser } from 'helpers/checkUser';

interface Props {
  closeModal: () => void;
  setActiveTab: (tab: number) => void;
  activeTab: number;
  tabs: { label: string; index: number }[];
  id?: number;
  getData?: () => {};
}

export const UpdateProduct: React.FC<Props> = ({
  setActiveTab,
  activeTab,
  tabs,
  closeModal,
  getData,
  id,
}) => {
  const [shopId, setShopId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [activateAt, setActivateAt] = useState(new Date());
  const [deactivateAt, setDeactivateAt] = useState(new Date());
  const [code, setCode] = useState('');
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [url, setUrl] = useState('');
  const [descriptionUz, setDescriptionUz] = useState('');
  const [descriptionRu, setDescriptionRu] = useState('');
  const [fileId, setFileId] = useState<number>();
  const [gallery, setGallery] = useState<any>([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState('');

  const [variants, setVariants] = useState<IResVariant[]>([]);
  const [variantTitleRu, setVariantTitleRu] = useState<string>('');
  const [variantTitleUz, setVariantTitleUz] = useState<string>('');
  const [productId, setProductId] = useState('');
  const [attributes, setAttributes] = useState<IVariantAttrubute[]>([]);
  const [arrivalPriceCurrencyId, setArrivalPriceCurrencyId] =
    useState<ISelect>();
  const [dillerPriceCurrencyId, setDillerPriceCurrencyId] = useState<ISelect>();
  const [retailPriceCurrencyId, setRetailPriceCurrencyId] = useState<ISelect>();
  const [arrivalPrice, setArrivalPrice] = useState('');
  const [dillerPrice, setDillerPrice] = useState('');
  const [retailPrice, setRetailPrice] = useState('');
  const [status, setStatus] = useState<ISelect>();
  const [price, setPrice] = useState('');
  const [attrFileId, setAttrFileId] = useState<number>();
  const [attrGallery, setAttrGallery] = useState<any[]>([]);
  const [selectedAttrUrl, setSelectedAttrUrl] = useState('');
  const [selectedGalleryAttrUrl, setSelectedGalleryAttrUrl] = useState<
    string[]
  >([]);
  const [count, setCount] = useState('');
  const [variantId, setVariantId] = useState<number>();
  const [brand, setBrand] = useState<any>();

  const { categoryId } = useParams<{ categoryId: string }>();
  const [take, setTake] = useState(15);
  const [page, setPage] = useState(0);
  const [attrs, setAttrs] = useState<IResAttribute[]>([]);
  const [attrPage, setAttrPage] = useState(0);
  const [attrTake, setAttrTake] = useState(15);
  const [numberAttrs, setNumberAttrs] = useState<any>([]);
  const [textAttrs, setTextAttrs] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<IResTag[]>([]);
  const [galleryFiles, setGalleryFiles] = useState<IResFile[]>([]);
  const [searchableBrandValue, setSearchableBrandValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [mainFile, setMainFile] = useState<IResFile>();
  const [attrFile, setAttrFile] = useState<IResFile>();
  const [searchableShopValue, setSearchableShopValue] = useState('');
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [optionsError, setOptionsError] = useState(false);
  const [optionChecked, setOptionChecked] = useState(false);
  const [updatedVariant, setUpdatedVariant] = useState(false);
  const [cashback, setCashback] = useState('');

  const { user } = useSelector((state: AppState) => state.profileReducer);
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  // useEffect(() => {
  //   if(attributes.length > 0){
  //     setNumberAttrs(attributes
  //       ?.filter((i: any) => i.type === 'NUMBER')
  //       .map((ii: any) => ({ id: ii.attributeId, label: ii.titleRu, text: ii.titleRu })) || [])
  //     setTextAttrs(attributes
  //       ?.filter((i: any) => i.type === 'TEXT')
  //       .map((ii: any) => ({ id: ii.attributeId, label: ii.titleRu, text: ii.titleRu })) || [])
  //   }
  // }, [attributes.length])

  useEffect(() => {
    setNumberAttrs(
      attrs
        ?.filter((i) => i.type === 'NUMBER')
        .map((ii) => ({
          id: ii.id,
          label: '',
          text: ii.titleRu,
          required: ii.required,
        })) || []
    );
    setTextAttrs(
      attrs
        ?.filter((i) => i.type === 'TEXT')
        .map((ii) => ({
          id: ii.id,
          label: '',
          text: ii.titleRu,
          required: ii.required,
        })) || []
    );
  }, [attrs]);

  useEffect(() => {
    getProductById();
    getVariants();
  }, [id]);

  useEffect(() => {
    getAttributes();
  }, [attrPage, attrTake]);

  const getAttributes = async () => {
    const params = {
      skip: attrTake * attrPage,
      take: 200,
      categoryId,
    };
    const res = await attributeServices
      .getAttributes(params)
      .then((res) => res.data);
    setAttrs(res.data);
  };

  const numberAttrsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number
  ) => {
    setNumberAttrs((prev: any) => {
      const arr = _.clone(prev);
      arr[index].label = e.target.value;
      return [...arr];
    });
    attributesChange({
      titleRu: e.target.value,
      titleUz: e.target.value,
      attributeId: Number(id),
    });
  };

  const textAttrsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number
  ) => {
    setTextAttrs((prev: any) => {
      const arr = _.clone(prev);
      lang === 'ru'
        ? (arr[index].titleRu = e.target.value)
        : (arr[index].titleUz = e.target.value);
      return [...arr];
    });
    attributesChange({
      titleRu: e.target.value,
      titleUz: e.target.value,
      attributeId: Number(id),
    });
  };

  const galleryAttrHanler = (data: string) => {
    setSelectedGalleryAttrUrl((prev) => [...prev, data]);
  };

  const getProductById = async () => {
    if (!id) return;
    const res = await productServices
      .getProductById(id)
      .then((res) => res.data);
    setShopId(res.shopId ? res.shopId.toString() : '');
    setBrandId(res.brand ? res.brand.id.toString() : '');
    setActivateAt(new Date(res.activateAt));
    setDeactivateAt(new Date(res.deactivateAt));
    setCode(res.code.toString());
    setTitleRu(res.titleRu);
    setTitleUz(res.titleUz);
    setUrl(res.url);
    setDescriptionUz(res.descriptionUz);
    setDescriptionRu(res.descriptionRu);
    setMainFile(res.file);
    setProductId(res.id.toString());
    setSelectedFileUrl(res.file ? `${res.file.name}.${res.file.ext}` : '');
    setGalleryFiles(res.gallery || []);
    setBrand(res.brand.name);
    setSelectedTags(res.tags);
    setSearchableBrandValue(res.brand.name);
    setSearchableShopValue(res.shop.name);
    setCashback(res.cashback ? res.cashback.toString() : '');
  };
  const getVariants = async () => {
    if (!id) return;
    const params = {
      productId: Number(id),
      skip: take * page,
      take: take,
    };
    const res = await variantsServices
      .getVariants(params)
      .then((res) => res.data);
    setVariants(res.data);
  };

  const updateProduct = async () => {
    if (!id) return;

    if (!activateAt || !deactivateAt) {
      showNotification('Заполните даты начала и окнчания');
      return;
    }
    if (
      !categoryId?.trim() ||
      (!titleRu.trim() && !titleUz.trim()) ||
      (!descriptionRu.trim() && !descriptionUz.trim())
    ) {
      showNotification('Заполните поля', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    if (!mainFile) {
      setHasError(true);
      showNotification('Добавьте изображения', NotificationStatus.WARNING);
      return;
    }
    const requestData = {
      brandId: Number(brandId),
      activateAt: new Date(activateAt),
      deactivateAt: new Date(deactivateAt),
      code,
      titleRu,
      titleUz,
      url,
      fileId: mainFile.id,
      descriptionUz,
      descriptionRu,
      gallery: galleryFiles.map((i) => i.id),
      tags: selectedTags.map((i) => i.id),
      ...(!!cashback.trim() ? { cashback } : {}),
    };
    try {
      await productServices.updateProduct(id, requestData);
      showNotification('Успешно сохранено', NotificationStatus.SUCCESS);
      try {
        if (getData) await getData();
        closeModal();
      } catch {}
    } catch {
      showNotification('Ошибка, попробуйте позже');
    }
  };

  const attributesChange = (data: IVariantAttrubute) => {
    const found = attributes?.some((i) => i.attributeId === data.attributeId);
    if (!found) {
      setAttributes((prev) => [...prev, data]);
    } else if (found) {
      let findIndex = attributes?.findIndex(
        (i) => i.attributeId === data.attributeId
      );
      const foundObj = isEqual(attributes[findIndex], data);
      if (!foundObj) {
        let result = attributes;
        let obj = result.find((i) => i.attributeId === data.attributeId);
        setAttributes([
          ...result.filter((i) => i.attributeId !== obj?.attributeId),
          {
            ...data,
            titleUz:
              lang === 'uz'
                ? data.titleUz
                : !!data.titleRu?.trim() || !!data.titleUz?.trim()
                ? obj?.titleUz
                : '',
            titleRu:
              lang === 'ru'
                ? data.titleRu
                : !!data.titleRu?.trim() || !!data.titleUz?.trim()
                ? obj?.titleRu
                : '',
          },
        ]);
      } else if (foundObj) {
        return;
      }
    }
  };

  const radioHandler = (e: boolean, data: IResVariant, update?: boolean) => {
    setVariantTitleRu(data.titleRu ? data.titleRu : '');
    setVariantTitleUz(data.titleUz ? data.titleUz : '');
    setArrivalPriceCurrencyId({
      label: data.arrivalPrice,
      value: data.arrivalPriceCurrencyId,
    });
    setDillerPriceCurrencyId({
      label: data.dillerPrice,
      value: data.dillerPriceCurrencyId,
    });
    setRetailPriceCurrencyId({
      label: data.retailPrice,
      value: data.retailPriceCurrencyId,
    });
    setArrivalPrice((data.arrivalPrice / 100).toString());
    setDillerPrice((data.dillerPrice / 100).toString());
    setRetailPrice((data.retailPrice / 100).toString());
    setStatus({
      label: data.status,
      value: data.status ? data.status : '',
    });
    setCount(data.count.toString());
    setSelectedAttrUrl(data.file ? `${data.file.name}.${data.file.ext}` : '');
    setSelectedGalleryAttrUrl(
      data.gallery ? data.gallery.map((i) => `${i.name}.${i.ext}`) : []
    );
    setVariantId(data.id);
    setAttrFile(data.file as any);
    setAttrGallery(data.gallery ? data.gallery : []);
    const findAttributes = attrs.map((i) => {
      const findAttr = data.variantAttributes?.find(
        (ii) => ii.attributeId === i.id
      );
      if (findAttr) {
        return {
          id: findAttr.attributeId,
          titleRu: findAttr.titleRu,
          titleUz: findAttr.titleUz,
          text: i.titleRu,
          required: i.required,
          type: i.type,
          attributeOptionId: findAttr.attributeOptionId,
        };
      }
      return {
        id: i.id,
        label: '',
        text: i.titleRu,
        required: i.required,
        type: i.type,
      };
    });
    setAttributes(
      findAttributes.map((i) => ({
        titleRu: i.titleRu,
        titleUz: i.titleUz,
        attributeId: i.id,
        attributeOptionId: i.attributeOptionId,
      }))
    );
    setNumberAttrs(
      findAttributes
        .filter((i) => i.type === 'NUMBER')
        .map((i) => ({ ...i, label: lang === 'ru' ? i.titleRu : i.titleUz }))
    );
    setTextAttrs(findAttributes.filter((i) => i.type === 'TEXT'));
    // setNumberAttrs(
    //   data.variantAttributes
    //     ? data.variantAttributes
    //         ?.filter((i) => i?.attribute?.type === 'NUMBER')
    //         .map((ii) => ({
    //           id: ii.attributeId,
    //           label: ii.titleRu,
    //           text: ii?.attribute?.titleRu,
    //           required: ii.attribute?.required,
    //         }))
    //     : []
    // );
    // setTextAttrs(
    //   data.variantAttributes
    //     ? data.variantAttributes
    //         ?.filter((i) => i?.attribute?.type === 'TEXT')
    //         .map((ii) => ({
    //           id: ii.attributeId,
    //           label: ii.titleRu,
    //           text: ii?.attribute?.titleRu,
    //           required: ii.attribute?.required,
    //         }))
    //     : []
    // );
    if (data.price) {
      setPrice((data.price / 100)?.toString() || '');
    }
  };

  const createVariant = async () => {
    if (optionsLoading) return;
    if (!variantTitleRu.trim() && !variantTitleUz.trim()) {
      setOptionsError(true);
      showNotification('Название не заполнено');
      return;
    }
    const requiredAttr = attrs.filter((i) => i.required);
    let validAttrs = false;
    requiredAttr.forEach((i) => {
      if (
        !attributes.some((ii) => ii.attributeId === i.id) ||
        attributes.some(
          (ii) =>
            ii.attributeId === i.id &&
            (ii.type === 'LIST' || ii.type === 'NUMBER') &&
            !titleRu.trim()
        )
      ) {
        validAttrs = true;
      }
    });
    if (validAttrs) {
      showNotification('Заполните атрибуты');
      setOptionsError(true);
      return;
    }
    if (!arrivalPrice.trim() || !dillerPrice.trim() || !retailPrice.trim()) {
      setOptionsError(true);
      showNotification('Укажите цены');
      return;
    }
    if (!status) {
      setOptionsError(true);
      showNotification('Укажите статус');
      return;
    }
    if (
      !arrivalPriceCurrencyId ||
      !dillerPriceCurrencyId ||
      !retailPriceCurrencyId
    ) {
      setOptionsError(true);
      showNotification('Укажите валюту');
      return;
    }
    if (!attrFile) {
      setOptionsError(true);
      showNotification('Загрузите изображения');
      return;
    }
    if (!count.trim()) {
      setOptionsError(true);
      showNotification('Укажите кол-во');
      return;
    }

    if (!productId) {
      setOptionsError(true);
      return;
    }
    setOptionsLoading(true);
    const data = {
      titleRu: variantTitleRu,
      titleUz: variantTitleUz,
      count: Number(count),
      productId: Number(productId),
      attributes: attributes.filter(
        (i) =>
          !!i.titleRu?.trim() || !!i.titleUz?.trim() || !!i.attributeOptionId
      ),
      arrivalPriceCurrencyId: Number(
        arrivalPriceCurrencyId?.value || arrivalPriceCurrencyId
      ),
      dillerPriceCurrencyId: Number(
        dillerPriceCurrencyId?.value || dillerPriceCurrencyId
      ),
      retailPriceCurrencyId: Number(
        retailPriceCurrencyId?.value || retailPriceCurrencyId
      ),
      arrivalPrice: Math.round(Number(arrivalPrice) * 100),
      dillerPrice: Math.round(Number(arrivalPrice) * 100),
      retailPrice: Math.round(Number(arrivalPrice) * 100),
      status: status?.value || status,
      fileId: attrFile?.id,
      gallery: attrGallery.map((i) => i.id),
      price: Number(price) * 100,
    };

    try {
      await variantsServices.createVariant(data);
      getVariants();
      setVariantTitleRu('');
      setVariantTitleUz('');
      setCount('');
      setAttributes([]);
      setArrivalPriceCurrencyId(undefined);
      setDillerPriceCurrencyId(undefined);
      setRetailPriceCurrencyId(undefined);
      setArrivalPrice('');
      setRetailPrice('');
      setDillerPrice('');
      setStatus(undefined);
      setAttrFileId(undefined);
      setAttrGallery([]);
      setPrice('');
      setVariantId(undefined);
      setSelectedGalleryAttrUrl([]);
      setAttrFile(undefined);
      setSelectedAttrUrl('');
      setNumberAttrs(
        attrs
          ?.filter((i) => i.type === 'NUMBER')
          .map((ii) => ({
            id: ii.id,
            label: '',
            text: ii.titleRu,
            required: ii.required,
          })) || []
      );
      setTextAttrs(
        attrs
          ?.filter((i) => i.type === 'TEXT')
          .map((ii) => ({
            id: ii.id,
            titleRu: '',
            titleUz: ii.titleRu,
            required: false,
          })) || []
      );
      setGallery([]);
      showNotification('Готово', NotificationStatus.SUCCESS);
      setOptionsError(false);
      setUpdatedVariant(true);
    } catch (e: any) {
      if (
        e.response.data.statusCode !== 409 &&
        e.response.data.statusCode !== 403
      ) {
        showNotification('Ошибка, попробуйте позже');
      }
    } finally {
      setUpdatedVariant(false);
      setOptionsLoading(false);
    }
  };
  const updateVariant = async () => {
    if (optionsLoading) return;
    const requiredAttr = attrs.filter((i) => i.required);
    let validAttrs = false;
    requiredAttr.forEach((i) => {
      const find = attributes.find((ii) => ii.attributeId === i.id);

      if (!find) {
        validAttrs = true;
      }

      if (
        (i?.type === 'TEXT' || i?.type === 'NUMBER') &&
        !find?.titleRu?.trim()
      ) {
        validAttrs = true;
      }
    });
    if (validAttrs) {
      showNotification('Заполните атрибуты');
      setOptionsError(true);
      return;
    }
    if (!status) {
      showNotification('Укажите статус');
      return;
    }
    if (
      !retailPriceCurrencyId ||
      !arrivalPriceCurrencyId ||
      !dillerPriceCurrencyId
    ) {
      showNotification('Укажите цены');
      return;
    }
    if (!attrFile) {
      showNotification('Загрзуите изображениея');
      return;
    }
    if (!variantId) {
      setOptionsError(true);
      return;
    }
    setOptionsLoading(true);
    const data = {
      titleRu: variantTitleRu,
      titleUz: variantTitleUz,
      count: Number(count),
      // productId: Number(productId),
      attributes: attributes.filter(
        (i) =>
          !!i.titleRu?.trim() || !!i.titleUz?.trim() || !!i.attributeOptionId
      ),
      arrivalPriceCurrencyId: Number(
        arrivalPriceCurrencyId.value || arrivalPriceCurrencyId
      ),
      dillerPriceCurrencyId: Number(
        dillerPriceCurrencyId.value || dillerPriceCurrencyId
      ),
      retailPriceCurrencyId: Number(
        retailPriceCurrencyId.value || retailPriceCurrencyId
      ),
      arrivalPrice: Math.round(Number(arrivalPrice) * 100),
      dillerPrice: Math.round(Number(dillerPrice) * 100),
      retailPrice: Math.round(Number(retailPrice) * 100),
      status: status.value || status,
      fileId: attrFile?.id,
      gallery: attrGallery.map((i) => i.id),
      price: Number(price) * 100,
    };
    try {
      await variantsServices.updateVariant(Number(variantId), data);
      setUpdatedVariant(true);
      getVariants();
      setVariantTitleRu('');
      setVariantTitleUz('');
      setCount('');
      setAttributes([]);
      setArrivalPriceCurrencyId(undefined);
      setDillerPriceCurrencyId(undefined);
      setRetailPriceCurrencyId(undefined);
      setArrivalPrice('');
      setRetailPrice('');
      setDillerPrice('');
      setStatus(undefined);
      setAttrFile(undefined);
      setAttrGallery([]);
      setPrice('');
      setSelectedGalleryAttrUrl([]);
      setVariantId(undefined);
      setSelectedAttrUrl('');
      setNumberAttrs(
        attrs
          ?.filter((i) => i.type === 'NUMBER')
          .map((ii) => ({
            id: ii.id,
            label: '',
            text: ii.titleRu,
            required: ii.required,
          })) || []
      );
      setTextAttrs(
        attrs
          ?.filter((i) => i.type === 'TEXT')
          .map((ii) => ({
            id: ii.id,
            titleRu: '',
            titleUz: '',
            text: ii.titleRu,
            required: ii.required,
          })) || []
      );
      showNotification('Готово', NotificationStatus.SUCCESS);
      setOptionsError(false);
      setOptionChecked(false);
    } catch (e: any) {
      if (
        e.response.data.statusCode !== 403 &&
        e.response.data.statusCode !== 409
      ) {
        showNotification('Ошибка, попробуйте позже');
      }
    } finally {
      setUpdatedVariant(false);
      setOptionsLoading(false);
    }
  };

  const deleteVariant = async (id: number) => {
    if (variants.length === 1) {
      showNotification('Должен остаться минимум 1 вариант');
      return;
    }
    try {
      await variantsServices.deleteVariant(id);
      getVariants();
      showNotification('Опция удалена ', NotificationStatus.SUCCESS);
    } finally {
    }
  };

  const setOptionAttr = (file?: IResFile, files?: IResFile[]) => {
    if (files) setAttrGallery(files);
    if (file) setAttrGallery((prev) => [...prev, file]);
  };

  const newTab = (index: number) => {
    setActiveTab(index);
  };

  const checkTab = () => {
    switch (activeTab) {
      case 2:
        return (
          <Options
            numberAttrs={numberAttrs}
            textAttrs={textAttrs}
            setNumberAttrs={numberAttrsChange}
            setTextAttrs={textAttrsChange}
            setProductId={setProductId}
            setAttributes={attributesChange}
            setStatus={setStatus}
            setPrice={setPrice}
            setTitleRu={setVariantTitleRu}
            setTitleUz={setVariantTitleUz}
            setMainFile={setAttrFile}
            mainFile={attrFile}
            setGallery={setOptionAttr}
            setCount={setCount}
            setSelectedFileUrl={setSelectedAttrUrl}
            setSelectedGalleryUrl={galleryAttrHanler}
            closeModal={closeModal}
            selectedFileUrl={selectedAttrUrl}
            selectedGalleryUrl={selectedGalleryAttrUrl}
            count={count}
            fileId={attrFileId}
            gallery={attrGallery}
            titleRu={variantTitleRu}
            titleUz={variantTitleUz}
            productId={productId}
            attributes={attributes}
            arrivalPrice={arrivalPrice}
            dillerPrice={dillerPrice}
            retailPrice={retailPrice}
            status={status}
            price={price}
            variants={variants}
            attrs={attrs}
            createVariant={createVariant}
            radioHandler={radioHandler}
            updateVariant={updateVariant}
            deleteHandler={deleteVariant}
            isUpdate={!!variantId}
            submit={updateProduct}
            arrivalPriceCurrencyId={arrivalPriceCurrencyId}
            setArrivalPriceCurrencyId={setArrivalPriceCurrencyId}
            dillerPriceCurrencyId={dillerPriceCurrencyId}
            setDillerPriceCurrencyId={setDillerPriceCurrencyId}
            retailPriceCurrencyId={retailPriceCurrencyId}
            setRetailPriceCurrencyId={setRetailPriceCurrencyId}
            setArrivalPrice={setArrivalPrice}
            setDillerPrice={setDillerPrice}
            setRetailPrice={setRetailPrice}
            actions={true}
            hasError={optionsError}
            checked={optionChecked}
            setChecked={setOptionChecked}
            updatedVariant={updatedVariant}
          />
        );
      case 3:
        return (
          <CreateProductTags
            selectTag={setSelectedTags}
            selectedTags={selectedTags}
          />
        );
      // case 4:
      //   return (
      //     <Price
      //       closeModal={closeModal}
      //       setCount={setCount}
      //       setArrivalPriceCurrencyId={setArrivalPriceCurrencyId}
      //       setDillerPriceCurrencyId={setDillerPriceCurrencyId}
      //       setRetailPriceCurrencyId={setRetailPriceCurrencyId}
      //       setArrivalPrice={setArrivalPrice}
      //       setDillerPrice={setDillerPrice}
      //       setRetailPrice={setRetailPrice}
      //       arrivalPriceCurrencyId={arrivalPriceCurrencyId}
      //       dillerPriceCurrencyId={dillerPriceCurrencyId}
      //       retailPriceCurrencyId={retailPriceCurrencyId}
      //       arrivalPrice={arrivalPrice}
      //       dillerPrice={dillerPrice}
      //       retailPrice={retailPrice}
      //       count={count}
      //       hasError={hasError}
      //     />
      //   );
      default:
        return (
          <Product
            mainFile={mainFile}
            setMainFile={setMainFile}
            galleryFiles={galleryFiles}
            setGalleryFiles={setGalleryFiles}
            selectedFileUrl={selectedFileUrl}
            setShopId={setShopId}
            setBrandId={setBrandId}
            setActivateAt={setActivateAt}
            setDeactivateAt={setDeactivateAt}
            setCode={setCode}
            setTitleRu={setTitleRu}
            setTitleUz={setTitleUz}
            setUrl={setUrl}
            setDescriptionUz={setDescriptionUz}
            setDescriptionRu={setDescriptionRu}
            shopId={shopId}
            brand={brand}
            activateAt={activateAt}
            deactivateAt={deactivateAt}
            code={code}
            titleRu={titleRu}
            titleUz={titleUz}
            url={url}
            descriptionUz={descriptionUz}
            descriptionRu={descriptionRu}
            isUpdate={true}
            searchableBrandValue={searchableBrandValue}
            setSearchableBrandValue={setSearchableBrandValue}
            hasError={hasError}
            setSearchableShopValue={setSearchableShopValue}
            searchableShopValue={searchableShopValue}
            cashback={cashback}
            setCashback={setCashback}
          />
        );
    }
  };

  return (
    <div className='update-product'>
      <div className='modal-tabs modal-tabs--inner update-product__tabs'>
        {tabs.map((i) => (
          <div
            className={cn('modal-tabs__item update-product__tabs-item', {
              'update-product__tabs-item--active': activeTab === i.index,
            })}
            key={i.index}
            onClick={() => newTab(i.index)}
          >
            <span>{i.label}</span>
          </div>
        ))}
        {activeTab !== 5 && <ChoiceLang />}
      </div>
      {checkTab()}
      {isCreateProductUser(user?.role) && (
        <ActionButtons closeModal={closeModal} submit={updateProduct} />
      )}
    </div>
  );
};
