import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { IShop } from 'api/services/shops/types';
import { MainInput } from 'components/MainInput/MainInput';
import { FileUploader } from 'components/FileUploader/FileUploader';
import './CreateShop.sass';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { IResFile } from 'api/services/files/types';
import { FileList } from 'components/FileList/FileList';
import showNotification from 'helpers/showNotification';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { FileModal } from '../FileModal/FileModal';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { InputType } from 'components/MainInput/types';

interface Props {
  submit: (data: IShop) => void;
  closeModal: () => void;
}

const statuses: ISelect[] = [
  {
    label: 'Активный',
    value: 'true',
  },
  {
    label: 'Неактивный',
    value: 'false',
  },
];

export const CreateShop: React.FC<Props> = ({ submit, closeModal }) => {
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [status, setStatus] = useState<ISelect>(statuses[1]);
  const [file, setFile] = useState<IResFile>();
  const [hasError, setHasError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [descriptionRu, setDescriptionRu] = useState('');
  const [descriptionUz, setDescriptionUz] = useState('');
  const [address, setAddress] = useState('');
  const [account, setAccount] = useState('');
  const [bank, setBank] = useState('');
  const [mfo, setMfo] = useState('');
  const [tin, setTin] = useState('');
  const [phone, setPhone] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  const { lang } = useSelector((state: AppState) => state.localeReducer);
  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !name.trim() ||
      !url.trim() ||
      (!descriptionRu.trim() && !descriptionUz.trim())
    ) {
      showNotification('Заполните поля');
      setHasError(true);
    }
    if (!file) {
      showNotification('Загрузите изображение');
      setHasError(true);
      return;
    }
    const isActive = status.value || status;

    submit({
      fileId: file.id,
      name,
      url,
      isActive: isActive === 'false' ? false : true,
      descriptionRu: descriptionRu,
      descritionUz: descriptionRu,
      address,
      mfo,
      tin,
      bank,
      phone,
      account,
      addtionalInformation: additionalInfo,
    });
  };

  const selectFile = (file: IResFile) => {
    setFile(file);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <FileModal
          fileSelected={selectFile}
          closeModal={() => setShowModal(false)}
        />
      )}
      <form className='create-shop' onSubmit={formSubmit}>
        <div className='create-shop__modal'>
          <div className='create-shop__tabs'>
            <div className='create-shop__tabs-item'>
              <span>Магазин</span>
            </div>
            <ChoiceLang />
          </div>
          <div className='create-shop__in'>
            <div className='create-shop__form'>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Название:</div>
                <MainInput
                  focused
                  updateInputValue={setName}
                  inputValue={name}
                  error={hasError && !name.trim()}
                />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Адрес:</div>
                <MainInput updateInputValue={setAddress} inputValue={address} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Расчетный счет:</div>
                <MainInput updateInputValue={setAccount} inputValue={account} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Банк:</div>
                <MainInput updateInputValue={setBank} inputValue={bank} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>МФО:</div>
                <MainInput updateInputValue={setMfo} inputValue={mfo} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>ИНН:</div>
                <MainInput updateInputValue={setTin} inputValue={tin} />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Телефон:</div>
                <MainInput
                  updateInputValue={setPhone}
                  inputValue={phone}
                  type={InputType.PHONE_NUMBER}
                />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>
                  Дополнительная информация:
                </div>
                <MainInput
                  updateInputValue={setAdditionalInfo}
                  inputValue={additionalInfo}
                />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>URL:</div>
                <MainInput
                  updateInputValue={setUrl}
                  inputValue={url}
                  error={hasError && !url.trim()}
                />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Статус:</div>
                <MainSelect
                  items={statuses}
                  selectItem={setStatus}
                  isGray
                  value={status}
                />
              </label>
              <label className='create-shop__form-row'>
                <div className='create-shop__form-label'>Описание:</div>
                {lang === 'ru' ? (
                  <MainInput
                    updateInputValue={setDescriptionRu}
                    inputValue={descriptionRu}
                    error={hasError && !descriptionRu.trim()}
                  />
                ) : (
                  <MainInput
                    updateInputValue={setDescriptionUz}
                    inputValue={descriptionUz}
                    error={hasError && !descriptionUz.trim()}
                    type={InputType.TEXTAREA}
                  />
                )}
              </label>
            </div>
            <div className='create-shop__form-row create-shop__form-row-uploader'>
              <div className='create-shop__uploader'>
                {!!file ? (
                  <FileList file={file} deleteFile={() => setFile(undefined)} />
                ) : (
                  <FileUploader setFile={setFile} error={hasError && !file} />
                )}
              </div>
              <button
                className='create-shop__choice-file main__button'
                onClick={() => setShowModal(true)}
                type='button'
              >
                Выбрать из файла
                <FolderIcon />
              </button>
            </div>
          </div>
        </div>
        <ActionButtons formSubmit={formSubmit} closeModal={closeModal} />
      </form>
    </>
  );
};
