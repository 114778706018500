import React, { useEffect, useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import './Settings.sass';
import { ISetting } from './types';
import { MainButton } from 'components/MainButton/MainButton';
import _ from 'lodash';

export interface Props {
  listName: string;
  items: ISetting[];
  confirm: (items: ISetting[]) => void;
  cancel: () => void;
}
export const Settings: React.FC<Props> = ({
  listName,
  items,
  confirm,
  cancel,
}) => {
  const [settingItems, setSettingItems] = useState<ISetting[]>(items);

  const checkAll = (all?: boolean) => {
    if (all)
      return setSettingItems((prev) =>
        prev.map((i) => ({ ...i, isActive: true }))
      );
    setSettingItems((prev) => prev.map((i) => ({ ...i, isActive: false })));
  };

  const checkItem = (item: ISetting, isChecked: boolean) => {
    setSettingItems((prev) => {
      const oldArr = _.clone(prev);
      const findIndex = oldArr.findIndex((i) => i.value === item.value);
      oldArr.splice(findIndex, 1, { ...item, isActive: isChecked });
      return oldArr;
    });
  };

  return (
    <div className='settings'>
      <h4 className='settings__title'>{`Настройка списка «${listName}»`}</h4>
      <div className='settings__checks'>
        <span
          className='settings__actions-check-all'
          onClick={() => checkAll(true)}
        >
          Выбрать все
        </span>
        <span
          className='settings__actions-check-all'
          onClick={() => checkAll()}
        >
          Отменить все
        </span>
      </div>
      <div className='settings__list'>
        {settingItems.map((i) => (
          <label className='settings__list-item' key={i.value}>
            <Checkbox
              isChecked={i.isActive}
              setChecked={(value: boolean) => checkItem(i, value)}
            />
            <span className='settings__list-item-name'>{i.label}</span>
          </label>
        ))}
      </div>
      <div className='settings__actions'>
        <MainButton type='button' title='Отменить' isCancel submit={cancel} />
        <MainButton title='Применить' submit={() => confirm(settingItems)} />
      </div>
    </div>
  );
};
