import React from 'react';
import cn from 'classnames';
import Star from 'assets/images/icons/star.svg';
import StarGray from 'assets/images/icons/star-gray.svg';

import './Rating.sass';

interface Props {
  className?: string;
  title?: string;
  size?: 'small';
  rating?: number;
  onClick?: (rating: number) => void;
}

export const Rating: React.FC<Props> = ({
  className = '',
  title,
  size,
  rating = 0,
  onClick,
}) => {
  return (
    <div
      className={cn('rating', {
        [className]: className,
        'rating--small': size,
      })}
    >
      {title && <div className='rating__title'>{title}</div>}
      <div className='rating__row'>
        <div className='rating__in'>
          {[1, 2, 3, 4, 5].map((i) => (
            <div
              key={i}
              className={cn('rating__item', {
                'rating__item--clickable': onClick,
                'rating__item--active': rating >= i,
              })}
              {...(onClick && rating !== i
                ? { onClick: () => onClick(i) }
                : {})}
            >
              <div className='rating__wrap'>
                <div className='rating__icon'></div>
                {/* <img src={Star} className="rating__icon rating__icon--yellow" />
                <img src={StarGray} className="rating__icon" /> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
