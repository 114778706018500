import { TransactionType } from 'api/services/transactions/types';
import { ISelect } from 'components/MainSelect/MainSelect';

export enum OrderStatus {
  NEW = 'NEW',
  CANCELED = 'CANCELED',
  ACCEPTED = 'ACCEPTED',
  ON_THE_WAY = 'ON_THE_WAY',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
}

export const orderStatuses = [
  {
    label: 'Обрабатываются',
    value: OrderStatus.NEW,
  },
  {
    label: 'Отмененные',
    value: OrderStatus.CANCELED,
  },
  {
    label: 'Принятые',
    value: OrderStatus.ACCEPTED,
  },
  {
    label: 'Доставляется',
    value: OrderStatus.ON_THE_WAY,
  },
  {
    label: 'Доставленные',
    value: OrderStatus.DELIVERED,
  },
];

export const paymentTypes: ISelect[] = [
  {
    label: 'Наличные',
    value: TransactionType.CASH,
  },
  {
    label: 'CLICK',
    value: TransactionType.CLICK,
  },
  {
    label: 'PAYME',
    value: TransactionType.PAYME,
  },
];
