import instance from 'api/instance';
import { ICreateStory, IGetStory, IgetStoryById, IUpdateStory } from './types';
import qs from 'query-string';

export const getStories: IGetStory = (storyGroupId) =>
  instance.get(
    `/stories?${qs.stringify({ storyGroupId }, { skipEmptyString: true })}`
  );

export const getStoryById: IgetStoryById = (id) =>
  instance.get(`/stories/${id}`);

export const createStory: ICreateStory = (data) =>
  instance.post('/stories', data);
export const updateStory: IUpdateStory = (id, data) =>
  instance.put(`/stories/${id}`, data);

export const deleteStory = (id: number) => instance.delete(`/stories/${id}`);
