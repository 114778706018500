import React, { useEffect, useState } from 'react';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { MainInput } from 'components/MainInput/MainInput';
import { ActionButtons } from '../../ActionButtons/ActionButtons';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import cn from 'classnames';

import './ProductAttributes.sass';
import { AttributeOptions } from 'components/AttributeOptions/AttributeOptions';
import {
  AttributeType,
  AttributeViewType,
  IAttributeOption,
} from 'api/services/attributes/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { attributeTypes, attributeViewTypes } from './settings';
import { MainButton } from 'components/MainButton/MainButton';
import { SelectList } from 'components/SelectList/SelectList';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { InputType } from 'components/MainInput/types';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { IResAttributeGroups } from 'api/services/attributesGroup/types';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';
import { attributeGroupServices } from 'api/services';
import { Checkbox } from 'components/Checkbox/Checkbox';

interface Props {
  closeModal: () => void;
  submit: (data: any) => void;
}

export const ProductAttributes: React.FC<Props> = ({ closeModal, submit }) => {
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  const [selectedType, setSelectedType] = useState<ISelect>();
  const [selectedView, setSelectedView] = useState<ISelect>(
    attributeViewTypes[0]
  );
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const [order, setOrder] = useState('');
  const [options, setOptions] = useState<IAttributeOption[]>([]);
  const [hasError, setHasError] = useState(false);
  const categories = useSelector((state: AppState) =>
    state.servicesReducer.categories.map((i) => ({
      label: i.titleRu,
      value: i.id,
    }))
  );
  const [isOption, setIsOption] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ISelect>();
  const [selectedCategories, setSelectedCategories] = useState<ISelect[]>([]);
  const [attributeGroups, setAttributeGroups] = useState<ISearchableSelect[]>(
    []
  );
  const [attributeGroupsLoading, setAttributeGroupsLoading] = useState(false);
  const [selectedAttributeGroup, setSelectedAttributeGroup] =
    useState<number>();
  const [attributeGroupsValue, setAttributeGroupValue] = useState('');
  const [required, setRequired] = useState(false);

  useEffect(() => {
    getAttributeGroups();
  }, []);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedType) {
      showNotification('Заполните поля', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    if (!titleRu.trim() && !titleUz.trim()) {
      showNotification('Заполните название', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    if (!selectedCategories.length) {
      showNotification('Выберите категорию', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    submit({
      titleRu,
      titleUz,
      categoryIds: selectedCategories.map((i) => i.value),
      options: options.map((i) => ({
        titleRu: i.titleRu,
        titleUz: i.titleUz,
        value: i.value,
      })),
      ...(selectedAttributeGroup
        ? { attributeGroupId: selectedAttributeGroup }
        : {}),
      required,
      viewType: selectedView?.value,
      type: selectedType?.value,
      order: Number(order),
      isOption,
    });
  };

  const getAttributeGroups = async () => {
    const res = await attributeGroupServices
      .getAttributeGroups({ skip: 0, take: 25 })
      .then((res) => res.data.data);
    setAttributeGroups(res.map((i) => ({ name: i.titleRu, value: i.id })));
    setAttributeGroupsLoading(false);
  };

  const addCategory = () => {
    if (!selectedCategory) {
      setHasError(true);
      showNotification('Выберите категорию');
      return;
    }
    if (selectedCategories.find((i) => i.value === selectedCategory.value))
      return;
    setSelectedCategories((prev) => [...prev, selectedCategory]);
  };

  const selectCategory = (value: string | number) => {
    const findCat = categories.find((i) => i.value === value);
    if (!findCat) return;

    setSelectedCategory(findCat);
  };

  const changeType = (type: AttributeType) => {
    setSelectedType(attributeTypes.find((i) => i.value === type));
  };
  const changeView = (type: AttributeViewType) => {
    const findView = attributeViewTypes.find((i) => i.value === type);
    if (!findView) return;
    setSelectedView(findView);
  };

  return (
    <form onSubmit={formSubmit} className='product-attributes'>
      <div className='product-attributes__modal'>
        <div className='product-attributes__tabs'>
          <div
            className={cn('product-attributes__tabs-item', {
              'product-attributes__tabs-item--active': activeTab === 1,
            })}
            onClick={() => setActiveTab(1)}
          >
            <span>Атрибут</span>
          </div>
          {selectedType?.value === AttributeType.LIST && (
            <div
              className={cn('product-attributes__tabs-item', {
                'product-attributes__tabs-item--active': activeTab === 2,
              })}
              onClick={() => setActiveTab(2)}
            >
              <span>Опции атрибута</span>
            </div>
          )}
          <ChoiceLang />
        </div>
        {activeTab === 1 ? (
          <div className='product-attributes__in'>
            {/* <div className='product-attributes__title'>ID: Новое</div> */}
            <div className='product-attributes__container'>
              <div className='product-attributes__container-item product-attributes__container-item--left'>
                <label className='product-attributes__item'>
                  <div className='product-attributes__title'>Обязательный</div>
                  <Checkbox isChecked={required} setChecked={setRequired} />
                </label>
                {selectedType?.value === AttributeType.LIST && (
                  <label className='product-attributes__item'>
                    <div className='product-attributes__title'>Опция</div>
                    <Checkbox isChecked={isOption} setChecked={setIsOption} />
                  </label>
                )}
                <label className='product-attributes__item'>
                  <div className='product-attributes__title'>Тип</div>
                  <MainSelect
                    items={attributeTypes}
                    selectItem={changeType}
                    value={selectedType}
                    withIcon
                    isGray
                    error={hasError && !selectedType}
                  />
                </label>
                <label className='product-attributes__item'>
                  <div className='product-attributes__title'>
                    Вид в умном фильтре
                  </div>
                  <MainSelect
                    items={attributeViewTypes}
                    selectItem={changeView}
                    value={selectedView}
                    isGray
                    withIcon
                  />
                </label>
                <label className='product-attributes__item'>
                  <div className='product-attributes__title'>Название</div>
                  {lang === 'ru' ? (
                    <MainInput
                      inputValue={titleRu}
                      error={hasError && !titleRu.trim()}
                      updateInputValue={setTitleRu}
                    />
                  ) : (
                    <MainInput
                      inputValue={titleUz}
                      error={hasError && !titleUz.trim()}
                      updateInputValue={setTitleUz}
                    />
                  )}
                </label>
                <label className='product-attributes__item'>
                  <div className='product-attributes__title'>Группа</div>
                  <CustomSearchableSelect
                    items={[{ name: 'Нет', value: null }, ...attributeGroups]}
                    loading={attributeGroupsLoading}
                    setValue={getAttributeGroups}
                    setLoading={setAttributeGroupsLoading}
                    setSelectItem={setSelectedAttributeGroup}
                    setSearchValue={setAttributeGroupValue}
                    searchValue={attributeGroupsValue}
                  />
                </label>
                <label className='product-attributes__item'>
                  <div className='product-attributes__title'>Сортировка</div>
                  <MainInput
                    inputValue={order}
                    error={hasError && !order.trim()}
                    updateInputValue={setOrder}
                    type={InputType.NUMBER}
                  />
                </label>

                <label className='product-attributes__item product-attributes__item--with-button'>
                  <div className='product-attributes__title'>Категории</div>
                  <div className='product-attributes__item-container'>
                    <MainSelect
                      items={categories}
                      selectItem={selectCategory}
                      value={selectedCategory}
                      error={!selectedCategories.length && hasError}
                      isGray
                      isSearchable
                    />
                    <MainButton title='Добавить' submit={addCategory} />
                  </div>
                </label>
                <div className='product-attributes__item'>
                  <SelectList
                    list={selectedCategories}
                    updateList={setSelectedCategories}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <AttributeOptions
            list={options}
            updateList={setOptions}
            lang={lang}
            hasColor={
              selectedView.value === AttributeViewType.COLOR &&
              selectedType?.value === AttributeType.LIST
            }
          />
        )}
      </div>
      <ActionButtons formSubmit={formSubmit} closeModal={closeModal} />
    </form>
  );
};
