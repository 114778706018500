import { ISelect } from 'components/MainSelect/MainSelect';

export const variantStatus: ISelect[] = [
  {
    label: 'UNDER_CONSIDERATION',
    value: 'UNDER_CONSIDERATION',
  },
  {
    label: 'VERIFIED',
    value: 'VERIFIED',
  },
  {
    label: 'REJECTED',
    value: 'REJECTED',
  },
  {
    label: 'DELETED',
    value: 'DELETED',
  },
];

export enum VariantStatusTypes {
  UNDER_CONSIDERATION = 'UNDER_CONSIDERATION',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}
