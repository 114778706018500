import React from 'react';
import { Link } from 'react-router-dom';
import { IBreadCrumb } from 'store/ducks/template/types';

import './Breadcrumbs.sass';

interface Props {
  items: IBreadCrumb[];
}

export const Breadcrumbs: React.FC<Props> = ({ items }) => {
  const changeRoute = (e: React.MouseEvent, id?: number | string) => {
    if (!id) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  return (
    <div className='bread-crumbs'>
      {items.map((i, index) => (
        <Link
          to={`/${i.id}`}
          onClick={(e) => changeRoute(e, i.id)}
          className='bread-crumbs__name'
          key={index}
        >
          <span></span>
          {i.title}
        </Link>
      ))}
    </div>
  );
};
