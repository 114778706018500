import instance from 'api/instance';
import {
  ICreateAttributeGroups,
  IGetAttributeGroups,
  IGetAttributeGroupsById,
  IUpdateAttributeGroups,
} from './types';
import qs from 'query-string';

export const getAttributeGroups: IGetAttributeGroups = (attrs) =>
  instance.get(
    `/attributeGroups?${qs.stringify(attrs, { skipEmptyString: true })}`
  );

export const getAttributeGroupsById: IGetAttributeGroupsById = (id) =>
  instance.get(`/attributeGroups/${id}`);

export const createAttributeGroups: ICreateAttributeGroups = (data) =>
  instance.post('/attributeGroups', data);

export const updateAttributeGroups: IUpdateAttributeGroups = (id, data) =>
  instance.put(`/attributeGroups/${id}`, data);

export const deleteAttributeGroups = (id: number) =>
  instance.delete(`/attributeGroups/${id}`);
