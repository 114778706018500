import React, { useState } from 'react';
import cn from 'classnames';
import { IModalTab } from 'components/Modal/types';
import { UploadArticles } from './UploadArticles/UploadArticles';

import { UploadBlogs } from './UploadBlogs/UploadBlogs';
import { Tags } from 'components/Tags/Tags';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { IResTag } from 'api/services/tags/types';
import { IArticle, IResArticle } from 'api/services/articles/types';
import { articleServices } from 'api/services';
import { UpdateArticle } from './UpdateArticle/UpdateArticle';
import './Moderation.sass';

interface Props {
  closeModal: () => void;
  setActiveTab: (tab: number) => void;
  activeTab: number;
  tabs: IModalTab[];
  submitUploadArticle?: (article: IArticle) => void;
  submitUpdateArticle?: (article: IArticle, id: number) => void;
  article?: IResArticle;
  loading: boolean;
}

export const Moderation: React.FC<Props> = ({
  setActiveTab,
  activeTab,
  tabs,
  submitUploadArticle,
  submitUpdateArticle,
  closeModal,
  article,
  loading,
}) => {
  const submitUploadBlogs = () => {};
  const checkTab = () => {
    switch (activeTab) {
      case 2:
        return <UploadBlogs submit={submitUploadBlogs} />;
      default:
        return article ? (
          <UpdateArticle
            submit={submitUpdateArticle}
            isTags={activeTab === 5}
            closeModal={closeModal}
            article={article}
            loading={loading}
          />
        ) : (
          <UploadArticles
            submit={submitUploadArticle}
            isTags={activeTab === 5}
            closeModal={closeModal}
            loading={loading}
          />
        );
    }
  };

  return (
    <div className='moderation'>
      <div className='modal-tabs modal-tabs--inner moderation__tabs'>
        {tabs.map((i) => (
          <div
            className={cn('modal-tabs__item moderation__tabs-item', {
              'moderation__tabs-item--active': activeTab === i.index,
            })}
            key={i.index}
            onClick={() => setActiveTab(i.index)}
          >
            <span>{i.label}</span>
          </div>
        ))}
        <ChoiceLang />
      </div>
      <div className='moderation__in'>{checkTab()}</div>
    </div>
  );
};
