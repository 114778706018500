import React, { useEffect, useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { MainInput } from 'components/MainInput/MainInput';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import { InputType } from 'components/MainInput/types';
import { Switch } from 'components/Switch/Switch';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { MainButton } from 'components/MainButton/MainButton';
import './UploadBanner.sass';
import { RadioType } from 'enums/radioType';
import { IBanner, IResBanner } from 'api/services/banners/types';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { BannerTypes } from 'enums/bannerTypes';
import { bannerServices, categoryServices, shopServices } from 'api/services';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import {
  ISearchableSelect,
  SearchableSelect,
} from 'components/SearchableSelect/SearchableSelect';
import { ActionButtons } from '../../ActionButtons/ActionButtons';
import { IResShop } from 'api/services/shops/types';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { IResCategory } from 'api/services/categories/types';
import { FileModal } from '../../FileModal/FileModal';
import { IResFile } from 'api/services/files/types';
import showNotification from 'helpers/showNotification';
import { useParams } from 'react-router';

interface Props {
  submit: (data: IBanner) => void;
  closeModal: () => void;
}

export const UploadBanner: React.FC<Props> = ({ submit, closeModal }) => {
  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [type, setType] = useState<BannerTypes>();
  const [isMobile, setIsMobile] = useState(false);
  const { key } = useSelector((state: AppState) => state.localeReducer);
  const [desktopFile, setDesktopFile] = useState<IResFile>();
  const [mobileFile, setMobileFile] = useState<IResFile>();
  const [url, setUrl] = useState('');
  const [selectedShopId, setSelectedShopId] = useState<number>();
  const [searchableShopValue, setSearchableShopValue] = useState('');
  const [searchableCategoryValue, setSearchableCategoryValue] = useState('');
  const [categories, setCategories] = useState<IResCategory[]>([]);
  const [showFileModal, setShowFileModal] = useState(false);
  const [searchableCategories, setSearchableCategories] = useState<
    ISearchableSelect[]
  >([]);
  const [searchableShops, setSearchableShops] = useState<ISearchableSelect[]>(
    []
  );
  const [searchableCategoriesLoading, seetSearchableCategoriesLoading] =
    useState(false);
  const [searchableShopsLoading, setSearchableShopsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<IResCategory[]>(
    []
  );
  const { bannerType } = useParams<{ bannerType: string }>();
  useEffect(() => {
    if (bannerType) setType(bannerType.toUpperCase() as BannerTypes);
  }, []);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !name.trim() ||
      !desktopFile ||
      !selectedCategories.length ||
      !url.trim() ||
      !selectedShopId
    ) {
      showNotification('Заполните поля');
      setHasError(true);
      return;
    }
    if (!mobileFile) {
      showNotification('Загрузите изображение для мобильной версии');
      setHasError(true);
      return;
    }

    if (!type) {
      showNotification('Тип не найден');
      return;
    }

    submit({
      name,
      fileId: desktopFile.id,
      mobileFileId: mobileFile.id,
      categoryIds: selectedCategories.map((i) => i.id),
      type: type.replaceAll('-', '_') as BannerTypes,
      isActive,
      url,
      shopId: selectedShopId,
    });
  };

  useEffect(() => {
    getShops();
    getCategories();
  }, []);

  const getShops = async (value?: string) => {
    const res = await shopServices
      .getShops({
        skip: 0,
        take: 25,
        search: value && value.length < 2 ? '' : value,
      })
      .then((res) => res.data);
    setSearchableShops(
      res.data.map((i) => ({
        name: i.name,
        value: i.id,
      }))
    );
  };

  const selectFileMobile = (file: any) => {
    setMobileFile(file);
  };

  const getCategories = async (value?: string) => {
    const res = await categoryServices
      .getCategories({
        search: value && value.length < 2 ? '' : value,
      })
      .then((res) => res.data);
    const postCtg = res.map((i) => ({
      name: i.titleRu,
      value: i.id,
    }));
    setSearchableCategories(postCtg);
    setCategories(res);
  };

  const selectCategory = (id: number) => {
    const findObj = categories.find((i) => i.id === id);
    if (!selectedCategories.some((i) => i.id === id) && findObj) {
      setSelectedCategories((prev) => [...prev, findObj]);
    }
  };
  const selectModalFile = (file: IResFile) => {
    if (isMobile) {
      setMobileFile(file);
      setShowFileModal(false);
      return;
    }
    setShowFileModal(false);
    setDesktopFile(file);
  };

  return (
    <form onSubmit={formSubmit} className='upload-banner'>
      {showFileModal && (
        <FileModal
          closeModal={() => setShowFileModal(false)}
          fileSelected={selectModalFile}
        />
      )}
      <div className='upload-banner__modal'>
        <div className='upload-banner__row'>
          <label className='upload-banner__row-item'>
            <div className='upload-banner__label'>Активность:</div>
            <Checkbox isChecked={isActive} setChecked={setIsActive} />
          </label>
        </div>
        <div className='upload-banner__row'>
          <label className='upload-banner__row-item'>
            <div className='upload-banner__label'>Нзвание</div>
            <MainInput
              inputValue={name}
              updateInputValue={setName}
              error={hasError && !name.trim()}
            />
          </label>
        </div>
        <div className='upload-banner__row'>
          <label className='upload-banner__row-item'>
            <div className='upload-banner__label'>Url:</div>
            <MainInput
              inputValue={url}
              updateInputValue={setUrl}
              error={hasError && !url.trim()}
            />
          </label>
        </div>
        {/* <div className='upload-banner__row'>
          <label className='upload-banner__row-item'>
            <div className='upload-banner__label'>Тип баннера</div>
            <MainSelect
              items={bannerTypes}
              selectItem={setType}
              value={type}
              isGray
              error={hasError && !type}
            />
          </label>
        </div> */}
        <div className='upload-banner__row'>
          <label className='upload-banner__row-item'>
            <div className='upload-banner__label'>Магазин</div>
            <CustomSearchableSelect
              items={searchableShops}
              loading={searchableShopsLoading}
              setLoading={setSearchableShopsLoading}
              setSelectItem={setSelectedShopId}
              setValue={getShops}
              searchValue={searchableShopValue}
              error={!selectedShopId && hasError}
              setSearchValue={setSearchableShopValue}
            />
          </label>
        </div>
        <div className='upload-banner__row'>
          <label className='upload-banner__row-item'>
            <div className='upload-banner__label'>Привязать категорию</div>
            <CustomSearchableSelect
              items={searchableCategories}
              loading={searchableCategoriesLoading}
              setValue={getCategories}
              setLoading={seetSearchableCategoriesLoading}
              setSelectItem={selectCategory}
              setSearchValue={setSearchableCategoryValue}
              searchValue={searchableCategoryValue}
              error={!selectedCategories.length && hasError}
            />
          </label>
        </div>
        <div className='upload-banner__row upload-banner__row--with-list'>
          <div className='upload-banner__row-list'>
            {selectedCategories.map((i) => (
              <div className='upload-banner__row-list-item' key={i.id}>
                <span>{i.titleRu}</span>
                <div
                  className='upload-banner__row-list-item-close'
                  onClick={() =>
                    setSelectedCategories((prev) =>
                      prev.filter((ii) => ii.id !== i.id)
                    )
                  }
                >
                  <CloseIcon />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='upload-banner__row upload-banner__row--uploader'>
          <div className='upload-banner__label'>Загрузка изображения</div>
          <div className='upload-banner__row-top'>
            <Switch
              value={isMobile}
              onChange={(e) => setIsMobile(e.target.checked)}
              toggleTitle='Десктоп'
              toggleTitle2='Мобильный'
            />
            <span
              className='upload-banner__button main__button'
              onClick={() => setShowFileModal(true)}
            >
              Выбрать из файла <FolderIcon />
            </span>
          </div>
          {!isMobile && (
            <FileUploader
              setFile={setDesktopFile}
              file={desktopFile}
              error={!desktopFile && hasError}
            />
          )}
          {isMobile && (
            <FileUploader
              setFile={setMobileFile}
              file={mobileFile}
              error={!mobileFile && hasError}
            />
          )}
        </div>
      </div>
      <ActionButtons formSubmit={formSubmit} closeModal={closeModal} />
    </form>
  );
};
