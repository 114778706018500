import React, { useEffect, useState } from 'react';
import { Modal } from 'components/Modal/Modal';
import { Filter } from 'components/Filter/Filter';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CreateCategory } from 'components/Modal/components/CreateCategory/CreateCategory';
import { ICategory, IResCategory } from 'api/services/categories/types';
import { categoryServices } from 'api/services';
import { AppState } from 'store/store';
import * as serviceActions from 'store/ducks/services/actions';
import { TableFolders } from 'components/Table/components/TableFolders';
import { ISelect } from 'components/MainSelect/MainSelect';
import { marginTypes } from 'enums/marginType';
import { UpdateCategory } from 'components/Modal/components/UpdateCategory/UpdateCategory';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';

import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { arrayToTree } from 'performant-array-to-tree';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { ISetting } from 'components/Modal/components/Settings/types';
import * as settingActions from 'store/ducks/settings/actions';
import './CatalogCategoriesPage.sass';
import { isEditUser } from 'helpers/checkUser';

export const CatalogCategoriesPage: React.FC = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [name, setName] = useState('');
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [parentId, setParentId] = useState('');
  const [marginType, setMarginType] = useState<ISelect>(marginTypes[0]);
  const [value, setValue] = useState('');
  const [updateCategoryId, setUpdateCategoryId] = useState<number>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [order, setOrder] = useState('');
  const { categoryId } = useParams<{ categoryId: string }>();
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<IResCategory[]>([]);
  const [all, setAll] = useState(true);
  const [showSettings, setShowSettings] = useState(false);

  const dispatch = useDispatch();

  const { ids } = useSelector((state: AppState) => state.categoryChildsReducer);
  const { catalogCategoriesSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );
  const { user } = useSelector((state: AppState) => state.profileReducer);
  const { pathname } = useLocation();
  const history = useNavigate();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        {
          title: 'Каталог',
          id: 'catalog/categories',
        },
      ])
    );
  }, [categoryId, pathname, ids]);

  useEffect(() => {
    getData();
  }, [searchValue]);

  const closeModal = () => {
    setShowCreateModal(false);
    setShowUpdateModal(false);
    setTitleRu('');
    setTitleUz('');
    setMarginType(marginTypes[0]);
    setValue('');
    setParentId('');
    setName('');
    setOrder('');
    setUpdateCategoryId(undefined);
  };

  const sendData = async (data: ICategory) => {
    setSubmitLoading(true);
    try {
      await categoryServices.createCategory(data);
      dispatch(serviceActions.getCategories());
      showNotification('Категория успешно создана', NotificationStatus.SUCCESS);
      closeModal();
      getData();
    } catch (e: any) {
      if (e.response.data.statusCode !== 409) {
        showNotification('Ошибка, попробуйте позже');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const attrs = {
        search: searchValue,
        ...(all ? { all } : {}),
      };
      const res = await categoryServices
        .getCategories(attrs)
        .then((res) => res.data);
      const arrayWithIcon = res.map((i) => ({
        ...i,
        hasIcon: true,
        url: `/catalog/categories/${i.id}/products`,
      }));
      if (
        arrayWithIcon.some(
          (i) =>
            arrayWithIcon.some((ii) => ii.id !== i.parentId) && !!searchValue
        )
      ) {
        return setCategories(arrayWithIcon);
      }
      const tree = arrayToTree(arrayWithIcon, {
        childrenField: 'items',
        dataField: '',
      }) as ICategory[];
      setCategories(tree as IResCategory[]);
    } finally {
      setLoading(false);
    }
  };

  const updateData = async (data: ICategory) => {
    if (!updateCategoryId) return;
    setSubmitLoading(true);
    try {
      await categoryServices.updateCategory(updateCategoryId, data);
      dispatch(serviceActions.getCategories());
      showNotification(
        'Категория изменена успешно',
        NotificationStatus.SUCCESS
      );
      closeModal();
      getData();
    } catch (e: any) {
      if (
        e.response.data.statusCode !== 409 &&
        e?.response.data.statusCode !== 403
      ) {
        showNotification('Ошибка, попробуйте позже');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const openModal = async (id: number) => {
    if (!isEditUser(user?.role)) {
      history(`/catalog/categories/${id}/products`);
      return;
    }
    setShowUpdateModal(true);
    setUpdateCategoryId(id);
  };

  const deleteItem = async (id: number) => {
    try {
      await categoryServices.deleteCategory(id);
      dispatch(serviceActions.getCategories());
      showNotification('Категория удалена!', NotificationStatus.SUCCESS);
    } catch (e: any) {
      if (e?.response?.data.statusCode !== 422) {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  const closeFilter = () => {
    setFilterShowModal(false);
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setCatalogCategoriesSettings(items));
    setShowSettings(false);
  };

  const newFilterValue = (value: string) => {
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Категории'
            items={catalogCategoriesSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={closeModal}>
          <CreateCategory
            submit={sendData}
            name={name}
            setName={setName}
            titleRu={titleRu}
            titleUz={titleUz}
            setTitleRu={setTitleRu}
            setTitleUz={setTitleUz}
            parentId={parentId}
            setParentId={setParentId}
            value={value}
            setValue={setValue}
            marginType={marginType}
            setMarginType={setMarginType}
            closeModal={closeModal}
            loading={submitLoading}
            order={order}
            setOrder={setOrder}
          />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal closeModal={closeModal}>
          <UpdateCategory
            submit={updateData}
            name={name}
            setName={setName}
            titleRu={titleRu}
            titleUz={titleUz}
            setTitleRu={setTitleRu}
            setTitleUz={setTitleUz}
            parentId={parentId}
            setParentId={setParentId}
            value={value}
            setValue={setValue}
            marginType={marginType}
            setMarginType={setMarginType}
            loading={submitLoading}
            closeModal={closeModal}
            categoryId={updateCategoryId}
            setOrder={setOrder}
            order={order}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={setSearchValue}
            submit={getData}
            closeFilter={closeFilter}
          >
            <div className='catalog-categories-page__filter-inputs'>
              <label className='catalog-categories-page__filter-inputs-item'>
                <Checkbox isChecked={all} setChecked={setAll} />
                <span className='catalog-categories-page__filter-inputs-item-label'>
                  Все
                </span>
              </label>
            </div>
          </ModalFilter>
        </Modal>
      )}
      <div className='page catalog-categories-page'>
        <Filter
          createFunc={
            isEditUser(user?.role) ? () => setShowCreateModal(true) : undefined
          }
          filterFunc={() => setFilterShowModal(true)}
          settingFunc={() => setShowSettings(true)}
          updateSearchValue={newFilterValue}
          search={getData}
          loading={loading}
        />
        <div className='page__main-content'>
          <TableFolders
            tableHead={catalogCategoriesSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            updateItem={openModal}
            tableBody={{
              keys: catalogCategoriesSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
              data: categories,
            }}
            withIcons
            items={categories}
            setCheckedIds={setCheckedIds}
            checkedIds={checkedIds}
            deleteHandler={isEditUser(user?.role) ? deleteItem : undefined}
            sorting
          />
        </div>
      </div>
    </>
  );
};
