import instance from 'api/instance';
import {
  ICreateSupplementaryService,
  IGetSupplementaryService,
  IGetSupplementaryServiceById,
  IUpdateSupplementaryService,
} from './types';
import qs from 'query-string';

export const getSupplementaryServices: IGetSupplementaryService = (attrs) =>
  instance.get(
    `/supplementary-services?${qs.stringify(attrs, { skipEmptyString: true })}`
  );

export const getSupplementaryServiceById: IGetSupplementaryServiceById = (
  id: number
) => instance.get(`/supplementary-services/${id}`);

export const createSupplementaryService: ICreateSupplementaryService = (data) =>
  instance.post(`/supplementary-services`, data);

export const updateSupplementaryService: IUpdateSupplementaryService = (
  id,
  data
) => instance.put(`/supplementary-services/${id}`, data);

export const deleteSupplementaryService = (id: number) =>
  instance.delete(`/supplementary-services/${id}`);
