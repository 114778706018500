import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IKnowledgeBase } from 'api/services/knowledge-base/types';
import { MainInput } from 'components/MainInput/MainInput';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { ActionButtons } from 'components/Modal/components/ActionButtons/ActionButtons';
import { HtmlEditor, HtmlEditor2 } from 'components/Editor/HtmlEditor';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { Checkbox } from 'components/Checkbox/Checkbox';
import './CreateKnowledgeBase.sass';

interface Props {
  submit: (data: IKnowledgeBase) => void;
  closeModal: () => void;
  loading: boolean;
}

export const CreateKnowledgeBase: React.FC<Props> = ({
  submit,
  closeModal,
  loading,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [video, setVideo] = useState('');
  const [descriptionRedactorRu, setDescriptionRedactorRu] = useState('');
  const [descriptionRedactorUz, setDescriptionRedactorUz] = useState('');
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [hasError, setHasError] = useState(false);
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if (!titleRu.trim() && !titleUz.trim()) {
      showNotification('Заполните название', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    if (!descriptionRedactorRu.trim() && !descriptionRedactorUz.trim()) {
      showNotification('Заполнте описание', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    submit({
      titleRu,
      titleUz,
      video,
      descriptionRu: descriptionRedactorRu,
      descriptionUz: descriptionRedactorUz,
      isActive,
    });
    setHasError(false);
  };

  return (
    <form onSubmit={formSubmit} className='create-knowledge-base '>
      <div className='create-knowledge-base__modal'>
        <div className='create-knowledge-base__tabs'>
          <div className='create-knowledge-base__tabs-item'>
            <span>Загрузка базы знаний</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='create-knowledge-base__in'>
          <div className='create-knowledge-base__form'>
            <div className='create-knowledge-base__form-row'>
              <div className='create-knowledge-base__form-label'>Активный:</div>
              <Checkbox isChecked={isActive} setChecked={setIsActive} />
            </div>
            <label className='create-knowledge-base__form-row'>
              <div className='create-knowledge-base__form-label'>Название:</div>
              {lang === 'ru' ? (
                <MainInput
                  error={hasError && !titleRu.trim()}
                  updateInputValue={setTitleRu}
                  inputValue={titleRu}
                />
              ) : (
                <MainInput
                  error={hasError && !titleUz.trim()}
                  updateInputValue={setTitleUz}
                  inputValue={titleUz}
                />
              )}
            </label>
            <label className='create-knowledge-base__form-row'>
              <div className='create-knowledge-base__form-label'>Видео:</div>
              <MainInput updateInputValue={setVideo} inputValue={video} />
            </label>
            <div className='create-knowledge-base__form-description'>
              <div className='create-knowledge-base__form-label'>Описание</div>
              {lang === 'ru' ? (
                <HtmlEditor
                  value={descriptionRedactorRu}
                  onChange={setDescriptionRedactorRu}
                  error={hasError && !descriptionRedactorRu.trim()}
                />
              ) : (
                <HtmlEditor2
                  value={descriptionRedactorUz}
                  onChange={setDescriptionRedactorUz}
                  error={hasError && !descriptionRedactorUz.trim()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
    </form>
  );
};
