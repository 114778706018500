import React, { ReactNode, useEffect, useRef } from 'react';
import CheckIcon from 'assets/images/icons/check-icon.svg';
import { ReactComponent as GlassIcon } from 'assets/images/icons/glass-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow-icon.svg';
import { IMaskInput } from 'react-imask';
import { InputType } from './types';
import cn from 'classnames';

import './MainInput.sass';

interface Props {
  type?: InputType;
  rows?: number;
  Icon?: ReactNode;
  placeholder?: string;
  inc?: () => void;
  dec?: () => void;
  updateInputValue?: (value: string) => void;
  inputValue?: string;
  focused?: boolean;
  error?: boolean;
  disabled?: boolean;
  setFocus?: (value: boolean) => void;
}

export const MainInput: React.FC<Props> = ({
  type = InputType.TEXT,
  Icon,
  rows,
  placeholder,
  inc,
  dec,
  updateInputValue,
  inputValue = '',
  focused,
  error,
  disabled,
  setFocus,
}) => {
  const inputRef = useRef<any>();
  const updateInputNumberValue = (value: string) => {
    if (!updateInputValue) return;
    if (/^[0-9.,+]+$/.test(value) || value.length === 0) {
      updateInputValue(value.replaceAll(',', '.') || '');
    }
  };

  useEffect(() => {
    if (!!focused) inputRef?.current?.focus();
  }, []);

  const updatePhoneValue = (e: any) => {
    if (!updateInputValue) return;
    updateInputValue(e.target.value.replaceAll(' ', ''));
  };
  const checkInputType = () => {
    if (!updateInputValue) return <></>;
    switch (type) {
      case InputType.DATE:
        return (
          <input
            disabled={disabled}
            placeholder={placeholder || ''}
            type='date'
            value={inputValue}
            ref={inputRef}
            lang='ru-RU'
            className={cn('main-input main-input--date', {
              'main-input--error': error,
              'main-input--disabled': disabled,
            })}
            onChange={(e) => updateInputValue(e.target.value)}
          />
        );
      case InputType.TEXTAREA:
        return (
          <textarea
            placeholder={placeholder || ''}
            value={inputValue}
            className={cn('main-input main-input--textarea', {
              'main-input--error': error,
              'main-input--disabled': disabled,
            })}
            onChange={(e) => updateInputValue(e.target.value)}
            rows={rows || 3}
          />
        );
      case InputType.TEXT_WITH_ICON:
        return (
          <div
            className={cn('main-input--with-icon', {
              'main-input--with-icon--active': !!inputRef?.current?.focused,
            })}
          >
            <input
              disabled={disabled}
              placeholder={placeholder || ''}
              type='text'
              ref={inputRef}
              value={inputValue}
              className={cn('main-input', {
                'main-input--error': error,
                'main-input--disabled': disabled,
              })}
              onFocus={() => !!setFocus && setFocus(true)}
              onBlur={() => !!setFocus && setFocus(false)}
              onChange={(e) => updateInputValue(e.target.value)}
            />
            <div className='main-input__icon'>{Icon}</div>
          </div>
        );
      case InputType.PASSWORD:
        return (
          <input
            disabled={disabled}
            placeholder={placeholder || ''}
            type='password'
            ref={inputRef}
            value={inputValue}
            className={cn('main-input', {
              'main-input--error': error,
              'main-input--disabled': disabled,
            })}
            onChange={(e) => updateInputValue(e.target.value)}
          />
        );
      case InputType.SELECT:
        return (
          <div className='main-input--with-icon main-input--select'>
            <input
              disabled={disabled}
              placeholder={placeholder}
              type='text'
              ref={inputRef}
              className={cn('main-input', {
                'main-input--error': error,
                'main-input--disabled': disabled,
              })}
              value={inputValue}
              onChange={(e) => updateInputValue(e.target.value)}
            />
            <div className='main-input__icon'>{Icon}</div>
          </div>
        );
      case InputType.SEARCH:
        return (
          <div className='main-input main-input--search'>
            <input
              disabled={disabled}
              placeholder={placeholder || ''}
              value={inputValue}
              ref={inputRef}
              type='text'
              onChange={(e) => updateInputValue(e.target.value)}
            />
            <button className='main-input main-input--search-button'>
              <GlassIcon />
            </button>
          </div>
        );
      case InputType.NUMBER_WITH_ARROWS:
        return (
          <div className='main-input main-input__number'>
            <input
              disabled={disabled}
              placeholder={placeholder || ''}
              type='text'
              ref={inputRef}
              value={inputValue}
              className={cn('main-input', {
                'main-input--error': error,
                'main-input--disabled': disabled,
              })}
              onChange={(e) => updateInputValue(e.target.value)}
            />
            <div className='main-input__number-container'>
              <ArrowIcon onClick={inc} />
              <ArrowIcon onClick={dec} />
            </div>
          </div>
        );
      case InputType.NUMBER:
        return (
          <input
            disabled={disabled}
            placeholder={placeholder || ''}
            value={inputValue}
            type='text'
            ref={inputRef}
            className={cn('main-input', {
              'main-input--error': error,
              'main-input--disabled': disabled,
            })}
            onChange={(e) => updateInputNumberValue(e.target.value)}
          />
        );
      case InputType.PHONE_NUMBER:
        return (
          <IMaskInput
            mask={'+000 00 0000000'}
            placeholder={placeholder || ''}
            value={inputValue}
            ref={inputRef}
            className={cn('main-input', {
              'main-input--error': error,
              'main-input--disabled': disabled,
            })}
            onChange={updatePhoneValue}
          />
        );
      default:
        return (
          <input
            disabled={disabled}
            placeholder={placeholder || ''}
            type='text'
            className={cn('main-input', {
              'main-input--error': error,
              'main-input--disabled': disabled,
            })}
            ref={inputRef}
            value={inputValue}
            onChange={(e) => updateInputValue(e.target.value)}
          />
        );
    }
  };
  return checkInputType();
};
