import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import { ReactComponent as CalendarIcon } from 'assets/images/icons/calendar-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg';

import './CustomDateTimePicker.sass';

interface Props {
  date?: Date;
  setDate: (date?: Date) => void;
  onlyDate?: boolean;
  disabled?: boolean;
  clear?: boolean;
}

export const CustomDateTimePicker: React.FC<Props> = ({
  date,
  setDate,
  onlyDate,
  disabled,
  clear,
}) => {
  return (
    <DateTimePicker
      clearIcon={clear ? <CloseIcon /> : null}
      onChange={setDate}
      value={date}
      className='custom-date-time-picker'
      calendarIcon={<CalendarIcon />}
      format={onlyDate ? 'dd.MM.yyyy' : 'dd.MM.yyyy HH:mm'}
      disabled={disabled}
    />
  );
};
