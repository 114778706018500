import React, { useState } from 'react';
import { MainButton } from 'components/MainButton/MainButton';
import { MainInput } from 'components/MainInput/MainInput';

import { IAttributeOption } from 'api/services/attributes/types';
import showNotification from 'helpers/showNotification';
import { SelectList } from 'components/SelectList/SelectList';

import './AttributeOptions.sass';
import { NotificationStatus } from 'store/ducks/notification/types';

interface Props {
  list: IAttributeOption[];
  updateList: (option: IAttributeOption[]) => void;
  lang: string;
  hasColor?: boolean;
}

export const AttributeOptions: React.FC<Props> = ({
  list,
  updateList,
  lang,
  hasColor,
}) => {
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [hasError, setHasEror] = useState(false);
  const [color, setColor] = useState('#');

  const addNewOption = () => {
    if (!titleRu.trim() && !titleUz.trim()) {
      setHasEror(true);
      showNotification('Заполните название');
      return;
    }

    // if (
    //   list.some(
    //     (i) =>
    //       (i.titleRu === titleRu && !!titleRu.trim()) ||
    //       (i.titleUz === titleUz && !!titleUz.trim())
    //   )
    // ) {
    //   showNotification('Название уже существует', NotificationStatus.WARNING);
    //   return;
    // }
    if (lang === 'ru' && !titleRu.length) {
      showNotification('Заполните название на русском');
      return;
    }
    if (lang === 'uz' && !titleUz.length) {
      showNotification('Заполните название на узбекском');
      return;
    }
    const newList = [
      ...list,
      {
        titleRu,
        titleUz,
        label: !!titleRu.length ? titleRu : titleUz,
        ...(!!color.trim() && color !== '#' ? { value: color } : null),
      },
    ];

    updateList(newList);
    setTitleRu('');
    setTitleUz('');
    setColor('#');
    setHasEror(false);
  };

  const updateColorInput = (value: string) => {
    if (!value.trim()) {
      setColor('#');
      return;
    }
    value.replace(/^/, '#');
    setColor(value);
  };
  const selectItem = (value: string) => {
    lang === 'ru' ? setTitleRu(value) : setTitleUz(value);
  };

  return (
    <div className='attribute-options'>
      <div className='attribute-options__field'>
        <div className='attribute-options__field-container'>
          <div className='attribute-options__inputs'>
            <div className='attribute-options__item'>
              <div className='attribute-options__item-title'>Название ru:</div>
              <MainInput
                inputValue={titleRu}
                updateInputValue={setTitleRu}
                error={!titleRu.trim() && hasError}
              />
              <div
                className='attribute-options__item-title'
                style={{ margin: '10px 0 ' }}
              >
                Название uz:
              </div>
              <div>
                <MainInput
                  inputValue={titleUz}
                  updateInputValue={setTitleUz}
                  error={!titleUz.trim() && hasError}
                />
              </div>
            </div>
            {hasColor && (
              <label className='attribute-options__item'>
                <div className='attribute-options__item-title'>Цвет</div>
                <MainInput
                  inputValue={color}
                  error={hasError && !color.trim()}
                  updateInputValue={updateColorInput}
                />
              </label>
            )}
          </div>
          <MainButton title='Добавить' submit={addNewOption} />
        </div>
      </div>
      <div className='attribute-options__list'>
        <SelectList list={list} updateList={updateList} />
      </div>
    </div>
  );
};
