import { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import * as templateActions from 'store/ducks/template/actions';
import { AppState } from 'store/store';
import { Filter } from 'components/Filter/Filter';
import { ProductAttributes } from 'components/Modal/components/CreateProduct/ProductAttributes/ProductAttributes';
import { attributeGroupServices, attributeServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { IResAttribute } from 'api/services/attributes/types';
import { UpdateProductAttribute } from 'components/Modal/components/CreateProduct/UpdateProductAttribute/UpdateProductAttribute';
import { NotificationStatus } from 'store/ducks/notification/types';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import './AttributesPage.sass';
import { ISetting } from 'components/Modal/components/Settings/types';
import * as settingActions from 'store/ducks/settings/actions';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { ISearchableSelect } from 'components/SearchableSelect/SearchableSelect';

export const AttributesPage = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState<IResAttribute>();
  const [attributes, setAttributes] = useState<IResAttribute[]>([]);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<ISelect>();
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [attributeGroups, setAttributeGroups] = useState<ISearchableSelect[]>(
    []
  );
  const [attributeGroupsLoading, setAttributeGroupsLoading] = useState(false);
  const [selectedAttributeGroup, setSelectedAttributeGroup] =
    useState<number>();
  const [attributeGroupsValue, setAttributeGroupValue] = useState('');

  const { key } = useSelector((state: AppState) => state.localeReducer);
  const categories = [
    { value: 0, label: 'Нет' },
    ...useSelector((state: AppState) => state.servicesReducer.categories)
      .sort((a, b) => a.titleRu.localeCompare(b.titleRu))
      .map((i) => ({
        label: i.titleRu,
        value: i.id,
      })),
  ];
  const { attributesSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Каталог', id: '' },
        { title: 'Атрибуты', id: 'catalog/attributes' },
      ])
    );
    getAttributeGroups();
  }, []);

  useEffect(() => {
    getAttributes();
  }, [page, searchValue]);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setAttributesSettings(items));
    setShowSettings(false);
  };

  const getAttributes = async () => {
    setLoading(true);

    const attrs = {
      take,
      skip: take * page,
      search: searchValue,
      groupId: selectedAttributeGroup,
      ...(selectedCategory ? { categoryId: Number(selectedCategory) } : {}),
    };
    try {
      const res = await attributeServices
        .getAttributes(attrs)
        .then((res) => res.data);
      setAttributes(res.data);
      setTotalCount(res.count);
    } finally {
      setLoading(false);
    }
  };

  const createAttr = async (data: any) => {
    try {
      await attributeServices.createAttribute(data);
      showNotification('Успешно создано', NotificationStatus.SUCCESS);
      setShowCreateModal(false);
      getAttributes();
    } catch (e: any) {
      if (e.response.data.message[0] === 'options should not be empty') {
        showNotification('Заполните опции');
        return;
      }
      showNotification('Произошла ошибка, попробуйте позже');
    } finally {
    }
  };
  const getAttributeGroups = async () => {
    const res = await attributeGroupServices
      .getAttributeGroups({ skip: 0, take: 25 })
      .then((res) => res.data.data);
    setAttributeGroups(res.map((i) => ({ name: i.titleRu, value: i.id })));
    setAttributeGroupsLoading(false);
  };

  const updateAttr = async (data: any) => {
    if (!selectedAttribute) return;
    try {
      await attributeServices.updateAttribute(selectedAttribute.id, data);
      showNotification('Изменение успешно', NotificationStatus.SUCCESS);
      closeModal();
      getAttributes();
    } catch {
      showNotification('Произошла ошибка, попробуйте позже');
    } finally {
    }
  };

  const getAttribute = async (id: number) => {
    const res = await attributeServices
      .getAttributeById(id)
      .then((res) => res.data);
    setSelectedAttribute(res);
    setShowUpdateModal(true);
  };

  const closeModal = () => {
    setSelectedAttribute(undefined);
    setShowUpdateModal(false);
  };

  const closeFilter = () => setFilterShowModal(false);

  const deleteAttribute = async (id: number) => {
    await attributeServices.deleteAttribute(id);
    showNotification('Удалено', NotificationStatus.SUCCESS);
    getAttributes();
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    if (!value.trim()) getAttributes();
    setSearchValue(value);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Атрибуты'
            items={attributesSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={() => setShowCreateModal(false)}>
          <ProductAttributes
            closeModal={() => setShowCreateModal(false)}
            submit={createAttr}
          />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal closeModal={closeModal}>
          <UpdateProductAttribute
            closeModal={closeModal}
            submit={updateAttr}
            data={selectedAttribute}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          >
            <div className='product-page__filter-inputs'>
              <label className='attributes-page__filter-inputs-item'>
                <MainSelect
                  items={categories}
                  selectItem={setSelectedCategory}
                  value={selectedCategory}
                  withIcon
                  isSearchable
                  whiteOption
                />
                <span className='product-page__filter-inputs-item-label'>
                  Категория
                </span>
              </label>
              <label className='attributes-page__filter-inputs-item'>
                <CustomSearchableSelect
                  items={[{ name: 'Нет', value: null }, ...attributeGroups]}
                  loading={attributeGroupsLoading}
                  setValue={getAttributeGroups}
                  setLoading={setAttributeGroupsLoading}
                  setSelectItem={setSelectedAttributeGroup}
                  setSearchValue={setAttributeGroupValue}
                  searchValue={attributeGroupsValue}
                  isWhite
                />
                <span className='product-page__filter-inputs-item-label'>
                  Группа
                </span>
              </label>
            </div>
          </ModalFilter>
        </Modal>
      )}
      <div className='page attributes-page'>
        <Filter
          createFunc={() => setShowCreateModal(true)}
          filterFunc={() => {
            setFilterShowModal(true);
          }}
          updateSearchValue={newFilterValue}
          loading={loading}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            tableBody={{
              keys: attributesSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
              data: attributes,
            }}
            tableHead={attributesSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            selectItem={getAttribute}
            skipHandler={setPage}
            totalCount={totalCount}
            take={take}
            deleteHandler={deleteAttribute}
            page={page}
            onlySu
          />
        </div>
      </div>
    </>
  );
};
