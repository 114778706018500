import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import cn from 'classnames';
import { isEqual } from 'lodash';
import _ from 'lodash';
import { attributeServices, productServices } from 'api/services';
import { IVariantAttrubute } from 'api/services/variant-attributes/types';
import { IVariant } from 'api/services/variants/types';
import showNotification from 'helpers/showNotification';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';
import { Attributes } from './Attributes/Attributes';
import { Options } from './Options/Options';
import { Price } from './Price/Price';
import { Product } from './Product/Product';
import { CreateProductTags } from './CreateProductTags/CreateProductTags';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import './CreateProduct.sass';
import { IResAttribute } from 'api/services/attributes/types';
import { ISelect } from 'components/MainSelect/MainSelect';
import { IResTag } from 'api/services/tags/types';
import { IResFile } from 'api/services/files/types';
import { NotificationStatus } from 'store/ducks/notification/types';
import * as uuidv1 from 'uuid';
import { isEditUser } from 'helpers/checkUser';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

interface Props {
  closeModal: () => void;
  setActiveTab: (tab: number) => void;
  activeTab: number;
  tabs: { label: string; index: number }[];
  id?: number;
  getData?: () => void;
}

export const CreateProduct: React.FC<Props> = ({
  setActiveTab,
  activeTab,
  tabs,
  closeModal,
  getData,
  id,
}) => {
  const [shopId, setShopId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [activateAt, setActivateAt] = useState(new Date());
  const [deactivateAt, setDeactivateAt] = useState(new Date());
  const [code, setCode] = useState('');
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [url, setUrl] = useState('');
  const [descriptionUz, setDescriptionUz] = useState('');
  const [descriptionRu, setDescriptionRu] = useState('');
  const [fileId, setFileId] = useState<number>();
  const [gallery, setGallery] = useState<any>([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState('');
  const [selectedGalleryUrl, setSelectedGalleryUrl] = useState<string[]>([]);

  const [variants, setVariants] = useState<IVariant[]>([]);
  const [variantTitleRu, setVariantTitleRu] = useState('');
  const [variantTitleUz, setVariantTitleUz] = useState('');
  const [productId, setProductId] = useState('');
  const [attributes, setAttributes] = useState<IVariantAttrubute[]>([]);
  const [arrivalPriceCurrencyId, setArrivalPriceCurrencyId] =
    useState<ISelect>();
  const [dillerPriceCurrencyId, setDillerPriceCurrencyId] = useState<ISelect>();
  const [retailPriceCurrencyId, setRetailPriceCurrencyId] = useState<ISelect>();
  const [arrivalPrice, setArrivalPrice] = useState('');
  const [dillerPrice, setDillerPrice] = useState('');
  const [retailPrice, setRetailPrice] = useState('');
  const [status, setStatus] = useState<ISelect>();
  const [price, setPrice] = useState('');
  const [attrFileId, setAttrFileId] = useState<number>();
  const [attrGallery, setAttrGallery] = useState<any[]>([]);
  const [selectedAttrUrl, setSelectedAttrUrl] = useState('');
  const [selectedGalleryAttrUrl, setSelectedGalleryAttrUrl] = useState<
    string[]
  >([]);
  const [count, setCount] = useState('');

  const { categoryId } = useParams<{ categoryId: string }>();
  const [attrs, setAttrs] = useState<IResAttribute[]>([]);
  const [attrPage, setAttrPage] = useState(0);
  const [attrTake, setAttrTake] = useState(15);
  const [numberAttrs, setNumberAttrs] = useState<any>([]);
  const [textAttrs, setTextAttrs] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<IResTag[]>([]);
  const [galleryFiles, setGalleryFiles] = useState<IResFile[]>([]);
  const [searchableBrandValue, setSearchableBrandValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [mainFile, setMainFile] = useState<IResFile>();
  const [mainAttrFile, setMainAttrFile] = useState<IResFile>();
  const [searchableShopValue, setSearchableShopValue] = useState('');
  const [optionsError, setOptionsError] = useState(false);
  const [editedOption, setEditedOption] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [cashback, setCashback] = useState('');
  const [activeOption, setActiveOption] = useState<number>();
  const [notUpdated, setNotUpdated] = useState(false);

  const { user } = useSelector((state: AppState) => state.profileReducer);
  const { lang } = useSelector((state: AppState) => state.localeReducer);

  // useEffect(() => {
  //   if(attributes.length > 0){
  //     setNumberAttrs(attributes
  //       ?.filter((i: any) => i.type === 'NUMBER')
  //       .map((ii: any) => ({ id: ii.attributeId, label: ii.titleRu, text: ii.titleRu })) || [])
  //     setTextAttrs(attributes
  //       ?.filter((i: any) => i.type === 'TEXT')
  //       .map((ii: any) => ({ id: ii.attributeId, label: ii.titleRu, text: ii.titleRu })) || [])
  //   }
  // }, [attributes.length])

  useEffect(() => {
    getAttributes();
  }, [attrPage, attrTake]);

  const getAttributes = async () => {
    const params = {
      skip: attrTake * attrPage,
      take: 200,
      categoryId,
    };
    const res = await attributeServices
      .getAttributes(params)
      .then((res) => res.data);
    setAttrs(res.data);
  };

  useEffect(() => {
    setNumberAttrs(
      attrs
        ?.filter((i) => i.type === 'NUMBER')
        .map((ii) => ({
          id: ii.id,
          label: '',
          text: ii.titleRu,
          type: ii.type,
          required: ii.required,
        })) || []
    );
    setTextAttrs(
      attrs
        ?.filter((i) => i.type === 'TEXT')
        .map((ii) => ({
          id: ii.id,
          titleRu: '',
          titleUz: '',
          text: ii.titleRu,
          type: ii.type,
          required: ii.required,
        })) || []
    );
  }, [attrs]);

  const galleryAttrHanler = (data: string) => {
    setSelectedGalleryAttrUrl((prev) => [...prev, data]);
  };

  const attributesChange = (data: IVariantAttrubute) => {
    if (
      !data.attributeOptionId &&
      !data.titleRu?.trim() &&
      !data.titleUz?.trim()
    ) {
      setAttributes((prev) =>
        prev.filter((i) => i.attributeId !== data.attributeId)
      );
      return;
    }
    const found = attributes?.some((i) => i.attributeId === data.attributeId);
    if (!found) {
      setAttributes((prev) => [
        ...prev,
        {
          ...data,
          titleRu: lang === 'ru' ? data.titleRu : '',
          titleUz: lang === 'uz' ? data.titleUz : '',
        },
      ]);
    } else if (found) {
      let findIndex = attributes?.findIndex(
        (i) => i.attributeId === data.attributeId
      );
      const foundObj = isEqual(attributes[findIndex], data);
      if (!foundObj) {
        let result = attributes;
        let obj = result.find((i) => i.attributeId === data.attributeId);
        setAttributes([
          ...result.filter((i) => i.attributeId !== obj?.attributeId),
          {
            ...data,
            titleRu: lang === 'ru' ? data.titleRu : obj?.titleRu,
            titleUz: lang === 'uz' ? data.titleUz : obj?.titleUz,
          },
        ]);
      } else if (foundObj) {
        return;
      }
    }
  };

  const numberAttrsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number,
    type: 'TEXT' | 'NUMBER' | 'LIST'
  ) => {
    setNumberAttrs((prev: any) => {
      const arr = _.clone(prev);
      arr[index].label = e.target.value;
      return [...arr];
    });
    attributesChange({
      titleRu: e.target.value,
      titleUz: e.target.value,
      attributeId: Number(id),
      type: type,
    });
  };

  const textAttrsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number,
    type: 'TEXT' | 'NUMBER' | 'LIST'
  ) => {
    setTextAttrs((prev: any) => {
      const arr = _.clone(prev);
      lang === 'ru'
        ? (arr[index].titleRu = e.target.value)
        : (arr[index].titleUz = e.target.value);
      return [...arr];
    });
    attributesChange({
      titleRu: e.target.value,
      titleUz: e.target.value,
      attributeId: Number(id),
      type: type,
    });
  };

  const deleteHandler = (id: number) => {
    setVariants((prev) => prev.filter((i) => i.id !== id));
  };

  const setOptionAttr = (file?: IResFile, files?: IResFile[]) => {
    if (files) setAttrGallery(files);
    if (file) setAttrGallery((prev) => [...prev, file]);
  };

  const checkTab = () => {
    switch (activeTab) {
      case 2:
        return (
          <Options
            numberAttrs={numberAttrs}
            textAttrs={textAttrs}
            setNumberAttrs={numberAttrsChange}
            setTextAttrs={textAttrsChange}
            setProductId={setProductId}
            setAttributes={attributesChange}
            setStatus={setStatus}
            setPrice={setPrice}
            setTitleRu={setVariantTitleRu}
            setTitleUz={setVariantTitleUz}
            setGallery={setOptionAttr}
            setCount={setCount}
            closeModal={closeModal}
            setSelectedFileUrl={setSelectedAttrUrl}
            setSelectedGalleryUrl={galleryAttrHanler}
            selectedFileUrl={selectedAttrUrl}
            selectedGalleryUrl={selectedGalleryAttrUrl}
            count={count}
            setMainFile={setMainAttrFile}
            mainFile={mainAttrFile}
            fileId={attrFileId}
            gallery={attrGallery}
            titleRu={variantTitleRu}
            titleUz={variantTitleUz}
            productId={productId}
            attributes={attributes}
            arrivalPrice={arrivalPrice}
            dillerPrice={dillerPrice}
            retailPrice={retailPrice}
            status={status}
            price={price}
            variants={variants}
            createVariant={() => createVariant(false)}
            attrs={attrs}
            submit={createProduct}
            actions={true}
            arrivalPriceCurrencyId={arrivalPriceCurrencyId}
            setArrivalPriceCurrencyId={setArrivalPriceCurrencyId}
            dillerPriceCurrencyId={dillerPriceCurrencyId}
            setDillerPriceCurrencyId={setDillerPriceCurrencyId}
            retailPriceCurrencyId={retailPriceCurrencyId}
            setRetailPriceCurrencyId={setRetailPriceCurrencyId}
            setArrivalPrice={setArrivalPrice}
            setDillerPrice={setDillerPrice}
            setRetailPrice={setRetailPrice}
            deleteHandler={deleteHandler}
            hideCheck
            hasError={optionsError}
            setEditedOption={editOption}
            updateVariant={() => createVariant(true)}
            isUpdate={!!editedOption}
            notUpdated={notUpdated}
          />
        );
      case 3:
        return (
          <CreateProductTags
            selectTag={setSelectedTags}
            selectedTags={selectedTags}
          />
        );
      // case 4:
      //   return (
      //     <Price
      //       closeModal={closeModal}
      //       setCount={setCount}
      //       setArrivalPriceCurrencyId={setArrivalPriceCurrencyId}
      //       setDillerPriceCurrencyId={setDillerPriceCurrencyId}
      //       setRetailPriceCurrencyId={setRetailPriceCurrencyId}
      //       setArrivalPrice={setArrivalPrice}
      //       setDillerPrice={setDillerPrice}
      //       setRetailPrice={setRetailPrice}
      //       arrivalPriceCurrencyId={arrivalPriceCurrencyId}
      //       dillerPriceCurrencyId={dillerPriceCurrencyId}
      //       retailPriceCurrencyId={retailPriceCurrencyId}
      //       arrivalPrice={arrivalPrice}
      //       dillerPrice={dillerPrice}
      //       retailPrice={retailPrice}
      //       count={count}
      //       hasError={hasError}
      //     />
      //   );
      default:
        return (
          <Product
            setMainFile={setMainFile}
            selectedFileUrl={selectedFileUrl}
            setShopId={setShopId}
            setBrandId={setBrandId}
            setActivateAt={setActivateAt}
            setDeactivateAt={setDeactivateAt}
            setCode={setCode}
            setTitleRu={setTitleRu}
            setTitleUz={setTitleUz}
            setUrl={setUrl}
            mainFile={mainFile}
            setDescriptionUz={setDescriptionUz}
            setDescriptionRu={setDescriptionRu}
            shopId={shopId}
            activateAt={activateAt}
            deactivateAt={deactivateAt}
            code={code}
            titleRu={titleRu}
            titleUz={titleUz}
            url={url}
            descriptionUz={descriptionUz}
            descriptionRu={descriptionRu}
            galleryFiles={galleryFiles}
            setGalleryFiles={setGalleryFiles}
            searchableBrandValue={searchableBrandValue}
            setSearchableBrandValue={setSearchableBrandValue}
            hasError={hasError}
            setSearchableShopValue={setSearchableShopValue}
            searchableShopValue={searchableShopValue}
            cashback={cashback}
            setCashback={setCashback}
          />
        );
    }
  };

  const editOption = (findObj: any) => {
    setEditedOption(findObj.id);
    setStatus(findObj.status);
    setAttrGallery(findObj.galleryFiles);
    setMainAttrFile(findObj.file);
    setVariantTitleRu(findObj.titleRu || '');
    setVariantTitleUz(findObj.titleUz || '');
    setCount(findObj.count.toString());
    setAttributes(findObj.attributes || []);
    setArrivalPrice((findObj.arrivalPrice / 100).toString());
    setDillerPrice((findObj.dillerPrice / 100).toString());
    setRetailPrice((findObj.retailPrice / 100).toString());
    setTextAttrs(
      attrs
        ?.filter((i) => i.type === 'TEXT')
        .map((i) => {
          const findAttr = findObj.attributes?.find(
            (ii: any) => ii.attributeId === i.id
          );
          return {
            id: findAttr?.attributeId,
            titleRu: findAttr?.titleRu,
            titleUz: findAttr?.titleUz,
            text: i?.titleRu,
            type: findAttr?.type,
            required: findAttr?.required,
          };
        })
    );
    setNumberAttrs(
      attrs
        ?.filter((i) => i.type === 'NUMBER')
        .map((i) => {
          const findAttr = findObj.attributes?.find(
            (ii: any) => ii.attributeId === i.id
          );
          return {
            id: findAttr?.attributeId,
            label: findAttr?.titleRu,
            text: i.titleRu,
            type: findAttr?.type,
            required: findAttr?.required,
          };
        })
    );
    setArrivalPriceCurrencyId({
      label: findObj.arrivalPrice,
      value: findObj.arrivalPriceCurrencyId,
    });
    setDillerPriceCurrencyId({
      label: findObj.dillerPrice,
      value: findObj.dillerPriceCurrencyId,
    });
    setRetailPriceCurrencyId({
      label: findObj.retailPrice,
      value: findObj.retailPriceCurrencyId,
    });
    setStatus({
      label: findObj.status,
      value: findObj.status ? findObj.status : '',
    });
    setPrice(findObj.price ? (findObj.price / 100).toString() : '');
  };

  const createVariant = async (isUpdate: boolean) => {
    if (!variantTitleRu.trim() && !variantTitleUz.trim()) {
      showNotification('Название не заполнено');
      setOptionsError(true);
      return;
    }
    const requiredAttr = attrs.filter((i) => i.required);
    let validAttrs = false;
    requiredAttr.forEach((i) => {
      const find = attributes.find((ii) => ii.attributeId === i.id);

      if (!find) {
        validAttrs = true;
      }
      if (
        (i?.type === 'TEXT' || i?.type === 'NUMBER') &&
        !find?.titleRu?.trim() &&
        isUpdate
      ) {
        validAttrs = true;
      }
    });
    if (validAttrs) {
      showNotification('Заполните атрибуты');
      setOptionsError(true);
      return;
    }
    if (!arrivalPrice.trim() || !dillerPrice.trim() || !retailPrice.trim()) {
      showNotification('Укажите цены');
      setOptionsError(true);
      return;
    }
    if (
      !arrivalPriceCurrencyId ||
      !dillerPriceCurrencyId ||
      !retailPriceCurrencyId
    ) {
      showNotification('Укажите валюту');
      setOptionsError(true);
      return;
    }
    if (!status) {
      showNotification('Укажите статус');
      setOptionsError(true);
      return;
    }
    if (!mainAttrFile) {
      showNotification('Загрузите изображения');
      setOptionsError(true);
      return;
    }
    if (!count.trim()) {
      showNotification('Укажите кол-во');
      setOptionsError(true);
      return;
    }

    setNotUpdated(true);
    const data = {
      titleRu: variantTitleRu,
      titleUz: variantTitleUz,
      count: Number(count),
      productId: !productId.trim() ? undefined : Number(productId),
      attributes: attributes.filter(
        (i) =>
          !!i.titleRu?.trim() || !!i.titleUz?.trim() || !!i.attributeOptionId
      ),
      arrivalPriceCurrencyId: Number(
        arrivalPriceCurrencyId?.value || arrivalPriceCurrencyId
      ),
      dillerPriceCurrencyId: Number(
        dillerPriceCurrencyId?.value || dillerPriceCurrencyId
      ),
      retailPriceCurrencyId: Number(
        retailPriceCurrencyId?.value || retailPriceCurrencyId
      ),
      arrivalPrice: Math.round(Number(arrivalPrice) * 100),
      dillerPrice: Math.round(Number(dillerPrice) * 100),
      retailPrice: Math.round(Number(retailPrice) * 100),
      status: status?.value || status,
      fileId: mainAttrFile?.id,
      gallery: attrGallery.map((i) => i.id),
      galleryFiles: attrGallery,
      file: mainAttrFile,
      price: Math.round(Number(price) * 100),
      id: isUpdate ? editedOption : variants.length + 1,
    };

    setTimeout(() => {
      setNotUpdated(false);
    }, 1000);
    if (!!editedOption && isUpdate) {
      setVariants((prev) => {
        const arr = _.clone(prev);
        const findIndex = arr.findIndex((i) => i.id === editedOption);
        arr.splice(findIndex, 1, data);
        return arr;
      });
      clearVariantFields();
      setActiveOption(undefined);
      setEditedOption(undefined);
      setOptionsError(false);
      return;
    }
    let found = _.some(variants, data);
    if (!found) {
      setVariants((prev: any) => [...prev, data]);
      clearVariantFields();
    } else {
      showNotification('Такая опция уже существует');
    }
    setOptionsError(false);
  };

  const clearVariantFields = () => {
    setVariantTitleRu('');
    setVariantTitleUz('');
    setCount('');
    setAttributes([]);
    setArrivalPriceCurrencyId(undefined);
    setDillerPriceCurrencyId(undefined);
    setRetailPriceCurrencyId(undefined);
    setArrivalPrice('');
    setRetailPrice('');
    setDillerPrice('');
    setStatus(undefined);
    setAttrFileId(undefined);
    setAttrGallery([]);
    setPrice('');
    setSelectedGalleryAttrUrl([]);
    setSelectedAttrUrl('');
    setMainAttrFile(undefined);
    setNumberAttrs(
      attrs
        ?.filter((i) => i.type === 'NUMBER')
        .map((ii) => ({
          id: ii.id,
          label: '',
          text: ii.titleRu,
          required: ii.required,
        })) || []
    );
    setTextAttrs(
      attrs
        ?.filter((i) => i.type === 'TEXT')
        .map((ii) => ({
          id: ii.id,
          titleRu: '',
          titleUz: '',
          text: ii.titleRu,
          required: ii.required,
        })) || []
    );
    setAttributes([]);
  };

  const createProduct = async () => {
    if (loading) return;
    if (!variants.length) {
      showNotification('Добавьте опции');
      setHasError(true);
      return;
    }
    if (!activateAt || !deactivateAt) {
      showNotification('Укажите даты начала и окончания');
      return;
    }
    if (
      !categoryId?.trim() ||
      (!shopId.trim() && isEditUser(user?.role)) ||
      !brandId.trim() ||
      (!titleRu.trim() && !titleUz.trim()) ||
      (!descriptionRu.trim() && !descriptionUz.trim())
    ) {
      showNotification('Заполните поля', NotificationStatus.WARNING);
      setHasError(true);
      return;
    }
    if (!mainFile) {
      setHasError(true);
      showNotification('Добавьте изображения', NotificationStatus.WARNING);
      return;
    }
    const requestData = {
      categoryId: Number(categoryId),
      shopId: isEditUser(user?.role) ? Number(shopId) : user?.shopId,
      brandId: Number(brandId),
      activateAt: new Date(activateAt),
      deactivateAt: new Date(deactivateAt),
      code,
      titleRu,
      titleUz,
      url,
      fileId: mainFile.id,
      descriptionUz,
      descriptionRu,
      ...(!!cashback.trim() ? { cashback } : {}),
      gallery: galleryFiles.map((i) => i.id),
      variants: variants.map((i) => {
        delete i['galleryFiles'];
        delete i['file'];
        return i;
      }),
      tags: selectedTags.map((i) => i.id),
    };

    setLoading(true);
    try {
      await productServices.createProduct(requestData);
      showNotification('Продукт создан', NotificationStatus.SUCCESS);
      try {
        if (getData) await getData();
      } finally {
        closeModal();
      }
    } catch (e: any) {
      setLoading(false);
      if (
        e.response.data.statusCode !== 409 &&
        e.response.data.statusCode !== 403
      ) {
        showNotification('Ошибка, попробуйте позже');
      }
    }
  };

  return (
    <div className='create-product'>
      <div className='modal-tabs modal-tabs--inner create-product__tabs'>
        {tabs.map((i) => (
          <div
            className={cn('modal-tabs__item create-product__tabs-item', {
              'create-product__tabs-item--active': activeTab === i.index,
            })}
            key={i.index}
            onClick={() => setActiveTab(i.index)}
          >
            <span>{i.label}</span>
          </div>
        ))}
        {activeTab !== 5 && <ChoiceLang />}
      </div>
      {checkTab()}
      <ActionButtons closeModal={closeModal} submit={createProduct} />
    </div>
  );
};
