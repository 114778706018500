import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { postCategoryServices } from 'api/services';
import { IPostCategory } from 'api/services/postCategories/types';
import { MainInput } from 'components/MainInput/MainInput';
import './UpdatePostCategory.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ActionButtons } from 'components/Modal/components/ActionButtons/ActionButtons';
import { ChoiceLang } from 'components/ChoiceLang/ChoiceLang';

interface Props {
  submit: (data: IPostCategory) => void;
  id?: any;
  closeModal: () => void;
}

export const UpdatePostCategory: React.FC<Props> = ({
  submit,
  id,
  closeModal,
}) => {
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [url, setUrl] = useState('');

  const { lang } = useSelector((state: AppState) => state.localeReducer);

  useEffect(() => {
    getDataById();
  }, [id]);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submit({
      titleRu,
      titleUz,
      url,
    });
  };

  const getDataById = async () => {
    if (!id) return;
    const res = await postCategoryServices
      .getPostCategoryById(id)
      .then((res) => res.data);
    setTitleRu(res.titleRu);
    setTitleUz(res.titleUz);
    setUrl(res.url);
  };

  return (
    <form onSubmit={formSubmit} className='update-post-category'>
      <div className='update-post-category__modal'>
        <div className='update-post-category__tabs'>
          <div className='update-post-category__tabs-item'>
            <span>Категория постов</span>
          </div>
          <ChoiceLang />
        </div>
        <div className='update-post-category__in'>
          <div className='update-post-category__form'>
            <label className='update-post-category__form-row'>
              <div className='update-post-category__form-label'>Название:</div>
              {lang === 'ru' ? (
                <MainInput updateInputValue={setTitleRu} inputValue={titleRu} />
              ) : (
                <MainInput updateInputValue={setTitleUz} inputValue={titleUz} />
              )}
            </label>
            <label className='create-post-category__form-row'>
              <div className='create-post-category__form-label'>URL:</div>
              <MainInput updateInputValue={setUrl} inputValue={url} />
            </label>
          </div>
        </div>
      </div>
      <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
    </form>
  );
};
