import React, { useEffect, useState } from 'react';
import { reportsServices } from 'api/services';
import { Filter } from 'components/Filter/Filter';
import { ISelect, MainSelect } from 'components/MainSelect/MainSelect';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { Modal } from 'components/Modal/Modal';
import { Table } from 'components/Table/Table';
import showNotification from 'helpers/showNotification';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationStatus } from 'store/ducks/notification/types';
import * as templateActions from 'store/ducks/template/actions';
import * as settingActions from 'store/ducks/settings/actions';
import { AppState } from 'store/store';
import { ISetting } from 'components/Modal/components/Settings/types';
import { Settings } from 'components/Modal/components/Settings/Settings';
import { IBalanceReport } from 'api/services/reports/types';
import axios, { AxiosRequestConfig } from 'axios';
import fs from 'file-saver';
import qs from 'query-string';
import moment from 'moment';
import './ReportBalancePage.sass';
import { isShop } from 'helpers/checkUser';

const ReportBalancePage: React.FC = () => {
  const [data, setData] = useState<IBalanceReport[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [take, setTake] = useState(12);
  const [page, setPage] = useState(0);
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedShop, setSelectedShop] = useState<number>();
  const [selectedBrand, setSelectedBrand] = useState<number>();
  const [dates, setDates] = useState([
    new Date(new Date().setMonth(new Date().getMonth() - 1)),
    new Date(),
  ]);
  const [showFilter, setShowFilter] = useState(false);
  const { user } = useSelector((state: AppState) => state.profileReducer);

  const dispatch = useDispatch();
  const { balanceReportSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  const { token } = useSelector((state: AppState) => state.authReducer);

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        {
          title: 'Отчет по остаткам',
          id: 'reports/balance',
        },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [page, JSON.stringify(dates), selectedBrand, selectedShop]);

  const getData = async () => {
    const attrs = {
      skip: take * page,
      take,
      from: moment(dates[0]).format('yyyy-MM-DD'),
      to: moment(dates[1]).format('yyyy-MM-DD'),
      ...(selectedBrand ? { brandId: selectedBrand } : {}),
      ...(selectedShop && !isShop(user?.role) ? { shopId: selectedShop } : {}),
    };
    const res = await reportsServices
      .getBalanceReports(attrs)
      .then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
  };

  const closeFilter = () => setFilterShowModal(false);

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setBalanceReportSettings(items));
    setShowSettings(false);
  };

  const exportReports = async () => {
    const attrs = {
      skip: take * page,
      take,
      from: moment(dates[0]).format('yyyy-MM-DD'),
      to: moment(dates[1]).format('yyyy-MM-DD'),
      ...(selectedBrand ? { brandId: selectedBrand } : {}),
      ...(selectedShop && !isShop(user?.role) ? { shopId: selectedShop } : {}),
    };
    const headers = {
      'Content-type': 'blob',
      common: {
        Authorization: `Bearer ${token}`,
      },
    } as any;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${
        process.env.REACT_APP_BASE_URL
      }/api/v1/reports/balance/export?${qs.stringify(attrs)}`,
      responseType: 'blob',
      headers,
    };

    const res = await axios(config).then((res) => res.data);
    const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
    fs.saveAs(blob, `Отчет по остаткам.xlsx`);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Отчет по остаткам'
            items={balanceReportSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      <div className='report-balance-page page'>
        <Filter
          settingFunc={() => setShowSettings(true)}
          productExport={exportReports}
          date={dates}
          setDate={setDates}
          filterFunc={() => setShowFilter(true)}
          isReport
          selectedBrand={selectedBrand}
          selectedShop={selectedShop}
          setSelectedBrand={setSelectedBrand}
          setSelectedShop={setSelectedShop}
        />
        <div className='report-balance-page__in page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            tableBody={{
              data: data?.map((i) => ({
                ...i,
                shopName: i.product.shop.name,
                brandName: i.product.brand.name,
                count: `${i.count} шт.`,
                process: i.counts.inProgress,
                completed: i.counts.completed,
                canceled: i.counts.canceled,
              })),
              keys: balanceReportSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={balanceReportSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            page={page}
          />
        </div>
      </div>
    </>
  );
};

export default ReportBalancePage;
