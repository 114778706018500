import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg';
import './SelectList.sass';

interface Props {
  list: any[];
  updateList: (data: any) => void;
}

export const SelectList: React.FC<Props> = ({ list, updateList }) => {
  const deleteItem = (e: React.MouseEvent, label: string) => {
    updateList(list.filter((i) => i.label != label));
  };

  return (
    <div className='select-list'>
      {list.map((i) => (
        <div className='select-list__item' key={i.label}>
          {i.label}
          <div
            className='select-list__item-close'
            onClick={(e) => deleteItem(e, i.label)}
          >
            <CloseIcon />
          </div>
        </div>
      ))}
    </div>
  );
};
