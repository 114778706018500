import instance from 'api/instance';
import { ICreateStock, IGetStock, IGetStockById, IUpdateStock } from './types';
import qs from 'query-string';
export const getStocks: IGetStock = (attrs) =>
  instance.get(`/discount?${qs.stringify(attrs, { skipEmptyString: true })}`);

export const getStockById: IGetStockById = (id) =>
  instance.get(`/discount/${id}`);

export const createStock: ICreateStock = (data) =>
  instance.post('/discount', data);

export const updateStock: IUpdateStock = (id, data) =>
  instance.put(`/discount/${id}`, data);

export const deleteStock = (id: number) => instance.delete(`/discount/${id}`);
