import { IState, TemplateType } from './types';
import { Reducer } from 'redux';

const initState = {
  breadCrumbs: [],
  showModal: false,
  haveModal: false,
  hashes: null,
};

const reducer: Reducer<IState> = (state = initState, action) => {
  switch (action.type) {
    case TemplateType.GET_BREADCRUBS:
      return {
        ...state,
        breadCrumbs: action.items,
      };
    case TemplateType.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case TemplateType.HIDE_MODAL:
      return {
        ...state,
        showModal: false,
        breadCrums: [],
      };
    case TemplateType.WITH_MODAL:
      return {
        ...state,
        haveModal: action.haveModal,
      };
    case TemplateType.WITH_HASHES:
      return {
        ...state,
        hashes: action.hashes,
      };
    default:
      return state;
  }
};

export default reducer;
