import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';

import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { Filter } from 'components/Filter/Filter';
import { IBrand, IResBrand } from 'api/services/brands/types';
import { brandServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
import { UpdateBrand } from 'components/Modal/components/UpdateBrand/UpdateBrand';
import { Brand } from 'components/Modal/components/CreateProduct/Brand/Brand';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { Settings } from 'components/Modal/components/Settings/Settings';
import * as settingActions from 'store/ducks/settings/actions';
import { ISetting } from 'components/Modal/components/Settings/types';
import { isEditUser } from 'helpers/checkUser';

const tableHead = ['ID', 'Лого', 'Название', 'Дата изменения'];

export const BrandPage = () => {
  const [activeTab, setActiveTab] = useState(5);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [data, setData] = useState<IResBrand[]>([]);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState<number>();
  const [id, setId] = useState<number>();
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showSettings, setShowSettings] = useState(false);

  const { brandsSettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );
  const { user } = useSelector((state: AppState) => state.profileReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Каталог', id: '' },
        { title: 'Бренд', id: 'catalog/brand' },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, [page, searchValue]);

  const getData = async (isSearch?: boolean) => {
    const searchVal = searchValue;
    if (isSearch) setPage(0);

    const params = {
      skip: take * page,
      take: take,
      search: searchVal,
    };
    const res = await brandServices.getBrands(params).then((res) => res.data);
    const arr = res.data.map((i) => ({
      ...i,
      img: `${process.env.REACT_APP_BASE_URL}/uploads/${i?.file?.userId}/${i?.file?.name}.${i?.file?.ext}`,
    }));
    setData(arr);
    setTotalCount(res.count);
  };

  const closeFilter = () => {
    setFilterShowModal(false);
  };

  const createBrand = async (data: IBrand) => {
    let validation = true;
    if (!data.name.trim()) {
      validation = false;
      showNotification('Поля не заполнены');
    } else if (!data.fileId) {
      validation = false;
      showNotification('Поля не заполнены');
    }

    if (!validation) {
      return;
    }

    try {
      await brandServices.createBrand(data);
      setShowCreateModal(false);
      showNotification('Готово', NotificationStatus.SUCCESS);
      getData();
    } finally {
    }
  };

  const newFilterValue = (value: string) => {
    setPage(0);
    setSearchValue(value);
  };

  const updateBrand = async (data: IBrand) => {
    if (!id) return;

    let validation = true;

    if (!data.name.trim()) {
      validation = false;
      showNotification('Поля не заполнены');
    } else if (!data.fileId) {
      validation = false;
      showNotification('Поля не заполнены');
    }

    if (!validation) {
      return;
    }

    try {
      await brandServices.updateBrand(id, data);
      getData();
      showNotification('Готово', NotificationStatus.SUCCESS);
    } catch {
    } finally {
      closeUpdateModal();
    }
  };

  const openUpdateBrand = (id: number) => {
    if (!isEditUser(user?.role)) return;
    setId(id);
    setShowUpdateModal(true);
    setActiveTab(8);
  };

  const closeUpdateModal = () => {
    setId(undefined);
    setShowUpdateModal(false);
  };

  const deleteItem = async (id: number) => {
    try {
      await brandServices.deleteBrand(id);
      showNotification('Бренд удален', NotificationStatus.SUCCESS);
      getData();
    } catch {
      showNotification('Ошибка попробуйте позже');
    }
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setBrandsSettings(items));
    setShowSettings(false);
  };

  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Товары'
            items={brandsSettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={() => setShowCreateModal(false)}>
          <Brand
            closeModal={() => setShowCreateModal(false)}
            submit={createBrand}
          />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal closeModal={closeUpdateModal}>
          <UpdateBrand
            closeModal={closeUpdateModal}
            submit={updateBrand}
            id={id}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={newFilterValue}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='page brand-page'>
        <Filter
          createFunc={
            !!isEditUser(user?.role)
              ? () => setShowCreateModal(true)
              : undefined
          }
          filterFunc={() => setFilterShowModal(true)}
          updateSearchValue={newFilterValue}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            selectItem={openUpdateBrand}
            tableBody={{
              data,
              keys: brandsSettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={brandsSettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            deleteHandler={isEditUser(user?.role) ? deleteItem : undefined}
            page={page}
          />
        </div>
      </div>
    </>
  );
};
