import { categoryServices } from 'api/services';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/store';
import { ServiceActionTypes, ServiceType } from './types';
import { arrayToTree } from 'performant-array-to-tree';
import { ICategory } from 'api/services/categories/types';

export const getCategories =
  (): ThunkAction<void, AppState, unknown, ServiceActionTypes> =>
  async (dispatch) => {
    const params = { all: true };
    const res = await categoryServices
      .getCategories(params)
      .then((res) => res.data);
    const arrayWithIcon = res
      .map((i) => ({
        ...i,
        hasIcon: true,
        url: `/catalog/categories/${i.id}/products`,
      }))
      .sort((a, b) => a.titleRu.localeCompare(b.titleRu));

    const tree = arrayToTree(arrayWithIcon, {
      childrenField: 'items',
      dataField: '',
    }) as ICategory[];

    dispatch({
      type: ServiceType.GET_CATEGORIES,
      categoriesTree: tree,
      categories: res,
    });
  };
