import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreateVariant,
  IDeleteVariant,
  IGetVariants,
  IUpdateVariant,
} from './types';

export const getVariants: IGetVariants = (attrs) =>
  instance.get(
    `/variants/admin?${qs.stringify(attrs, { skipEmptyString: true })}`
  );

export const createVariant: ICreateVariant = (data) =>
  instance.post(`/variants`, data);

export const updateVariant: IUpdateVariant = (id, data) =>
  instance.put(`/variants/${id}`, data);

export const deleteVariant: IDeleteVariant = (id) =>
  instance.delete(`/variants/${id}`);
