export enum ProfileType {
  GET_PROFILE = '@profile/GET_PROFILE',
  CLEAR_PROFILE = '@/profile/CLEAR_PROFILE',
}

export interface IGetProfile {
  type: ProfileType.GET_PROFILE;
}

export interface IClearProfile {
  type: ProfileType.CLEAR_PROFILE;
}

export type ProfileActionTypes = IGetProfile | IClearProfile;
