import axios from 'axios';
import store from 'store/store';
import * as authActions from 'store/ducks/auth/actions';
import showNotification from 'helpers/showNotification';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json charset=utf-8',
    } as any,
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.data.statusCode === 401 &&
      error.response.data.message === 'Unauthorized' || error.response.data.statusCode === 401 &&
      error.response.data.message === 'User not found'
    ) {
      store.dispatch(authActions.signOut());
      showNotification('Авторизуйтесь заново');
    }
    if (
      error.response.data.statusCode === 422 &&
      error.response.data.message !== 'Not enought'
    ) {
      showNotification(
        'Удаление невозможно, элемент используется другим компонентом'
      );
    }
    if (
      error.response.data.statusCode === 422 &&
      error.response.data.message === 'Not enought'
    ) {
      showNotification('Указанное количество превышает остаток');
    }
    if (error.response.data.statusCode === 409) {
      showNotification('Запись с таким названием уже существует');
    }
    if (
      error.response.data.statusCode === 403 &&
      error.response.data.message === 'Forbidden resource'
    ) {
      showNotification(
        'У вас нет прав для данного действия, обратитесь к администратору'
      );
    }
    return Promise.reject(error);
  }
);

export default instance;
