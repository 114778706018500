import { IBreadCrumb, TemplateType } from './types';

export const getBreadCrumbs = (items: IBreadCrumb[]) => {
  localStorage.setItem('crumbs', JSON.stringify(items));
  return {
    type: TemplateType.GET_BREADCRUBS,
    items,
  };
};

export const showModal = () => {
  return {
    type: TemplateType.SHOW_MODAL,
  };
};

export const hideModal = () => {
  return {
    type: TemplateType.HIDE_MODAL,
  };
};

export const haveModal = (haveModal: boolean) => {
  return {
    type: TemplateType.WITH_MODAL,
    haveModal,
  };
};

export const getHashes = (hashes: string[]) => {
  return {
    type: TemplateType.WITH_HASHES,
    hashes,
  };
};
