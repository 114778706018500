export enum WidgetTypes {
  CREATE_WIDGET = '@widgets/CREATE_WIDGET',
  GET_WIDGETS = '@widgets/GET_WIDGETS',
}

export enum WidgetType {
  ACTIVE_ORDERS = 'ACTIVE_ORDERS',
  ORDERS = 'ORDERS',
  REPORTS = 'REPORTS',
  POST_COMMENTS = 'POST_COMMENTS',
  PRODUCT_COMMENTS = 'PRODUCT_COMMENTS',
  REVIEWS = 'REVIEWS',
  COUPONS = 'COUPONS',
  PROMOCODES = 'PROMOCODES',
}

export interface IInitState {
  widgets: IWidget[];
}

export interface IWidget {
  label: string;
  value: WidgetType;
}
