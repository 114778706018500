import React, { useEffect, useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { MainInput } from 'components/MainInput/MainInput';
import { RadioInput } from 'components/RadioInput/RadioInput';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg';
import { InputType } from 'components/MainInput/types';
import { HtmlEditor, HtmlEditor2 } from 'components/Editor/HtmlEditor';
import { RadioType } from 'enums/radioType';
import { IArticle } from 'api/services/articles/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder-icon.svg';
import {
  ISearchableSelect,
  SearchableSelect,
} from 'components/SearchableSelect/SearchableSelect';
import { IResPostCategory } from 'api/services/postCategories/types';
import { postCategoryServices, productServices } from 'api/services';
import { ISelect } from 'components/MainSelect/MainSelect';
import { IResProduct } from 'api/services/products/types';
import { IResTag } from 'api/services/tags/types';
import { ActionButtons } from 'components/Modal/components/ActionButtons/ActionButtons';

import './UploadArticles.sass';
import { Tags } from 'components/Tags/Tags';
import showNotification from 'helpers/showNotification';
import { SearchableSelect2 } from 'components/SearchableSelect/Index';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import _ from 'lodash';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { FileList } from 'components/FileList/FileList';
import { IResFile } from 'api/services/files/types';
import { FileModal } from '../../FileModal/FileModal';

interface Props {
  submit?: (data: IArticle) => void;
  isTags: boolean;
  closeModal: () => void;
  loading: boolean;
}

export const UploadArticles: React.FC<Props> = ({
  submit,
  isTags,
  closeModal,
  loading,
}) => {
  const [redactorValueRu, setRedactorValueRu] = useState('');
  const [redactorValueUz, setRedactorValueUz] = useState('');
  const [textValueRu, setTextValueRu] = useState('');
  const [textValueUz, setTextValueUz] = useState('');
  const [descriptionType, setDescriptionType] = useState<RadioType>();
  const [isActive, setIsActive] = useState(false);
  const [onMainPage, setOnMainPage] = useState(true);
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [slug, setSlug] = useState('');
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [products, setProducts] = useState<IResProduct[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<IResProduct[]>([]);
  const [video, setVideo] = useState('');
  const [selectedTags, setSelectedTags] = useState<IResTag[]>([]);
  const [hasError, setHasError] = useState(false);
  const { key, lang } = useSelector((state: AppState) => state.localeReducer);
  const [searchablePostCategories, setSearchablePostCategories] = useState<
    ISearchableSelect[]
  >([]);
  const [searchableProducts, setSearchableProducts] = useState<
    ISearchableSelect[]
  >([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [postCategoriesLoading, setPostCategoriesLoading] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const [selectedPostCategoryId, setSelectedPostCategoryId] =
    useState<number>();
  const [postCategorySearchValue, setPostCategorySearchValue] = useState('');
  const [productSearchValue, setProductSearchValue] = useState('');
  const [showFileModal, setShowFileModal] = useState(false);
  const [articleFile, setArticleFile] = useState<IResFile>();

  useEffect(() => {
    setDescriptionType(RadioType.TEXT);
    getPostCategories();
    getProducts();
    return () => {
      setRedactorValueRu('');
      setRedactorValueUz('');
      setTextValueRu('');
      setTextValueUz('');
      setIsActive(false);
      setOnMainPage(true);
      setTitleRu('');
      setTitleUz('');
      setSlug('');
      setTagIds([]);
      setProducts([]);
      setSelectedProductId(undefined);
      setSelectedPostCategoryId(undefined);
      setVideo('');
      setSelectedTags([]);
      setHasError(false);
      setSearchablePostCategories([]);
      setSearchableProducts([]);
    };
  }, []);

  const selectTag = (tag: IResTag) => {
    if (!selectedTags.some((i) => i.id === tag.id)) {
      setSelectedTags((prev) => [...prev, tag]);
    }
  };

  const changeDescriptionType = (type: RadioType) => {
    setDescriptionType(type);
  };

  const getPostCategories = async (value?: string) => {
    if (value && value?.length < 3) {
      setPostCategoriesLoading(false);
      setSearchablePostCategories([]);
      return;
    }
    try {
      const res = await postCategoryServices
        .getPostCategories({
          skip: 0,
          take: 25,
          search: value && value.length < 3 ? '' : value,
        })
        .then((res) => res.data);
      const postCtg = res.data.map((i) => ({
        name: i.titleRu,
        value: i.id,
      }));
      setSearchablePostCategories(postCtg);
    } finally {
      setPostCategoriesLoading(false);
    }
  };

  const getProducts = async (value?: string) => {
    if (value && value?.length < 3) {
      setProductsLoading(false);
      setSearchableProducts([]);
      return;
    }

    try {
      const res = await productServices
        .getProducts({
          skip: 0,
          take: 20,
          search: value && value.length < 2 ? '' : value,
        })
        .then((res) => res.data);

      const products = res.data.map((i) => ({
        name: i.titleRu,
        value: i.id,
      }));
      setProducts(res.data);
      setSearchableProducts(products);
    } finally {
      setProductsLoading(false);
    }
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if (!submit) return;
    if (!titleRu.trim() && !titleUz.trim()) {
      setHasError(true);
      showNotification('Заполните название');
      return;
    }
    if (!redactorValueRu.trim() && !redactorValueUz.trim()) {
      showNotification('Заполните описание');
      setHasError(true);
      return;
    }
    if (!selectedPostCategoryId) {
      setHasError(true);
      showNotification('Заполните поля');
      return;
    }
    if (!articleFile) {
      setHasError(true);
      showNotification('Загрузите изображение');
      return;
    }
    submit({
      descriptionRu: redactorValueRu,
      descriptionUz: redactorValueUz,
      onMainPage,
      isActive,
      titleRu,
      titleUz,
      productIds: selectedProducts.map((i) => i.id),
      video,
      postCategoryId: selectedPostCategoryId,
      tagIds: selectedTags.map((i) => i.id),
      slug,
      fileId: articleFile.id,
    });
  };

  const removeSelectedTag = (id: number) => {
    setSelectedTags((prev) => {
      return prev.filter((i) => i.id !== id);
    });
  };

  const selectProducts = (id: number) => {
    const test = products?.find((i) => i.id === id);
    if (!test) return;
    if (!selectedProducts.some((i) => i.id === test.id)) {
      setSelectedProducts((prev) => [...prev, test]);
    }
  };
  const mainFileSelect = (file: IResFile) => {
    setArticleFile(file);
    setShowFileModal(false);
  };

  return (
    <>
      {showFileModal && (
        <FileModal
          closeModal={() => setShowFileModal(false)}
          fileSelected={mainFileSelect}
        />
      )}
      <form className='upload-articles' onSubmit={formSubmit}>
        {isTags ? (
          <Tags
            selectTags={selectTag}
            selectedTags={selectedTags}
            deleteItem={removeSelectedTag}
            withCreate
          />
        ) : (
          <div className='upload-articles__modal'>
            <div className='upload-articles__row'>
              <label className='upload-articles__row-item'>
                <div className='upload-articles__label'>Активность:</div>
                <Checkbox isChecked={isActive} setChecked={setIsActive} />
              </label>
            </div>
            <div className='upload-articles__row'>
              <label className='upload-articles__row-item'>
                <div className='upload-articles__label'>
                  Показывать на главной странице
                </div>
                <Checkbox isChecked={onMainPage} setChecked={setOnMainPage} />
              </label>
            </div>
            <div className='upload-articles__row'>
              <label className='upload-articles__row-item'>
                <div className='upload-articles__label'>Название</div>
                {lang === 'ru' ? (
                  <MainInput
                    error={hasError && !titleRu.trim()}
                    inputValue={titleRu}
                    updateInputValue={setTitleRu}
                  />
                ) : (
                  <MainInput
                    error={hasError && !titleUz.trim()}
                    inputValue={titleUz}
                    updateInputValue={setTitleUz}
                  />
                )}
              </label>
            </div>
            <div className='upload-articles__row'>
              <div className='upload-articles__row-item'>
                <div className='upload-articles__label'>Категория</div>
                <CustomSearchableSelect
                  items={searchablePostCategories}
                  loading={postCategoriesLoading}
                  setValue={getPostCategories}
                  setLoading={setPostCategoriesLoading}
                  setSelectItem={setSelectedPostCategoryId}
                  searchValue={postCategorySearchValue}
                  setSearchValue={setPostCategorySearchValue}
                  error={!selectedPostCategoryId && hasError}
                />
              </div>
            </div>
            <div className='upload-articles__row'>
              <label className='upload-articles__row-item'>
                <div className='upload-articles__label'>Ссылка</div>
                <MainInput inputValue={slug} updateInputValue={setSlug} />
              </label>
            </div>
            <div className='upload-articles__row'>
              <label className='upload-articles__row-item'>
                <div className='upload-articles__label'>Ссылка на видео</div>
                <MainInput inputValue={video} updateInputValue={setVideo} />
              </label>
            </div>
            <div className='upload-articles__row'>
              <div className='upload-articles__row-item'>
                <div className='upload-articles__label'>
                  Показать товар в статье
                </div>
                <CustomSearchableSelect
                  items={searchableProducts}
                  loading={productsLoading}
                  setValue={getProducts}
                  setLoading={setProductsLoading}
                  setSelectItem={selectProducts}
                  searchValue={productSearchValue}
                  setSearchValue={setProductSearchValue}
                  error={!selectedProductId && hasError}
                />
              </div>
            </div>
            <div className='upload-articles__row upload-articles__row--with-list'>
              <div className='upload-articles__row-list'>
                {selectedProducts.map((i) => (
                  <div className='upload-articles__row-list-item' key={i.id}>
                    <span>{i.titleRu}</span>
                    <div
                      className='upload-articles__row-list-item-close'
                      onClick={() =>
                        setSelectedProducts((prev) =>
                          prev.filter((ii) => ii.id !== i.id)
                        )
                      }
                    >
                      <CloseIcon />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='upload-articles-item upload-articles--uploader'>
              <div className='upload-articles__label--uploader'>
                Загрузить главное изображение:
                <span onClick={() => setShowFileModal(true)}>
                  Выбрать из файла <FolderIcon />
                </span>
              </div>
              <div className='upload-articles__uploader-files'>
                {!!articleFile ? (
                  <FileList
                    file={articleFile}
                    deleteFile={() => setArticleFile(undefined)}
                  />
                ) : (
                  <FileUploader
                    setFile={setArticleFile}
                    error={hasError && !articleFile}
                  />
                )}
              </div>
            </div>
            <div className='upload-articles__description'>
              <div className='upload-articles__textarea'>
                {lang === 'ru' ? (
                  <HtmlEditor
                    value={redactorValueRu}
                    error={hasError && !redactorValueRu.trim()}
                    onChange={setRedactorValueRu}
                  />
                ) : (
                  <HtmlEditor2
                    value={redactorValueUz}
                    error={hasError && !redactorValueUz.trim()}
                    onChange={setRedactorValueUz}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <ActionButtons closeModal={closeModal} formSubmit={formSubmit} />
      </form>
    </>
  );
};
