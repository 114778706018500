import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { Modal } from 'components/Modal/Modal';
import * as templateActions from 'store/ducks/template/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { Filter } from 'components/Filter/Filter';
import { deliveryServices } from 'api/services';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'store/ducks/notification/types';
// import { UpdateDelivery } from 'components/Modal/components/UpdateDelivery/UpdateDelivery';
import { ModalFilter } from 'components/Modal/components/ModalFilter/ModalFilter';
import { Settings } from 'components/Modal/components/Settings/Settings';
import * as settingActions from 'store/ducks/settings/actions';
import { ISetting } from 'components/Modal/components/Settings/types';
import { IDelivery, IResDelivery } from 'api/services/delivery/types';
import { CreateDelivery } from 'components/Modal/components/CreateDelivery/CreateDelivery';
import { UpdateDelivery } from 'components/Modal/components/UpdateDelivery/UpdateDelivery';
import { checkAmount } from 'helpers/checkAmount';

export const DeliveryPage = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [data, setData] = useState<IResDelivery[]>([]);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState<number>();
  const [id, setId] = useState<number>();
  const [filterShowModal, setFilterShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [loading, setLoading] = useState(false);

  const { deliverySettings } = useSelector(
    (state: AppState) => state.settingsReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      templateActions.getBreadCrumbs([
        { title: 'Каталог', id: '' },
        { title: 'Доставка', id: 'catalog/delivery' },
      ])
    );
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const searchVal = searchFilterValue || searchValue;
    const params = {
      search: searchVal,
    };
    const res = await deliveryServices
      .getDelivery(params)
      .then((res) => res.data);
    setData(res);
  };

  const closeFilter = () => {
    setSearchValue('');
    setSearchFilterValue('');
    setFilterShowModal(false);
  };

  const createDelivery = async (data: IDelivery) => {
    setLoading(true);
    try {
      await deliveryServices.createDelivery(data);
      setShowCreateModal(false);
      showNotification('Готово', NotificationStatus.SUCCESS);
      getData();
    } finally {
      setLoading(false);
    }
  };

  const updateDelivery = async (data: IDelivery) => {
    if (!id) return;
    setLoading(true);
    try {
      await deliveryServices.updateDelivery(id, data);
      getData();
      showNotification('Готово', NotificationStatus.SUCCESS);
      closeUpdateModal();
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const openupdateDelivery = (id: number) => {
    setId(id);
    setShowUpdateModal(true);
  };

  const closeUpdateModal = () => {
    setId(undefined);
    setShowUpdateModal(false);
  };

  const deleteItem = async (id: number) => {
    try {
      await deliveryServices.deleteDelivery(id);
      showNotification('Доставка удалена', NotificationStatus.SUCCESS);
      getData();
    } catch {
      showNotification('Ошибка попробуйте позже');
    }
  };

  const confirmSettings = (items: ISetting[]) => {
    dispatch(settingActions.setDeliverySettings(items));
    setShowSettings(false);
  };
  return (
    <>
      {showSettings && (
        <Modal closeModal={() => setShowSettings(false)}>
          <Settings
            listName='Доставка'
            items={deliverySettings}
            cancel={() => setShowSettings(false)}
            confirm={confirmSettings}
          />
        </Modal>
      )}
      {showCreateModal && (
        <Modal closeModal={() => setShowCreateModal(false)}>
          <CreateDelivery
            closeModal={() => setShowCreateModal(false)}
            submit={createDelivery}
            loading={loading}
          />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal closeModal={closeUpdateModal}>
          <UpdateDelivery
            closeModal={closeUpdateModal}
            submit={updateDelivery}
            loading={loading}
            id={id}
          />
        </Modal>
      )}
      {filterShowModal && (
        <Modal closeModal={closeFilter}>
          <ModalFilter
            searchValue={searchValue}
            updateSearchValue={setSearchValue}
            submit={getData}
            closeFilter={closeFilter}
          ></ModalFilter>
        </Modal>
      )}
      <div className='page delivery-page'>
        <Filter
          createFunc={() => setShowCreateModal(true)}
          searchValue={searchFilterValue}
          search={getData}
          settingFunc={() => setShowSettings(true)}
        />
        <div className='page__main-content'>
          <Table
            skipHandler={setPage}
            totalCount={Number(totalCount)}
            take={take}
            selectItem={openupdateDelivery}
            tableBody={{
              data: data.map((i) => ({
                ...i,
                freeLimit: checkAmount(i.freeLimit),
              })),
              keys: deliverySettings
                .filter((i) => i.isActive)
                .map((i) => i.value),
            }}
            tableHead={deliverySettings
              .filter((i) => i.isActive)
              .map((i) => i.label)}
            withPaginate
            deleteHandler={deleteItem}
          />
        </div>
      </div>
    </>
  );
};
